const { dpCrud,  CREATE, GET_ONE } = require('../../DataProviders/crudgeneric');

/**
 *
 * @param ledgerNumber
 * @param ledgerVersion
 * @returns {Promise<Response>}
 */
export async function getAntifraud(ledgerNumber, ledgerVersion) {
    try {
        const data = await dpCrud(GET_ONE, 'antifraud', {
            ledger: {
                ledgerNumber: ledgerNumber,
                ledgerVersion: ledgerVersion
            }
        });
        return data.data ? data.data : null;
    } catch (ex) {
        console.error('ERROR getAntifraud', ex);
    }
    return null;
}

/**
 *
 * @param data
 * @returns {Promise<AxiosPromise<any>>}
 */
export async function saveAntifraud(ledgerNumber, ledgerVersion, data) {
    const response = await dpCrud(CREATE, 'antifraud',
        {
            data: { ...data, ledgerNumber, ledgerVersion }
        }
    );
    console.info('ERROR saveAntifraud', response);
    return response;
}