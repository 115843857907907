import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
    colorSwitchBase: {
        color: 'rgb(99,34,34,0.8)',
        '&$colorChecked': {
            color: '#632222',
            '& + $colorBar': {
                backgroundColor: '#632222',
            },
        },
    },
    icon: { width: 20, height: 20 },
    colorBar: {},
    colorChecked: {},
    label: { fontSize: '1em', fontFamily: '"Source Sans Pro", sans-serif', fontWeight: 400, color: '#3f3e3e' },
    labelDisabled: { fontSize: '1em', fontFamily: '"Source Sans Pro", sans-serif', fontWeight: 400, color: '#b9b9b9' },
    hide: { display: "none" }
});

function ChaiSwitch(props) {
    const { classes, value, name, label, style, disabled, display } = props;
    const { onChange } = props;

    return (
        <FormControlLabel
            className={display === false ? classes.hide : null}
            style={style}
            control={
                <Switch
                    size={50}
                    checked={value}
                    onChange={onChange}
                    value={name}
                    disabled={disabled}
                    classes={{
                        switchBase: classes.colorSwitchBase,
                        checked: classes.colorChecked,
                        bar: classes.colorBar,
                        icon: classes.icon,
                    }}
                />
            }
            classes={{
                label: value ? classes.label : classes.labelDisabled
            }}
            label={label}
        />
    );
}

ChaiSwitch.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiSwitch);
