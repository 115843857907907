import React from 'react';
import PropTypes from 'prop-types';
import { Link, Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core';

import ChaiAuthenticatedRoutes from '../ChaiAuthenticatedRoutes';
import ChaiPublicRoutes from '../ChaiPublicRoutes';

import ChaiCountdown from '../ChaiCountdown';

const styles = theme => ({});

const ChaiContainer = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [redirectAfterLogin, setRedirectAfterLogin] = React.useState("/");

    const notifyLoadingChange = loading => setLoading(loading);

    return (
        <div style={props.logged ? { boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.49)' } : null}>
            <Switch>
                {
                    props.logged
                        ? <ChaiAuthenticatedRoutes
                            {...props}
                            disabled={loading}
                            notifyLoadingChange={notifyLoadingChange}
                            redirectAfterLogin={redirectAfterLogin}
                            setRedirectAfterLogin={setRedirectAfterLogin}
                        />
                        : <ChaiPublicRoutes
                            {...props}
                            redirectAfterLogin={redirectAfterLogin}
                            setRedirectAfterLogin={setRedirectAfterLogin}
                          />
                }
                <Route exact path='/countdown'
                    component={routeProp => <ChaiCountdown {...routeProp} />}
                />
                <Route component={() =>
                    <Link to={'/'}>Something went wrong please go back to homepage</Link>
                } />
            </Switch>
        </div>
    );
};

ChaiContainer.propTypes = { classes: PropTypes.object.isRequired };

export default withStyles(styles)(ChaiContainer);
