import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography
 } from '@material-ui/core'

const styles = theme => ({
});

function ChaiModal(props) {
    const { open, onClose, footerButtons, title, content, width = 'md' } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="select-wine-template-dialog-titles"
            fullWidth
            maxWidth={width}
        >
            <DialogTitle id="template-dialog-title">
                <Typography variant="h2" style={{ wordBreak: 'break-word' }}>
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent style={{ overflow: 'hidden' }}>
                { content }
            </DialogContent>
            <DialogActions style={{ marginRight: 25 }}>
                {footerButtons}
            </DialogActions>
        </Dialog>
    );
}

ChaiModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiModal);
