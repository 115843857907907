import externalLog from '../../Log';
class Logger {
    constructor(referer){
        this.referer = referer;
    }

    error(error) {
        console.error(`CHAI-BLOCKCHAIN ${this.referer} ERROR:`, error);
    }

    info(about, msg) {
        console.info(`CHAI-BLOCKCHAIN ${this.referer}:`, about, msg);
    }

    async errorExternal(about, error, type, ledger) {
        const referrerMsg = `CHAI-BLOCKCHAIN ${this.referer} ${about} ERROR:`;
        const payload = {
            transactionType: type,
            detail: `${referrerMsg}: ${JSON.stringify(error)}`
        }
        return await externalLog('Error', payload, ledger);
    }

    async infoExternal(about, msg, type, ledger) {
        const referrerMsg = `CHAI-BLOCKCHAIN ${this.referer} ${about}:`;
        const payload = {
            transactionType: type,
            detail: `${referrerMsg}: ${JSON.stringify(msg)}`
        }
        return await externalLog('Info', payload, ledger);
    }

    async warningExternal(about, msg, type, ledger) {
        const referrerMsg = `CHAI-BLOCKCHAIN ${this.referer} ${about}:`;
        const payload = {
            transactionType: type,
            detail: `${referrerMsg}: ${JSON.stringify(msg)}`
        }
        return await externalLog('Warning', payload, ledger);
    }
}

export default referer => new Logger(referer);
