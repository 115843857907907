const otherCapsuleOptions = [
    { id: 1, description: 'Badly Corroded' },
    { id: 2, description: 'Corroded' },
    { id: 3, description: 'Lightly Corroded' },
    { id: 4, description: 'Heavily Corroded' },
    { id: 5, description: 'Heavily Oxidized' },
    { id: 6, description: 'Oxidized' },
    { id: 7, description: 'Lightly Oxidized' },
    { id: 8, description: 'Nicked' },
    { id: 9, description: 'Lightly Nicked' },
    { id: 10, description: 'Torn' },
    { id: 11, description: 'Signs of Seepage' },
    { id: 12, description: 'Signs of Old Seepage' },
    { id: 13, description: 'Seepage due to Overfill' },
    { id: 14, description: 'Cracked' },
    { id: 15, description: 'Badly Cracked' },
    { id: 16, description: 'Cracked and Mostly Missing' },
    { id: 17, description: 'Corroded on Top' },
    { id: 18, description: 'Corroded at Base' },
    { id: 19, description: 'Lightly Scuffed' },
    { id: 20, description: 'Scuffed' },
    { id: 21, description: 'Wrinkled' },
    { id: 22, description: 'Badly Wrinkled' },
    { id: 23, description: 'Slightly Depressed' },
    { id: 24, description: 'Depressed' },
    { id: 25, description: 'Crowned' },
    { id: 26, description: 'Slightly Crowned' },
    { id: 27, description: 'Signs of Light Seepage' },
    { id: 28, description: 'Cut for Inspection' },
    { id: 29, description: 'Slightly Cracked' }
];

const fillLevelOptions = [
    { id: 1, description: 'Shouldered Bottle' },
    { id: 2, description: 'Burgundy or Hock Bottle' },
];

const shoulderedOptions = [
    { id: 1, description: 'In Neck' },
    { id: 2, description: 'Base Neck' },
    { id: 3, description: 'Very Top Shoulder' },
    { id: 4, description: 'Top Shoulder' },
    { id: 5, description: 'High Shoulder' },
    { id: 6, description: 'High to Mid-Shoulder' },
    { id: 7, description: 'Mid-Shoulder' },
    { id: 8, description: 'Low Shoulder' },
    { id: 9, description: 'Below Low Shoulder' },
];

const burgundyOptions = [
    { id: 1, description: 'less than 1cm' },
    { id: 2, description: '1cm' },
    { id: 3, description: '1.5cm' },
    { id: 4, description: '2cm' },
    { id: 5, description: '2.5cm' },
    { id: 6, description: '3cm' },
    { id: 7, description: '3.5cm' },
    { id: 8, description: '4cm' },
    { id: 16, description: '4.5cm' },
    { id: 9, description: '5cm' },
    { id: 10, description: '5.5cm' },
    { id: 11, description: '6cm' },
    { id: 12, description: '6.5cm' },
    { id: 13, description: '7cm' },
    { id: 14, description: '7.5cm' },
    { id: 15, description: 'below 7.5cm' }
];

const conditionOptions = [
    { id: 1, description: 'Excellent' },
    { id: 2, description: 'Very Good' },
    { id: 3, description: 'Good' },
    { id: 4, description: 'Average' },
    { id: 5, description: 'Below Average' },
    { id: 6, description: 'Poor' },
    { id: 7, description: 'Very Poor' },
    { id: 8, description: 'Missing' }
];

const corkConditionOptions = [
    { id: 1, description: 'Excellent' },
    { id: 2, description: 'Very Good' },
    { id: 3, description: 'Good' },
    { id: 4, description: 'Average' },
    { id: 5, description: 'Below Average' },
    { id: 6, description: 'Poor' },
    { id: 7, description: 'Very Poor' },
    { id: 8, description: 'Shrinking' },
    { id: 9, description: 'Failing' },
    { id: 10, description: 'NA - Obscured By Capsule' },
    { id: 11, description: 'NA' },

];

const sedimentOptions = [
    { id: 1, description: 'Ample' },
    { id: 2, description: 'Moderate' },
    { id: 3, description: 'Very Little' },
    { id: 4, description: 'None' },
    { id: 5, description: 'Fine' },
    { id: 6, description: 'Fine Suspended Matter' },
    { id: 7, description: 'Silty' },
    { id: 8, description: 'Slimy' },
    { id: 9, description: 'Chunky' },
    { id: 10, description: 'Flakey' },
    { id: 11, description: 'Coffee Ground-like' },
    { id: 12, description: 'Light Suspended Matter' },
    { id: 13, description: 'Caked to Glass' },
    { id: 14, description: 'KHT Crystals' },
    { id: 15, description: 'NA' },

];


// I don't know why this is working on dev, wo exporting this
module.exports = {
    otherCapsuleOptions,
    fillLevelOptions,
    shoulderedOptions,
    burgundyOptions,
    conditionOptions,
    corkConditionOptions,
    sedimentOptions
}
