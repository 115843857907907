import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ChaiTableToolbar from './chaiTableToolbar.js';
import ChaiTableHead from './chaiTableHead';
import ChaiCheck from '../ChaiCheck';
import ChaiTextField from '../ChaiTextField';
import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiImageInput from '../ChaiImageInput';

const styles = theme => ({
	root: {
		width: '100%',
		boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.49)'
	},
	tableWrapper: {
		overflowX: 'auto',
	},
	rootRow: {
		"&$hover:hover": {
			backgroundColor: '#ffecec',
		}
	},
	hover: {},
	cell: {
		fontFamily: '"Source Sans Code", sans-serif',
		fontSize: '1em',
		color: '#3f3e3e', padding: '20px'
	}
});

class ChaiTable extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			order: 'asc',
			orderBy: 'ledgerNumber',
			selected: [],
			page: 0,
			rowsPerPage: 25,
			filter:"",
			rowsPerPageOptions: [10, 25, 50, 100],
			...props
		};
	}

	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if ((this.props.data !== prevProps.data) || (this.props.enableCheckBox !== prevProps.enableCheckBox)) {
			this.setState({ data: this.props.data });
		}	
	}

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}

		const data =
			order === 'desc'
				? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
				: this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

		this.setState({ data, order, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeTextFilter = (event) => {
		let data = this.props.data
		data = data.filter(row => row.wineName.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1)
		this.setState({data,filter:event.target.value});
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};

	getLink = (link, label) => {
		if (link) {
			return (<a href={link} target="_blank" rel="noopener noreferrer">{label}</a>);
		}
		return "Not Available";
	}

	isSelected = id => this.state.selected.indexOf(id) !== -1;

	render() {
		const { classes, title, onClick, enableCheckBox, onCheck, enableDelete, 
				onDelete, enableTextBoxFilter } = this.props;
		const { columns, order, orderBy, rowsPerPage, page, data, rowsPerPageOptions } = this.state;
		const baseRowsPerPage = data.length ? 
			(data.length >= rowsPerPage) ? rowsPerPage : 
				(data.length < 5 ? ((5 - data.length) + data.length) : data.length) : 5;
		const emptyRows = baseRowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
		
		return (
			<Paper className={classes.root} elevation={0} >
				
				{title ?
					<ChaiTableToolbar title={title}/> : null}
				{ enableTextBoxFilter &&<ChaiTextField
					style={{ width: '100%', backgroundColor: "#efefef" }}
					placeholder={'Type any Wine Name...'}
					onChange={(e) => this.handleChangeTextFilter(e)}
					value={this.state.filter}
					containerProperties={{
						padding: 0,
						height: '100%'
					}}
				></ChaiTextField>}
				<div className={classes.tableWrapper}>
					<Table className={classes.table} aria-labelledby="tableTitle">
						<ChaiTableHead
							order={order}
							orderBy={orderBy}
							onRequestSort={this.handleRequestSort}
							rowCount={data.length}
							columns={columns}
							enableCheckBox={enableCheckBox}
							enableDelete={enableDelete}
						/>
						<TableBody>
							{data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i) => {
								const isSelected = this.isSelected(n.id);
								return (
									<TableRow
										key={i}
										hover
										role="checkbox"
										aria-checked={isSelected}
										tabIndex={-1}
										selected={isSelected}
										classes={{ root: classes.rootRow, hover: classes.hover }}
									>
										{enableCheckBox &&(
												<TableCell className={classes.cell} >
													<ChaiCheck
														onChange={(ev) => onCheck(ev,n)}
                                                        iconColor={null}
                                                        checked={n.checked}
													/>
												</TableCell>)
											}
										{columns.map((value) => {
											if (value.link) {
												return (
													<TableCell className={classes.cell} >
														{
															this.getLink(n[value.id], value.label)
														}
													</TableCell>)

											} else if (value.image) {
												return (
													<TableCell className={classes.cell} style={{display:"flex"}} >
														<ChaiImageInput
															fileUrl={n[value.id]}
															fileName="Brand Image "
															disabled={true}
															viewMode={true}
															/>
													</TableCell>)

											}  
											else {
												return (
													<TableCell className={classes.cell} 
														onClick={(e) => onClick ? 
															onClick(n) : 
															e.preventDefault()} >
														{n[value.id]}
													</TableCell>)
											}

										})}
										{enableDelete &&(
												<TableCell className={classes.cell} >
													<ChaiHeaderButton label="X" 
														onClick={(ev) => onDelete(ev,n)}/>
												</TableCell>)
											}
									</TableRow>
								);
							})}
							{emptyRows > 0 && (
								<TableRow style={{ height: 49 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</div>
				<TablePagination
					component="div"
					count={data.length}
					rowsPerPage={rowsPerPage}
					page={page}
					rowsPerPageOptions={rowsPerPageOptions}
					backIconButtonProps={{
						'aria-label': 'Previous Page',
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page',
					}}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
				/>
			</Paper>
		);
	}
}

ChaiTable.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiTable);
