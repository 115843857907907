import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChaiBodyContainer from '../ChaiBodyContainer';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';
import ChaiShareForm from './ChaiShareForm';

const styles = theme => ({
    tabsRoot: {
        backgroundColor: '#efefef'
    },
    tabsIndicator: {
        height: 0
    },
    tab: {
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '1.2em',
        fontWeight: 600
    },
    tabRoot: {
        boxShadow: 'inset 7px -8px 23px -2px rgba(0,0,0,0.47)',
        backgroundColor: '#3f3e3e',
        color: 'white',
        '&$tabSelected': {
            boxShadow: 'none',
            color: '#3f3e3e',
            backgroundColor: '#efefef'
        }
    },
    tabSelected: {},
    tabContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }
});

class ChaiShareAndSale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            loading: false
        };
    }

    handleTab = (event, tab) => {
        if (!this.state.loading){
            this.setState({ tab });
        }
    };

    handleLoading = (loading) => {
        this.setState({ loading: loading });
        this.props.notifyLoadingChange(loading);
    };

    isOwner = () => {
        let isOwner = false;
        let isAdmin = false;
        let isShared = false;
        if (this.props.currentLedger !== undefined) {
            const { creator, owner, allowedToEdit } = this.props.currentLedger;
            const user = JSON.parse(localStorage.getItem('user'));
            isOwner = (
                owner && (
                    owner.userId === user.id ||
                    (creator && owner.userId === '' && creator.userId === user.id)
                )
            );
            isAdmin = user.rol === 'Administrator';
            isShared = allowedToEdit && allowedToEdit.userId === user.id;
        }
        return isOwner || isAdmin || isShared;
    };

    render() {
        const { tab } = this.state;
        const { classes, theme, currentLedger, showNotification, handleSelectLedger } = this.props;

        if (currentLedger && !this.isOwner()) {
            showNotification('Unauthorized to edit Share & Sale screen');
            handleSelectLedger(currentLedger);
        }

        return (
            <Fragment>
                {this.state.loading && <LinearProgress />}
                <ChaiBodyContainer style={{padding: 0}}>
                    <AppBar position='static' style={{ boxShadow: 'none' }}>
                        <Tabs value={tab} onChange={this.handleTab} fullWidth classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
                            <Tab disabled={this.state.loading} classes={{ root: classes.tabRoot, label: classes.tab, selected: classes.tabSelected }} label='Share' />
                            <Tab disabled={this.state.loading} classes={{ root: classes.tabRoot, label: classes.tab, selected: classes.tabSelected }} label='Sale' />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={tab}
                        onChangeIndex={this.handleChangeIndex}
                    >
                        {
                            tab === 0 &&
                            <div dir={theme.direction}>
                                <ChaiShareForm {...this.props} ledgers={[this.props.currentLedger]}></ChaiShareForm>
                            </div>
                        }
                        {
                            tab === 1 &&
                            <div dir={theme.direction} className={classes.tabContainer}>

                            </div>
                        }
                    </SwipeableViews>
                </ChaiBodyContainer>
            </Fragment>
        );
    }
}

ChaiShareAndSale.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ChaiShareAndSale);
