const { dpCrud, GET_LIST } = require('../DataProviders/crudgeneric');

export const getFlag = async key => {
    try {
        const response = await dpCrud(GET_LIST, `system/${key}`);
        return (response.data.value === true);
    } catch(err) {
        console.error(`ERROR FOR FF ${key}`, err);
        return false;
    }
};
