import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import ActiveIcon from '@material-ui/icons/Check';
import InactiveIcon from '@material-ui/icons/Clear';
import BlockchainIcon from '@material-ui/icons/VpnLock';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import ChaiAccountDialog from './ChaiAccountDialog';
import ChaiAccountBlockchainDialog from './ChaiAccountBlockchainDialog';

import {
    updateAccount,
    getAllUsersFromDatabaseAndBlockchain,
    updateUserStatusBlockchain,
    createUserBlockchain
} from './service';

import Search from './ChaiSearch';
import { Tooltip } from '@material-ui/core';
import { systemRoles } from './options';

const styles = theme => ({
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
    title: { fontSize: 14 },
    card: { marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#efefef' },
    actions: { display: 'flex' },
    toolbar: { display: 'flex', padding: '30px', marginLeft: 20 },
    progress: { padding: '20px', display: 'flex', justifyContent: 'center' },
    certificate: { cursor: 'pointer' },
    active: { color: 'green', fontSize: 14, fontWeight: 'bold' },
    inactive: { color: 'red', fontSize: 14, fontWeight: 'bold' },
    activeBC: { color: 'green' },
    inactiveBC: { color: 'red' },
    nonExistentBC: { color: 'gray' },
    editCard: { width: '40%', margin: '10px' },
    search: { color: '#1e559e !important' },
    root: {
        width: '95%',
        boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.49)',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 40
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    rootRow: {
        backgroundColor: 'white', 
        "&$hover:hover": {
            backgroundColor: '#ffecec',
        }
    },
    hover: {},
    cell: {
        fontFamily: '"Source Sans Code", sans-serif',
        fontSize: '1em',
        color: '#3f3e3e', padding: '20px'
    },
    headerCell: {
        backgroundColor: 'rgb(99,34,34, 0.7)',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '1.1em',
        fontWeight: 600,
        textTransform: 'uppercase',
        padding: 15,
        color: 'white'
    },
    table: { padding: 20 }
});

class ChaiAccounts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accounts: [],
            accountsFiltered: [],
            page: 0,
            rowsPerPage: 5,
            loading: false,
            originalPicked: null,
            picked: null,
            open: false,
            saving: false,
            openAlert: false
        }
    }

    componentDidMount() {
        this.loadAccounts();
    }

    timer = null;

    async loadAccounts() {
        this.setState({ loading: true });

        try {
            let accounts = await getAllUsersFromDatabaseAndBlockchain();
            console.info(accounts);

            if (!accounts) {
                throw new Error('An error occurred while getting all Accounts');
            }

            this.setState({ accounts: accounts, accountsFiltered: accounts, loading: false });
        } catch (err) {
            this.setState({ loading: false });
            this.props.showNotification(err.message, { autoHideDuration: 10000 });
        }
    }

    handleAccountSelect = (account) => {
        this.setState({ picked: account, originalPicked: { ...account }, open: true });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleSearch = (event) => {
        let searchText = event.target.value;
        let filtered = this.state.accounts.filter(acc => {
            let text = acc.user.toLowerCase();

            return text.indexOf(searchText.toLowerCase()) > -1;
        });

        if (!filtered.length) {
            filtered = this.state.accounts.filter(acc => {
                let text = acc.email.toLowerCase();

                return text.indexOf(searchText.toLowerCase()) > -1;
            });
        }

        if (!filtered.length) {
            filtered = this.state.accounts.filter(acc => {
                let text = acc.rol.toLowerCase();

                return text.indexOf(searchText.toLowerCase()) > -1;
            });
        }

        this.setState({ accountsFiltered: filtered });
    };

    handleClose = () => {
        let allAccounts = this.state.accounts;
        let userIndex = -1;
        
        allAccounts[userIndex] = this.state.originalPicked;

        this.setState({ open: false, picked: null, originalPicked: null, accounts: allAccounts });
    };

    handleOpen = (account) => {
        this.setState({ openAlert: true, picked: account });
    };

    handleCloseAlert = () => {
        this.setState({ openAlert: false });
    };

    handleChange = (account, name, event) => {
        let allAccounts = this.state.accounts;
        let userIndex = -1;
        let userInEdition = allAccounts.find((acc, index) => {
            if (acc.address === account.address) {
                userIndex = index;
                return acc;
            }
            return null;
        });

        let finalValue = null;
        if (userInEdition) {
            if (name === 'rol') {
                let roleType = systemRoles.find(type => type.id === event.target.value);
                finalValue = roleType.name;
            } else if (name === 'user') {
                finalValue = event.target.value;
            } else { // default for checkboxes
                finalValue = event.target.value === 'false' ? true : false;
            }

            userInEdition[name] = finalValue;
            allAccounts[userIndex] = userInEdition;

            this.setState({ accounts: allAccounts });
        }
    };

    handleSave = async (account) => {
        try {
            this.setState({ saving: true });
            let saveResult = await updateAccount(account);

            if (!saveResult) {
                this.props.showNotification(saveResult.message);
                this.setState({ saving: false });
            } else {
                this.props.showNotification('User Account saved successfuly!');
                this.setState({ saving: false, open: false });
            }

        } catch (ex) {
            this.props.showNotification(ex.message);
            this.setState({ saving: false });
        }
    }

    handleUpdateUserStatusBlockchain = async () => {
        try {
            this.setState({ saving: true });
            let saveResult = await updateUserStatusBlockchain(this.state.picked);

            if (!saveResult) {
                this.props.showNotification(saveResult.message);
                this.setState({ saving: false });
            } else {
                this.props.showNotification('User status updated on the Blockchain successfuly! It may take a couple of minutes to reflect the change');
                this.setState({ saving: false, openAlert: false });
                this.loadAccounts();
            }
        } catch (ex) {
            console.error('Account View', ex.message);
            this.props.showNotification(ex.message);

            this.setState({ saving: false });
        }
    }

    handleCreateUserBlockchain = async () => {
        try {
            this.setState({ saving: true });
            let saveResult = await createUserBlockchain(this.state.picked);

            if (!saveResult) {
                this.props.showNotification(saveResult.message);
                this.setState({ saving: false });
            } else {
                this.props.showNotification('User created on the Blockchain successfuly! It may take a couple of minutes to reflect the change');
                this.setState({ saving: false, openAlert: false });
                this.loadAccounts();
            }
        } catch (ex) {
            console.error('Account View', ex.message);
            this.props.showNotification(ex.message);

            this.setState({ saving: false });
        }
    }

    render() {
        const { classes } = this.props;
        const { accounts, accountsFiltered, loading, page, rowsPerPage, picked, open, openAlert, saving } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, accounts.length - page * rowsPerPage);

        return (
            <Card className={classes.card}>
                <Toolbar className={classes.toolbar}>
                    <Search
                        loading={loading}
                        handleSearch={this.handleSearch}
                    />
                </Toolbar>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow
                            classes={{ root: classes.rootRow, hover: classes.hover }}>
                            <TableCell className={classes.headerCell}>User Name</TableCell>
                            <TableCell className={classes.headerCell}>Email</TableCell>
                            <TableCell className={classes.headerCell}>Role</TableCell>
                            <TableCell className={classes.headerCell}>Address</TableCell>
                            <TableCell className={classes.headerCell}>Active</TableCell>
                            <TableCell className={classes.headerCell}>Blockchain</TableCell>
                            <TableCell className={classes.headerCell}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {accountsFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((acc, index) => {
                            const activeIcon = <ActiveIcon style={styles.active} />;
                            const inactiveIcon = <InactiveIcon style={styles.inactive} />;
                            const blockchainIcon = (
                                <Tooltip title={acc.blockchain ?
                                    acc.blockchain[2] ? `User ACTIVE on the Blockchain` : `User INACTIVE on the Blockchain`
                                    : 'User does not exist on the Blockchain'}>
                                    <BlockchainIcon onClick={() => this.handleOpen(acc)}
                                        className={acc.blockchain ?
                                            acc.blockchain[0] && acc.blockchain[2] ? classes.activeBC : classes.inactiveBC : classes.nonExistentBC}
                                    />
                                </Tooltip>);

                            return (
                                <TableRow
                                    hover
                                    classes={{ root: classes.rootRow, hover: classes.hover }}
                                    key={index}
                                >
                                    <TableCell className={classes.cell}>{acc.user}</TableCell>
                                    <TableCell className={classes.cell}>{acc.email}</TableCell>
                                    <TableCell className={classes.cell}>{acc.rol}</TableCell>
                                    <TableCell className={classes.cell}>{acc.address}</TableCell>
                                    <TableCell className={classes.cell} style={{ textAlign: 'center' }}>{acc.active ? activeIcon : inactiveIcon}</TableCell>
                                    <TableCell className={classes.cell} style={{ textAlign: 'center' }}>
                                        {blockchainIcon}
                                    </TableCell>
                                    <TableCell className={classes.cell} style={{ textAlign: 'center' }}>
                                        <EditIcon onClick={() => this.handleAccountSelect(acc)} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 49 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={3}
                                count={accountsFiltered.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
                <ChaiAccountBlockchainDialog
                    openAlert={openAlert}
                    handleCloseAlert={this.handleCloseAlert}
                    account={picked}
                    saving={saving}
                    handleUpdateUserStatusBlockchain={this.handleUpdateUserStatusBlockchain}
                    handleCreateUserBlockchain={this.handleCreateUserBlockchain}
                />
                {
                    picked ?
                        <ChaiAccountDialog
                            open={open}
                            account={picked}
                            saving={saving}
                            handleClose={this.handleClose}
                            handleChange={this.handleChange}
                            handleSave={this.handleSave}
                        />
                        : null
                }
            </Card >
        )
    }
}

ChaiAccounts.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiAccounts);
