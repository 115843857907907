export default class ErrorHandler {
    constructor(notificator){
        this.notificator = notificator;
    }

    async catch(err){
        const message = await this.handle(err);
        this.notify(message);
    }

    async handle(err){
        Promise.reject('Implement this method');
    }

    notify(message){
        message && this.notificator(message);
    }
}