import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import ChaiTextField from '../ChaiTextField';
import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiProgress from '../ChaiProgress';
import { forgot } from './service';
import ChaiForgotLayout from './forgotLayout';

const styles = theme => ({});

class ChaiForgot extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null,
            loading: false,
            logged: false,
            open:false,
            message:"",
        };
    }

    showNotification = (message) => {
        this.setState({ open: true, message: message });

        setTimeout(() => {
            this.setState({ open: false });
        }, 7000);
    };

    handleChange = (e, field) => {
        const state = this.state;
        state[field] = e.target.value;

        this.setState({ state });

        if (e.key === 'Enter') {
            this.handleForgot();
        }
    };

    getField = (field) => {
        const value = this.state[field];

        const propsField = {
            value: value,
            onChange: (e) => this.handleChange(e, field),
            keyField: field
        };

        return propsField;
    };

    handleForgot = async () => {
        try {
            this.setState({ loading: true });

            const credentials = {
                email: this.state.email
            };

            const result = await forgot(credentials);
            if (result) {
                this.setState({ loading: false });
                window.location = "/";
            } else {
                this.setState({ loading: false });
            }

        } catch (error) {
            const message =  error.message ? error.message : "An error occurred on your request";
            this.showNotification(message);
            this.setState({ loading: false });
        }
    };

    render() {
        const { loading , message, open } = this.state;

        return (
            <ChaiForgotLayout message={message} open={open} >
                        <div style={{padding: 40}}>
                            <ChaiRowBodyContainer>
                                <ChaiTextField {...this.getField('email')} label="EMAIL" style={{ width: '100%' }}
                                />
                            </ChaiRowBodyContainer>
                            <ChaiRowBodyContainer>
                                {loading ?
                                    <ChaiProgress /> :
                                    <ChaiHeaderButton label="RESET PASSWORD" style={{marginLeft: 'auto', marginRight: 'auto'}} onClick={this.handleForgot} />}
                            </ChaiRowBodyContainer>
                        </div>
            </ChaiForgotLayout>
        );
    }
}

ChaiForgot.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChaiForgot);
