
const systemRoles = [
    { name: 'Master', id: 'master' },
    { name: 'Administrator', id: 'admin' },
    { name: 'TCM Authenticator', id: 'auth' },
    { name: 'Vendor Licensee', id: 'lic' },
    { name: 'Vendor - Selling Only', id: 'sell' },
    { name: '1er / Producer', id: 'prod' },
    { name: 'Collector', id: 'user' }
];

module.exports = {
    systemRoles
};