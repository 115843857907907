import { stringify } from 'query-string'
import {
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
    GET_MANY,
    GET_MANY_REFERENCE
} from 'react-admin'

require('dotenv').config();
const sha512 = require('js-sha512');

const API_URL = process.env.REACT_APP_CHAI_SECURITY_API_HOST || 'gateway/';

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
export default async (type, resource, params) => {
    if (!localStorage.getItem('token')) return Promise.reject('token not found!');

    let url = '';
    let options = {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-type': 'application/json'
        },
    };

    switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([
                    (page - 1) * perPage,
                    page * perPage - 1,
                ]),
                filter: JSON.stringify(params.filter),
            };
            url = `${API_URL}${resource}/list?${stringify(query)}`;
            break
        }
        case GET_ONE:
            url = `${API_URL}${resource}/${params.id}`;
            break;
        case CREATE:
            if (resource === 'register') {
                options = {
                    headers: new Headers({
                        Accept: 'application/json',
                        'Content-type': 'application/json'
                    }),
                }
            }
            url = `${API_URL}${resource}`;
            options.method = 'POST';
            params.data.password = sha512(params.data.password);
            options.body = JSON.stringify(params.data);
            break;
        case UPDATE:
            url = `${API_URL}${resource}/${params.id}`;
            options.method = 'PUT';
            options.body = JSON.stringify(params.data);
            break
        case UPDATE_MANY:
            let query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            url = `${API_URL}${resource}?${stringify(query)}`;
            options.method = 'PATCH';
            options.body = JSON.stringify(params.data);
            break;
        case DELETE:
            url = `${API_URL}${resource}/${params.id}`;
            options.method = 'DELETE';
            break;
        case DELETE_MANY:
            query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            url = `${API_URL}${resource}?${stringify(query)}`;
            options.method = 'DELETE';
            break;
        case GET_MANY: {
            query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            url = `${API_URL}${resource}?${stringify(query)}`;
            break
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([
                    (page - 1) * perPage,
                    page * perPage - 1,
                ]),
                filter: JSON.stringify({
                    ...params.filter,
                    [params.target]: params.id,
                }),
            };
            url = `${API_URL}${resource}?${stringify(query)}`;
            break
        }
        default:
            throw new Error(`Unsupported Data Provider request type ${type}`)
    }

    try {
        let response = await fetch(url, options);
        let result = await response.json();

        if (response.status !== 200) {
            console.log('response error', result);
            throw result.error;
        }

        const finalData = {};
        finalData.data = result;
        finalData.total = result.length;

        return finalData
    } catch (error) {
        console.log(error);
        return null;
    }
}
