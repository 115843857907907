const { dpCrud, UPDATE } = require('../../DataProviders/crudgeneric');

/**
 *
 * @param brands
 * @param capsuleBatch
 * @returns {Promise<Response>}
 */
export async function updateBrands(brands) {
    try {
        const data = await dpCrud(UPDATE, "brands", {data:brands});
        return data;
    } catch (ex) {
        console.error("Error updating the brands>> ", ex);
        throw ex;
    }
}