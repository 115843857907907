const isValid = require('../contentProcessors/config.processor').default.isValid;
const BadConfigurationException = require("../exceptions/badConfig.exception").default;
let config = {};
export default {
    getConfig: _ => config,
    setConfig: sentConfig => {
        if(isValid(sentConfig)){
            config = sentConfig;
        } else {
            throw new BadConfigurationException('Bad configuration provided. Review log for more info');
        }
    }
}
