const BadConfigurationError = require('../exceptions/badConfig.exception').default;
class BaseService {
    constructor(contractKey, config, blockchainHelper){
        if(!config.contracts){
            throw new BadConfigurationError("No contracts loaded for services")
        }
        if(!config.mainAccountAddress) {
            throw new BadConfigurationError(`No Main account specified for contract ${contractKey}`)
        }

        this.contract = blockchainHelper.getContract(contractKey);
        if(!this.contract){
            throw new BadConfigurationError(`No contract config added for ${contractKey}`);
        }
        this.mainAccount = config.mainAccountAddress;
    }
}

export default BaseService;
