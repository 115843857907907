const { dpCrud,  CREATE, GET_ONE } = require('../../DataProviders/crudgeneric');const { uploadImage } = require('../../DataProviders/crudgeneric');

/**
 *
 * @param ledgerNumber
 * @param ledgerVersion
 * @returns {Promise<Response>}
 */
export async function getLabel(ledgerNumber, ledgerVersion) {
    try {
        const data = await dpCrud(GET_ONE, "labels", {
            ledger: {
                ledgerNumber: ledgerNumber,
                ledgerVersion: ledgerVersion
            }
        });
        return data.data ? prepareToUI(data.data) : null;
    } catch (ex) {
        console.error("ERROR getLabel", ex);
    }
    return null;
}

/**
 *
 * @param data
 * @returns {Promise<AxiosPromise<any>>}
 */
export async function saveLabel(ledgerNumber, ledgerVersion, data) {
    const response = await dpCrud(CREATE, "labels/main",
        {
            data: { ...prepareToSend(data), ledgerNumber, ledgerVersion }
        }
    );
    console.info("saving response >> ", response);
    return response;
}

export const saveImage = async (image) => {
    const result = await uploadImage(image);
    return result;
}

let buildLocationRef = (loc, desc) => {
    return { "location": loc, "description": desc }
};

function prepareToUI(rawData) {
    let data = {
        ...rawData,

        waterMarkLocation: rawData.waterMarkLocationRef ? rawData.waterMarkLocationRef.location : "",
        waterMarkDescription: rawData.waterMarkLocationRef ? rawData.waterMarkLocationRef.description : "",

        stampsMarkingsLocation: rawData.stampsMarkingsLocationRef ? rawData.stampsMarkingsLocationRef.location : "",
        stampsMarkingsDescription: rawData.stampsMarkingsLocationRef ? rawData.stampsMarkingsLocationRef.description : "",

        labelEmbossingLocation: rawData.labelEmbossingLocationRef ? rawData.labelEmbossingLocationRef.location : "",
        labelEmbossingDescription: rawData.labelEmbossingLocationRef ? rawData.labelEmbossingLocationRef.description : "",

        otherLabelLocation: rawData.otherLabelLocationRef ? rawData.otherLabelLocationRef.location : "",
        otherLabelDescription: rawData.otherLabelLocationRef ? rawData.otherLabelLocationRef.description : ""
    };

    if(!data.labelPrintMethod){
        data.labelPrintMethod=[];
    }

    return data;
}

export function prepareToSend(values) {
    return {
        ...values,

        waterMarkLocationRef: values.waterMark
            ? buildLocationRef(values.waterMarkLocation, values.waterMarkDescription)
            : null,

        stampsMarkingsLocationRef: values.stampsMarkings
            ? buildLocationRef(values.stampsMarkingsLocation, values.stampsMarkingsDescription)
            : null,

        labelEmbossingLocationRef: values.labelEmbossing
            ? buildLocationRef(values.labelEmbossingLocation, values.labelEmbossingDescription)
            : null,

        otherLabelLocationRef: values.otherLabel
            ? buildLocationRef(values.otherLabelLocation, values.otherLabelDescription)
            : null,
    };
}
