import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChaiTextField from '../ChaiTextField';
import ChaiDropDown from '../ChaiDropDown';
import ChaiDropDownMultiple from '../ChaiDropDownMultiple';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBaseForm from '../ChaiBaseForm';
import ChaiFooterContainer from "../ChaiFooterContainer";
import ChaiSwitch from "../ChaiSwitch";
import ChaiImageInput from '../ChaiImageInput';
import { getBottleData, saveBottleData } from './service';
import formNames from '../ChaiBaseForm/formNames';
import ChaiBodyContainerHeader from '../ChaiBodyContainerHeader'

import LinearProgress from '@material-ui/core/LinearProgress';
import ChaiRadioButton from '../ChaiRadioButton';

const styles = theme => ({
    radio: { fontFamily: '"Source Sans Code", sans-serif', fontSize: '0.95em', color: '#3f3e3e' },
    radioContainer: { width: '32%', display: 'flex', padding: '20px', alignItems: 'center' }
});

class ChaiBottleData extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.BOTTLEDATA;
        let emptyDataState = {
            hasSerialNumber: false,
            serialNumber: null,
            serialNumberLocation: null,
            hasLotNumber: false,
            isBottleImageFront: false,
            fullBottleImage: '',
            lotNumber: null,
            lotNumberLocation: null,
            partOfGroup: false,
            caseLotID: null,
            labelConditionForAge: 1,
            notableNicksTearsConditions: false,
            notableNicksLocation: null,
            notableNicksDescription: null,
            notableNicksImage: null,
            otherLabelsConditions: false,
            otherLabelsConditionsDescription: null,
            capsuleCondition: 1,
            capsuleConditionDetails: [],
            capsuleOther: false,
            capsuleOtherDescription: null,
            corkCondition: 1,
            isVintageLegible: false,
            fillLevel: 0,
            shoulderedOption: 0,
            burgundyOption: 0,
            glassMarkings: false,
            glassMarkingsDescription: null,
            glassMarkingsImage: null,
            puntAirVent: true,
            sediments: []
        };

        super({ ...props, formName, emptyDataState });
        this.state = {
            ...this.state,
            softLoad: true,
        };

        const { addSetTemplateFunction } = props;
        addSetTemplateFunction(this.state.setTemplate);

    }

    getElement = async (ledger, version) => {
        await this.getOptionsWithFormName(formNames.BOTTLEDATA);
        return getBottleData(ledger.ledgerNumber, 0)
            .then(result => {
                return result ? result.data : this.emptyDataState
            });

    };

    saveElement = async (ledger, version, element) => {
        return saveBottleData(ledger.ledgerNumber, version, element);
    };



    handleFullBottleImage = event => {
        console.info('handleFullBottleImage', event.target.value);
        let data = { ...this.state.data };
        data.isBottleImageFront = event.target.value === 'front';
        console.info('handleFullBottleImage DATA', data);
        this.setState({ data });
    };

    render() {
        const formName = formNames.BOTTLEDATA;

        const { hideFooter, key } = this.props;

        const isTablet = window.innerWidth <= 1024;

        return (
            <Fragment>
                {this.state.loading && <LinearProgress />}
                <ChaiBodyContainer style={hideFooter && { paddingTop: '0' }}>
                    <ChaiBodyContainerHeader {...this.props}>
                    {
                        !hideFooter &&
                        <>
                            <ChaiRowBodyContainer>
                                <ChaiDropDown
                                    {...this.getField('fillLevel')}
                                    label="FILL LEVEL*" style={{ width: '25%' }}
                                    items={this.state.options[formName]['fillLevel'] || []}
                                />
                                {
                                    (this.state.data.fillLevel == 1) ? (
                                        <ChaiDropDown
                                            {...this.getField('shoulderedOption')}
                                            label="SHOULDERED BOTTLE OPTIONS*" style={{ width: '25%' }}
                                            items={this.state.options[formName]['shouldered'] || []}
                                        />
                                    ) :

                                        (this.state.data.fillLevel == 2) ? (
                                            <ChaiDropDown
                                                {...this.getField('burgundyOption')}
                                                label="BURGUNDY OR HOCK BOTTLE OPTIONS*" style={{ width: '25%' }}
                                                items={this.state.options[formName]['burgundy'] || []}
                                            />
                                        ) : <div style={{ width: '25%' }}></div>
                                }
                                <ChaiDropDown
                                    {...this.getField('labelConditionForAge')}
                                    label="LABEL CONDITIONS FOR AGE*" style={{ width: '25%' }}
                                    items={this.state.options[formName]['condition'] || []}
                                />
                                <ChaiDropDown
                                    {...this.getField('corkCondition')}
                                    label="CORK CONDITION*" style={{ width: '25%' }}
                                    items={this.state.options[formName]['corkCondition'] || []}
                                />
                            </ChaiRowBodyContainer>
                            <ChaiRowBodyContainer>
                                <ChaiDropDown
                                    {...this.getField('capsuleCondition')}
                                    label="CAPSULE CONDITION*" style={{ width: '25%' }}
                                    items={this.state.options[formName]['condition'] || []}
                                />
                                {
                                    (this.state.data.capsuleCondition !== 1 && this.state.data.capsuleCondition !== 8) ? ( // Added Missing option to exclussion
                                        <Fragment>
                                            <ChaiDropDownMultiple
                                                {...this.getMultipleDropDownField('capsuleConditionDetails')}
                                                label="DETAILS*"
                                                style={{ width: '50%' }}
                                                items={this.state.options[formName]['otherCapsule'] || []}
                                            />
                                        </Fragment>
                                    ) :
                                        <div style={{ width: '50%' }}></div>
                                }
                                <ChaiSwitch
                                    {...this.getField('isVintageLegible', true)}
                                    label='Vintage Legible' style={{ width: '25%' }}
                                />

                            </ChaiRowBodyContainer>
                            <ChaiRowBodyContainer>
                                <ChaiDropDownMultiple
                                    {...this.getMultipleDropDownField('sediments')}
                                    label="SEDIMENT - Select all that Apply*"
                                    style={{ width: '75%' }}
                                    items={this.state.options[formName]['sediment'] || []}
                                />
                            </ChaiRowBodyContainer>
                            <ChaiRowBodyContainer>
                                <ChaiRadioButton
                                    checked={this.state.data.isBottleImageFront}
                                    onChange={this.handleFullBottleImage}
                                    value="front"
                                    name="bottleImage"
                                    label="Use Front Image"
                                    aria-label="front"
                                    color='primary'
                                    disabled={this.state.loading}
                                    style={{ width: '15%' }}
                                />
                                <ChaiRadioButton
                                    checked={!this.state.data.isBottleImageFront}
                                    onChange={this.handleFullBottleImage}
                                    value="back"
                                    name="bottleImage"
                                    label="Use Back Image"
                                    aria-label="back"
                                    color='primary'
                                    disabled={this.state.loading}
                                    style={{ width: '15%' }}
                                />
                                <ChaiImageInput
                                    {...this.getFileField('fullBottleImageFront')}
                                    label={'Bottle Front*'}
                                    style={{ width: '15%' }}
                                />
                                <div style={{ width: '5%' }}></div>
                                <ChaiImageInput
                                    {...this.getFileField('fullBottleImageBack')}
                                    label={'Bottle Back*'}
                                    style={{ width: '15%' }}
                                />
                                <div style={{ width: '35%' }}></div>
                            </ChaiRowBodyContainer>
                            {isTablet ?
                                <Fragment>
                                    <ChaiRowBodyContainer>
                                        <ChaiSwitch
                                            label="Has Serial Number"
                                            {...this.getField('hasSerialNumber', true)}
                                            style={{ width: '25%' }}
                                        />
                                        {
                                            this.state.data.hasSerialNumber ? (
                                                <Fragment>
                                                    <ChaiTextField
                                                        {...this.getField('serialNumber')}
                                                        label="NUMBER"
                                                        style={{ width: '32%' }}
                                                    />
                                                    <ChaiTextField
                                                        {...this.getField('serialNumberLocation')}
                                                        label="LOCATION"
                                                        style={{ width: '32%' }}
                                                    />
                                                    <div style={{ width: '10%' }}></div>
                                                </Fragment>
                                            ) : null
                                        }
                                    </ChaiRowBodyContainer>
                                    <ChaiRowBodyContainer>
                                        <ChaiSwitch
                                            label="Has Lot Number"
                                            {...this.getField('hasLotNumber', true)}
                                            style={{ width: '25%' }}
                                        />
                                        {
                                            this.state.data.hasLotNumber ? (
                                                <Fragment>
                                                    <ChaiTextField
                                                        {...this.getField('lotNumber')}
                                                        label="NUMBER"
                                                        style={{ width: '32%' }}
                                                    />
                                                    <ChaiTextField
                                                        {...this.getField('lotNumberLocation')}
                                                        label="LOCATION"
                                                        style={{ width: '32%' }}
                                                    />
                                                    <div style={{ width: '10%' }}></div>
                                                </Fragment>
                                            ) : null
                                        }
                                    </ChaiRowBodyContainer>
                                </Fragment>
                                :
                                <ChaiRowBodyContainer>
                                    <ChaiSwitch
                                        label="Has Serial Number"
                                        {...this.getField('hasSerialNumber', true)}
                                        style={{ width: '10%' }}
                                    />
                                    {
                                        this.state.data.hasSerialNumber ? (
                                            <Fragment>
                                                <ChaiTextField
                                                    {...this.getField('serialNumber')}
                                                    label="NUMBER"
                                                    style={{ width: '20%' }}
                                                />
                                                <ChaiTextField
                                                    {...this.getField('serialNumberLocation')}
                                                    label="LOCATION"
                                                    style={{ width: '20%' }}
                                                />
                                            </Fragment>
                                        ) :
                                            <div style={{ width: '40%' }}></div>
                                    }
                                    <ChaiSwitch
                                        label="Has Lot Number"
                                        {...this.getField('hasLotNumber', true)}
                                        style={{ width: '10%' }}
                                    />
                                    {
                                        this.state.data.hasLotNumber ? (
                                            <Fragment>
                                                <ChaiTextField
                                                    {...this.getField('lotNumber')}
                                                    label="NUMBER"
                                                    style={{ width: '20%' }}
                                                />
                                                <ChaiTextField
                                                    {...this.getField('lotNumberLocation')}
                                                    label="LOCATION"
                                                    style={{ width: '20%' }}
                                                />
                                            </Fragment>
                                        )
                                            :
                                            <div style={{ width: '40%' }}></div>
                                    }
                                </ChaiRowBodyContainer>
                            }
                            <ChaiRowBodyContainer>
                                <ChaiSwitch
                                    label="Notable Nicks / Tears / Conditions"
                                    {...this.getField('notableNicksTearsConditions', true)}
                                    style={{ width: '25%' }}
                                />
                                {
                                    this.state.data.notableNicksTearsConditions && (
                                        <Fragment>
                                            <ChaiTextField
                                                {...this.getField('notableNicksLocation')}
                                                label="NOTABLE NICKS LOCATION" style={{ width: '25%' }}
                                            />
                                            <ChaiTextField
                                                {...this.getField('notableNicksDescription')}
                                                label="NOTABLE NICKS DESCRIPTION" style={{ width: '25%' }}
                                            />
                                            <ChaiImageInput
                                                {...this.getFileField('notableNicksImage')}
                                                style={{ width: '10%' }}
                                            />
                                        </Fragment>
                                    )
                                }
                            </ChaiRowBodyContainer>
                            <ChaiRowBodyContainer>
                                <ChaiSwitch
                                    label="Other Labels Conditions"
                                    {...this.getField('otherLabelsConditions', true)}
                                    style={{ width: '25%' }}
                                />
                                {
                                    this.state.data.otherLabelsConditions && (
                                        <Fragment>
                                            <ChaiTextField
                                                {...this.getField('otherLabelsConditionsDescription')}
                                                label="DESCRIPTION" style={{ width: '75%' }}
                                            />
                                        </Fragment>
                                    )
                                }
                            </ChaiRowBodyContainer>
                            <ChaiRowBodyContainer>
                                <ChaiSwitch
                                    label="Capsule Condition Other"
                                    {...this.getField('capsuleOther', true)}
                                    style={{ width: '25%' }}
                                />
                                {
                                    this.state.data.capsuleOther && (
                                        <Fragment>
                                            <ChaiTextField
                                                {...this.getField('capsuleOtherDescription')}
                                                label="CAPSULE OTHER DESCRIPTION" style={{ width: '75%' }}
                                            />
                                        </Fragment>
                                    )
                                }
                            </ChaiRowBodyContainer>
                        </>
                    }
                    <ChaiRowBodyContainer>
                        <ChaiSwitch
                            label="Glass Markings/Embossing*"
                            {...this.getField('glassMarkings', true)}
                            style={{ width: '25%' }}
                        />
                        {
                            this.state.data.glassMarkings && (
                                <Fragment>
                                    <ChaiTextField
                                        {...this.getField('glassMarkingsDescription')}
                                        label="DESCRIPTION*" style={{ width: '65%' }}
                                    />
                                    <ChaiImageInput
                                        {...this.getFileField('glassMarkingsImage')}
                                        style={{ width: '10%' }}
                                    />
                                </Fragment>
                            )
                        }
                    </ChaiRowBodyContainer>
                    <ChaiRowBodyContainer>
                        <ChaiSwitch
                            label="Punt Air Vent"
                            {...this.getField('puntAirVent', true)}
                            style={{ width: '25%' }}
                        />
                    </ChaiRowBodyContainer>
                    {
                        !hideFooter &&
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                label="Part of a Group, Case (Or a Lot/parcel for Sales)"
                                {...this.getField('partOfGroup', true)}
                                style={{ width: '25%' }}
                            />
                            {
                                this.state.data.partOfGroup && (
                                    <Fragment>
                                        <ChaiTextField
                                            {...this.getField('caseLotID')}
                                            label="CASE / LOT ID"
                                            style={{ width: '75%' }}
                                        />
                                    </Fragment>
                                )
                            }
                        </ChaiRowBodyContainer>
                    }
                    </ChaiBodyContainerHeader>
                </ChaiBodyContainer>
            </Fragment>
        );
    }
}

ChaiBottleData.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiBottleData);
