import { INSPECTION_LOCATION, INSPECTION_WITNESSES, INSPECTION_COMPANY } from '../../constants';
const blockchain = require('../../Services/blockchain');
const sha512 = require('js-sha512');
const blockchainHelper = require('../../Blockchain/helpers/blockchain.helper').default();
const BASE_URL = process.env.REACT_APP_CHAI_SECURITY_API_HOST || 'gateway/';

export const login = async (params) => {
    const { email, password } = params;
    const url = `${BASE_URL}auth/login`;

    const data = { email: email, password: sha512(password) };
    const options = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data)
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }

        localStorage.setItem('user', JSON.stringify(result));
        localStorage.setItem('token', result.token);

        return result;
    } catch (error) {
        console.error('ERROR login()', error);
        throw error && error.content ? error.content.message : 'An error occurred while Login the user';
    }
};

export const register = async (params) => {
    try {
        const blockchainAccount = await blockchainHelper.createNewAccount();

        if (blockchainAccount) {
            const url = `${BASE_URL}register`;
            const { name, email, password, authenticatorCode } = params;
            const data = {
                name: name,
                email: email,
                password: sha512(password),
                rol: 'Collector',
                address: blockchainAccount.address,
                authenticatorCode: authenticatorCode
            };
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data)
            };
            const response = await fetch(url, options);
            const result = await response.json();

            if (response.status !== 200) {
                throw result;
            }

            return { user: result, blockchain: blockchainAccount };
        } else {
            throw 'An error occurred while generating Blockchain Account';
        }
    } catch (error) {
        console.error('ERROR register()', error);
        throw error.error ? error.error : error;
    }
};

export const recover = async (params) => {
    try {
        const data = { password: sha512(params.password),
            token: params.token
        };
        
        const url = `${BASE_URL}recoverypassword`;
        const options = {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(data)
        };
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }

        return { user: result,};
        
    } catch (error) {
        console.error('ERROR recover pasword()', error);
        throw error.error ? error.error : error;
    }
};

export const verifyRecoveryToken = async (token) => {
    try {
        const url = `${BASE_URL}recoverypassword/verify/${token}`;
        const options = {
            method: 'GET'
        };
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }

        return result;
    } catch (error) {
        console.error('ERROR verifyRecoveryToken()', error);
        throw error.error ? error.error : error;
    }
}

export const forgot = async (params) => {
    try {
        const url = `${BASE_URL}forgotpassword`;
        const options = {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(params)
        };
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }

        return { user: result,};
    
} catch (error) {
    console.error('ERROR forgot pasword()', error);
    throw error.error ? error.error : error;
}
};

export const setFileDownloaded = async (email) => {
    const url = `${BASE_URL}setFileDownloaded`;

    const data = { email: email};
    const options = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify(data)
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }
        return result;
    } catch (error) {
        console.error('ERROR SetFileDownloaded()', error);
        throw error && error.content ? error.content.message : 'An error occurred while saving information of the User';
    }
};

export const logOut = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('ledgerSearch');
    localStorage.removeItem(INSPECTION_LOCATION);
    localStorage.removeItem(INSPECTION_WITNESSES);
    localStorage.removeItem(INSPECTION_COMPANY);
    const expired = localStorage.getItem('expired');

    if(!expired) {
        window.location.href = '/';
    }
};

export const isLoggedIn = () => {
    return (localStorage.getItem("user") !== null && localStorage.getItem("token") !== null)
}

export const isAdminPrivileges = () => {
    if(isLoggedIn()){
        try{
            const user = JSON.parse(localStorage.getItem("user"))
            return user.rol === 'Administrator' || user.rol === 'TCM Authenticator'
        } catch (ex) {
            return false;
        }
    }
    return false;
}

