 import loggerHelper from "../helpers/logger.helper"
 const logger = loggerHelper("ConfigProcessor");
 
 const isValid = config => {
    if(!config){
        logger.error('Configuration is required for chai-blockchain');
        throw false;
    }
    if(!config.contracts || !Array.isArray(config.contracts)){
        logger.error('Invalid contract config. Must be array');
        return false;
    }
    if(!config.mainAccountAddress || typeof(config.mainAccountAddress) !== 'string'){
        logger.error('Invalid mainAccountAddress config. Must be a string');
        return false;
    }
    /* Overriding for local path
    if(!config.contractsFolderPath || typeof(config.contractsFolderPath) !== 'string'){
        logger.error('Invalid contractsFolderPath config. Must be a string');
        return false;
    }
    */
    if(!config.blockchainUrl || typeof(config.blockchainUrl) !== 'string'){
        logger.error('Invalid blockchainUrl config. Must be a string');
        return false;
    }
    return true;
}

export default {
    isValid: isValid
}