import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        boxShadow: 'none'
    },
    avatar: {
        backgroundColor: 'blue',
        fontWeight: '900',
        fontSize: '2em'
    },
    title: {
        fontFamily: '"Source Sans Code", sans-serif',
        fontSize: '1.2em',
        color: '#3e3e3e',
        textTransform: 'uppercase'
    },
    subheader: {
        fontFamily: '"Source Sans Code", sans-serif',
        fontSize: '1em',
        color: '#b9b9b9',
        textTransform: 'uppercase'
    },
    keysTitle: {
        color: '#EB7E02'
    }
});

const ChaiAccountDetail = props => {
    const { classes, account  } = props;

    return (
        <Card className={classes.root}>
            <CardHeader
                title="Account Information"
                subheader="Credentials"
                avatar={
                    <Avatar src="/keyInfoIcon.png"></Avatar>
                }
                classes={{ title: classes.title, subheader: classes.subheader }}
            />
            <CardContent>
                <Typography paragraph style={{ wordBreak: 'break-word' }}>
                    <strong className={classes.keysTitle}>Address</strong><br /> {account.address}
                </Typography>
                <Typography paragraph style={{ wordBreak: 'break-word' }}>
                    <strong className={classes.keysTitle}>Private Key</strong><br /> {account.privateKey}
                </Typography>
            </CardContent>
        </Card>
    )
};

ChaiAccountDetail.propTypes = {
    classes: PropTypes.object,
    accInfo: PropTypes.object.isRequired,
    downloadFile: PropTypes.func.isRequired
};

export default withStyles(styles)(ChaiAccountDetail);
