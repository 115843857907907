import React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import { StepButton, StepLabel } from '@material-ui/core';
import StepperIcon from '@material-ui/icons/Adjust';
import NotCompletedIcon from '@material-ui/icons/FiberManualRecord';
import ArrowIcon from '@material-ui/icons/ArrowDropUp';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

const isTablet = window.innerWidth <= 1024;
const styles = theme => ({
    root: {
        width: '100%',
    },
    rootFixed: {
        width: '97%',
        position: 'fixed',
        top: 0,
        zIndex: 3,
    },
    button: {
        marginRight: theme.spacing.unit,
    },
    backButton: {
        marginRight: theme.spacing.unit,
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    alternativeLabelOK: {
        color: 'white !important',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: isTablet ? '1.1em' : '1.2em'
    },
    alternativeLabel: {
        color: '#b9b9b9 !important',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: isTablet ? '1.1em' : '1.2em'
    },
    labelContainer: {
        height: '2em',
        "& $alternativeLabelOK": {
            marginTop: 0,
            fontWeight: 600
        },
        "& $alternativeLabel": {
            marginTop: 0
        }
    },
    arrowIcon: { color: '#efefef', height: '2em', width: '2em', position: "absolute", left: '50%', bottom: '50%', marginBottom: '-2em', marginLeft: '-1em' }
});

const ChaiStepper = (props) => {
    const { classes, steps, activeStep, disabled } = props;
    const { handleMenuNavigation, isStepComplete } = props;

    const [rootStyles, setRootStyles] = React.useState(classes.root)

    const applyStyles = (value) => {
        setRootStyles(value)
    }
    
    const listenScrollEvent = e => {
        if (window.scrollY > 200) {
            applyStyles(classes.rootFixed)
        } else {
            applyStyles(classes.root)
        }
    }


    const useEventListener = (target, type, listener, ...options) => {
        React.useEffect(
            () => {
                target.addEventListener(type, listener, ...options);
                return () => {
                    target.removeEventListener(type, listener, ...options);
                };
            },
            [target, type, listener, options]
        );
    };

    useEventListener(window, 'scroll', listenScrollEvent);
    return (
        activeStep > 0 &&
        <div className={rootStyles}>
            <Stepper
                style={{ backgroundColor: 'rgb(99,34,34)', width: 'auto' }}
                alternativeLabel
                nonLinear
                connector={false}
                activeStep={activeStep}>
                {
                    steps && steps.length &&
                    steps.filter(f => !f.hideInStepper).map((step, index) => {
                        const stepProps = {};
                        const i = index + 1;
                        const icon = step.completed || activeStep == i ?
                            <StepperIcon style={{ color: 'white' }} /> :
                            step.valid === true ? <CheckIcon /> :
                            step.valid === false ? <CloseIcon /> :
                            <NotCompletedIcon style={{ color: '#b9b9b9' }} />

                        return (
                            <Step key={i} {...stepProps} >
                                <StepButton
                                    style={{
                                        fontWeight: activeStep == i
                                            ? 700
                                            : 400,
                                        backgroundColor: 'rgb(99,34,34)'
                                    }}
                                    icon={icon}

                                    onClick={() => handleMenuNavigation(i)}
                                    completed={() => isStepComplete(i)}
                                    disabled={disabled}
                                >
                                    <StepLabel
                                        classes={{
                                            alternativeLabel: step.completed || activeStep === i
                                                ? classes.alternativeLabelOK
                                                : classes.alternativeLabel,
                                            labelContainer: classes.labelContainer
                                        }}
                                    >
                                        {step.mandatory ? `*${step.menuTitle}` : step.menuTitle}
                                    </StepLabel>
                                </StepButton>
                                {
                                    activeStep === i &&
                                    <div style={{ position: 'relative' }}>
                                        <ArrowIcon className={classes.arrowIcon} />
                                    </div>
                                }
                            </Step>
                        );
                    })
                }
            </Stepper>
        </div>
    );
};

ChaiStepper.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiStepper);
