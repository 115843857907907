import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '1em',
        color: '#3f3e3e'
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        backgroundColor: 'white',
        padding: theme.spacing.unit * 1.2,
        border: '1px solid #6d6c6c'
    },
    label: { paddingLeft: 8, fontFamily: '"Source Sans Pro", sans-serif', fontSize: '1.05rem', color: '#3f3e3e', fontWeight: 600, textTransform: 'uppercase' },
    menuItem: { fontFamily: '"Source Sans Pro", sans-serif', fontSize: '1em' },
    chips: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    chip: {
        fontFamily: '"Source Sans Pro", sans-serif', fontSize: '1em', color: '#3f3e3e',
        backgroundColor: '#d49292',
        margin: 2
    },
});

const ITEM_HEIGHT = '2em';
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 1.2
        },
    },
};

const ChaiDropDownMultiple = (props) => {
    const { classes, value, label, items, style, disabled } = props;
    const { onChange, onDelete } = props;

    return (
        <div style={{ ...style }}>
            <div style={{ display: 'grid', alignContent: 'baseline' }} >
                <label className={classes.label} >
                    {label}
                </label>
                <FormControl className={classes.formControl}>
                    <Select
                        disabled={disabled}
                        multiple
                        disableUnderline={true}
                        value={value}
                        onChange={onChange}
                        inputProps={{
                            classes: {
                                root: classes.root
                            }
                        }}
                        renderValue={selected => (<div className={classes.chips}>
                            {selected.map(value => {
                                
                                const chipValue = items.find(x => x.id === value);
                                return chipValue ? (
                                    <Chip
                                        key={value}
                                        label={chipValue.description}
                                        className={classes.chip}
                                        onDelete={disabled ? null : () => onDelete(value)} />
                                ) : console.error("ChaiMultipleDropDown Error: Item Not Found On Avalaible Options ")
                            })}
                        </div>)
                        }
                        MenuProps={MenuProps}
                    >
                        {
                            items && items.length && items.map(i => (
                                <MenuItem className={classes.menuItem} key={i.id} value={i.id}>
                                    {i.description}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>
        </div>
    );
}

ChaiDropDownMultiple.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiDropDownMultiple);
