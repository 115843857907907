import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableLabel from '@material-ui/core/TableSortLabel';

import Tooltip from '@material-ui/core/Tooltip';
import ChaiCheck from '../ChaiCheck'
const isTablet = window.innerWidth <= 1024;
const styles = theme => ({
    cell: {
        backgroundColor: 'rgb(99,34,34, 0.7)',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: isTablet ? '1em' : '1.1em',
        fontWeight: 600,
        textTransform: 'uppercase',
        padding: 0,
        color: 'white'
    },
    sizeIcon: {
        fontSize: 25,
        color: '#dce0e496',
		padding: 1
	},
	sizeIconActive: {
        fontSize: 25,
        color: '#6D0006',
		padding: 1
    },
    check: {
        color: '#6D0006',
        '&$checked': {
            color: '#6D0006',
        },
        padding: "0px",
        backgroundColor: "rgba(198, 198, 212, 0.22)",
        borderRadius: "0px",
        marginLeft: "1.5em",
        height:'0.7em',
        width:"0.7em"
    },
    checked: {}
});

class ChaiTableHead extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            checkAll:false
        }
    }
    componentDidUpdate(prevProps){
        if(prevProps.checkAllInitialValue !== this.props.checkAllInitialValue ){
            this.setState({checkAll: this.props.checkAllInitialValue});
        }
    }

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    handleCheckAll = (ev) => {
        this.setState({checkAll:ev.target.checked});
        this.props.checkAllOnPage(ev);
    }

    render() {
        const { classes, order, orderBy, columns, enableCheckBox } = this.props;
        const { checkAll } = this.state;
        return (
            <TableHead>
                <TableRow>
                {enableCheckBox&&(
                        <TableCell className={classes.cell}>
                            
                                <ChaiCheck
                                    onChange={(ev) => this.handleCheckAll(ev)}
                                    checked={checkAll}
                                    className={classes.check}
                                    iconColor={checkAll ? classes.sizeIconActive : classes.sizeIcon}
                                />
													
                                <TableSortLabel
                                        style={{color: 'white',
                                        fontWeight: 600}}
                                    >
                                </TableSortLabel>
                        </TableCell>)}
                    {columns && columns.length && columns.map(column => {
                        return column.sorteable ? (
                            <TableCell
                                key={column.id}
                                sortDirection={orderBy === column.id ? order : false}
                                className={classes.cell}
                                style={{cursor: "pointer",
                                marginRight:'10px'}}
                                
                            >
                                <Tooltip
                                    title="Sort"
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={order}
                                        onClick={this.createSortHandler(column.id)}
                                        style={{color: 'white',
                                        fontWeight: 600,
                                        }}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            ) : (
                            <TableCell
                                key={column.id}
                                className={classes.cell}
                            >
                                <TableLabel
                                    active={orderBy === column.id}
                                    direction={order}
                                    style={{color: 'white',
                                    fontWeight: 600, cursor: "auto"}}
                                >
                                    {column.label}
                                </TableLabel>
                            </TableCell>
                            );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

ChaiTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default withStyles(styles)(ChaiTableHead);
