import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChaiTextField from '../ChaiTextField';

import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBaseForm from "../ChaiBaseForm";
import ChaiFooterContainer from "../ChaiFooterContainer";
import ChaiBodyContainerHeader from '../ChaiBodyContainerHeader'
import { getMeasurements, saveMeasurements } from "./service.js";
import LinearProgress from '@material-ui/core/LinearProgress';
import formNames from '../ChaiBaseForm/formNames';


const styles = theme => ({

});

class ChaiMeasurements extends ChaiBaseForm {

    constructor(props) {
        let formName = formNames.MEASUREMENTS;
        let emptyDataState = {
            'labelHeight': "",
            'labelWidth': "",

            'vintageHeight': 'N/A',
            'vintageWidth': 'N/A',

            'capsuleLength': "",
            'corkLength': 'N/A',
            'bottleHeight': "",
            'lipHeight': "",
            'puntDiameter': ""
        };

        super({ ...props, formName, emptyDataState, loadTemplate: true });

        this.state = {
            ...this.state,
            softLoad: true,
        };

        const { addSetTemplateFunction } = props;
        addSetTemplateFunction(this.state.setTemplate);
    }

    getElement = async (ledger, version) => {

        return getMeasurements(ledger.ledgerNumber, 0)
            .then(result => {

                if (result) {
                    if (!result.vintageHeight) {
                        result.vintageHeight = 'N/A';
                    }
                    if (!result.vintageWidth) {
                        result.vintageWidth = 'N/A';
                    }
                    if (!result.corkLength) {
                        result.corkLength = 'N/A';
                    }
                    return result;
                }
                return this.emptyDataState;
            });
    };

    saveElement = async (ledger, version, element) => {
        return saveMeasurements(ledger.ledgerNumber, 0, element);
    };

    render() {
        return (
            <Fragment>
                {this.state.loading && <LinearProgress />}
                <ChaiBodyContainer>
                    <ChaiBodyContainerHeader {...this.props}>
                        <ChaiRowBodyContainer>
                            <ChaiTextField {...this.getField('bottleHeight')} type={'number'} label="Bottle Height (cm)*" {...this.getTemplateFieldStyle('bottleHeight', { width: '25%' })}
                            />
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiTextField {...this.getField('labelHeight')} type={'number'} label="Label Measurements Height (cm)*" {...this.getTemplateFieldStyle('labelHeight', { width: '25%' })}
                            />
                            <ChaiTextField {...this.getField('labelWidth')} type={'number'} label="Label Measurements Width (cm)*" {...this.getTemplateFieldStyle('labelWidth', { width: '25%' })}
                            />
                            <ChaiTextField {...this.getField('vintageHeight')} label="Vintage Tag Height Measurements (cm)" {...this.getTemplateFieldStyle('vintageHeight', { width: '25%' })}
                            />
                            <ChaiTextField {...this.getField('vintageWidth')} label="Vintage Tag Width (cm)" {...this.getTemplateFieldStyle('vintageWidth', { width: '25%' })}
                            />
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiTextField {...this.getField('lipHeight')} type={'number'} label="Lip Height/Shape (cm)" {...this.getTemplateFieldStyle('lipHeight', { width: '25%' })}
                            />
                            <ChaiTextField {...this.getField('capsuleLength')} type={'number'} label="Capsule Length (mm)" {...this.getTemplateFieldStyle('capsuleLength', { width: '25%' })}
                            />
                            <ChaiTextField {...this.getField('corkLength')} label="Cork Length (mm)*" {...this.getTemplateFieldStyle('corkLength', { width: '25%' })}
                            />
                            <ChaiTextField {...this.getField('puntDiameter')} type={'number'} label="Punt Diameter (cm)*" {...this.getTemplateFieldStyle('puntDiameter', { width: '25%' })}
                            />
                        </ChaiRowBodyContainer>
                    </ChaiBodyContainerHeader>
                </ChaiBodyContainer>
            </Fragment>
        );
    }
}

ChaiMeasurements.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiMeasurements);
