const { dpCrud,  CREATE, GET_ONE } = require('../../DataProviders/crudgeneric');
const { provenanceTypesOptions, currencyTypesOptions } = require('./options');
const BASE_URL = process.env.REACT_APP_CHAI_SECURITY_API_HOST || 'gateway/';
const SEARCH_USERS_LIMIT = 10;

/**
 *
 * @param ledgerNumber
 * @param ledgerVersion
 * @returns {Promise<Response>}
 */
export async function getProvenance(ledgerNumber, ledgerVersion) {
    try {
        const data = await dpCrud(GET_ONE, "provenances", {
            ledger: {
                ledgerNumber: ledgerNumber,
                ledgerVersion: ledgerVersion
            }
        });
        return data.data ? prepareToUI(data.data) : null;
    } catch (ex) {
        console.error("ERROR getProvenance", ex);
    }
    return null;
}

/**
 *
 * @param userID
 * @returns {Promise<AxiosPromise<any>>}
 */
export async function GetUserById(userID) {

    const token = localStorage.getItem('token');
    const url = `${BASE_URL}provenances/user/${userID}`;

    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
        method: 'GET'
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }

        return result;
    } catch (error) {
        console.error('ERROR GetUserById()', error);
        throw error.error ? error.error : error;
    }
}

/**
 *
 * @param filter
 * @param limit
 * @returns {Promise<AxiosPromise<any>>}
 */
export async function searchUsers(filter, limit = SEARCH_USERS_LIMIT) {
    const token = localStorage.getItem('token');
    const url = `${BASE_URL}provenances/users/${filter}/${limit}`;
    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
        method: 'GET'
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }

        return result;
    } catch (error) {
        console.error('ERROR searchUsers()', error);
        throw error.error ? error.error : error;
    }
}

/**
 *
 * @param data
 * @returns {Promise<AxiosPromise<any>>}
 */
export async function saveProvenance(ledgerNumber, ledgerVersion, data) {
    const response = await dpCrud(CREATE, "provenances",
        {
            data: { ...prepareToSend(data), ledgerNumber, ledgerVersion }
        }
    );
    console.info("ERROR saveProvenance", response);
    return response;
}

function prepareToUI(rawData) {
    let provenance = {...rawData};
    
    provenance.provenanceDetails = rawData.provenanceDetails.map((p, index) => {
        const detail = {...p};
        
        detail.localID = index;
        detail.ownerInfo = p.owner.info;
        detail.ownerName = p.owner.name;
        detail.ownerAddress = p.owner.address;
        detail.ownerEmail = p.owner.email;
        detail.ownerId = p.owner.id;
        detail.type = provenanceTypesOptions.find(x => x.id === parseInt(p.type)).description;
        detail.currency =  p.currency ? currencyTypesOptions.find(x => x.id === p.currency).description : "";

        return detail;
    });

    return {
        provenanceDetails: provenance.provenanceDetails,
        updateOwnership: provenance.updateOwnership
    }
}

function prepareToSend(rawData) {
    let provenance = {...rawData};
    provenance.provenanceDetails = rawData.provenanceDetails.map(p => {
        const detail = {...p};
        
        delete detail.actions;
        delete detail.localID;
        detail.proofOfPurchaseImage= p.proofOfPurchaseImage;
        detail.owner = { name: p.ownerName, address: p.ownerAddress ,email: p.ownerEmail ,info: p.ownerInfo, id: p.ownerId }; 
        detail.type = provenanceTypesOptions.find(x => x.description === p.type).id;
        detail.currency = p.currency ? currencyTypesOptions.find(x => x.description === p.currency).id : null;

        return detail;
    });

    return { ...provenance};
}

/**
 *
 * @param ledgerNumber
 * @param ledgerVersion
 * @returns {Promise<Response>}
 */
export async function deleteFutureOwnershipTransfer(ledgerNumber, ledgerVersion = 0) {
    try {
        const token = localStorage.getItem('token');
        const url = `${BASE_URL}provenances/${ledgerNumber}/versions/${ledgerVersion}/deleteFutureOwnershipTransfer`;
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-type': 'application/json'
            },
            method: 'GET'
        };

        return await fetch(url, options);
    } catch (ex) {
        console.error("ERROR deleteFutureOwnershipTransfer", ex);
    }
    return null;
}

export const getProvenancesForCsvExport = async () => {
    const url = `${BASE_URL}provenances/getProvenancesForCsvExport`;
    const options = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }, method: 'GET'
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }
        return result;
    } catch (error) {
        console.error('ERROR getProvenancesForCsvExport()', error);
        throw error.error ? error.error : error;
    }
};
