const logger = require("../helpers/logger.helper").default("WineLocationAuthenticatorProcessor")
const isNumber = require("../helpers/number.helper").default.isNumber;

const prepareForBlockchain = (content, ledger, userAddr) => {
    /**
     * From solidity contract:
     *
        uint ledgerNumber,
        uint ledgerVersion,
        string wineLocationOne,
        string wineLocationTwo,
        string wineLocationThree,
        string wineLocationFour
     * */
    return {
        ledgerNumber: ledger.ledgerNumber,
        ledgerVersion: ledger.ledgerVersion,
        wineLocationOne: content.wineLocationOne || 'n/a',
        wineLocationTwo: content.wineLocationTwo || 'n/a',
        wineLocationThree: content.wineLocationThree || 'n/a',
        wineLocationFour: content.wineLocationFour || 'n/a'
    };
};

const isValidContent = (ledger, content) => {
    if(!content){
        logger.error(`isValidContent Empty content`);
        return false;
    }

    if(!isNumber(ledger.ledgerNumber) || !isNumber(ledger.ledgerVersion)){
        logger.error(`isValidContent Invalid ledger Data`);
        return false;
    }
    return true;
}

export default {
    isValidContent: isValidContent,
    prepareForBlockchain: prepareForBlockchain
}