import AdminService from '../services/admin.service';
import BottleInfoAndUniqueService from '../services/bottle-info-unique.service';
import ProvenanceAuctionAndPrivateService from '../services/provenance-auction-private.service';
import ProvenanceRetailService from '../services/provenance-retail.service';
import LedgerIdsService from '../services/ledger-ids.service';
import AuthenticatorService from '../services/authenticator.service';
import WineLocationService from '../services/wineLocation.service';
import SecurityService from '../facades/security.facade';

class LedgerFacade {
    
    constructor(
        _adminService = new AdminService(),
        _bottleInfoUniqueService = new BottleInfoAndUniqueService(),
        _ledgerIdsService = new LedgerIdsService(),
        _provenanceAuctionAndPrivateService = new ProvenanceAuctionAndPrivateService(),
        _provenanceRetailService = new ProvenanceRetailService(),
        _authenticatorService = new AuthenticatorService(),
        _wineService = new WineLocationService(),
        _securityService = new SecurityService()
    ){
        this.adminService = _adminService;
        this.bottleInfoAndUniqueService = _bottleInfoUniqueService;
        this.ledgerIdsService = _ledgerIdsService;
        this.provenanceAuctionAndPrivateService = _provenanceAuctionAndPrivateService;
        this.provenanceRetailService = _provenanceRetailService;
        this.authenticatorService = _authenticatorService;
        this.wineService = _wineService;
        this.securityService = _securityService;
    }

    async createLedgerAdmin(content, ledger, wallet){
        try {
            return await this.adminService.create(content, ledger, wallet);
        } catch(ex){
            throw ex;
        }
    }

    async updateLedgerAdmin(content, ledger, wallet){
        try {
            return await this.adminService.update(content, ledger, wallet);
        } catch(ex){
            throw ex;
        }
    }

    async getLedgerAdmin(ledger, userAddr){
        try {
            return await this.adminService.get(ledger, userAddr);
        } catch(ex){
            throw ex;
        }
    }

    async createBottleInfoAndUnique(content, ledger, wallet){
        try {
            
            return await this.bottleInfoAndUniqueService.create(content, ledger, wallet);
        } catch(ex){
            throw ex;
        }
    }

    async updateBottleInfoAndUnique(content, ledger, wallet){
        try {
            return await this.bottleInfoAndUniqueService.update(content, ledger, wallet);
        } catch(ex){
            throw ex;
        }
    }

    async getBottleInfoAndUnique(ledger, userAddr){
        try {
            return await this.bottleInfoAndUniqueService.get(ledger, userAddr);
        } catch(ex){
            throw ex;
        }
    }

   /*  async createLedgerID(content, ledger, wallet){
        try {
            const wallet = walletProvider.getUserWallet(wallet);
            const canRequest = await this.securityService.canRequest(wallet.address);
            if(!canRequest) {
                logger.info('Create Ledger ID', `User has no balance for this transaction`);
                return false;
            }
            return await this.ledgerIdsService.create(content, ledger, wallet);
        } catch(ex){
            throw ex;
        }
    } */

    async getProvenanceAuctionAndPrivate(ledgerNumber, dateOfSale, userAddr){
        try {
            return await this.provenanceAuctionAndPrivateService.get(ledgerNumber, dateOfSale, userAddr);
        } catch(ex){
            throw ex;
        }
    }

    async createProvenanceAuctionAndPrivate(content, ledger, wallet){
        try {
            return await this.provenanceAuctionAndPrivateService.create(content, ledger, wallet);
        } catch(ex){
            throw ex;
        }
    }

    async getProvenanceRetail(ledgerNumber, dateOfSale, userAddr){
        try {
            return await this.provenanceRetailService.get(ledgerNumber, dateOfSale, userAddr);
        } catch(ex){
            throw ex;
        }
    }

    async createProvenanceRetail(content, ledger, wallet){
        try {
            return await this.provenanceRetailService.create(content, ledger, wallet);
        } catch(ex){
            throw ex;
        }
    }

    async getAuthenticator(ledger, userAddr){
        try {
            return await this.authenticatorService.get(ledger, userAddr);
        } catch(ex){
            throw ex;
        }
    }

    async createAuthenticator(content, ledger, wallet){
        try {
            return await this.authenticatorService.create(content, ledger, wallet);
        } catch(ex){
            throw ex;
        }
    }

    async getWineLocation(ledger, userAddr){
        try {
            return await this.wineService.get(ledger, userAddr);
        } catch(ex){
            throw ex;
        }
    }

    async createWineLocation(content, ledger, wallet){
        try {
            return await this.wineService.create(content, ledger, wallet);
        } catch(ex){
            throw ex;
        }
    }
}

export default LedgerFacade;
