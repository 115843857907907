import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChaiTextField from '../ChaiTextField';

import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBaseForm from "../ChaiBaseForm";
import ChaiFooterContainer from "../ChaiFooterContainer";
import ChaiSwitch from "../ChaiSwitch";
import ChaiBodyContainerHeader from '../ChaiBodyContainerHeader'
import { getOtherLabel, prepareToSend, saveOtherLabel } from './service';
import LinearProgress from '@material-ui/core/LinearProgress';
import formNames from '../ChaiBaseForm/formNames';


const styles = theme => ({

});

class ChaiOtherLabel extends ChaiBaseForm {

    constructor(props) {
        let formName = formNames.OTHERLABEL;
        let emptyDataState = {
            producerBackLabel: false,
            healthWarningLabel: false,
            vintageNeckLabel: false,

            importLabel: false,
            importLabelDescription: '',

            otherBackLabel: false,
            otherBackLabelDescription: '',

            otherNeckLabel: false,
            otherNeckLabelDescription: '',

            otherLabel1: false,
            otherLabel1Description: '',

            otherLabel2: false,
            otherLabel2Description: '',

            retailMerchantOrAuction1: false,
            retailMerchantOrAuction1Description: '',

            retailMerchantOrAuction2: false,
            retailMerchantOrAuction2Description: ''
        };

        super({ ...props, formName, emptyDataState, loadTemplate: true });

        this.state = {
            ...this.state,
            softLoad: true,
            prepareToSendFunc: prepareToSend,
        };

        const { addSetTemplateFunction } = props;
        addSetTemplateFunction(this.state.setTemplate);
    }

    getElement = async (ledger, version) => {
        return getOtherLabel(ledger.ledgerNumber, version).then(result => {
            return result ? result : this.emptyDataState
        });
    };

    saveElement = async (ledger, version, element) => {
        return saveOtherLabel(ledger.ledgerNumber, version, element);
    };

    render() {
        const { hideFooter } = this.props;
        return (
            <Fragment>
                {this.state.loading && <LinearProgress />}
                <ChaiBodyContainer>
                    <ChaiBodyContainerHeader {...this.props}>
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                label="Producer Back Label"
                                {...this.getField('producerBackLabel', true)}
                                {...this.getTemplateFieldStyle('producerBackLabel', { width: "25%" })}
                            />
                            <ChaiSwitch
                                label="Health Warning Label"
                                {...this.getField('healthWarningLabel', true)}
                                {...this.getTemplateFieldStyle('healthWarningLabel', { width: "25%" })}
                            />
                            <div style={{ width: '5%' }}></div>
                            <ChaiSwitch
                                label="Vintage Neck Label"
                                {...this.getField('vintageNeckLabel', true)}
                                {...this.getTemplateFieldStyle('vintageNeckLabel', { width: "25%" })}
                            />
                            <div style={{ width: '20%' }}></div>
                        </ChaiRowBodyContainer>
                        {
                            !hideFooter &&
                            <ChaiRowBodyContainer>
                                <ChaiSwitch
                                    label="Import Label"
                                    {...this.getField('importLabel', true)}
                                    {...this.getTemplateFieldStyle('importLabel', { width: "25%" })}
                                />
                                {
                                    this.state.data.importLabel ? (
                                        <ChaiTextField
                                            label="Description"
                                            {...this.getField('importLabelDescription')}
                                            {...this.getTemplateFieldStyle('importLabelDescription', { width: "25%" })}
                                        />
                                    ) : <div style={{ width: '25%' }}></div>
                                }
                                <div style={{ width: '5%' }}></div>
                                <ChaiSwitch
                                    label="Other Back Label"
                                    {...this.getField('otherBackLabel', true)}
                                    {...this.getTemplateFieldStyle('otherBackLabel', { width: "20%" })}
                                />
                                {
                                    this.state.data.otherBackLabel ? (
                                        <ChaiTextField
                                            label="Description"
                                            {...this.getField('otherBackLabelDescription')}
                                            {...this.getTemplateFieldStyle('otherBackLabelDescription', { width: "25%" })}
                                        />
                                    ) : <div style={{ width: '25%' }}></div>
                                }
                            </ChaiRowBodyContainer>
                        }
                        {
                            !hideFooter &&
                            <ChaiRowBodyContainer>
                                <ChaiSwitch
                                    label="Other Neck Label"
                                    {...this.getField('otherNeckLabel', true)}
                                    {...this.getTemplateFieldStyle('otherNeckLabel', { width: "25%" })}
                                />
                                {
                                    this.state.data.otherNeckLabel ? (
                                        <ChaiTextField
                                            label="Description"
                                            {...this.getField('otherNeckLabelDescription')}
                                            {...this.getTemplateFieldStyle('otherNeckLabelDescription', { width: "25%" })}
                                        />
                                    ) : <div style={{ width: '25%' }}></div>
                                }
                                <div style={{ width: '5%' }}></div>
                                <ChaiSwitch
                                    label="Other Label 1"
                                    {...this.getField('otherLabel1', true)}
                                    {...this.getTemplateFieldStyle('otherLabel1', { width: "20%" })}
                                />
                                {
                                    this.state.data.otherLabel1 ? (
                                        <ChaiTextField
                                            label="Description"
                                            {...this.getField('otherLabel1Description')}
                                            {...this.getTemplateFieldStyle('otherLabel1Description', { width: "25%" })}
                                        />
                                    ) : <div style={{ width: '25%' }}></div>
                                }
                            </ChaiRowBodyContainer>
                        }
                        {
                            !hideFooter &&
                            <ChaiRowBodyContainer>
                                <ChaiSwitch
                                    label="Other Label 2"
                                    {...this.getField('otherLabel2', true)}
                                    {...this.getTemplateFieldStyle('otherLabel2', { width: "25%" })}
                                />
                                {
                                    this.state.data.otherLabel2 ? (
                                        <ChaiTextField
                                            label="Description"
                                            {...this.getField('otherLabel2Description')}
                                            {...this.getTemplateFieldStyle('otherLabel2Description', { width: "25%" })}
                                        />
                                    ) : <div style={{ width: '25%' }}></div>
                                }
                                <div style={{ width: '5%' }}></div>
                                <ChaiSwitch
                                    label="Retail Merchant Or Auction 1"
                                    {...this.getField('retailMerchantOrAuction1', true)}
                                    {...this.getTemplateFieldStyle('retailMerchantOrAuction1', { width: "20%" })}
                                />
                                {
                                    this.state.data.retailMerchantOrAuction1 ? (
                                        <ChaiTextField
                                            label="Description"
                                            {...this.getField('retailMerchantOrAuction1Description')}
                                            {...this.getTemplateFieldStyle('retailMerchantOrAuction1Description', { width: "25%" })}
                                        />
                                    ) : <div style={{ width: '25%' }}></div>
                                }
                            </ChaiRowBodyContainer>
                        }
                        {
                            !hideFooter &&
                            <ChaiRowBodyContainer>
                                <ChaiSwitch
                                    label="Retail Merchant Or Auction 2"
                                    {...this.getField('retailMerchantOrAuction2', true)}
                                    {...this.getTemplateFieldStyle('retailMerchantOrAuction2', { width: "25%" })}
                                />
                                {
                                    this.state.data.retailMerchantOrAuction2 && (
                                        <ChaiTextField
                                            label="Description"
                                            {...this.getField('retailMerchantOrAuction2Description')}
                                            {...this.getTemplateFieldStyle('retailMerchantOrAuction2Description', { width: "25%" })}
                                        />
                                    )
                                }
                                <div style={{ width: '50%' }}></div>
                            </ChaiRowBodyContainer>
                        }
                    </ChaiBodyContainerHeader>
                </ChaiBodyContainer>
            </Fragment>
        );
    }
}

ChaiOtherLabel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiOtherLabel);
