import loggerHelper from '../helpers/logger.helper';
import UserService from '../services/user.service';
import walletProvider from '../providers/wallet.provider';
const logger = loggerHelper("UserFacade");

class UserFacade {

    constructor(_userService = new UserService()) {
        this.userService = _userService;
    }

    async addUserToSecurityBlockchain(user) {
        try {
            const receipt = await this.userService.createUser(user);
            logger.info('Add User To Security Blockchain', receipt);
            return receipt;
        } catch (ex) {
            throw ex;
        }
    }

    async handleActiveUserOnBlockchain(active, userAddress) {
        try {
            let receipt = null;
            if (active) {
                receipt = await this.userService.reactivateUser(userAddress);
            } else {
                receipt = await this.userService.deactivateUser(userAddress);
            }

            logger.info('Active User On Blockchain', receipt);
            return receipt;
        } catch (ex) {
            throw ex;
        }
    }

    async updateUserToSecurityBlockchain(user) {
        try {
            const receipt = await this.userService.updateUser(user);
            logger.info('Update User To Security Blockchain', receipt);

            return receipt;
        } catch (ex) {
            throw ex;
        }
    }

    async getUserFromSecurityBlockchain(userAddress) {
        try {
            const receipt = await this.userService.getUser(userAddress);
            logger.info('Get User To Security Blockchain', receipt);

            return receipt;
        } catch (ex) {
            throw ex;
        }
    }

    async getUserAddresses() {
        try {
            let receipt = await this.userService.getAddresses();
            logger.info('Get User Addresses', receipt);
            
            return receipt;
        } catch (ex) {
            throw ex;
        }
    }

    async getUserAddressFromPk(privateKey){
        try {
            let wallet = walletProvider.getUserWallet(privateKey);
            return wallet.address;
        } catch(ex){
            logger.error(ex);
            throw new Error("Unable to decode private key. Please verify your private key and try again");
        }
    }
}

export default UserFacade;