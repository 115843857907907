const logger = require('../helpers/logger.helper').default('LedgerBottleInfoAndUniqueService');
const BaseService = require("./base.service").default;
const config = require('../providers/config.provider').default.getConfig();
const blockchainHelper = require('../helpers/blockchain.helper').default;
const processor = require("../contentProcessors/bottleInfoUnique.processor").default;
const ContentValidationError = require("../exceptions/contentValidation.exception").default;
const ContractMethodError = require("../exceptions/contractMethod.exception").default;
const provideGas = require("../providers/wallet.provider").default.provideGas;

class LedgerBottleInfoAndUniqueService extends BaseService {
    async create(content, ledger, wallet) {
        if (processor.isValidContent(ledger, content)) {
            try {
                const overrides = {
                    gasLimit: 3000000
                };
                const gasProvided = await provideGas(wallet);
                if (gasProvided) {
                    const createData = processor.prepareForBlockchain(content, ledger, wallet.address);
                    // const estimate = await this.contract.estimate.createLedgerAdmin(...Object.values(createData), overrides);
                    const gasProvided = await provideGas(wallet);

                    if (gasProvided) {
                        let contractWithSigner = this.contract.connect(wallet);
                        const receiptTx = await contractWithSigner.createBottleInfoAndUnique(...Object.values(createData), overrides);
                        logger.info('BOTTLE INFO AND UNIQUE CONTRACT', createData);

                        const receipt = await receiptTx.wait();
                        await logger.infoExternal('createBottleInfoAndUnique', receipt, 'send', ledger);

                        /* const readData = await this.get(ledger);
                        logger.info('BOTTLE INFO AND UNIQUE CONTRACT READ', readData); */

                        return receipt;
                    }
                }
            } catch (ex) {
                await logger.errorExternal('createBottleInfoAndUnique', ex.message, 'send', ledger);
                throw new ContractMethodError('Create Bottle And Unique Ledger Data', ex);
            }
        } else {
            throw new ContentValidationError('Ledger Bottle And Unique Data is invalid');
        }
    }

    async update(content, ledger, wallet) {
        if (processor.isValidContent(ledger, content)) {
            try {
                const overrides = {
                    gasLimit: 3000000
                };
                const updateData = processor.prepareForBlockchain(content, ledger, wallet.address);
                let contractWithSigner = this.contract.connect(wallet);
                const receiptTx = await contractWithSigner.updateBottleInfoAndUnique(...Object.values(updateData), overrides);

                const receipt = await receiptTx.wait();
                await logger.infoExternal('updateBottleInfoAndUnique', receipt, 'send', ledger);

                //const readData = await this.get(ledger);
                //logger.info('BOTTLE INFO AND UNIQUE DATA CONTRACT READ', readData);

                return receipt;
            } catch (ex) {
                await logger.errorExternal('updateBottleInfoAndUnique', ex.message, 'send', ledger);
                throw new ContractMethodError('Update Bottle And Unique Ledger Data', ex);
            }
        } else {
            throw new ContentValidationError('Ledger Bottle And Unique Data is not valid');
        }
    }

    async get(ledger, userAddress) {
        try {
            const response = await this.contract.getBottleInfoAndUnique(ledger.ledgerNumber, ledger.ledgerVersion);
            await logger.infoExternal('getBottleInfoAndUnique', response, 'call', ledger);
            return response;
        } catch (ex) {
            await logger.errorExternal('getBottleInfoAndUnique', ex.message, 'call', ledger);
            throw new ContractMethodError('Get Bottle And Unique Ledger Data', ex);
        }
    }
}

//Mapped contract key
export default (_config = config, _blockchainHelper = blockchainHelper()) => new LedgerBottleInfoAndUniqueService('ChaiBottleInfoAndUnique', _config, _blockchainHelper);
