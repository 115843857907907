import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

const isTablet = window.innerWidth <= 1024;
const styles = theme => ({
    cell: {
        backgroundColor: 'rgb(99,34,34, 0.7)',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: isTablet ? '1em' : '1.1em',
        fontWeight: 600,
        textTransform: 'uppercase',
        padding: 15,
        color: 'white'
    }
});

class ChaiTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { classes, order, orderBy, columns, enableCheckBox, enableDelete } = this.props;

        return (
            <TableHead>
                <TableRow>
                {enableCheckBox&&(
                        <TableCell className={classes.cell}>
                                <TableSortLabel
                                        style={{color: 'white',
                                        fontWeight: 600}}
                                    >Select
                                </TableSortLabel>
                        </TableCell>)}
                    {columns && columns.length && columns.map(column => {
                        return (
                            <TableCell
                                key={column.id}
                                sortDirection={orderBy === column.id ? order : false}
                                className={classes.cell}
                            >
                                <Tooltip
                                    title="Sort"
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={order}
                                        onClick={this.createSortHandler(column.id)}
                                        style={{color: 'white',
                                        fontWeight: 600}}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                    {enableDelete&&(
                        <TableCell className={classes.cell}>
                                <TableSortLabel
                                        style={{color: 'white',
                                        fontWeight: 600}}
                                    >Delete
                                </TableSortLabel>
                        </TableCell>)}
                </TableRow>
            </TableHead>
        );
    }
}

ChaiTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default withStyles(styles)(ChaiTableHead);
