import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import '../../Assets/css/fontello.css';

const isTablet = window.innerWidth <= 1024;
const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '1.18rem',
        color: '#3f3e3e'
    },
    rootDisabled: {
        display: 'flex',
        flexWrap: 'wrap',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '1em',
        color: '#c3c3c3'
    },
    formControl: {
        minWidth: 120,
        backgroundColor: 'white',
        padding: theme.spacing.unit * 1.2,
        border: '1px solid #6d6c6c',
        margin: 4
    },
    select: {
        color: 'red !important'
    },
    label: {
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: isTablet ? '0.85rem' : '1.00rem',
        color: '#3f3e3e',
        fontWeight: 600,
        textTransform: 'uppercase',
        paddingLeft: 5,
        marginBottom: 0
    },
    menuItem: { fontFamily: '"Source Sans Pro", sans-serif', fontSize: '1.18rem' },
    hide: {
        visibility: 'hidden'
    }
});


const ChaiDropDown = (props) => {
    const { classes, optional, value, label, items, style, disabled, certificate, visibility } = props;
    const { onChange } = props;
    const compuestLabel = certificate ? getLabelWithCertificateLogo(label) : label;

    function getLabelWithCertificateLogo(label){
        return (<div>{label}&nbsp;<i class="demo-icon icon-icon-isotype" style={{color:"#63222"}} title="This field will be included in the ledger certificate"></i></div>)
    }
    return (
        <div style={{ ...style }}
             className={visibility === false ?  classes.hide : null }
        >
            <div style={{ display: 'grid', alignContent: 'baseline' }} >
                <label className={classes.label} >
                    {compuestLabel}
                </label>
                <FormControl className={classes.formControl}>
                    <Select
                        disableUnderline={true}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                        displayEmpty
                        inputProps={{
                            classes: {
                                root: disabled ? classes.rootDisabled : classes.root
                            }
                        }}
                    >
                        {(<MenuItem value={undefined} disabled>
                        &#60;Choose&#62;
                        </MenuItem>)}
                        {optional &&(<MenuItem value={0}>
                            NONE
                        </MenuItem>
                        )}
                        {
                            items && items.length && items.map(i => (
                                <MenuItem
                                    className={classes.menuItem}
                                    key={i.id}
                                    value={i.id}
                                >{i.description}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

ChaiDropDown.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiDropDown);
