import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChaiTextField from '../ChaiTextField';

import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBaseForm from '../ChaiBaseForm';
import ChaiFooterContainer from '../ChaiFooterContainer';
import ChaiSwitch from '../ChaiSwitch';
import ChaiImageInput from '../ChaiImageInput';
import ChaiBodyContainerHeader from '../ChaiBodyContainerHeader'
import LinearProgress from '@material-ui/core/LinearProgress';
import formNames from '../ChaiBaseForm/formNames';

import { getAntifraud, saveAntifraud } from './service';

const styles = theme => ({

});

class ChaiAntifraud extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.ANTIFRAUD;
        let emptyDataState = {
            glassEtchingEngraving: false,
            glassEtchingEngravingLocation: null,
            glassEtchingEngravingDescription: null,
            glassEtchingEngravingImage: null,

            invisibleInk: false,
            invisibleInkLocation: null,
            invisibleInkDescription: null,
            invisibleInkImage: null,

            hologram: false,
            hologramLocation: null,
            hologramDescription: null,
            hologramImage: null,

            microWriting: false,
            microWritingLocation: null,
            microWritingDescription: null,
            microWritingImage: null,

            qrCode: false,
            qrCodeLocation: null,
            qrCodeDescription: null,
            qrCodeImage: null,

            producerBarcode: false,
            producerBarcodeLocation: null,
            producerBarcodeDescription: null,
            producerBarcodeImage: null,

            chip: false,
            chipLocation: null,
            chipLocationDescription: null,
            chipImage: null,

            other: false,
            otherLocation: null,
            otherDescription: null,
            otherImage: null

        };

        super({ ...props, formName, emptyDataState, loadTemplate: true });
        this.state = {
            ...this.state,
            softLoad: true,
        };

        const { addSetTemplateFunction } = props;
        addSetTemplateFunction(this.state.setTemplate);
    }

    getElement = async (ledger, version) => {
        return getAntifraud(ledger.ledgerNumber, version).then(result => {
            return result ? result : this.emptyDataState
        });
    };

    saveElement = async (ledger, version, element) => {
        return saveAntifraud(ledger.ledgerNumber, version, element);
    };

    render() {
        const { hideFooter } = this.props;
        return (
            <Fragment>
                {this.state.loading && <LinearProgress />}
                <ChaiBodyContainer>
                    <ChaiBodyContainerHeader {...this.props}>
                        {
                            !hideFooter &&
                            <ChaiRowBodyContainer>
                                <ChaiSwitch
                                    label='Glass Etching/Engraving'
                                    {...this.getField('glassEtchingEngraving', true)}
                                    {...this.getTemplateFieldStyle('glassEtchingEngraving', { width: "20%" })}
                                />
                                {
                                    this.state.data.glassEtchingEngraving && (
                                        <Fragment>
                                            <ChaiTextField
                                                label='Location'
                                                {...this.getField('glassEtchingEngravingLocation')}
                                                {...this.getTemplateFieldStyle('glassEtchingEngravingLocation', { width: "30%" })}
                                            />
                                            <ChaiTextField
                                                label='Description'
                                                {...this.getField('glassEtchingEngravingDescription')}
                                                {...this.getTemplateFieldStyle('glassEtchingEngravingDescription', { width: "30%" })}
                                            />
                                            <ChaiImageInput
                                                {...this.getFileField('glassEtchingEngravingImage')}
                                                {...this.getTemplateFieldStyle('glassEtchingEngravingImage', {})}
                                            />
                                        </Fragment>
                                    )
                                }
                            </ChaiRowBodyContainer>
                        }
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                label='Invisible Ink'
                                {...this.getField('invisibleInk', true)}
                                {...this.getTemplateFieldStyle('invisibleInk', { width: "20%" })}
                            />
                            {
                                this.state.data.invisibleInk && (
                                    <Fragment>
                                        <ChaiTextField
                                            label='Location'
                                            {...this.getField('invisibleInkLocation')}
                                            {...this.getTemplateFieldStyle('invisibleInkLocation', { width: "30%" })}

                                        />
                                        <ChaiTextField
                                            label='Description'
                                            {...this.getField('invisibleInkDescription')}
                                            {...this.getTemplateFieldStyle('invisibleInkDescription', { width: "30%" })}

                                        />
                                        <ChaiImageInput
                                            {...this.getFileField('invisibleInkImage')}
                                            {...this.getTemplateFieldStyle('invisibleInkImage', {})}
                                        />

                                    </Fragment>
                                )
                            }
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                label='Hologram'
                                {...this.getField('hologram', true)}
                                {...this.getTemplateFieldStyle('hologram', { width: "20%" })}
                            />
                            {
                                this.state.data.hologram && (
                                    <Fragment>
                                        <ChaiTextField
                                            label='Location'
                                            {...this.getField('hologramLocation')}
                                            {...this.getTemplateFieldStyle('hologramLocation', { width: "30%" })}

                                        />
                                        <ChaiTextField
                                            label='Description'
                                            {...this.getField('hologramDescription')}
                                            {...this.getTemplateFieldStyle('hologramDescription', { width: "30%" })}

                                        />
                                        <ChaiImageInput
                                            {...this.getFileField('hologramImage')}
                                            {...this.getTemplateFieldStyle('hologramImage', {})}
                                        />
                                    </Fragment>
                                )
                            }
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                label='Micro Writing'
                                {...this.getField('microWriting', true)}
                                {...this.getTemplateFieldStyle('microWriting', { width: "20%" })}
                            />
                            {
                                this.state.data.microWriting && (
                                    <Fragment>
                                        <ChaiTextField
                                            label='Location'
                                            {...this.getField('microWritingLocation')}
                                            {...this.getTemplateFieldStyle('microWritingLocation', { width: "30%" })}
                                        />
                                        <ChaiTextField
                                            label='Description'
                                            {...this.getField('microWritingDescription')}
                                            {...this.getTemplateFieldStyle('microWritingDescription', { width: "30%" })}
                                        />
                                        <ChaiImageInput
                                            {...this.getFileField('microWritingImage')}
                                            {...this.getTemplateFieldStyle('microWritingImage', {})}
                                        />
                                    </Fragment>
                                )
                            }
                        </ChaiRowBodyContainer>
                        {
                            !hideFooter &&
                            <ChaiRowBodyContainer>
                                <ChaiSwitch
                                    label='QR Code on Label/Capsule/Glass'
                                    {...this.getField('qrCode', true)}
                                    {...this.getTemplateFieldStyle('qrCode', { width: "20%" })}

                                />
                                {
                                    this.state.data.qrCode && (
                                        <Fragment>
                                            <ChaiTextField
                                                label='Location'
                                                {...this.getField('qrCodeLocation')}
                                                {...this.getTemplateFieldStyle('qrCodeLocation', { width: "30%" })}

                                            />
                                            <ChaiTextField
                                                label='Description'
                                                {...this.getField('qrCodeDescription')}
                                                {...this.getTemplateFieldStyle('qrCodeDescription', { width: "30%" })}

                                            />
                                            <ChaiImageInput
                                                {...this.getFileField('qrCodeImage')}
                                                {...this.getTemplateFieldStyle('qrCodeImage', {})}
                                            />
                                        </Fragment>
                                    )
                                }
                            </ChaiRowBodyContainer>
                        }
                        {
                            !hideFooter &&
                            <ChaiRowBodyContainer>
                                <ChaiSwitch
                                    label='Producer Barcode'
                                    {...this.getField('producerBarcode', true)}
                                    {...this.getTemplateFieldStyle('producerBarcode', { width: "20%" })}

                                />
                                {
                                    this.state.data.producerBarcode && (
                                        <Fragment>
                                            <ChaiTextField
                                                label='Location'
                                                {...this.getField('producerBarcodeLocation')}
                                                {...this.getTemplateFieldStyle('producerBarcodeLocation', { width: "30%" })}

                                            />
                                            <ChaiTextField
                                                label='Description'
                                                {...this.getField('producerBarcodeDescription')}
                                                {...this.getTemplateFieldStyle('producerBarcodeDescription', { width: "30%" })}

                                            />
                                            <ChaiImageInput
                                                {...this.getFileField('producerBarcodeImage')}
                                                {...this.getTemplateFieldStyle('producerBarcodeImage', {})}

                                            />
                                        </Fragment>
                                    )
                                }
                            </ChaiRowBodyContainer>
                        }
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                label='Chip Location'
                                {...this.getField('chip', true)}
                                {...this.getTemplateFieldStyle('chip', { width: "20%" })}

                            />
                            {
                                this.state.data.chip && (
                                    <Fragment>
                                        <ChaiTextField
                                            label='Location'
                                            {...this.getField('chipLocation')}
                                            {...this.getTemplateFieldStyle('chipLocation', { width: "30%" })}

                                        />
                                        <ChaiTextField
                                            label='Description'
                                            {...this.getField('chipLocationDescription')}
                                            {...this.getTemplateFieldStyle('chipLocationDescription', { width: "30%" })}
                                        />
                                        <ChaiImageInput
                                            {...this.getFileField('chipImage')}
                                            {...this.getTemplateFieldStyle('chipImage', {})}
                                        />
                                    </Fragment>
                                )
                            }
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                label='Antifraud Other'
                                {...this.getField('other', true)}
                                {...this.getTemplateFieldStyle('other', { width: "20%" })}

                            />
                            {
                                this.state.data.other && (
                                    <Fragment>
                                        <ChaiTextField
                                            label='Location'
                                            {...this.getField('otherLocation')}
                                            {...this.getTemplateFieldStyle('otherLocation', { width: "30%" })}

                                        />
                                        <ChaiTextField
                                            label='Description'
                                            {...this.getField('otherDescription')}
                                            {...this.getTemplateFieldStyle('otherDescription', { width: "30%" })}

                                        />
                                        <ChaiImageInput
                                            {...this.getFileField('otherImage')}
                                            {...this.getTemplateFieldStyle('otherImage', {})}

                                        />
                                    </Fragment>
                                )
                            }
                        </ChaiRowBodyContainer>
                    </ChaiBodyContainerHeader>
                </ChaiBodyContainer>
            </Fragment>
        );
    }
}

ChaiAntifraud.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiAntifraud);
