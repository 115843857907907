import SemiCircle from '../../Assets/SemiCircleBg.png'
import EmptyBg from '../../Assets/EmptyBg.png'

const GOLDEN = "#C39751";
const generalSidePadding = 25;
const generalStyles = {
    xxsText: {
        fontSize: '0.3em'
    },
    xsText: {
        fontSize: '0.8em'
    },
    smText: {
        fontSize: '1em'
    },
    mdText: {
        fontSize: '1.3em'
    },
    lgText: {
        fontSize: '1.8em'
    },
    whiteText: {
        color: "#ffffff"
    },
    goldenText: {
        color: GOLDEN
    },
    textSeparator: {
        marginBottom: '20px'
    },
    lightText: {
        fontWeight: 40
    },
    boldText: {
        fontWeight: 400
    },
    bolderText: {
        fontWeight: 600
    }
}

export const ledgerStyles = theme => ({
    ...generalStyles,
    certificateMainContainer: {
        color: '#ffffff',
        background: "linear-gradient(90deg, rgba(84,2,29,1) 0%, rgba(122,0,37,1) 100%),linear-gradient(0deg, rgba(88,1,30,1) 0%, rgba(122,0,37,1) 50%, rgba(84,2,29,1) 100%)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "25% 100%, 100% 100%"
    },
    imageContainer: {
        backgroundImage: `url(${SemiCircle})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: "auto 100%",
        backgroundPosition: 'right',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center'
        } 
    },
    detailContainer: {
        backgroundImage: `url(${EmptyBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: "100%",
        paddingTop: '25px', 
        paddingLeft: '55px',
        paddingRight: '55px',
        paddingBottom: '55px'
    },
    generalDetailsContainer: {
        marginBottom: '20px'
    },
    provenanceContainer: {
        marginLeft: generalSidePadding,
        marginRight: generalSidePadding,
        backgroundColor: "#000000",
        border: `2px solid ${GOLDEN}`
    },
    cell: {
        
    },
    cellProvenance: {
        textAlign: 'center'
    },
    titleContainer: {
        marginBottom: 25
    },
    mainTitle: {
        fontSize: '2.2em', fontWeight: 100,
        textAlign: 'center', paddingBottom: 5
    },
    subtitle: {
        fontSize: '1.1em',
        textAlign: 'center'
    },
    subtitleProvenance: {
        paddingTop: 10,
        paddingBottom: 10,
        color: '#ffffff',
        fontSize: '1.8em',
        fontWeight: 40,
        borderBottom: `1px solid ${GOLDEN}`,
        textAlign: 'center'
    },
    title: {fontSize: '1.1em', fontWeight: 40},
    detail: {fontSize: '1em', marginBottom: '0.8em', fontWeight: 600, marginTop: 0},
    detailProvenance: { paddingLeft: 5, fontSize: '1.1em', fontWeight: 40},
    provenanceItemContainer: { 
        padding: 20
    },
    provenanceDetailContainer: {
        
    },
    provenanceIcon: {fontSize: '2em', paddingBottom: 10 },
    carouselArrow: { paddingLeft: 5, paddingRight: 5}
})

export const footerStyles = theme => ({
    ...generalStyles,
    root: {
        paddingTop: 50, 
        paddingBottom: 25,
        paddingLeft: generalSidePadding,
        paddingRight: generalSidePadding
    },
    signatureContainer: {
        marginBottom: 25, 
        paddingRight: '35px', 
        paddingLeft: '35px'
    }
})