const { dpCrud,  CREATE, GET_ONE } = require('../../DataProviders/crudgeneric');

export const getClosureCapsule = async (ledgerNumber, ledgerVersion) => {
    try {
        const data = await dpCrud(GET_ONE, "capsules", {
            ledger: {
                ledgerNumber: ledgerNumber,
                ledgerVersion: ledgerVersion
            }
        });
        return data.data;
    } catch (ex) {
        console.error("ERROR getClosureCapsule", ex);
    }
    return null;
};

export const saveClosureCapsule = async (ledgerNumber, ledgerVersion, data) => {
        const response = await dpCrud(CREATE, "capsules", {
            data: prepareToSend(data, ledgerNumber, ledgerVersion)
        });
        return response;
};

export function prepareToSend(values, ledgerNumber, ledgerVersion) {
    let capsule = {
        LedgerNumber: ledgerNumber,
        LedgerVersion: ledgerVersion,
        IsDraft: true,
        ClosureCapsule: values.closureCapsule,
        ...values
    };

    return capsule;
}
