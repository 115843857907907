import configProvider from "./providers/config.provider";

export default config => {
    try {
        configProvider.setConfig(config);
        const UserFacade = require('./facades/user.facade').default;
        const SecurityFacade = require('./facades/security.facade').default;
        const LedgerFacade = require('./facades/ledger.facade').default;
        const WalletProvider = require('./providers/wallet.provider').default;
        const Helper = require("./helpers/blockchain.helper").default();;

        return {
            user: new UserFacade(),
            security: new SecurityFacade(),
            ledger: new LedgerFacade(),
            wallet: WalletProvider,
            helper: Helper
        }
    } catch(ex){
        throw ex;
    }
}


