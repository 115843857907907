import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import ChaiTextField from '../ChaiTextField';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBaseForm from '../ChaiBaseForm';
import ChaiFooterContainer from '../ChaiFooterContainer';
import ChaiSwitch from '../ChaiSwitch';
import ChaiDropDownMultiple from '../ChaiDropDownMultiple';
import ChaiBodyContainerHeader from '../ChaiBodyContainerHeader'
import { getCounterfeit, saveCounterfeit } from './service';
import formNames from '../ChaiBaseForm/formNames';

import {
    printMethodOptions
} from './options';
import ChaiImageInput from "../ChaiImageInput";

const styles = theme => ({

});

class ChaiCounterfeit extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.COUNTERFEIT;
        let emptyDataState = {
            printMethods: [],
            otherPrintMethod: null,
            printMethodsImage: null,

            recycledMaterial: false,
            recycledMaterialDescription: null,
            recycledMaterialImage: null,

            ageingApplications: false,
            ageingApplicationsDescription: null,
            ageingApplicationsImage: null,

            labelsOtherCharacteristics: null,
            labelsOtherImage: null,

            otherFindings: null
        };

        super({ ...props, formName, emptyDataState });
        this.state = {
            ...this.state,
            softLoad: true,
        };

        const { addSetTemplateFunction } = props;
        addSetTemplateFunction(this.state.setTemplate);
    }

    getElement = async (ledger, version) => {
        return getCounterfeit(ledger.ledgerNumber, 0)
            .then(result => { return result ? result : this.emptyDataState });
    };

    saveElement = async (ledger, version, element) => {
        return saveCounterfeit(ledger.ledgerNumber, version, element);
    };

    onRemoveArrayField = (item) => {
        const data = this.state.data;

        const printMethods = [...data.printMethods];
        const toDelete = printMethods.findIndex(x => x === item);

        printMethods.splice(toDelete, 1);
        data.printMethods = printMethods;
        if (item === 5) {// if other print method
            data.otherPrintMethod = null;
        }

        this.setState({ data });
    };

    render() {
        const { currentLedger, hideFooter, showNotification } = this.props;
        if (currentLedger && currentLedger.isAuthentic && currentLedger.isAuthentic === true) {
            showNotification('In order to access the Counterfeit screen, please save the Ledger as Counterfeit first.');
            return <Redirect to='/bottleInformation' />
        }

        const printMethodOther = this.state.data.printMethods.some(x => x === 5);

        return (
            <Fragment>
                {this.state.loading && <LinearProgress />}
                <ChaiBodyContainer>
                    <ChaiBodyContainerHeader {...this.props}>
                        <ChaiRowBodyContainer>
                            <ChaiDropDownMultiple
                                {...this.getMultipleDropDownField('printMethods')}
                                label="PRINT METHODS"
                                style={{ width: '45%' }}
                                items={printMethodOptions}
                            />
                            <ChaiImageInput {...this.getFileField('printMethodsImage')} />
                            {
                                printMethodOther ?
                                    <ChaiTextField
                                        label='Other Print Method'
                                        {...this.getField('otherPrintMethod')}
                                        style={{ width: '45%' }}
                                    />
                                    :
                                    <div style={{ width: '45%' }}></div>

                            }
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                label='Recycled Material In Paper'
                                {...this.getField('recycledMaterial', true)}
                                style={{ width: '25%' }}
                            />
                            {
                                this.state.data.recycledMaterial &&
                                <Fragment>
                                    <ChaiTextField
                                        label='Description'
                                        {...this.getField('recycledMaterialDescription')}
                                        style={{ width: '40%' }} />
                                    <ChaiImageInput {...this.getFileField('recycledMaterialImage')} />
                                </Fragment>
                            }
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                label='Ageing Applications'
                                {...this.getField('ageingApplications', true)}
                                style={{ width: '25%' }}
                            />
                            {
                                this.state.data.ageingApplications &&
                                <Fragment>
                                    <ChaiTextField
                                        label='Description'
                                        {...this.getField('ageingApplicationsDescription')}
                                        style={{ width: '40%' }} />
                                    <ChaiImageInput {...this.getFileField('ageingApplicationsImage')} />
                                </Fragment>
                            }
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiTextField
                                label='Labels Other Counterfeit Characteristics'
                                {...this.getField('labelsOtherCharacteristics')}
                                style={{ width: '50%' }}
                            />
                            <ChaiImageInput {...this.getFileField('labelsOtherImage')} />
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiTextField
                                label='Other Findings'
                                {...this.getField('otherFindings')}
                                style={{ width: '50%' }}
                            />
                        </ChaiRowBodyContainer>
                    </ChaiBodyContainerHeader>
                </ChaiBodyContainer>
            </Fragment>
        );
    }
}

ChaiCounterfeit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiCounterfeit);
