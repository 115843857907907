import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import { CardContent, CircularProgress } from '@material-ui/core';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiHeaderButton from '../ChaiHeaderButton';
import { getProvenancesForCsvExport } from '../ChaiProvenance/service';
import { provenanceExportCsvHeaders } from './service'
import { CSVLink } from "react-csv";

const styles = theme => ({
    card: { marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#efefef' },
    log: {
        width: '100%', marginBottom: 10, paddingBottom: 10, borderBottom: '1px solid #3e3e3e',
        fontFamily: '"Source Sans Code", sans-serif', fontSize: '1em', color: '#3e3e3e'
    }
});

const messages = ['Still processing your data...', 'Working with provenances...', 'This may take a while...', 'Will finish soon...'];
let interval;

class ChaiProvenanceExport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: null,
            message: '',
            downloaded: false,
            downloading: false,
            open: false,
            processed: false
        }
    }

    export = async () => {
        try {
            const { downloaded, processed } = this.state;
            const { isUploading } = this.props;

            if (!downloaded && processed) {
                this.setState({ open: true });
                return;
            };

            isUploading(true);
            this.setState({ uploading: true, message: 'Export process begun...' });

            interval = setInterval(() => {
                const currentMessage = messages[Math.floor(Math.random() * 4) + 1];
                this.setState({ message: currentMessage });
            }, 1 * 5000);

            const provenances = await getProvenancesForCsvExport();

            const data = await this.parseResponse(provenances);
            this.setState({data, processed: true, uploading: false, message: ''});

            isUploading(false);
            clearInterval(interval);
        } catch (error) {
            this.props.isUploading(false);
            this.setState({
                uploading: false,
                processed: false,
                downloaded: false,
                message: error || 'Export process interrupted due to an error. Please, try again later or contact your Administrator.'
            });
            clearInterval(interval);
        }
    };

    parseResponse = async (provenances) => {
        if (provenances && provenances.length > 0) {
            let parsedData = [];

            provenances.map((provenance) => {
                provenance.provenanceDetails.map(details => {
                    const parsedDetails = {};
                    parsedDetails.ledgerNumber = provenance.ledgerNumber;
                    parsedDetails.ledgerVersion = provenance.ledgerVersion || 0;
                    //parsedDetails.created = provenance.created;
                    parsedDetails.undraftedAt = provenance.undraftedAt;

                    parsedDetails.ownerName = details.owner.name || "";
                    parsedDetails.ownerAddress = details.owner.address || "";
                    parsedDetails.ownerInfo = details.owner.info || "";
                    parsedDetails.ownerEmail = details.owner.email || "";
                    parsedDetails.ownerId = details.owner.id || "";

                    parsedDetails.type = details.type || "";
                    parsedDetails.bottleCost = details.bottleCost || "";
                    parsedDetails.lotCost = details.lotCost || "";
                    parsedDetails.currency = details.currency || "";
                    parsedDetails.dateOfSale = details.dateOfSale || "";
                    parsedDetails.lastEdited = details.lastEdited || "";
                    parsedDetails.proofOfPurchaseImage = details.proofOfPurchaseImage || "";

                    parsedDetails.draft = provenance.draft === false ? "false" : "true";
                    parsedDetails.updateOwnership = details.updateOwnership === false ? "false" : "true";
                    parsedDetails.uploadedToBlockchain = details.uploadedToBlockchain === false ? "false" : "true";
                    parsedDetails.approved = details.approved === false ? "false" : "true";
                    parsedDetails.futureOwnershipTransfer = details.futureOwnershipTransfer === false ? "false" : "true";

                    parsedDetails.auctionHouse = details.auctionHouse || "";
                    parsedDetails.saleNumber = details.saleNumber || "";
                    parsedDetails.saleLot = details.saleLot || "";
                    parsedDetails.consignmentDescription = details.consignmentDescription || "";
                    parsedDetails.consignmentDescriptionImage = details.consignmentDescriptionImage || "";
            
                    parsedDetails.purchasedFrom = details.purchasedFrom || "";
            
                    parsedDetails.vendor = details.vendor || "";

                    parsedData = [...parsedData, parsedDetails];
                })
            });

            return parsedData;
        }
    };

    render() {
        const { classes } = this.props;
        const { uploading, message, processed, data } = this.state;

        return (
            <Card className={classes.card}>
                <CardContent>
                    <ChaiBodyContainer>
                        <div style={{ display: 'flex', justifyContent: 'unset', alignItems: 'center' }}>
                            <div style={{ width: 40 }} ></div>
                            {
                                uploading ? <CircularProgress /> :
                                    <ChaiHeaderButton
                                        onClick={this.export}
                                        disabled={processed}
                                        label={'EXPORT ALL PROVENANCES'}
                                        style={{ marginTop: 20 }} />
                            }
                            {
                                processed && <CSVLink style={{ padding: '10px 20px' }} filename={`CV-AdminAllProvenancesExport-${(new Date().getTime())}.csv`} separator={';'} headers={provenanceExportCsvHeaders} data={data}>Download File</CSVLink>
                            }
                        </div>
                        <ChaiRowBodyContainer>
                            {message}
                        </ChaiRowBodyContainer>
                    </ChaiBodyContainer>
                </CardContent>
            </Card>
        )
    }
}

ChaiProvenanceExport.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiProvenanceExport);