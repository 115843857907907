import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';

// TODO implement chai styles.
const styles = theme => ({
    root: {
        width: 'auto',
        top: '2.5em',
        padding: 0
    }
});

const ChaiMenu = (props) => {
    const { classes, id, anchorEl, open, onClose } = props;

    return (
        <Menu
            id={id}
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            className={classes.root}
        >
            {props.children}
        </Menu>
    )
};


ChaiMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiMenu);