import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiProgress from '../ChaiProgress';

const styles = theme => ({
    paper: { overflow: 'hidden' }
});

const ChaiAccountBlockchainDialog = (props) => {
    const { openAlert, handleCloseAlert, account, saving, handleUpdateUserStatusBlockchain, handleCreateUserBlockchain, classes } = props;
    const blockchain = account ? account.blockchain : null;
    const userInBlockchain = blockchain ? blockchain[0] : false;
    const userActive = userInBlockchain ? blockchain[2] : false;

    return (
        <Dialog
            open={openAlert}
            onClose={handleCloseAlert}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper:classes.paper } }
        >
            <DialogTitle id="alert-dialog-title">
                {userInBlockchain ? `${userActive ? `Deactivate` : `Activate`} User on Blockchain?` :
                    `Create User on Blockchain?`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {userInBlockchain ? userActive ? `The User will no longer be able to interact with the Blockchain` : `The User will be able to interact with the Blockchain` :
                        `By agreeing you will generate a transaction that creates the User on the
                            Blockchain and can not be removed, only inactivate it.`}
                </DialogContentText>
            </DialogContent>
            {
                saving ?
                    <ChaiProgress /> :
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 40, paddingLeft: 40 }}>
                        <ChaiHeaderButton label="Disagree" onClick={handleCloseAlert} />
                        <ChaiHeaderButton label="Agree" onClick={account && blockchain ? handleUpdateUserStatusBlockchain : handleCreateUserBlockchain} />
                    </DialogActions>
            }
        </Dialog>
    );
}


ChaiAccountBlockchainDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiAccountBlockchainDialog);
