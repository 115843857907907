import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChaiTextField from '../ChaiTextField';
import ChaiDropDown from '../ChaiDropDown';
import ChaiDropDownMultiple from '../ChaiDropDownMultiple';

import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBaseForm from '../ChaiBaseForm';
import ChaiFooterContainer from "../ChaiFooterContainer";
import ChaiSwitch from "../ChaiSwitch";
import ChaiBodyContainerHeader from '../ChaiBodyContainerHeader'
import LinearProgress from '@material-ui/core/LinearProgress';
import formNames from '../ChaiBaseForm/formNames';

import { getLabel, prepareToSend, saveLabel } from './service';

import ChaiImageInput from "../ChaiImageInput";

const styles = theme => ({

});

class ChaiMainLabel extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.MAINLABEL;
        let emptyDataState = {
            statedABV: null,
            statedABVImage: null,

            paperFinish: null,
            paperColor: null,
            ribbing: 0,

            labelPrintMethod: [],
            labelPrintMethodImage: null,

            labelType: null,

            inkColor: [],
            inkColorImage: null,

            waterMark: false,
            waterMarkLocation: null,
            waterMarkDescription: null,

            stampsMarkings: false,
            stampsMarkingsLocation: null,
            stampsMarkingsDescription: null,

            labelEmbossing: false,
            labelEmbossingLocation: null,
            labelEmbossingDescription: null,

            labelFoiling: false,
            labelFoilingOption: 0,
            labelFoilingDescription: null,

            otherLabel: false,
            otherLabelLocation: null,
            otherLabelDescription: null,
        };

        super({ ...props, formName, emptyDataState, loadTemplate: true });
        this.state = {
            ...this.state,
            softLoad: true,
            prepareToSendFunc: prepareToSend,
        };

        const { addSetTemplateFunction } = props;
        addSetTemplateFunction(this.state.setTemplate);
    }

    getElement = async (ledger, version) => {
        await this.getOptionsWithFormName(formNames.MAINLABEL);
        return getLabel(ledger.ledgerNumber, version).then(result => {
            return result ? result : this.emptyDataState;
        });
    };

    saveElement = async (ledger, version, element) => {
        return saveLabel(ledger.ledgerNumber, version, element);
    };

    render() {
        const formName = formNames.MAINLABEL;
        return (
            <Fragment>
                {this.state.loading && <LinearProgress />}
                <ChaiBodyContainer>
                    <ChaiBodyContainerHeader {...this.props}>
                        <ChaiRowBodyContainer>
                            <div style={{ width: '30%', display: "inherit" }}>
                                <ChaiTextField
                                    label="Stated ABV% *"
                                    {...this.getField('statedABV')}
                                    {...this.getTemplateFieldStyle('statedABV', { width: '40%' })}
                                />
                                <ChaiImageInput
                                    label="( optional )"
                                    {...this.getTemplateFieldStyle('statedABVImage', { width: '10%' })}
                                    {...this.getFileField('statedABVImage')}
                                />
                            </div>
                            <div style={{ width: "30%" }}>
                                <ChaiDropDown
                                    label="Ribbing"
                                    {...this.getField('ribbing')}
                                    {...this.getTemplateFieldStyle('ribbing', { width: '70%' })}
                                    optional={true}
                                    items={this.state.options[formName]['ribbing'] || []}
                                />
                            </div>
                            <div style={{ width: "30%" }}>
                                <ChaiDropDown
                                    label="Label Type*"
                                    {...this.getField('labelType')}
                                    items={this.state.options[formName]['labelType'] || []}
                                    {...this.getTemplateFieldStyle('labelType', { width: '70%' })}
                                />
                            </div>
                        </ChaiRowBodyContainer>
                        {
                            this.state.data.labelType === 1 &&
                            <ChaiRowBodyContainer>
                                <div style={{ width: '15%', display: "inherit" }}>
                                    <ChaiDropDown
                                        label="Paper Finish*"
                                        {...this.getField('paperFinish')}
                                        items={this.state.options[formName]['paperFinish'] || []}
                                        {...this.getTemplateFieldStyle('paperFinish', { width: '80%' })}
                                    />
                                </div>
                                <div style={{ width: '15%', display: "inherit" }}>
                                    <ChaiDropDown
                                        label="Paper Color*"
                                        {...this.getField('paperColor')}
                                        items={this.state.options[formName]['paperColor'] || []}
                                        {...this.getTemplateFieldStyle('paperColor', { width: '80%' })}
                                    />
                                </div>
                                <div style={{ width: '5%' }}></div>
                                <div style={{ width: '30%', display: "inherit" }}>
                                    <ChaiDropDownMultiple
                                        {...this.getMultipleDropDownField('labelPrintMethod')}
                                        label="Label Print Method"
                                        items={this.state.options[formName]['labelPrintMethod'] || []}
                                        {...this.getTemplateFieldStyle('labelPrintMethod', { width: '80%' })}
                                    />
                                    {
                                        this.state.data.labelPrintMethod ?
                                            <ChaiImageInput
                                                {...this.getTemplateFieldStyle('labelPrintMethodImage', { width: '10%' })}
                                                {...this.getFileField('labelPrintMethodImage')}
                                            /> : <div style={{ width: '10%' }}></div>
                                    }
                                </div>
                                <div style={{ width: '5%' }}></div>
                                <div style={{ width: '30%', display: "inherit" }}>
                                    <ChaiDropDownMultiple
                                        {...this.getMultipleDropDownField('inkColor')}
                                        {...this.getTemplateFieldStyle('inkColor', { width: '65%' })}
                                        label="Ink Color"
                                        items={this.state.options[formName]['inkColors'] || []}
                                    />
                                    {
                                        this.state.data.inkColor ?
                                            <ChaiImageInput
                                                {...this.getTemplateFieldStyle('inkColorImage', { width: '10%' })}
                                                {...this.getFileField('inkColorImage')}
                                            /> : <div style={{ width: '10%' }}></div>
                                    }
                                </div>
                            </ChaiRowBodyContainer>
                        }
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                label="Watermark"
                                {...this.getField('waterMark', true)}
                                {...this.getTemplateFieldStyle('waterMark', { width: '25%' })}
                            />
                            {
                                this.state.data.waterMark && (
                                    <Fragment>
                                        <ChaiTextField
                                            label="Location"
                                            {...this.getField('waterMarkLocation')}
                                            {...this.getTemplateFieldStyle('waterMarkLocation', { width: '35%' })}
                                        />
                                        <ChaiTextField
                                            label="Description"
                                            {...this.getField('waterMarkDescription')}
                                            {...this.getTemplateFieldStyle('waterMarkDescription', { width: '35%' })}
                                        />
                                    </Fragment>
                                )
                            }
                            <div style={{ width: '5%' }}></div>
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                label="Stamps Markings"
                                {...this.getField('stampsMarkings', true)}
                                {...this.getTemplateFieldStyle('stampsMarkings', { width: '25%' })}
                            />
                            {
                                this.state.data.stampsMarkings && (
                                    <Fragment>
                                        <ChaiTextField
                                            label="Location"
                                            {...this.getField('stampsMarkingsLocation')}
                                            {...this.getTemplateFieldStyle('stampsMarkingsLocation', { width: '35%' })}
                                        />
                                        <ChaiTextField
                                            label="Description"
                                            {...this.getField('stampsMarkingsDescription')}
                                            {...this.getTemplateFieldStyle('stampsMarkingsDescription', { width: '35%' })}
                                        />
                                    </Fragment>
                                )
                            }
                            <div style={{ width: '5%' }}></div>
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                label="Label Embossing"
                                {...this.getField('labelEmbossing', true)}
                                {...this.getTemplateFieldStyle('labelEmbossing', { width: '25%' })}
                            />
                            {
                                this.state.data.labelEmbossing && (
                                    <Fragment>
                                        <ChaiTextField
                                            label="Location"
                                            {...this.getField('labelEmbossingLocation')}
                                            {...this.getTemplateFieldStyle('labelEmbossingLocation', { width: '35%' })}
                                        />
                                        <ChaiTextField
                                            label="Description"
                                            {...this.getField('labelEmbossingDescription')}
                                            {...this.getTemplateFieldStyle('labelEmbossingDescription', { width: '35%' })}
                                        />
                                    </Fragment>
                                )
                            }
                            <div style={{ width: '5%' }}></div>
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                label="Label Foiling"
                                {...this.getField('labelFoiling', true)}
                                {...this.getTemplateFieldStyle('labelFoiling', { width: '25%' })}
                            />

                            {
                                this.state.data.labelFoiling &&
                                (
                                    <Fragment>
                                        <ChaiDropDown
                                            label="Option"
                                            {...this.getField('labelFoilingOption')}
                                            items={this.state.options[formName]['labelFoiling'] || []}
                                            {...this.getTemplateFieldStyle('labelFoilingOption', { width: '35%' })}
                                        />
                                        <ChaiTextField
                                            label="Description"
                                            {...this.getField('labelFoilingDescription')}
                                            {...this.getTemplateFieldStyle('labelFoilingDescription', { width: '35%' })}
                                        />
                                    </Fragment>
                                )
                            }
                            <div style={{ width: '5%' }}></div>
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                label="Label Other"
                                {...this.getField('otherLabel', true)}
                                {...this.getTemplateFieldStyle('otherLabel', { width: '25%' })}
                            />
                            {

                                this.state.data.otherLabel && (
                                    <Fragment>
                                        <ChaiTextField
                                            label="Description"
                                            {...this.getField('otherLabelDescription')}
                                            {...this.getTemplateFieldStyle('otherLabelDescription', { width: '70%' })}
                                        />
                                    </Fragment>
                                )
                            }
                            <div style={{ width: '5%' }}></div>
                        </ChaiRowBodyContainer>
                    </ChaiBodyContainerHeader>
                </ChaiBodyContainer>
            </Fragment>
        );
    }
}

ChaiMainLabel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiMainLabel);
