import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getPendingOwnershipLedgersByUser, approveOrRevokeProvenance } from './service';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiLedgersTable from '../ChaiLedgersTable';
import LinearProgress from '@material-ui/core/LinearProgress';
import ChaiTextField from '../ChaiTextField';
import ChaiHeaderButton from '../ChaiHeaderButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import chaiErrorHandler from '../../Handler/chaiErrorHandler';
const sha512 = require('js-sha512');

const styles = theme => ({
    tabsRoot: {
        backgroundColor: '#efefef'
    },
    tabsIndicator: {
        height: 0
    },
    tab: {
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '1.2em',
        fontWeight: 600
    },
    tabRoot: {
        boxShadow: 'inset 7px -8px 23px -2px rgba(0,0,0,0.47)',
        backgroundColor: '#3f3e3e',
        color: 'white',
        '&$tabSelected': {
            color: '#3f3e3e',
            backgroundColor: '#efefef',
            boxShadow: 'none'
        }
    },
    tabSelected: {},
    tabContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }
});

const columnData = [
    { id: 'ledgerNumber', label: 'Ledger Number' },
    { id: 'authenticator', label: 'Authenticator' },
    { id: 'wineName', label: 'Bottle Name' },
    { id: 'lastEdited', label: 'Last Edited'}
];

class ChaiMassiveSale extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ledgers: [],
            saleLedgers:[],
            password:"",
            modal:false,
            approve:false,        
        }
    }

    handlePasswordChange = (e) => {
        this.setState({ password: e.target.value });
        if (e.key === 'Enter') {
            this.handleAuthenticationConfirmation(e);
        }
    };

    openModal = () => this.setState({ modal: true });
    
    closeModal = () => this.setState({ modal: false });

    renderModal = (e) => {
        const { modal } = this.state;

        return (
            <Dialog
                open={modal}
                onClose={this.closeModal}
                aria-labelledby="form-dialog-title"
                fullWidth
            >
                <DialogContent>
                    <ChaiTextField
                        value={this.state.password}
                        onChange={(e) => this.handlePasswordChange(e)}
                        type={'password'}
                        autoComplete={'new-password'}
                        label={'Re-Enter Password to confirm'}
                        style={{ width: '100%' }}
                    />
                </DialogContent>
                <DialogActions style={{ marginRight: 25 }}>
                    <ChaiHeaderButton label="Confirm" onClick={this.handleAuthenticationConfirmation} />
                </DialogActions>
            </Dialog>
        )
    };

    handleAuthenticationConfirmation = async (e) => {
        const { password} = this.state;
        if ( password.length > 0 ) {
            try {
                this.handleApproveOrRevoke(e,this.state.approve)
            } catch (error) {
                chaiErrorHandler(this.notify).catch(error);
            }
        } else {
            this.props.showNotification('Incorrect password');
        }
    };

    handleLoading = (loading ) => {
        this.setState({loading});
    };

    handleCheckLedger = (event, ledgers) => {
        if (event.target.checked) {
            let saleLedgers = this.state.saleLedgers;
            saleLedgers = saleLedgers.concat(ledgers);
            this.setState({saleLedgers});
        } else {
            const ledgersId = ledgers.map(led => led.ledgerNumber)
            const saleLedgers = this.state.saleLedgers.filter( (led) => {
                return ledgersId.indexOf(led.ledgerNumber) === -1;
            });
            this.setState({saleLedgers});
        }
       
    };

    handleApproveOrRevoke = async (e,value) => {
        const ledgers = this.state.saleLedgers
            .filter(led => led.checked)
            .map(led => ({
                ledgerNumber: led.ledgerNumber,
                ledgerVersion: 0
            }));
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            await approveOrRevokeProvenance({
                password: sha512(this.state.password),
                email: user.email,
                ledgers,
                approved: value
            });
            this.closeModal();
            this.props.showNotification(
                "All ledgers updated successfully",
                'warning',
                { autoHideDuration: 10000 }
            );
            this.props.onApprove();
        } catch (err) {
            this.setState({ loading: false });
            this.props.showNotification(err.message, 'warning', { autoHideDuration: 10000 });
        }
       
    }

    handleRevoke = async (e) => this.setState({ approve: false, modal:true });
    handleConfirm = async (e) => this.setState({ approve: true, modal:true });

    render() {
        const { saleLedgers } = this.state;

        return (
            <Fragment>
                {this.renderModal()}
                {this.state.loading && <LinearProgress />}
                <ChaiBodyContainer style={{padding: 0, boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.49)'}}>
                    
                    <ChaiRowBodyContainer>
                        <ChaiLedgersTable
                            columns={columnData}
                            handleLoading={this.handleLoading}
                            enableCheckBox={true}
                            ownLedger={true}
                            enableTextBoxFilter={true}
                            onCheck={this.handleCheckLedger}
                            checkedLedgers={saleLedgers}
                            fetchFunction={getPendingOwnershipLedgersByUser}
                            {...this.props} />
                    </ChaiRowBodyContainer>
                    <ChaiRowBodyContainer>
                        <ChaiHeaderButton label={'CONFIRM'} onClick={this.handleConfirm}  disabled={saleLedgers.length === 0} />
                        <ChaiHeaderButton label={'REVOKE'} onClick={this.handleRevoke}  disabled={saleLedgers.length === 0} />
                    </ChaiRowBodyContainer>
                </ChaiBodyContainer>
                
            </Fragment>
        );
    }
}
ChaiMassiveSale.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ChaiMassiveSale);
