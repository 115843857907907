import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ChaiProvenanceUpload from './ChaiProvenanceUpload';
import ChaiMassUpload from './ChaiMassUpload';
import ChaiMassUploadCellarTracker from './ChaiMassUploadCellarTracker';
import ChaiLedgersExport from './ChaiLedgersExport';
import ChaiProvenancesExport from './ChaiProvenancesExport';

const styles = theme => ({
    tabsRoot: {
        backgroundColor: '#efefef'
    },
    tabsIndicator: {
        height: 0
    },
    tab: {
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '1.2em',
        fontWeight: 600
    },
    tabRoot: {
        boxShadow: 'inset 7px -8px 23px -2px rgba(0,0,0,0.47)',
        backgroundColor: '#3f3e3e',
        color: 'white',
        '&$tabSelected': {
            boxShadow: 'none',
            color: '#3f3e3e',
            backgroundColor: '#efefef'
        }
    },
    tabSelected: {},
    tabContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }
});

class ChaiUploadSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            tab: 0,
            loading: false
        };
    }

    handleTab = (event, tab) => {
        if (!this.state.loading) {
            this.setState({ tab });
        }
    };

    handleLoading = (loading) => {
        this.setState({ loading: loading });
    };

    showUploadsPanel = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        return user.rol === 'Administrator' || user.rol === 'TCM Authenticator';
    };

    showProvenancesExport = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        return user.rol === 'Administrator';
    }

    render() {
        const { tab } = this.state;
        const { classes, theme, showNotification, isUploading } = this.props;

        return (
            <Fragment>
                <AppBar position='static' style={{ boxShadow: 'none' }}>
                    <Tabs value={tab} onChange={this.handleTab} fullWidth classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
                        <Tab disabled={this.state.loading}
                            classes={{ root: classes.tabRoot, label: classes.tab, selected: classes.tabSelected }}
                            label='Provenances Upload' />
                        <Tab disabled={this.state.loading || !this.showUploadsPanel()}
                            classes={{ root: classes.tabRoot, label: classes.tab, selected: classes.tabSelected }}
                            label='Cellar Tracker Upload' />
                        <Tab disabled={this.state.loading || !this.showUploadsPanel()}
                            classes={{ root: classes.tabRoot, label: classes.tab, selected: classes.tabSelected }}
                            label='Mass Upload' />
                        <Tab disabled={this.state.loading || !this.showUploadsPanel()}
                            classes={{ root: classes.tabRoot, label: classes.tab, selected: classes.tabSelected }}
                            label='Export Ledgers' />
                        <Tab disabled={this.state.loading || !this.showProvenancesExport()}
                            classes={{ root: classes.tabRoot, label: classes.tab, selected: classes.tabSelected }}
                            label='Export Provenances' />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={tab}
                >
                    {
                        tab === 0 &&
                        <div dir={theme.direction}>
                            <ChaiProvenanceUpload showNotification={showNotification} isUploading={isUploading} />
                        </div>
                    }
                    {
                        tab === 1 &&
                        <div dir={theme.direction} >
                            <ChaiMassUploadCellarTracker showNotification={showNotification} isUploading={isUploading} />
                        </div>
                    }
                    {
                        tab === 2 &&
                        <div dir={theme.direction} >
                            <ChaiMassUpload showNotification={showNotification} isUploading={isUploading} />
                        </div>
                    }
                    {
                        tab === 3 &&
                        <div dir={theme.direction} >
                            <ChaiLedgersExport showNotification={showNotification} isUploading={isUploading} />
                        </div>
                    }
                    {
                        tab === 4 &&
                        <div dir={theme.direction} >
                            <ChaiProvenancesExport showNotification={showNotification} isUploading={isUploading} />
                        </div>
                    }
                </SwipeableViews>
            </Fragment>
        );
    }
}

ChaiUploadSection.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ChaiUploadSection);
