const logger = require("../helpers/logger.helper").default("AuthenticatorProcessor")
const isNumber = require("../helpers/number.helper").default.isNumber;

const prepareForBlockchain = (content, ledger, userAddr) => {
    /**
     * From solidity contract:
     *
        uint ledgerNumber,
        uint ledgerVersion,
        string memory nameOfPerson,
        string memory authenticatorCode,
        string memory authenticatorSignature,
        uint dateOfInspection,
        string memory inspectionLocation,
        string memory currentConditionReport,
        string memory pastConditionReport
     * */
    let date = new Date().getTime();
    if (content.dateOfInspection) {
        date = new Date(content.dateOfInspection).getTime();
    }
    let data = {
        ledgerNumber: ledger.ledgerNumber,
        ledgerVersion: ledger.ledgerVersion,
        nameOfPerson: content.nameOfPerson || 'n/a',
        authenticatorCode: content.authenticatorCode || 'n/a',
        authenticatorSignature: content.authenticatorSignature || 'n/a',
        dateOfInspection: date,
        inspectionLocation: content.inspectionLocation || 'n/a',
        currentConditionReport: content.currentConditionReport || 'n/a',
        pastConditionReport: content.pastConditionReport || 'n/a'
    };
    logger.info('AUTHENTICATOR CONTRACT prepareForBlockchain', data);

    return data;
};

const isValidContent = (ledger, content) => {
    if (!content) {
        logger.error(`isValidContent Empty content`);
        return false;
    }

    if (!isNumber(ledger.ledgerNumber) || !isNumber(ledger.ledgerVersion)) {
        logger.error(`isValidContent Invalid ledger Data`);
        return false;
    }
    return true;
}

export default {
    isValidContent: isValidContent,
    prepareForBlockchain: prepareForBlockchain
}