import React from 'react';
import PropTypes from 'prop-types';
import ChaiBaseForm from '../ChaiBaseForm';
import formNames from '../ChaiBaseForm/formNames'
import { withStyles } from '@material-ui/core';
import ChaiTextField from '../ChaiTextField';
import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiDropDown from '../ChaiDropDown';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { updateBrands } from './service';
import chaiErrorHandler from '../../Handler/chaiErrorHandler';
import ChaiImageInput from "../ChaiImageInput";
import ChaiBodyContainer from "../ChaiBodyContainer";
import ChaiTable from '../ChaiTable';

const styles = theme => ({});
let columnData = [
    { id: 'name', label: 'Name' },
    { id: 'link', label: 'Link', link: true },
    { id: 'image', label: 'Image', image: true },
];
class ChaiBranding extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.BRANDING;
        super(props);
        const user = JSON.parse(localStorage.getItem('user'));
        const brands = user.brands;
        const activeBrand = brands && brands.length ? brands.findIndex((brand) => brand.active) : [];
        const selectBrands = this.formatBrands(brands);
        const emptyDataState = {
            capsuleBatch: user.capsuleBatch || "",
            brandName: "",
            brandLink: "",
            brandImage: "",
            brands: brands ? brands : [],
            activeBrand: activeBrand
        };

        super({ ...props, formName, emptyDataState });
        this.state = {
            ...this.state,
            deleteModal: false,
            deletedBrand: {},
            selectBrands: selectBrands,
        }
    }

    //Override permissions
    //Todo think better way to do it
    calculateWritePermission = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        return user ? (user.rol === 'Administrator') || (user.rol === 'TCM Authenticator') || (user.rol === 'Vendor Licensee') : false;
    };

    formatBrands = (brands) => {
        return brands && brands.length ? brands.map((brand, index) => {
            return { id: index, description: brand.name }
        }) : null;
    }

    handleSetActiveBrand = async () => {
        this.setLoadingState(true);
        try {
            const data = this.state.data
            const payload = { capsuleBatch: data.capsuleBatch, brands: data.brands };
            await updateBrands(payload);
            this.setLoadingState(false);
            this.updateLocalStorage(data.brands);
            this.setState({ data, selectBrands: this.formatBrands(data.brands) });
            this.props.showNotification('Brand updated successfully');
        } catch (error) {
            this.setLoadingState(false);
            chaiErrorHandler(this.notify).catch(error);
        }
    }

    handleAddBrand = async () => {
        this.setLoadingState(true);
        try {
            const data = this.state.data
            const { capsuleBatch, brandName, brandLink, brandImage, brands } = data
            if (brandName && brandName.length > 0 &&
                brandLink && brandLink.length > 0 &&
                brandImage && brandImage.length > 0
            ) {
                const newBrand = { name: brandName, link: brandLink, image: brandImage }
                brands.push(newBrand);
                const payload = { capsuleBatch, brands: brands };
                const result = await updateBrands(payload);
                this.updateLocalStorage(data.brands);
                this.setState({
                    data: {
                        brands,
                        brandName: "",
                        brandLink: "",
                        brandImage: "",
                        capsuleBatch,
                        activeBrand: data.activeBrand
                    },
                    selectBrands: this.formatBrands(brands)
                });
                this.props.showNotification('Brand added successfully');
            } else {
                this.props.showNotification('Some Brand attributes are empty or invalid.');
            }
            this.setLoadingState(false);
        } catch (error) {
            this.setLoadingState(false);
            chaiErrorHandler(this.notify).catch(error);
        }
    }

    setActiveBrand = (ev) => {
        const activeBrand = parseInt(ev.target.value);
        const data = this.state.data;
        data.brands = data.brands.map((brand, index) => {
            if (index === activeBrand) {
                brand.active = true;
            } else {
                brand.active = false;
            }
            return brand;
        })
        data.activeBrand = activeBrand;
        this.setState({ data });
        this.updateLocalStorage(data.brands);
    }

    updateLocalStorage = (brands) => {
        const user = JSON.parse(localStorage.getItem('user'));
        user.brands = brands;
        user.capsuleBatch = this.state.data.capsuleBatch;
        localStorage.setItem('user', JSON.stringify(user));
    }

    openModal = (ev, brand) => this.setState({ deleteModal: true, deletedBrand: brand });
    closeModal = (ev) => {
        if (!this.state.loading) {
            this.setState({ deleteModal: false });
        }
    }

    handleDelete = async (event, brand) => {
        try {
            const data = this.state.data
            let isDeletedActive = false
            // handle delete active brand
            data.brands = data.brands.filter((br) => {
                if (br.name !== brand.name) {
                    return true
                }
                if (br.active === true) {
                    isDeletedActive = true
                }
                return false
            });

            this.closeModal();
            if (isDeletedActive && data.brands.length > 0) {
                data.brands[0].active = true
            }

            const payload = { capsuleBatch: data.capsuleBatch, brands: data.brands };

            await updateBrands(payload);
            this.updateLocalStorage(data.brands);
            const activeBrand = data.brands && data.brands.length ? data.brands.findIndex((brand) => brand.active) : [];
            data.activeBrand = activeBrand
            this.setState({ data, selectBrands: this.formatBrands(data.brands) });
        } catch (error) {
            chaiErrorHandler(this.notify).catch(error);
        }

    }

    renderDeleteModal = () => {
        const { deletedBrand, deleteModal } = this.state;
        return (
            <Dialog
                open={deleteModal}
                onClose={this.closeModal}
                aria-labelledby="form-dialog-title"
                maxWidth={"s"}
            >
                <DialogContent>
                    <ChaiBodyContainer >
                        <ChaiRowBodyContainer>
                            <p>This will delete brand {deletedBrand.name}. Are you sure?</p>
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiHeaderButton label="Delete" style={{ width: '20%' }} onClick={(ev) => this.handleDelete(ev, deletedBrand)} />
                            <ChaiHeaderButton label="Do not delete" style={{ width: '80%', marginLeft: "10px" }} onClick={this.closeModal} />
                        </ChaiRowBodyContainer>
                    </ChaiBodyContainer >
                </DialogContent>
            </Dialog>
        )
    }

    render() {
        const user = localStorage.getItem('user');
        const { modal, onClose } = this.props;
        const { brands } = user ? JSON.parse(user) : { brands: [] };

        return (
            <Dialog
                open={modal}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                maxWidth={"md"}
                fullWidth
            >
                <DialogContent>
                    {this.renderDeleteModal()}
                    <ChaiBodyContainer >
                        <ChaiRowBodyContainer>

                            <ChaiTextField
                                {...this.getField('capsuleBatch')}
                                value={this.state.data.capsuleBatch}
                                label={'Capsule Batch'}
                                style={{ width: '50%' }}
                            />
                            <ChaiDropDown
                                onChange={this.setActiveBrand}
                                value={this.state.data.activeBrand}
                                label="Active Brand" style={{ width: '50%' }}
                                items={this.state.selectBrands}
                            />
                            <ChaiHeaderButton label="Save"
                                onClick={this.handleSetActiveBrand}
                                style={{ width: '20%', marginTop: "20px" }}
                                disabled={this.state.loading}
                            />
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiTextField
                                {...this.getField('brandName')}
                                label={'Brand Name'}
                                style={{ width: '30%' }}
                            />

                            <ChaiTextField
                                {...this.getField('brandLink')}
                                label={'Brand Link'}
                                style={{ width: '30%' }}
                            />

                            <ChaiImageInput
                                {...this.getFileField('brandImage')}
                                label={'Brand Image'}
                                style={{ width: '20%' }}
                            />

                            <ChaiHeaderButton label="Add brand"
                                onClick={this.handleAddBrand}
                                style={{ width: '20%', marginTop: "20px" }}
                                disabled={this.state.loading}
                            />

                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiTable
                                columns={columnData}
                                rowsPerPage={10}
                                enableDelete={true}
                                onDelete={this.openModal}
                                data={brands}
                                {...this.props} />
                        </ChaiRowBodyContainer>
                    </ChaiBodyContainer >
                </DialogContent>
            </Dialog>
        )
    }
}

ChaiBranding.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChaiBranding);
