class ContractMethodError extends Error {
    constructor(method, error){
        super(method);
        this.message = `Transaction ${method} unsuccesful`;
        const customErrorIndex = error.message.toLowerCase().indexOf('operation denied')
        if(customErrorIndex > -1){
            //We extract only the custom error from 'Operation Denied'
            this.message+=`: ${error.message.substr(customErrorIndex, error.message.length - customErrorIndex)}`;
        }
        console.error(`ERROR ${method}`, error);
    }
}

export default ContractMethodError;