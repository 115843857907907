const logger = require('../helpers/logger.helper').default('LedgerAdminService');
const BaseService = require("./base.service").default;
const config = require('../providers/config.provider').default.getConfig();
const blockchainHelper = require('../helpers/blockchain.helper').default;
const processor = require("../contentProcessors/admin.processor").default;
const ContentValidationError = require("../exceptions/contentValidation.exception").default;
const ContractMethodError = require("../exceptions/contractMethod.exception").default;
const { provideGas } = require("../providers/wallet.provider").default;

class LedgerAdminService extends BaseService {
    async create(content, ledger, wallet) {
        if (processor.isValidContent(ledger, content)) {
            try {
                const overrides = {
                    gasLimit: 3000000
                };
                
                const gasProvided = await provideGas(wallet);
                if (gasProvided) {
                    const createData = processor.prepareForBlockchain(content, ledger, wallet.address);
                    let contractWithSigner = this.contract.connect(wallet);
                    const receiptTx = await contractWithSigner.createLedgerAdmin(...Object.values(createData), overrides);
                    logger.info('LEDGER ADMIN CONTRACT', createData);

                    //Get receipt and log
                    const receipt = await receiptTx.wait();
                    await logger.infoExternal("createLedgerAdmin", receipt, 'send', ledger);

                    //const readData = await this.get(ledger);
                    //logger.info('LEDGER ADMIN CONTRACT READ', readData);

                    return receipt;
                }
            } catch (ex) {
                await logger.errorExternal('createLedgerAdmin', ex.message, 'send', ledger);
                throw new ContractMethodError('Create Ledger Admin', ex);
            }
        } else {
            throw new ContentValidationError('Ledger Admin Data is invalid');
        }
    }

    async update(content, ledger, wallet) {
        if (processor.isValidContent(ledger, content)) {
            try {
                const overrides = {
                    gasLimit: 3000000
                };
                const updateData = processor.prepareForBlockchain(content, ledger, wallet.address);
                const estimate = await this.contract.estimate.updateLedgerAdmin(...Object.values(updateData), overrides);
                if (await provideGas(wallet, estimate)) {
                    let contractWithSigner = this.contract.connect(wallet);
                    const receiptTx = await contractWithSigner.updateLedgerAdmin(...Object.values(updateData), overrides);

                    //Get receipt and log
                    const receipt = await receiptTx.wait();
                    await logger.infoExternal("updateLedgerAdmin", receipt, 'send', ledger);

                    return receipt;
                }
            } catch (ex) {
                await logger.errorExternal("updateLedgerAdmin", ex.message, 'send', ledger);
                throw new ContractMethodError('Update Ledger Admin', ex);
            }
        } else {
            throw new ContentValidationError('Ledger Admin Data is not valid');
        }
    }

    async get(ledger, userAddress) {
        try {
            const ledgerAdmin = await this.contract.getLedgerAdmin(ledger.ledgerNumber, ledger.ledgerVersion);
            await logger.infoExternal("getLedgerAdmin", ledgerAdmin, 'call', ledger);
            return ledgerAdmin;
        } catch (ex) {
            await logger.errorExternal("getLedgerAdmin", ex.message, 'call', ledger);
            throw new ContractMethodError('Get Ledger Admin', ex);
        }
    }
}

//Mapped contract key
export default (_config = config, _blockchainHelper = blockchainHelper()) => new LedgerAdminService('ChaiLedgerAdmin', _config, _blockchainHelper);
