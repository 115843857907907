import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import classnames from 'classnames';
import { getLedgerCertificate, formOptions } from './service';
import LinearProgress from '@material-ui/core/LinearProgress';
import formNames from '../ChaiBaseForm/formNames';
import CertificateFooter from './footer';
import { getPublicImage, getSignatureInformation } from '../../DataProviders/crudgeneric';
import { Redirect } from 'react-router-dom'
import { isLoggedIn, isAdminPrivileges } from "../ChaiAuthorization/service";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChaiHeaderButton from '../ChaiHeaderButton';
import { ledgerStyles } from './styles';
import { Grid } from '@material-ui/core';
import CarouselArrowLeft from '../../Assets/CarouselArrowLeft.svg'
import CarouselArrowRight from '../../Assets/CarouselArrowRight.svg'
import ItemsCarousel from 'react-items-carousel';

const styles = theme => ledgerStyles(theme);

class ChaiPublicLedger extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ledgerId: '',
            qrCode: '',
            loading: false,
            wineName: null,
            producer: null,
            vintage: null,
            size: null,
            country: null,
            region: null,
            apellation: null,
            classification: null,
            classificationDescription: null,
            authenticationDate: null,
            authenticationLocation: null,
            conditionReport: null,
            conditionDate: null,
            provenanceDate: null,
            provenanceDetails: null,
            authenticatedBy: null,
            bottleImage: null,
            imageUrl: null,
            redirect: false,
            dialogMessage: ' ',
            modal: false,
            activeItemIndex: 0,
            showConditionDetails: false,
            sellerLink: '',
            sellerLogo: '',
            sellerName: '',
            signatureURL: '',
            signatureDescription: ''
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.ledgerId !== this.props.ledgerId) || (prevProps.qrCode !== this.props.qrCode)) {
            this.fetchCertificate();
        }
    }

    componentDidMount() {
        this.fetchCertificate();
    }

    fetchCertificate = async () => {
        const { ledgerId, qrCode } = this.props;

        if (ledgerId || qrCode) {
            this.setState({ loading: true });
            const idForSearch = ledgerId || qrCode;
            let sellerLogoUpdated = false;

            try {
                let options = [];
                if (!this.state.options) {
                    options = await formOptions();
                }
                let ledgerCertificateResult = await getLedgerCertificate(idForSearch, 0);
                if (ledgerCertificateResult) {
                    const resultLedgerNumber = ledgerCertificateResult.ledgerNumber;
                    this.getImageURL(ledgerCertificateResult.bottleImage, 'imageUrl');

                    if (ledgerCertificateResult.sellerLogo && ledgerCertificateResult.sellerLogo.length > 0) {
                        this.getImageURL(ledgerCertificateResult.sellerLogo, 'sellerLogo');
                        sellerLogoUpdated = true;
                    }

                    let authDate = '';
                    if (ledgerCertificateResult.authenticationDateConverted === "") {
                        let fullDate = (ledgerCertificateResult.authenticationDate && 
                            ledgerCertificateResult.authenticationDate.indexOf("T") > -1) ? ledgerCertificateResult.authenticationDate.split("T") : new Date().split(" ");
                        authDate = fullDate[0] ? new Date(fullDate[0] + ' 23:59:59').toDateString() : new Date().toDateString();
                    } else {
                        authDate = ledgerCertificateResult.authenticationDateConverted;
                    }

                    if(sellerLogoUpdated) {
                        ledgerCertificateResult.sellerLogo = this.state.sellerLogo;
                    }

                    this.setState({
                        loading: false, 
                        ...ledgerCertificateResult,
                        ledgerId: resultLedgerNumber,
                        qrCode: qrCode,
                        authenticationDate: authDate,
                        activeItemIndex: ledgerCertificateResult.provenanceDetails.length - 1,
                        options
                    });

                    let sigantureResult = await getSignatureInformation(ledgerCertificateResult.authenticatedBy);
                    if (sigantureResult) {
                        let signatureDesc = '';
                        if (sigantureResult.signatureURL) {
                            const keyNameArray = sigantureResult.signatureURL.split('/');
                            const key = `files/${keyNameArray[keyNameArray.length - 1]}`;

                            signatureDesc = sigantureResult.signatureDescription;
                            this.getImageURL(key, 'signatureURL');
                        }

                        this.setState({
                            signatureDescription: signatureDesc,
                        });
                    }
                }
            } catch (err) {
                const defaultMessage = isLoggedIn() ?
                    (isAdminPrivileges() ? "The selected ledger does not exist. " : "The requested ledger is unavailable.") :
                    "This ledger is not available for public view. Please login or verify the ledger's address";

                const message = err.content.message ? err.content.message : defaultMessage;
                this.setState({ dialogMessage: message, loading: false });
                if (!isLoggedIn()) {
                    if (ledgerId) {
                        this.props.setRedirectAfterLogin("/#/ledger/" + ledgerId);
                    } else {
                        this.props.setRedirectAfterLogin("/#/pl/" + qrCode);
                    }
                }
                //if error redirec to countdown
                window.location = "/#/countdown";
            }
        };
    }

    notify = message => {
        this.props.showNotification(message);
    };

    getField = (label, value, isProvenance, style) => {
        const { classes } = this.props;
        const renderValue = value ? (value !== 'NA' && value !== 'N/A') : false;
        return renderValue ? (
            <div className={isProvenance ? classes.cellProvenance : classes.cell} style={style}>
                <p className={isProvenance ? classes.detailProvenance : classes.detail}> <span className={classes.title}> {label} </span>: {value} </p>
            </div>
        ) : null;
    };

    getOptions = (formName, fieldName) => {
        const data = this.state.options && this.state.options[formName]
            ? this.state.options[formName][fieldName]
            : [];
        return data;
    };

    getFillLevelAndDetail = (report) => {
        const fillLevelOptions = this.getOptions(formNames.BOTTLEDATA, 'fillLevel');
        const shoulderedOptions = this.getOptions(formNames.BOTTLEDATA, 'shouldered');
        const burgundyOptions = this.getOptions(formNames.BOTTLEDATA, 'burgundy');
        const condition = fillLevelOptions.find((obj) => {
            return obj.id === report.fillLevel;
        });

        const detailCondition = report.fillLevel === 1 ?
            shoulderedOptions.find((obj) => {
                return obj.id === report.shoulderedOption;
            }) :
            burgundyOptions.find((obj) => {
                return obj.id === report.burgundyOption;
            });

        return condition ? `${detailCondition ? detailCondition.description : 'No details added'}` : false;
    };

    getLabelConditionForAge = (report) => {
        const conditionOptions = this.getOptions(formNames.BOTTLEDATA, 'condition');
        const condition = conditionOptions.find((obj) => {
            return obj.id === report.labelConditionForAge;
        });

        return condition ? condition.description : false;
    };

    getCapsuleConditionAndDetails = (report) => {
        const conditionOptions = this.getOptions(formNames.BOTTLEDATA, 'condition');
        const otherCapsuleOptions = this.getOptions(formNames.BOTTLEDATA, 'otherCapsule');
        const condition = conditionOptions.find((obj) => {
            return obj.id === report.capsuleCondition;
        });

        const detailConditions = report.capsuleConditionDetails.map((detail) => {

            let capsuleDetail = otherCapsuleOptions.find((obj) => {
                return obj.id === detail;
            });

            return capsuleDetail ? capsuleDetail.description : false;
        });
        return condition ? `${condition.description} - ${detailConditions.length ? detailConditions.join(', ') : 'No details added'}` : false;
    };

    getCorkCondition = (report) => {
        const corkConditionOptions = this.getOptions(formNames.BOTTLEDATA, 'corkCondition');
        const condition = corkConditionOptions.find((obj) => {
            return obj.id === report.corkCondition;
        });

        return condition ? condition.description : false;
    };

    getValueFromOptions = (value, fieldName, formName) => {
        const data = this.getOptions(formName, fieldName);
        const result = data.find((obj) => {
            return obj.id === value
        });
        return result ? result.description : "Not Defined"
    };

    getSize = (value) => {
        return this.getValueFromOptions(value, 'bottleSize', formNames.BOTTLEINFORMATION);
    };

    getCountry = (value) => {
        return this.getValueFromOptions(value, 'country', formNames.BOTTLEINFORMATION);
    };

    getClassification = (value) => {
        return this.getValueFromOptions(value, 'classification', formNames.BOTTLEINFORMATION);
    };

    getStorge = (value) => {
        return this.getValueFromOptions(value, 'typeOfStorage', formNames.AUTHENTICATOR);
    };

    toggleConditionDetails = () => {
        this.setState({
            showConditionDetails: !this.state.showConditionDetails
        })
    };

    getCurrentConditionReport = (report) => {
        const { showConditionDetails } = this.state
        return (
            <Fragment>
                {this.getField('Bottle Condition Summary', `${report.bottleConditionSummary || ""}`, false)}
                <ChaiHeaderButton style={{ width: '100%' }} label='View more details' onClick={this.toggleConditionDetails}></ChaiHeaderButton>
                {
                    showConditionDetails &&
                    <Fragment>
                        {this.getField('Fill Level', `${this.getFillLevelAndDetail(report) || "NA"}`, false)}
                        {this.getField('Capsule Condition', `${this.getCapsuleConditionAndDetails(report) || "NA"}`, false)}
                        {this.getField('Cork Condition', `${this.getCorkCondition(report) || 'NA'}`, false)}
                        {this.getField('Other Labels Conditions', `${report.otherLabelsConditions || "NA"}`, false)}
                    </Fragment>
                }
            </Fragment>
        );
    };

    /* TODO Change this HASH */
    getOwnerHashCode = owner => {
        const ownerStr = JSON.stringify(owner);
        var hash = 0, i, chr;
        if (ownerStr.length === 0) return hash;
        for (i = 0; i < ownerStr.length; i++) {
            chr = ownerStr.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return `#${hash * Math.sign(hash)}`;
    };

    getImageURL = async (image, fieldName) => {
        const resultURL = await getPublicImage(image);
        const data = {};
        data[fieldName] = resultURL.imageUrl;
        this.setState({ ...data })
    };

    openModal = () => {
        this.setState({ modal: true });
    };

    closeModal = () => {
        this.setState({ modal: false });
        this.setRedirect();
    };

    renderModal = () => {
        const { modal } = this.state;

        return (
            <Dialog
                open={modal}
                aria-labelledby="form-dialog-title"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">Informative Message</DialogTitle>
                <DialogContent>
                    <p>{this.state.dialogMessage}</p>
                </DialogContent>
                <DialogActions style={{ marginRight: 25 }}>
                    <ChaiHeaderButton label="Ok" onClick={this.closeModal} />
                </DialogActions>
            </Dialog>
        )
    };


    setRedirect = () => {
        this.setState({
            redirect: true
        })
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/' />
        }
    };

    createCarouselChildren = (items, wineLocationTypeOfStorage) => {
        const { classes } = this.props;
        const { authenticationDate } = this.state;
        const indexForCurrentOwner = items.filter(x => !x.futureOwnershipTransfer).length - 1;

        return items.map((detail, i) => {
            let titleProvenance = i === indexForCurrentOwner ? 'Authenticated & Registered' : 'Ownership Transfer';
            let purchaseInfo = 'N/A';
            switch (parseInt(detail.type)) {
                case 1:
                case 5:
                    purchaseInfo = detail.vendor;
                    break;
                case 2:
                case 6:
                case 7:
                case 8:
                    purchaseInfo = `${detail.auctionHouse}, sale ${detail.saleNumber}, lot ${detail.saleLot}`;
                    break;
                case 3:
                case 4:
                    purchaseInfo = `${detail.purchasedFrom}`;
                    break;
                default:
                    break;
            }

            const bothEncrypted = !detail.futureOwnershipTransfer ?
                (detail.owner.name === 'Confidential' && detail.owner.info === 'Confidential' ? true : false) : false;

            return (
                <Grid key={i} item container direction="column" align="center" justify="center" className={classes.provenanceItemContainer}>
                    <span className={classnames(classes.smText, classes.whiteText)}>{i === indexForCurrentOwner ? 'Current Owner' : !detail.futureOwnershipTransfer ? 'Previous Owner' : ''}</span>
                    {bothEncrypted ?
                        <span className={classnames(classes.lgText, classes.goldenText, classes.textSeparator, classes.lightText)}>{detail.owner.name}</span>
                        :
                        <Fragment>
                            <span className={classnames(classes.lgText, classes.goldenText, classes.lightText)}>{!detail.futureOwnershipTransfer ? detail.owner.name : ''}</span>
                            <span className={classnames(classes.smText, classes.goldenText, classes.textSeparator, classes.lightText)}>{!detail.futureOwnershipTransfer ? detail.owner.info : ''}</span>
                        </Fragment>
                    }
                    {purchaseInfo !== 'N/A' &&
                        <Fragment>
                            <span className={classnames(classes.smText, classes.whiteText)}>{detail.futureOwnershipTransfer ? 'Available for Sale' : 'Purchase'}</span>
                            <span className={classnames(classes.mdText, classes.goldenText, classes.bolderText)}>{purchaseInfo}</span>
                        </Fragment>
                    }
                    {
                        detail.dateOfSale &&
                        <Fragment>
                            <span className={classnames(classes.mdText, classes.goldenText, classes.textSeparator, classes.bolderText)}>{detail.dateOfSale}</span>
                        </Fragment>
                    }
                    {/*<span className={classnames(classes.smText, classes.whiteText)}>Storage</span>*/}
                    {/*<span className={classnames(classes.mdText, classes.goldenText, classes.textSeparator, classes.bolderText)}>{this.getStorge(wineLocationTypeOfStorage ? wineLocationTypeOfStorage : 1)}</span>*/}
                    {
                        detail.futureOwnershipTransfer === false &&
                        <Fragment>
                            <span className={classnames(classes.smText, classes.whiteText)}>{titleProvenance}</span>
                            <span className={classnames(classes.lgText, classes.goldenText, classes.textSeparator, classes.lightText)}>{authenticationDate}</span>
                        </Fragment>
                    }
                </Grid>
            )
        });
    };

    changeActiveItem = (activeItemIndex) => this.setState({ activeItemIndex });

    render() {
        const { classes, width } = this.props;
        let maxItems = width === 'xs' ? 1 : width === 'sm' ? 2 : 3;
        const {
            ledgerId,
            wineName,
            producer,
            vintage,
            size,
            country,
            region,
            apellation,
            classification,
            classificationDescription,
            imageUrl,
            authenticationDate,
            authenticationLocation,
            conditionReport,
            provenanceDetails,
            activeItemIndex,
            sellerLink,
            sellerLogo,
            sellerName,
            signatureURL,
            signatureDescription
        } = this.state;

        return (
            <div className={classes.certificateMainContainer}>
                {this.renderModal()}
                {this.renderRedirect()}
                {this.state.loading && <LinearProgress />}
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                >
                    <Grid
                        item
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-end"
                        md={3}
                        xs={12}
                        className={classes.imageContainer}
                    >
                        {
                            imageUrl &&
                            <img
                                src={imageUrl}
                                style={{ width: '85%', border: "5px solid #000000" }}
                                alt=""
                            />
                        }
                    </Grid>
                    <Grid item container md={9} xs={12} className={classes.detailContainer}>
                        <Grid item xs={12} className={classes.titleContainer}>
                            <div className={classes.mainTitle}>
                                {'Ledger of Authenticity & Provenance'}
                            </div>
                            <div className={classes.subtitle}>Certificate N<sup>o</sup>:&nbsp;{ledgerId}</div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            className={classes.generalDetailsContainer}
                            direction="row"
                            justify="center"
                            alignItems="flex-start"
                        >
                            <Grid item container direction="column" md={6} xs={12}>
                                {this.getField("Name", wineName, false, { width: '100%' })}
                                {this.getField("Producer", producer, false, { width: '100%' })}
                                {this.getField("Vintage", vintage, false, { width: '100%' })}
                                {this.getField(
                                    "Size",
                                    this.getSize(size),
                                    false,
                                    { width: '100%' }
                                )}
                                {this.getField(
                                    "Country",
                                    this.getCountry(country),
                                    false,
                                    { width: '100%' }
                                )}
                                {this.getField(
                                    "Region",
                                    region ? region : '-',
                                    false,
                                    { width: '100%' }
                                )}
                                {this.getField(
                                    "Appellation",
                                    apellation ? apellation : '-',
                                    false,
                                    { width: '100%' }
                                )}
                                {this.getField(
                                    "Classification",
                                    classification === 12
                                        ? classificationDescription
                                        : this.getClassification(classification),
                                    false,
                                    { width: '100%' }
                                )}
                                {this.getField(
                                    "Authentication Date",
                                    authenticationDate,
                                    false,
                                    { width: '100%' }
                                )}
                                {this.getField(
                                    "Authentication Location",
                                    authenticationLocation,
                                    false,
                                    { width: '100%' }
                                )}
                            </Grid>
                            <Grid item container direction="column" md={5} xs={12}>
                                <h1 style={{ marginTop: 0, fontWeight: 40 }}>
                                    Condition Report - {authenticationDate}
                                </h1>
                                {conditionReport
                                    ? this.getCurrentConditionReport(conditionReport)
                                    : ''}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} className={classes.provenanceContainer}>
                    <Grid className={classes.subtitleProvenance}>{`Provenance`}</Grid>
                    {
                        provenanceDetails &&
                        <ItemsCarousel
                            numberOfCards={Math.min(provenanceDetails.length, maxItems)}

                            showSlither={true}
                            firstAndLastGutter={true}
                            freeScrolling={false}

                            requestToChangeActive={this.changeActiveItem}
                            activeItemIndex={activeItemIndex}
                            activePosition={'center'}
                            rightChevron={
                                <img
                                    className={classes.carouselArrow}
                                    src={CarouselArrowRight}
                                    alt=">"
                                />
                            }
                            leftChevron={
                                <img
                                    className={classes.carouselArrow}
                                    src={CarouselArrowLeft}
                                    alt="<"
                                />
                            }
                        >
                            {this.createCarouselChildren(provenanceDetails)}
                        </ItemsCarousel>
                    }
                </Grid>
                <CertificateFooter signature={signatureURL} signatureDescription={signatureDescription} sellerLink={sellerLink || ''} sellerLogo={sellerLogo || ''} sellerName={sellerName || ''} />
            </div>
        )
    }
}
export default withWidth()(withStyles(styles)(ChaiPublicLedger));
