import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import { CardContent, Tooltip } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import OKIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import EmptyIcon from '@material-ui/icons/VisibilityOff';
import WarningIcon from '@material-ui/icons/Warning';

import ChaiTextField from '../ChaiTextField';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiHeaderButton from '../ChaiHeaderButton';

import { getContractData } from '../../Services/ledgers';
import { getProvenance } from '../ChaiProvenance/service';

const styles = theme => ({
    card: { width: '100%', marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#efefef' },
    heading: { display: 'flex', alignItems: 'center' },
    iconError: { color: 'red', marginLeft: 10 },
    iconEmpty: { color: 'gray', marginLeft: 10 },
    iconOK: { color: 'green', marginLeft: 10 },
    iconWarning: { color: 'orange', marginLeft: 10 }
});

class ChaiBlockchainTransactions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ledgerNumber: '',
            ledgerVersion: '0',
            loading: false,
            searched: false,
            contracts: [
                { name: 'ChaiBottleInfoAndUnique', data: null },
                { name: 'ChaiAuthenticator', data: null },
                { name: 'ChaiAuthenticatorWineLocation', data: null },
                /* { name: 'ChaiProvenanceAuctionAndPrivate', data: null },
                { name: 'ChaiProvenanceRetail', data: null }, */
                { name: 'ChaiLedgerAdmin', data: null }
            ]
        }
    }

    handleChange = (e, field) => {
        const data = { ...this.state };
        data[field] = e.target.value;

        this.setState(data);
    };

    getField = (field) => {
        return {
            value: this.state[field],
            onChange: (e) => this.handleChange(e, field),
            keyField: field,
            disabled: this.state.loading
        };
    };

    getPropertyMappingForContract = (contractName, data) => {
        switch (contractName) {
            case 'ChaiBottleInfoAndUnique':
                return {
                    typeOfInput: data[0],
                    vintage: data[1],
                    bottleSize: data[2],
                    bottleByAt: data[3],
                    labelConditionForAge: data[4],
                    notableNicksTearsConditions: data[5],
                    capsuleCondition: data[6],
                    partGroupCaseOrLotParcel: data[7],
                    fullImageBodyHash: data[8]
                }
            case 'ChaiAuthenticator':
                return {
                    nameOfPerson: data[0],
                    authenticatorCode: data[1],
                    authenticatorSignature: data[2],
                    dateOfInspection: data[3],
                    inspectionLocation: data[4],
                    currentConditionReport: data[5],
                    pastConditionReport: data[6]
                }
            case 'ChaiAuthenticatorWineLocation':
                return {
                    wineLocationOne: data[0],
                    wineLocationTwo: data[1],
                    wineLocationThree: data[2],
                    wineLocationFour: data[3]
                }
            case 'ChaiProvenanceAuctionAndPrivate':
                return {
                    auctionHouse: data[0],
                    saleLot: data[1],
                    saleNumber: data[2],
                    consignmentDescription: data[3],
                    purchasedFrom: data[4]
                }
            case 'ChaiProvenanceRetail':
                return {
                    ownerName: data[0],
                    ownerInformation: data[1],
                    currency: data[2],
                    bottleCost: data[3],
                    image: data[4],
                    vendor: data[5]
                }
            case 'ChaiLedgerAdmin':
                return {
                    lwinCode: data[0],
                    qrCode: data[1],
                    rfidCode: data[2],
                    makeLedgerAvailableForSale: data[3],
                    updateConditionReport: data[4],
                    updateOwnership: data[5],
                    encryptOwnerName: data[6],
                    encryptOwnerInformation: data[7]
                }
            default:
                return null;
        }
    };

    getContractsData = async () => {
        try {
            const { ledgerNumber, ledgerVersion, contracts } = this.state;

            this.setState({ loading: true, searched: false });

            const provenances = await getProvenance(ledgerNumber, ledgerVersion);
            console.info('PROVENANCE', provenances);

            let provenanceDetails = null;
            if (provenances && provenances.provenanceDetails) {
                provenanceDetails = provenances.provenanceDetails.map(prov => {
                    const dateOfSale = parseInt(prov.dateOfSale.replace(/-/gi, ''));

                    switch (prov.type) {
                        case 'Private Sale':
                            return {
                                contract: 'ChaiProvenanceAuctionAndPrivate',
                                dateOfSale: dateOfSale
                            };
                        case 'Auction Sale':
                            return {
                                contract: 'ChaiProvenanceAuctionAndPrivate',
                                dateOfSale: dateOfSale
                            };
                        case 'Retail Sale':
                            return {
                                contract: 'ChaiProvenanceRetail',
                                dateOfSale: dateOfSale
                            };
                        default:
                            return null;
                    }
                });
            }

            const contractPromises = contracts.map(c => {
                return getContractData(c.name, { ledgerNumber, ledgerVersion });
            });

            const contractProvenancePromises = provenanceDetails.map(c => {
                return getContractData(c.contract, { ledgerNumber, ledgerVersion }, c.dateOfSale);
            });

            const results = await Promise.all(contractPromises.concat(contractProvenancePromises));

            console.info('CONTRACTS', results);
            let aux = 0;
            const newContracts = results.map((r, index) => {
                if (index > contracts.length - 1) {
                    const object = {
                        name: provenanceDetails[aux].contract,
                        data: (!r.exception && !r.warning) ? this.getPropertyMappingForContract( provenanceDetails[aux].contract, r) : null,
                        exception: r.exception,
                        warning: r.warning
                    };
                    aux++;

                    return object;
                } else {
                    return {
                        name: contracts[index].name,
                        data: (!r.exception && !r.warning) ? this.getPropertyMappingForContract(contracts[index].name, r) : null,
                        exception: r.exception,
                        warning: r.warning
                    };
                }

            });
            console.info('CONTRACT RESULTS', newContracts);

            this.setState({ contracts: newContracts, loading: false, searched: true });
        } catch (error) {
            this.props.showNotification(error);
        }
    };

    render() {
        const { classes } = this.props;
        const { loading, searched, contracts } = this.state;

        return (
            <Card className={classes.card}>
                <CardContent>
                    <ChaiRowBodyContainer style={{ width: '100%' }}>
                        <ChaiTextField
                            {...this.getField('ledgerNumber')}
                            label="LEDGER NUMBER"
                            style={{ width: '20%' }}
                        />
                        <ChaiTextField
                            {...this.getField('ledgerVersion')}
                            label="LEDGER VERSION"
                            style={{ width: '20%' }}
                        />
                        {
                            loading ? <CircularProgress /> :
                                <ChaiHeaderButton
                                    label="SEARCH"
                                    style={{ width: '10%', marginTop: '15px' }}
                                    onClick={this.getContractsData} />
                        }
                        <div style={{ width: '50%' }}></div>
                    </ChaiRowBodyContainer>
                    <ChaiBodyContainer>
                        {
                            searched && contracts.map(c => {

                                const icon = c.exception ? <ErrorIcon className={classes.iconError} /> :
                                    c.warning ? <WarningIcon className={classes.iconWarning} /> :
                                        c.data ? <OKIcon className={classes.iconOK} /> :
                                            <EmptyIcon className={classes.iconEmpty} />;
                                const tooltipMessage = c.exception ? c.exception :
                                    c.warning ? c.warning :
                                        c.data ? 'Information found' : 'No information found';

                                return (
                                    <ExpansionPanel>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography className={classes.heading}>
                                                {c.name}
                                                <Tooltip title={tooltipMessage} >{icon}</Tooltip>
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            {JSON.stringify(c.data ? c.data : '')}
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )
                            })
                        }
                    </ChaiBodyContainer>
                </CardContent>
            </Card>
        )
    }
}

ChaiBlockchainTransactions.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiBlockchainTransactions);
