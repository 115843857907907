const logger = require("../helpers/logger.helper").default("LedgerIdsProcessor")
const isNumber = require("../helpers/number.helper").default.isNumber;

const prepareForBlockchain = (content, ledger, userAddr) => {
    /**
     * From solidity contract:
     *
         uint ledgerNumber,
        uint ledgerVersion,
        string memory IDType,
        string memory IDNumber
     * */

    return {
        ledgerNumber: ledger.ledgerNumber,
        ledgerVersion: ledger.ledgerVersion,
        //TODO change this when contract is clarified
        IDType: content.idType || 'n/a',
        IDNumber: content.idNumber || 'n/a'
    };
};

const isValidContent = (ledger, content) => {
    if(!content){
        logger.error(`isValidContent Empty content`);
        return false;
    }

    if(!isNumber(ledger.ledgerNumber) || !isNumber(ledger.ledgerVersion)){
        logger.error(`isValidContent Invalid ledger Data`);
        return false;
    }
    const toCheck = [
        'idType',
        'idNumber'
    ]
    for(let f of toCheck){
        if(typeof(content[f]) === 'undefined'){
            logger.error(`isValidContent Invalid ${f}`);
            return false;
        }
    }
    return true;
}

export default {
    isValidContent: isValidContent,
    prepareForBlockchain: prepareForBlockchain
}