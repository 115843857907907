import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const isTablet = window.innerWidth <= 1024;
const styles = theme => ({
    bootstrapRoot: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: isTablet ? '1em' : '1.2em',
        borderRadius: 0,
        padding: isTablet ? '5px 40px' :'10px 40px',
        border: '1px solid',
        backgroundColor: '#3f3e3e',
        borderColor: '#b9b9b9',
        color: '#ffffff',
        fontFamily: "'Source Sans Pro', sans-serif",
        '&:hover': {
            backgroundColor: '#3f3e3e',
            color: '#ffffff',
            boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.49)'
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#b9b9b9',
            borderColor: '#b9b9b9',
            color: '#ffffff'
        }
    },
});

function ChaiMainButton(props) {
    const { classes, label, onClick, disabled } = props;

    return (
        <Button
            disabled={disabled}
            variant="flat"
            color="primary"
            disableRipple
            className={classNames(classes.margin, classes.bootstrapRoot)}
            onClick={onClick}
        >
            {label}
        </Button>
    );
}

ChaiMainButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiMainButton);
