const logger = require("../helpers/logger.helper").default("ProvenanceProcessor")
const isNumber = require("../helpers/number.helper").default.isNumber;

const prepareAuctionAndPrivateDataForBlockChain = (content, ledger, userAddr)  => {
    /**
     * From solidity contract:
     *
     *  uint ledgerNumber,
        uint dateOfSale,
        string memory auctionHouse,
        string memory saleLot,
        string memory saleNumber,
        string memory consignmentDescription,
        string memory purchasedFrom
     */
    let date = new Date();
    if (content.dateOfSale) {
        //date = new Date(Date.parse(content.dateOfSale));
        date = content.dateOfSale.replace(/-/gi, '');
    }
    return {
        ledgerNumber: ledger.ledgerNumber,
        //dateOfSale: parseInt(`${date.getFullYear()}${date.getMonth()}${date.getDay()}`),
        dateOfSale: parseInt(date),
        auctionHouse: content.auctionHouse || 'n/a',
        saleLot: content.saleLot || 'n/a',
        saleNumber: content.saleNumber || 'n/a',
        consignmentDescription: content.consignmentDescription || 'n/a',
        purchasedFrom: content.purchasedFrom || 'n/a',
    };
};

const prepareRetailDataForBlockChain = (content, ledger, userAddr) => {
    /**
     * From solidity contract:
     *
        uint ledgerNumber,
        uint dateOfSale,
        string memory ownerName,
        string memory ownerInformation,
        uint currency,
        string memory bottleCost,
        string memory image,
        string memory vendor
     */

    let date = 0;
    if (content.dateOfSale) {
        //date = new Date(Date.parse(content.dateOfSale));
        date = content.dateOfSale.replace(/-/gi, '');
    }
    return {
        ledgerNumber: ledger.ledgerNumber,
        //dateOfSale: parseInt(`${date.getFullYear()}${date.getMonth()}${date.getDay()}`),
        dateOfSale: parseInt(date),
        ownerName: (content.ownerName ? content.ownerName : 'n/a'),
        ownerInformation: (content.ownerInformation ? content.ownerInformation : 'n/a'),
        currency: content.currency || 0,
        //TODO change when contract is changed
        bottleCost: content.bottleCost? content.bottleCost.toString() : 'n/a',
        image: content.image || 'n/a',
        vendor: content.vendor ||  'n/a'
    };
};

const isValidAuctionAndPrivateContent = (ledger, content) => {
    if(!content){
        logger.error(`isValidAuctionAndPrivateContent Empty content`);
        return false;
    }

    if(!isNumber(ledger.ledgerNumber) || !isNumber(ledger.ledgerVersion)){
        logger.error(`isValidAuctionAndPrivateContent Invalid ledger Data`);
        return false;
    }
    return true;
};

const isValidRetailContent = (ledger, content) => {
    if(!content){
        logger.error(`isValidRetailContent Empty content`);
        return false;
    }

    if(!isNumber(ledger.ledgerNumber) || !isNumber(ledger.ledgerVersion)){
        logger.error(`isValidRetailContent Invalid ledger Data`);
        return false;
    }
    return true;
};

export default {
    retail: {
        isValidContent: isValidRetailContent,
        prepareForBlockchain: prepareRetailDataForBlockChain
    },
    auctionAndPrivate: {
        isValidContent: isValidAuctionAndPrivateContent,
        prepareForBlockchain: prepareAuctionAndPrivateDataForBlockChain
    }
}