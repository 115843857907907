import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChaiTextField from '../ChaiTextField';
import ChaiImageInput from '../ChaiImageInput';
import ChaiDropDown from '../ChaiDropDown';
import ChaiSwitch from '../ChaiSwitch';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBaseForm from '../ChaiBaseForm';
import ChaiFooterContainer from "../ChaiFooterContainer";
import ChaiBodyContainerHeader from '../ChaiBodyContainerHeader'
import { getGlass, saveGlass } from './service';
import LinearProgress from '@material-ui/core/LinearProgress';
import formNames from '../ChaiBaseForm/formNames';

const styles = theme => ({

});

class ChaiGlass extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.GLASS;
        let emptyDataState = {
            puntTexture: null,
            puntDepth: null,
            glassColor: 1,
            puntBase: null,
            puntBaseDescription: null,
            puntImage: ' ',
            glassProduction: 4,
            glassOther: false,
            glassOtherDescription: null,
            kickShape: null
        };

        super({ ...props, formName, emptyDataState, loadTemplate: true });
        this.state = {
            ...this.state,
            query: '',
            results: [],
            softLoad: true,
        };

        const { addSetTemplateFunction } = props;
        addSetTemplateFunction(this.state.setTemplate);
    }

    getElement = async (ledger, version) => {
        await this.getOptionsWithFormName(formNames.GLASS);
        return getGlass(ledger.ledgerNumber, 0)
            .then(result => {
                return result ? result : this.emptyDataState;
            });
    };

    saveElement = async (ledger, version, element) => {
        return saveGlass(ledger.ledgerNumber, version, element);
    };

    render() {
        const formName = formNames.GLASS;
        const { hideFooter } = this.props;
        return (
            <Fragment>
                {this.state.loading && <LinearProgress />}
                <ChaiBodyContainer style={hideFooter && { paddingBottom: '0' }}>
                    <ChaiBodyContainerHeader {...this.props}>
                        <ChaiRowBodyContainer>
                            <ChaiDropDown
                                {...this.getField('glassColor')}
                                {...this.getTemplateFieldStyle('glassColor', { width: '25%' })}
                                label="Glass Color/Density*"
                                items={this.state.options[formName]['color'] || []}
                            />
                            <ChaiDropDown
                                {...this.getField('puntTexture')}
                                {...this.getTemplateFieldStyle('puntTexture', { width: '25%' })}
                                label="Punt Base Markings - Texture*"
                                items={this.state.options[formName]['markings'] || []}
                            />
                            <ChaiDropDown
                                {...this.getField('puntDepth')}
                                {...this.getTemplateFieldStyle('puntDepth', { width: '25%' })}
                                label="Punt Depth*"
                                items={this.state.options[formName]['depth'] || []}
                            />
                            {this.state.data.puntDepth != null ? (
                                <ChaiImageInput
                                    label="Punt Image"
                                    {...this.getTemplateFieldStyle('puntImage', { width: '10%' })}
                                    {...this.getFileField('puntImage')}
                                />
                            ) : <div style={{ width: '10%' }}></div>}
                            <div style={{ width: '15%' }}></div>
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiDropDown
                                {...this.getField('puntBase')}
                                {...this.getTemplateFieldStyle('puntBase', { width: '25%' })}
                                label="Punt Base Shape*"
                                items={this.state.options[formName]['shape'] || []}
                            />
                            {
                                this.state.data.puntBase === 7 ?
                                    <ChaiTextField
                                        {...this.getField('puntBaseDescription')}
                                        {...this.getTemplateFieldStyle('puntBaseDescription', { width: '25%' })}
                                        label="DESCRIPTION*"
                                    /> : <div style={{ width: '25%' }}></div>
                            }
                            <ChaiDropDown
                                {...this.getField('glassProduction')}
                                {...this.getTemplateFieldStyle('glassProduction', { width: '25%' })}
                                label="Glass Production Method*"
                                items={this.state.options[formName]['method'] || []}
                            />
                            <ChaiDropDown
                                {...this.getField('kickShape')}
                                {...this.getTemplateFieldStyle('kickShape', { width: '25%' })}
                                label="Kick Shape*"
                                items={this.state.options[formName]['kickShape'] || []}
                            />
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                {...this.getField('glassOther', true)}
                                {...this.getTemplateFieldStyle('glassOther', { width: '25%' })}
                                label="Glass Other"
                            />
                            {this.state.data.glassOther && (
                                <Fragment>
                                    <ChaiTextField
                                        {...this.getField('glassOtherDescription')}
                                        {...this.getTemplateFieldStyle('glassOtherDescription', { width: '50%' })}
                                        label="Glass Other Description"
                                    />
                                </Fragment>
                            )}
                            <div style={{ width: '25%' }}></div>
                        </ChaiRowBodyContainer>
                    </ChaiBodyContainerHeader>
                </ChaiBodyContainer>
            </Fragment>
        );
    }
}

ChaiGlass.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiGlass);
