import ChaiBlockchain from './blockchain';

// There are 8 contracts, but the ones used for ChaiVault ledgers purposes are 6

const ledger = ChaiBlockchain.ledger;
const security = ChaiBlockchain.security;
const wallet = ChaiBlockchain.wallet;
const NoBalanceError = require('../Blockchain/exceptions/noBalance.exception');

// contracts chain
const ledgerSegments = {
    'ChaiLedgerAdmin': {
        'methods': {
            'add': 'createLedgerAdmin',
            'update': 'updateLedgerAdmin',
            'get': 'getLedgerAdmin'
        }
    },
    'ChaiBottleInfoAndUnique': {
        'methods': {
            'add': 'createBottleInfoAndUnique',
            'update': 'updateBottleInfoAndUnique',
            'get': 'getBottleInfoAndUnique'
        }
    },/* 
    'ChaiLedgerIDs': {
        'methods': {
            'add': 'createLedgerID'
        }
    }, */
    'ChaiProvenanceAuctionAndPrivate': {
        'methods': {
            'add': 'createProvenanceAuctionAndPrivate',
            'get': 'getProvenanceAuctionAndPrivate'
        }
    },
    'ChaiProvenanceRetail': {
        'methods': {
            'add': 'createProvenanceRetail',
            'get': 'getProvenanceRetail'
        }
    },
    'ChaiAuthenticator': {
        'methods': {
            'add': 'createAuthenticator',
            'get': 'getAuthenticator'
        }
    },
    'ChaiAuthenticatorWineLocation': {
        'methods': {
            'add': 'createWineLocation',
            'get': 'getWineLocation'
        }
    }
};

// DEPRECATED
export async function addNewLedgerData(privateKey, ledgerHeader, ledgerData) {
    let exceptions = [];
    try {
        exceptions = await sendAllContracts(privateKey, ledgerHeader, ledgerData, 'add');
    } catch (ex) {
        exceptions.push(ex.message);
    }
    return {
        success: exceptions.length === 0,
        errors: exceptions
    }
}

export async function getContractData(contractName, ledgerInfo, dateOfSale) {
    try {
        let result = null;
        const segment = ledgerSegments[contractName];

        if (contractName === 'ChaiProvenanceRetail' || contractName === 'ChaiProvenanceAuctionAndPrivate') {
            result = await ledger[segment.methods['get']](ledgerInfo.ledgerNumber, dateOfSale);
        } else {
            result = await ledger[segment.methods['get']](ledgerInfo);
        }

        return result;
    } catch (ex) {
        return { exception: ex.message };
    }
}

async function sendAllContracts(privateKey, ledgerHeader, ledgerData, method) {
    let exceptions = [];
    const userWallet = wallet.getUserWallet(privateKey);
    const canRequest = await security.canRequest(userWallet.address, ledgerData.length);

    if (!canRequest) {
        throw new NoBalanceError('Create Ledger Admin data');
    } else {
        for (let dataSegment of ledgerData) {
            try {
                const segment = ledgerSegments[dataSegment.contractName];
                console.info('sendAllContracts SEGMENT', segment);

                //We iterate the data in [] form as requests and execute the proper method based on the prior mapping
                segment && await ledger[segment.methods[method]](dataSegment.contractFields, ledgerHeader, userWallet);
            } catch (ex) {
                exceptions.push(ex && ex.message ? ex.message : ex);
                break;
            }
        }
        return exceptions;
    }
}
