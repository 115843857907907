export default {
    'LEDGERS': 'ledgers',
    'AUTHENTICATOR': 'authenticator',
    'CLOSURECORK': 'closureCork',
    'BOTTLEINFORMATION': 'bottleInformation',
    'MEASUREMENTS': 'measurements',
    'BOTTLEDATA': 'bottleData',
    'ADMINISTRATIVE': 'administrative',
    'CLOSURECAPSULE': 'closureCapsule',
    'ANTIFRAUD': 'antifraud',
    'PROVENANCE': 'provenance',
    'MAINLABEL': 'mainLabel',
    'GLASS': 'glass',
    'OTHERLABEL': 'otherLabel',
    'COUNTERFEIT': 'counterfeit',
    'SHARE': 'share',
    'BRANDING': 'branding',
    'MULTIBOTTLEENTRY': 'multiBottleEntry'
}
