import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  root: {
    padding: `${theme.spacing.unit * 6}px ${theme.spacing.unit * 3}px 0`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit / 2,
    top: theme.spacing.unit / 2,
    color: theme.palette.grey[500],
  },
})

const ChaiModalHeader = (props) => {
  const { children, classes, onClose, TypographyProps } = props

  return (
    <div className={classes.root}>
      <Typography align="center" variant="title" {...TypographyProps}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </div>
  )
}

ChaiModalHeader.displayName = 'ChaiModalHeader'

ChaiModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  TypographyProps: PropTypes.object,
}

export default withStyles(styles)(ChaiModalHeader)