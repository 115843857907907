const { dpCrud, UPDATE } = require('../../DataProviders/crudgeneric');
const queryString = require('query-string');
const BASE_URL = process.env.REACT_APP_CHAI_SECURITY_API_HOST || 'gateway/';

/**
 * 
 * 
 */
export async function approveOrRevokeProvenance(data) {
    try {
        const url = `provenances/approveOrRevoke`;
        const response = await dpCrud(UPDATE, url,{data});
        return response;
    } catch (ex) {
        throw ex;
    }
}

/**
 * 
 * 
 */
export const getPendingOwnershipLedgersByUser = async (config = {
    page: 1,
    itemsPerPage: 10,
    ownLedger: true,
}) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const qString = queryString.stringify(config);
    const url = `${BASE_URL}users/${user.id}/ledgers/pendingOwnership?${qString}`;
    const options = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },method: 'GET'
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (response.status !== 200) {
            throw result;
        }
        return result;
    } catch (error) {
        console.error('ERROR getLedgersByUser()', error);
        throw error.error ? error.error : error;
    }
};