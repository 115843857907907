import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { lighten } from '@material-ui/core/styles/colorManipulator';


const styles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
        backgroundColor: '#efefef',
        border: 0
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        marginLeft: '25px',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '12px',
        color: 'rgb(63, 62, 62)',
        fontWeight: 600,
        textTransform: 'uppercase'
    },
});

const ChaiTableToolbar = props => {
    const {  classes, title } = props;

    return (
        <Toolbar className={classes.root} >
            <div className={classes.title}>
                {title}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
               
            </div>
        </Toolbar>
    );
};

ChaiTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiTableToolbar);
