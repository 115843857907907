const { dpCrud,  CREATE, GET_ONE } = require('../../DataProviders/crudgeneric');
/**
 *
 * @param ledgerNumber
 * @param ledgerVersion
 * @returns {Promise<Response>}
 */
export async function getBottleData(ledgerNumber, ledgerVersion) {
    try {
        const data = await dpCrud(GET_ONE, "bottlesData", {
            ledger: {
                ledgerNumber: ledgerNumber,
                ledgerVersion: ledgerVersion
            }
        });
        return data;
    } catch (ex) {
        console.error("Error getting bottle data>> ", ex);
    }
    return null;
}

/**
 *
 * @param data
 * @returns {Promise<AxiosPromise<any>>}
 */
export async function saveBottleData(ledgerNumber, ledgerVersion, data) {
        const response = await dpCrud(CREATE, "bottlesData/data", {
            data: {...data, ledgerNumber, ledgerVersion }
        });
        console.info("saving response >> ", response);

        return response;
}
