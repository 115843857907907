import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiMainButton from '../ChaiMainButton';
import ConfigIcon from '@material-ui/icons/Settings';
import ChaiVaultLogo from '../../Assets/ChaiVaultLogo.png';
import ChaiTextField from '../ChaiTextField';
import ChaiMenu from '../ChaiMenu';
import ChaiMenuItem from '../ChaiMenuItem';
import { getLedgerByRfid, getLedgerByBarcode, getMenuPermissions } from './service';
import chaiErrorHandler from '../../Handler/chaiErrorHandler';
import { logOut } from '../ChaiAuthorization/service';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChaiMassiveSale from '../ChaiSales/ChaiMassiveSale'
import ChaiBranding from '../ChaiBranding';
import ChaiBulkBlockchain from '../ChaiBulkBlockchain/';
import ChaiProvenancesBulkBlockchain from '../ChaiBulkBlockchain/provenances';
import { Link } from 'react-router-dom';

const isTablet = window.innerWidth <= 1024;
const defaultPermissions = {
    "share": false,
    "brands": false,
    "uploadCSV": false,
    "blockchain": false,
    "sale": false,
    "bulkBlockchain": false,
    "bulkProvenancesBlockchain": false
};

const styles = theme => ({
    main: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 },
    icon: { color: '#b9b9b9', fontSize: '30px', '&:hover': { color: '#3f3e3e', cursor: 'pointer' } },
    title: { fontSize: isTablet ? '1.2em' : '1.8em', width: '45%', textAlign: 'center', color: '#3f3e3e', fontFamily: '"Source Sans Pro", sans-serif', fontWeight: 600, display: 'flex', flexDirection: 'column', textTransform: 'uppercase' },
    titleIconContainer: { padding: '20px', display: 'flex', justifyContent: 'center', height: 'auto' },
    titleUnderline: { borderBottom: '1px solid #dee0df', width: '50%', marginLeft: 'auto', marginRight: 'auto' },
    buttonsContainer: { width: isTablet ? '35%' : '35%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', margin: 0 },
    menuItem: { fontFamily: '"Source Sans Pro", sans-serif', fontSize: '1em' },
    menuItemDisabledBottom: {
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '1em',
        fontWeight: 'bold',
        color: 'gray',
        padding: 15,
        borderBottom: 'solid 1px lightgray'
    },
    menuItemDisabled: {
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '1em',
        fontWeight: 'bold',
        color: 'gray',
        padding: 15,
        outline: 'none'
    },
    link: {
        textDecoration: "none",
        '&:hover': { textDecoration: "none", color: "black" },
        '&:visited': { textDecoration: "none", color: "black" },
        '&:link': { textDecoration: "none", color: "black" },
        '&:active': { textDecoration: "none", color: "black" }
    },
    divider: { borderTop: 'solid 1px lightgray' },
    logo: { width: '25%', textAlign: 'center', '&:hover': { cursor: 'pointer' } },
    tabRoot: {
        boxShadow: 'inset 7px -8px 23px -2px rgba(0,0,0,0.47)',
        backgroundColor: '#3f3e3e',
        color: 'white',
        '&$tabSelected': {
            color: '#3f3e3e',
            backgroundColor: '#efefef',
            boxShadow: 'none'
        }
    },
    tabsRoot: {
        backgroundColor: '#efefef'
    },
    tabSelected: {},
    tabsIndicator: {
        backgroundColor: "#632222"
    }
});

const ChaiHeader = (props) => {
    const {
        classes,
        menuTitle,
        showNotification,
        handleSelectLedger,
        updateLedgerInfo,
        openModal,
        openModalUpload,
        handleEnableShare,
        shareEnabled,
        ...inheritableProps
    } = props;
    const [icon, setIconTarget] = React.useState(null);
    const [modal, setModal] = React.useState(false);
    const [saleModal, setSaleModal] = React.useState(false);
    const [bulkBlockchainModal, setbulkBlockchainModal] = React.useState(false);
    const [
        bulkProvenancesBlockchainModal,
        setProvenancesBulkBlockchainModal
    ] = React.useState(false);
    const [form, setForm] = React.useState("");
    const [rfidNumber, setRfidNumber] = React.useState("");
    const [barcodeNumber, setBarcodeNumber] = React.useState("");
    const [searching, setSearching] = React.useState(false);
    const [brands, setBrandsModal] = React.useState(false);
    const [menu, setMenu] = React.useState([]);

    function openMenu(event) {
        setIconTarget(event.currentTarget);
    }

    function openChipModal(form) {
        setBarcodeNumber("");
        setRfidNumber("");

        setModal(true);
        setForm(form);
    }

    function closeChipModal() {
        setModal(false);
    }

    useEffect(() => {
        async function build() {
            try {
                const permissions = await getMenuPermissions();
                setMenu(buildCogMenu(permissions));
            } catch (e) {
                setMenu(buildCogMenu(defaultPermissions));
            }
        };
        build();
    }, [])

    async function handleSendRfid() {
        if (rfidNumber.length === 0) {
            showNotification("Empty RFID Code");
            return;
        }

        try {
            setSearching(true);
            const ledger = await getLedgerByRfid(rfidNumber);
            ledger.lastEdited = new Date(ledger.lastEdited).toLocaleDateString();
            setSearching(false);
            if (ledger) {
                handleSelectLedger(ledger);
                closeChipModal();
            }
        } catch (error) {
            setSearching(false);
            chaiErrorHandler(showNotification).catch(error);
        }
    }

    async function handleSendBarcode() {
        if (barcodeNumber.length === 0) {
            showNotification("Empty Barcode");
            return;
        }

        try {
            setSearching(true);
            const ledger = await getLedgerByBarcode(barcodeNumber);
            ledger.lastEdited = new Date(ledger.lastEdited).toLocaleDateString();
            setSearching(false);
            if (ledger) {
                handleSelectLedger(ledger);
                closeChipModal();
            }
        } catch (error) {
            setSearching(false);
            chaiErrorHandler(showNotification).catch(error);
        }

    }

    function onChangeBarcode(e) {
        if (e.key === 'e') {
            e.preventDefault()
        }
        setBarcodeNumber(e.target.value);
        if (e.key === 'Enter') {
            handleSendBarcode();
        }
    }

    function onChangeRfid(e) {
        if (e.key === 'e') {
            e.preventDefault()
        }
        setRfidNumber(e.target.value);
        if (e.key === 'Enter') {
            handleSendRfid();
        }
    }

    function renderModal() {
        const scanHandler = form === 'rfid' ? handleSendRfid : handleSendBarcode;
        const changeHandler = form === 'rfid' ? onChangeRfid : onChangeBarcode;
        const data = form === 'rfid' ? rfidNumber : barcodeNumber;

        return (
            <Dialog
                open={modal}
                aria-labelledby="form-dialog-title"
                maxWidth={"md"}
                fullWidth
            >
                <DialogTitle id="form-dialog-title">{'PLEASE READ THE CODE NOW...'}</DialogTitle>
                <DialogContent>
                    {renderScanModal(scanHandler, changeHandler, data)}
                </DialogContent>
            </Dialog>
        )
    };

    function renderSalesModal() {
        return (
            <Dialog
                open={saleModal}
                aria-labelledby="form-dialog-title"
                maxWidth={"md"}
                fullWidth
            >
                <DialogTitle id="form-dialog-title">SALES VIEW</DialogTitle>
                <DialogContent>
                    <ChaiMassiveSale {...inheritableProps} onApprove={() => setSaleModal(false)} />
                </DialogContent>
                <DialogActions style={{ marginRight: 25 }}>
                    <ChaiHeaderButton label="Cancel" onClick={() => setSaleModal(false)} />
                </DialogActions>
            </Dialog>
        )
    };

    function renderBulkBlockchainModal() {
        return (
            <Dialog
                open={bulkBlockchainModal}
                aria-labelledby="form-dialog-title"
                maxWidth={"sm"}
                fullWidth
            >
                <DialogTitle id="form-dialog-title">BULK BLOCKCHAIN PROCESS</DialogTitle>
                <DialogContent>
                    <ChaiBulkBlockchain {...inheritableProps} />
                </DialogContent>
                <DialogActions style={{ marginRight: 25 }}>
                    <ChaiHeaderButton label="Cancel" onClick={() => setbulkBlockchainModal(false)} />
                </DialogActions>
            </Dialog>
        )
    };

    function renderProvenancesBulkBlockchainModal() {
        return (
            <Dialog
                open={bulkProvenancesBlockchainModal}
                aria-labelledby="form-dialog-title"
                maxWidth={"sm"}
                fullWidth
            >
                <DialogTitle id="form-dialog-title-prov">BULK PROVENANCES BLOCKCHAIN PROCESS</DialogTitle>
                <DialogContent>
                    <ChaiProvenancesBulkBlockchain {...inheritableProps} />
                </DialogContent>
                <DialogActions style={{ marginRight: 25 }}>
                    <ChaiHeaderButton label="Cancel" onClick={() => setProvenancesBulkBlockchainModal(false)} />
                </DialogActions>
            </Dialog>
        )
    };

    function renderScanModal(scanHandler, changeHandler, data) {
        return (
            <div>
                <div>
                    <ChaiTextField
                        label="Or enter it manually"
                        value={data}
                        maxlength={80}
                        style={{ width: '100%' }}
                        onChange={(e) => changeHandler(e)}
                        autoFocus={true}
                    />
                </div>
                <div style={{ display: 'flex', float: 'right', marginTop: 20 }}>
                    {
                        searching ? <CircularProgress /> :
                            <Fragment>
                                <ChaiMainButton label={'Cancel'} onClick={closeChipModal} />
                                <div style={{ width: 20 }}></div>
                                <ChaiHeaderButton label={'Search'} onClick={scanHandler} />
                            </Fragment>
                    }
                </div>
            </div>
        )
    }

    function handleClose() {
        setIconTarget(null);
    }

    function handleCloseUpload() {
        setIconTarget(null);
    }

    function localLogout() {
        handleClose();
        logOut();
    }

    function handleBlockchainMenu() {
        handleClose();
        openModal();
    }

    function handleUploadCSVMenu() {
        handleCloseUpload();
        openModalUpload();
    }

    const menuOptions = [
        { "name": "sale", "component": <ChaiMenuItem className={classes.menuItem} onClick={() => setSaleModal(true)}>Sales View</ChaiMenuItem> },
        { "name": "share", "component": <ChaiMenuItem className={classes.menuItem} onClick={() => handleEnableShare(!shareEnabled)}>Enable Share Ledgers</ChaiMenuItem> },
        { "name": "brands", "component": <ChaiMenuItem className={classes.menuItem} onClick={() => setBrandsModal(!brands)}>Brands</ChaiMenuItem> },
        { "name": "uploadCSV", "component": <ChaiMenuItem className={classes.menuItem} onClick={handleUploadCSVMenu}>{'Import/Export Data'}</ChaiMenuItem> },
        { "name": "blockchain", "component": <ChaiMenuItem className={classNames(classes.menuItem, classes.divider)} onClick={handleBlockchainMenu}>{'Blockchain & User Administration'}</ChaiMenuItem> },
        { "name": "bulkBlockchain", "component": <ChaiMenuItem className={classes.menuItem} onClick={() => setbulkBlockchainModal(true)}>{'Bulk Blockchain Process'}</ChaiMenuItem> },
        { "name": "bulkProvenancesBlockchain", "component": <ChaiMenuItem className={classes.menuItem} onClick={() => setProvenancesBulkBlockchainModal(true)}>{'Bulk Provenances Blockchain Process'}</ChaiMenuItem> }
    ];

    function buildCogMenu(permissions) {
        return menuOptions.filter(item => permissions[item.name]);
    };

    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const userInfo = user ? `${user.name}, ${user.rol}` : '';
    const userID = user ? `User ID: ${user.id}` : '';

    function isRootPath() {
        return window.location.hash === '#/';
    }

    return (
        <div className={classes.main}>
            {renderModal()}
            {renderSalesModal()}
            {renderBulkBlockchainModal()}
            {renderProvenancesBulkBlockchainModal()}
            <ChaiBranding handleComplete={() => { }} handleIncomplete={() => { }} {...inheritableProps} modal={brands} onClose={() => { setBrandsModal(false) }} skipStepper={true} />
            <div className={classes.logo}>
                <img
                    src={ChaiVaultLogo}
                    style={{ width: isTablet ? '120%' : '100%' }}
                    onClick={props.handleGoHome}
                />
            </div>
            <div style={{ width: "5%" }} />
            <div className={classes.title}>
                <div className={classes.titleIconContainer}>
                    {menuTitle}
                </div>
                <div className={classes.titleUnderline} />
            </div>
            <div className={classes.buttonsContainer}>
                <ChaiHeaderButton label={'VIEW LEDGER FROM CHIP'} onClick={() => openChipModal("rfid")} style={{ marginRight: "5px", fontSize: "0.8em", maxWidth: "120px", padding: "8px" }} disabled={!isRootPath()} />
                <ChaiHeaderButton label={'VIEW LEDGER FROM BARCODE'} onClick={() => openChipModal("barcode")} style={{ marginRight: "5px", fontSize: "0.8em", maxWidth: "120px", padding: "8px" }} disabled={!isRootPath()} />
                {user && user.rol !== 'Collector' &&
                    <ChaiHeaderButton
                        label={'+ ADD A NEW BOTTLE'}
                        onClick={props.handleAddNewBottle}
                        style={{ fontSize: "0.8em", maxWidth: "120px", padding: "8px" }}
                    />
                }
                <div style={{ width: '2%' }} />
                <ConfigIcon className={classes.icon} onClick={openMenu} />
                <ChaiMenu
                    id="simple-menu"
                    anchorEl={icon}
                    open={Boolean(icon)}
                    onClose={handleClose}
                >
                    <div className={classes.menuItemDisabled} classes={{ $focus: classes.menuItemDisabledFocus }} >{userInfo}</div>
                    <div className={classes.menuItemDisabledBottom} classes={{ $focus: classes.menuItemDisabledFocus }}>{userID}</div>
                    {menu && menu.length && menu.map((item, i) => <div key={i}>{item.component}</div>)}
                    <Link to={"/profile"} onClick={() => handleClose()} className={classes.link}><ChaiMenuItem className={classNames(classes.menuItem, classes.divider)} > Profile</ChaiMenuItem></Link>
                    <ChaiMenuItem className={classNames(classes.menuItem, classes.divider)} onClick={localLogout}>Log Out</ChaiMenuItem>
                </ChaiMenu>
            </div>
        </div>
    )
};

ChaiHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ChaiHeader);
