const { dpCrud, GET_LIST } = require('../DataProviders/crudgeneric');

export const getRole = async userId => {
    try {
        const response = await dpCrud(GET_LIST, `users/${userId}/getRole`);
        if(response && response.data)
            return response.data.role;

        throw 'An error occurred while retrieving role for the user';
    } catch(err) {
        console.error(`ERROR getRole ${userId}`, err);
        throw err;
    }
};
