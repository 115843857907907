import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import ChaiTextField from '../ChaiTextField';
import ChaiBaseForm from "../ChaiBaseForm";
import ChaiSwitch from "../ChaiSwitch";
import { getOtherLabel, saveOtherLabel } from './service';
import formNames from '../ChaiBaseForm/formNames';

const fieldHeight = 66;

const styles = theme => ({
    field: {
        display: 'flex',
        height: '66px',
        position: 'relative'
    },
    fieldBody: {
        alignItems: 'center',
        display: 'inline-flex',
        margin: '0 auto'
    },
    height2t: {
        height: `${fieldHeight * 2}px`
    }
});

class ChaiOtherLabel extends ChaiBaseForm {

    constructor(props) {
        let formName = formNames.OTHERLABEL;
        let emptyDataState = {
            producerBackLabel: false,
            healthWarningLabel: false,
            vintageNeckLabel: false,

            importLabel: false,
            importLabelDescription: '',

            otherBackLabel: false,
            otherBackLabelDescription: '',

            otherNeckLabel: false,
            otherNeckLabelDescription: '',

            otherLabel1: false,
            otherLabel1Description: '',

            otherLabel2: false,
            otherLabel2Description: '',

            retailMerchantOrAuction1: false,
            retailMerchantOrAuction1Description: '',

            retailMerchantOrAuction2: false,
            retailMerchantOrAuction2Description: ''
        };

        super({ ...props, formName, emptyDataState, loadTemplate: true });
    }

    getElement = async (ledger, version) => {
        return getOtherLabel(ledger.ledgerNumber, version).then(result => {
            return result ? result : this.emptyDataState
        });
    };

    saveElement = async (ledger, version, element) => {
        return saveOtherLabel(ledger.ledgerNumber, version, element);
    };

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <div className={classNames(classes.line, classes.height2t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch
                                {...this.getField('importLabel', true)}
                                {...this.getTemplateFieldStyle('importLabel')}
                            />
                        </div>
                    </div>
                    <ChaiTextField
                        {...this.getField('importLabelDescription')}
                        {...this.getTemplateFieldStyle('importLabelDescription')}
                        placeholder="Description"
                        style={{
                            opacity: this.state.data.importLabel ? '1' : '0',
                            transition: '200ms'
                        }}
                    />
                </div>

                <div className={classNames(classes.line, classes.height2t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch
                                {...this.getField('otherBackLabel', true)}
                                {...this.getTemplateFieldStyle('otherBackLabel')}
                            />
                        </div>
                    </div>
                    <ChaiTextField
                        {...this.getField('otherBackLabelDescription')}
                        {...this.getTemplateFieldStyle('otherBackLabelDescription')}
                        placeholder="Description"
                        style={{
                            opacity: this.state.data.otherBackLabel ? '1' : '0',
                            transition: '200ms'
                        }}
                    />
                </div>
                
                <div className={classNames(classes.line, classes.height2t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch
                                {...this.getField('otherNeckLabel', true)}
                                {...this.getTemplateFieldStyle('otherNeckLabel')}
                            />
                        </div>
                    </div>
                    <ChaiTextField
                        {...this.getField('otherNeckLabelDescription')}
                        {...this.getTemplateFieldStyle('otherNeckLabelDescription')}
                        placeholder="Description"
                        style={{
                            opacity: this.state.data.otherNeckLabel ? '1' : '0',
                            transition: '200ms'
                        }}
                    />
                </div>

                <div className={classNames(classes.line, classes.height2t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch
                                {...this.getField('otherLabel1', true)}
                                {...this.getTemplateFieldStyle('otherLabel1')}
                            />
                        </div>
                    </div>
                    <ChaiTextField
                        {...this.getField('otherLabel1Description')}
                        {...this.getTemplateFieldStyle('otherLabel1Description')}
                        placeholder="Description"
                        style={{
                            opacity: this.state.data.otherLabel1 ? '1' : '0',
                            transition: '200ms'
                        }}
                    />
                </div>

                <div className={classNames(classes.line, classes.height2t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch
                                {...this.getField('otherLabel2', true)}
                                {...this.getTemplateFieldStyle('otherLabel2')}
                            />
                        </div>
                    </div>
                    <ChaiTextField
                        {...this.getField('otherLabel2Description')}
                        {...this.getTemplateFieldStyle('otherLabel2Description')}
                        placeholder="Description"
                        style={{
                            opacity: this.state.data.otherLabel2 ? '1' : '0',
                            transition: '200ms'
                        }}
                    />
                </div>

                <div className={classNames(classes.line, classes.height2t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch
                                {...this.getField('retailMerchantOrAuction1', true)}
                                {...this.getTemplateFieldStyle('retailMerchantOrAuction1')}
                            />
                        </div>
                    </div>
                    <ChaiTextField
                        {...this.getField('retailMerchantOrAuction1Description')}
                        {...this.getTemplateFieldStyle('retailMerchantOrAuction1Description')}
                        placeholder="Description"
                        style={{
                            opacity: this.state.data.retailMerchantOrAuction1 ? '1' : '0',
                            transition: '200ms'
                        }}
                    />
                </div>

                <div className={ classes.height2t}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch
                                {...this.getField('retailMerchantOrAuction2', true)}
                                {...this.getTemplateFieldStyle('retailMerchantOrAuction2')}
                            />
                        </div>
                    </div>
                    <ChaiTextField
                        {...this.getField('retailMerchantOrAuction2Description')}
                        {...this.getTemplateFieldStyle('retailMerchantOrAuction2Description')}
                        placeholder="Description"
                        style={{
                            opacity: this.state.data.retailMerchantOrAuction2 ? '1' : '0',
                            transition: '200ms'
                        }}
                    />
                </div>
            </Fragment>
        );
    }
}

ChaiOtherLabel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiOtherLabel);