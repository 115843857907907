import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiBottleInformation from '../ChaiBottleInformation';
import ChaiBottleData from '../ChaiBottleData';
import ChaiMeasurements from '../ChaiMeasurements';
import ChaiMainLabel from '../ChaiMainLabel'
import ChaiClosure from '../ChaiClosure';
import ChaiGlass from '../ChaiGlass';


import ChaiOtherLabel from "../ChaiOtherLabel";
import ChaiAntifraud from '../ChaiAntifraud';
import ChaiCounterfeit from '../ChaiCounterfeit';
import ChaiFooterContainer from '../ChaiFooterContainer';

import { saveLedgerForSinglePage } from './service';

import _ from 'lodash';

const styles = theme => ({
    marginBottom: {
        marginBottom: '130px'
    },
});

function ChaiBottleMultiSectionComponent(props) {
    const [templateFunctions, setTemplateFunctions] = React.useState([]);
    const [callbackFunc, setCallbackFunc] = React.useState([]);
    const sectionNames = ['Bottle Info', 'Bottle Data', 'Measurements',
        'Main Label', 'Other Labels', 'Closure', 'Glass', 'Antifraud', 'Counterfeit'];

    const [formData, setFormData] = React.useState({});
    const [saveData, setSaveData] = React.useState({});


    const addSetTemplateFunction = (newSetTemplateFunction) => {
        templateFunctions.push(newSetTemplateFunction);
        setTemplateFunctions(templateFunctions);
    };

    const getNextStep = () => {
        const { steps } = props;
        let provenanceIndex = 0;
        steps.map((step, index) => {
            if (step.menuTitle === 'Provenance') {
                provenanceIndex = index;
            }
        })
        return provenanceIndex;
    }

    const handleFormUpdate = (formName, { fetchedData, data, originalData, prepareToSendFunc }) => {
        const processToSave = prepareToSendFunc;
        const { currentLedger } = props;
        if (fetchedData) {
            formData[formName] = data;
            let processedData = data;
            if (processToSave) {
                processedData = processToSave(data, currentLedger.ledgerNumber, 0);
            }
            saveData[formName] = processedData;

            setFormData({ ...formData });
            setSaveData({ ...saveData });
        }
        props.handleFormUpdate(formName, { data, originalData, saveFunction: save });
    };

    useEffect(() => {
        let hasClosure = false;
        const sections = props.steps.filter(s => {
            if (s.menuTitle === 'Closure') { hasClosure = true }
            return sectionNames.includes(s.menuTitle);
        })
        let lengthToWait = sections.length
        if (hasClosure) { lengthToWait = lengthToWait + 1 }

        try {
            if (!localStorage.getItem('bottleInfo')) {
                const keys = Object.keys(formData);

                if (keys.length === lengthToWait) {
                    localStorage.setItem('bottleInfo', JSON.stringify(formData));
                }
            }
        } catch(err) {
            console.error('SINGLE PAGE ERROR', err);
            console.log('Component not mounted');
        }
    });

    const save = async () => {
        const { currentLedger, showNotification } = props;
        localStorage.bottleInfo = JSON.stringify(formData);
        try {
            const result = await saveLedgerForSinglePage(currentLedger.ledgerNumber, 0, saveData);
            showNotification('Data saved');
        } catch (error) {
            showNotification(
                'Data Saved but Some Bottle Information attributes are empty or invalid.');
        }
    };

    const saveAndNext = async () => {
        await save();
        props.handleNextStep(getNextStep());
    };

    const { notifyLoadingChange, classes } = props;

    const footerData = {
        message: 'Please be sure to fill out the entire form before moving on.',
        buttonLabel: 'Save & Next',
        loading: false,
        fixed: true,
    };

    const propagateTemplate = () => {
        templateFunctions.map(templateFunction => {
            templateFunction();
        });
    };

    const handleTypeOfInput = (type) => {
        const { currentLedger, handleSelectLedger } = props;
        if (type == 1) {
            currentLedger.isAuthentic = false;
        } else {
            currentLedger.isAuthentic = true;
        }
        handleSelectLedger(currentLedger);
    };

    const getSection = (sectionName) => {
        switch (sectionName) {
            case 'Bottle Info':
                return (
                    <ChaiBottleInformation
                        {...props}
                        notifyLoadingChange={notifyLoadingChange}
                        sectionName='Bottle Info'
                        addSetTemplateFunction={addSetTemplateFunction}
                        propagateTemplate={propagateTemplate}
                        handleTypeOfInput={handleTypeOfInput}
                        handleFormUpdate={handleFormUpdate}
                    />
                )
            case 'Bottle Data':
                return (
                    <ChaiBottleData
                        {...props}
                        notifyLoadingChange={notifyLoadingChange}
                        sectionName='Bottle Data'
                        addSetTemplateFunction={addSetTemplateFunction}
                        handleFormUpdate={handleFormUpdate}
                    />
                )
            case 'Measurements':
                return (
                    <ChaiMeasurements
                        {...props}
                        notifyLoadingChange={notifyLoadingChange}
                        sectionName='Measurements'
                        addSetTemplateFunction={addSetTemplateFunction}
                        handleFormUpdate={handleFormUpdate}
                    />
                )
            case 'Main Label':
                return (
                    <ChaiMainLabel
                        {...props}
                        notifyLoadingChange={notifyLoadingChange}
                        sectionName='Main Label'
                        addSetTemplateFunction={addSetTemplateFunction}
                        handleFormUpdate={handleFormUpdate}
                    />
                )
            case 'Other Labels':
                return (
                    <ChaiOtherLabel
                        {...props}
                        notifyLoadingChange={notifyLoadingChange}
                        sectionName='Other Labels'
                        addSetTemplateFunction={addSetTemplateFunction}
                        handleFormUpdate={handleFormUpdate}
                    />
                )
            case 'Closure':
                return (
                    <ChaiClosure
                        {...props}
                        notifyLoadingChange={notifyLoadingChange}
                        sectionName='Closure'
                        addSetTemplateFunction={addSetTemplateFunction}
                        handleFormUpdate={handleFormUpdate}
                    />
                )
            case 'Glass':
                return (
                    <ChaiGlass
                        {...props}
                        notifyLoadingChange={notifyLoadingChange}
                        sectionName='Glass'
                        addSetTemplateFunction={addSetTemplateFunction}
                        handleFormUpdate={handleFormUpdate}
                    />
                )
            case 'Antifraud':
                return (
                    <ChaiAntifraud
                        {...props}
                        notifyLoadingChange={notifyLoadingChange}
                        sectionName='Antifraud'
                        addSetTemplateFunction={addSetTemplateFunction}
                        handleFormUpdate={handleFormUpdate}
                    />
                )
            case 'Counterfeit':
                return (
                    <ChaiCounterfeit
                        {...props}
                        notifyLoadingChange={notifyLoadingChange}
                        sectionName='Counterfeit'
                        addSetTemplateFunction={addSetTemplateFunction}
                        handleFormUpdate={handleFormUpdate}
                    />
                )
            default:

        }
    }

    return (
        <Fragment>
            <div className={classes.marginBottom}>
                {props.steps.map(step => {
                    return getSection(step.menuTitle)
                })}
            </div>
            <ChaiFooterContainer {...footerData} onClick={saveAndNext} showInfo={false} />
        </Fragment>
    );
}


ChaiBottleMultiSectionComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiBottleMultiSectionComponent);
