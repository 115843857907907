import blockchain from '../../Services/blockchain';

const ethers = require("ethers");
const dataProvider = require('../../DataProviders/index').default;
const { GET_LIST, UPDATE } = require('../../DataProviders/crudgeneric');
const blockchainHelper = require('../../Blockchain/helpers/blockchain.helper').default();
const { systemRoles } = require('./options');

/* PUBLIC FUNCTIONS */
export async function getAllUsersFromDatabaseAndBlockchain() {
    try {
        let params = {
            pagination: { page: 0, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' }
        };
        let databaseAccounts = await dataProvider(GET_LIST, 'users', params);

        if (databaseAccounts && databaseAccounts.data) {
            databaseAccounts = databaseAccounts.data;
        }

        let mappingUsers = databaseAccounts.map(async (userDB) => {
            if (ethers.utils.isHexString(userDB.address)) {

                let userBlockchain = await blockchain.user.getUserFromSecurityBlockchain(userDB.address);
                let userType = userBlockchain[1] ? systemRoles.find(type => type.id === userBlockchain[1]) : null;

                return {
                    user: userBlockchain[0] ? userBlockchain[0] : userDB.name,
                    email: userDB.email,
                    rol: userType ? userType.name : userDB.rol,
                    address: userDB.address,
                    active: userDB.active,
                    blockchain: userType ? userBlockchain : null
                }
            } else {
                return {
                    user: userDB.name,
                    email: userDB.email,
                    rol: userDB.rol,
                    address: `${userDB.address} - NOT VALID ADDRESS`,
                    active: userDB.active,
                    blockchain: null
                }
            }
        });

        return Promise.all(mappingUsers);
    } catch (ex) {
        console.error('getAllUsersFromDatabaseAndBlockchain ERROR', ex.message);
        return ex.message;
    }
}

export async function createUserBlockchain(account) {
    try {
        let userType = systemRoles.find(type => type.name === account.rol);
        let userData = {
            userName: account.user,
            userAddress: account.address,
            userType: userType.id
        };
        const unlocked = await blockchain.security.unlockMainAccount();
        if (unlocked) {
            await blockchain.user.addUserToSecurityBlockchain(userData);
            return { ok: true };
        } else {
            return { ok: false };
        }
    } catch (ex) {
        console.error('updateUserStatusBlockchain ERROR', ex.message);
        return { ok: false, message: ex.message };
    }
}

export async function updateUserStatusBlockchain(account) {
    try {
        const currentStatus = account.blockchain[2];
        const unlocked = await blockchain.security.unlockMainAccount();
        if (unlocked) {
            await blockchain.user.handleActiveUserOnBlockchain(!currentStatus, account.address);
            return { ok: true };
        } else {
            return { ok: false };
        }
    } catch (ex) {
        console.error('updateUserStatusBlockchain ERROR', ex.message);
        return { ok: false, message: ex.message };
    }
}

export async function updateAccount(account) {
    try {
        const userType = systemRoles.find(type => type.name === account.rol);

        const userData = {
            userName: account.user,
            userAddress: account.address,
            userType: userType.id
        };

        const params = {
            id: account.email,
            data: {
                email: account.email,
                role: userType.name,
                active: account.active
            }
        }

        // TODO: also update MongoDB User Role
        await dataProvider(UPDATE, 'users', params);

        const unlocked = await blockchain.security.unlockMainAccount();
        if (unlocked) {
            await blockchain.user.updateUserToSecurityBlockchain(userData);
            return { ok: true };
        } else {
            return { ok: false };
        }
    } catch (ex) {
        console.error('addUserToSecurityBlockchain ERROR', ex.message);
        return { ok: false, message: ex.message };
    }
}

export async function getTotalSupplyOfTokens() {
    try {
        let receipt = await blockchain.security.getBalanceAndAllowanceTokenInformation(blockchainHelper.getMainAccount());
        return receipt.balance;
    } catch (ex) {
        console.error('getTotalSupplyOfTokens ERROR', ex.message);
        return ex.message;
    }
}

export async function getAllUserInformationForTokens() {
    try {
        let params = {
            pagination: { page: 0, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' }
        };
        let databaseAccounts = await dataProvider(GET_LIST, 'users', params);

        if (databaseAccounts && databaseAccounts.data) {
            databaseAccounts = databaseAccounts.data;
        }

        let mappingUsers = databaseAccounts.map(async (userDB) => {
            if (ethers.utils.isHexString(userDB.address)) {
                let userBlockchain = await blockchain.security.getBalanceAndAllowanceTokenInformation(userDB.address);
                console.info(userBlockchain);

                return {
                    name: userDB.name,
                    address: userDB.address,
                    allowance: userBlockchain.allowance,
                    balance: userBlockchain.balance,
                    rol: userDB.rol,
                    active: userDB.active
                }
            } else {
                return {
                    name: userDB.name,
                    address: userDB.address,
                    allowance: 0,
                    balance: 0,
                    rol: userDB.rol,
                    active: userDB.active
                }
            }
        });

        return Promise.all(mappingUsers);
    } catch (ex) {
        console.error('getAllUserInformationForTokens ERROR', ex.message);
        return ex.message;
    }
}

export async function getBalanceAndAllowanceByUser(userAddress) {
    try {
        let userTokenInformation = await blockchain.security.getBalanceAndAllowanceTokenInformation(userAddress);
        return userTokenInformation;
    } catch (ex) {
        console.error('getBalanceAndAllowanceByUser ERROR', ex.message);
        return ex.message;
    }
}

export async function addBalanceToUser(userAddress, balance) {
    try {
        const receipt = await blockchain.security.addBalanceTokens(userAddress, balance);
        return receipt;
    } catch (ex) {
        console.error('addBalanceToUser ERROR', ex.message);
        return ex.message;
    }
}

export async function modifyAllowanceToUser(userAddress, allowance) {
    try {
        const receipt = await blockchain.security.modifyAllowanceOfTokens(userAddress, allowance);
        return receipt;
    } catch (ex) {
        console.error('modifyAllowanceToUser ERROR', ex.message);
        return ex.message;
    }
}

export async function getTotalSupply() {
    try {
        let receiptSupply = await blockchain.security.getTotalSupplyOfTokens();
        return receiptSupply;
    } catch (ex) {
        console.error('getTotalSupply ERROR', ex.message);
        return ex.message;
    }
}
