const { dpCrud, CREATE } = require('../../DataProviders/crudgeneric');

export async function createLedgers(data) {
    const response = await dpCrud(CREATE, "ledgers/create", {
        data,
        ledgerNumber: 0,
        ledgerVersion: 0
    });
    console.info("createLedgers response >> ", response);
    return response;
}
