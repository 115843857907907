import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChaiBodyContainer from '../ChaiBodyContainer';
import CorkForm from './CorkForm';
import CapsuleForm from './CapsuleForm'

import LinearProgress from '@material-ui/core/LinearProgress';
import ChaiBodyContainerHeader from '../ChaiBodyContainerHeader';

import formNames from '../ChaiBaseForm/formNames';

const styles = theme => ({
});

class ChaiClosure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    handleLoading = (loading) => {
        this.setState({ loading: loading });
        this.props.notifyLoadingChange(loading);
    };

    render() {
        const { classes, theme } = this.props;

        return (
            <Fragment>
                {this.state.loading && <LinearProgress />}
                <ChaiBodyContainer>
                    <ChaiBodyContainerHeader {...this.props}>
                        <CapsuleForm
                            {...this.props}
                            formName={formNames.CLOSURECAPSULE}
                            notifyLoadingChange={this.handleLoading}
                        />
                        <CorkForm
                            {...this.props}
                            formName={formNames.CLOSURECORK}
                            notifyLoadingChange={this.handleLoading}
                        />
                    </ChaiBodyContainerHeader>
                </ChaiBodyContainer>
            </Fragment>
        );
    }
}

ChaiClosure.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ChaiClosure);
