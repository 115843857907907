const BaseService = require('./base.service').default;
const config = require('../providers/config.provider').default.getConfig();
const blockchainHelper = require('../helpers/blockchain.helper').default();
const ContractMethodError = require("../exceptions/contractMethod.exception").default;

class UserService extends BaseService {
    getContractWithMainAccountSigner () {
        const signer = blockchainHelper.getProvider().getSigner(0);

        return this.contract.connect(signer);
    }

    async createUser(user){
        try {
            const overrides = { 
                gasLimit: 3000000
            };

            let contractWithSigner = this.getContractWithMainAccountSigner();
            const result = await contractWithSigner.createUser(...Object.values(user), overrides);

            return result;
        } catch (ex) {
            throw new ContractMethodError('Create user', ex);
        }
    }

    async reactivateUser(userAddress){
        try {
            const overrides = { 
                gasLimit: 3000000
            };

            let contractWithSigner = this.getContractWithMainAccountSigner();
            const result = await contractWithSigner.reactivateUser(userAddress, overrides);

            return result;
        } catch (ex) {
            throw new ContractMethodError('Reactivate User', ex);
        }
    }

    async deactivateUser(userAddress){
        try {
            const overrides = { 
                gasLimit: 3000000
            };

            let contractWithSigner = this.getContractWithMainAccountSigner();
            const result = await contractWithSigner.deactivateUser(userAddress, overrides);

            return result;
        } catch (ex) {
            throw new ContractMethodError('Deactivate User', ex);
        }
    }

    async updateUser(user){
        try {
            const overrides = { 
                gasLimit: 3000000
            };

            let contractWithSigner = this.getContractWithMainAccountSigner();
            const result = await contractWithSigner.updateUser(...Object.values(user), overrides);

            return result;
        } catch (ex) {
            throw new ContractMethodError('Update User', ex);
        }
    }

    async getUser(userAddress){
        try {
            const result = await this.contract.getUser(userAddress);
            return result;
        } catch (ex) {
            throw new ContractMethodError('Get User', ex);
        }
    }

    async getAddresses(){
        try {
            const overrides = { 
                gasLimit: 3000000 
            };
            const result = await this.contract.getAddresses(overrides);
            return result;
        } catch (ex) {
            throw new ContractMethodError('Get Contract Addresses', ex);
        }
    }
}

//Mapped contract key
export default (_config = config, _blockchainHelper = blockchainHelper) => new UserService('ChaiUsers', _config, _blockchainHelper);
