const httpStatus = require('http-status-codes');

const API_URL = process.env.REACT_APP_CHAI_SECURITY_API_HOST || 'gateway/';

export const CREATE = "CREATE";
export const GET_ONE = "GET_ONE";
export const UPDATE = "UPDATE";
export const GET_LIST = "GET_LIST";
export const PATCH = "PATCH";

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} params Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
export async function dpCrud(type, resource, params) {
    if (!localStorage.getItem('token')) return false;

    let url = '';
    let options = {
        headers: new Headers({
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }),
    };

    switch (type) {
        case CREATE:
            url = `${API_URL}${resource}`;
            options.method = 'POST';
            options.body = JSON.stringify(params.data);
            break;
        case UPDATE:
            url = `${API_URL}${resource}`;
            options.method = 'PUT';
            options.body = params && params.data ? JSON.stringify(params.data) : null;
            break;
        case GET_ONE:
            url = `${API_URL}${resource}/${params.ledger.ledgerNumber}/versions/${params.ledger.ledgerVersion}`;
            options.method = 'GET';
            break;
        case GET_LIST:
            url = `${API_URL}${resource}`;
            options.method = 'GET';
            break;
        case PATCH:
            url = `${API_URL}${resource}`;
            options.method = 'PATCH';
            options.body = params && params.data ? JSON.stringify(params.data) : null;
            break;

        default:
            throw new Error(`Unsupported Data Provider request type ${type}`);
    }

    try {
        let response = await fetch(url, options);
        let result = await response.json();

        if (response.status !== 200 || result.isError) {
            throw result;
        }

        const finalData = {};
        finalData.data = result;
        finalData.total = result.length;

        return finalData;
    } catch (error) {
        console.error('ERROR crudgeneric', error);
        throw error;
    }
}

export const uploadImage = async (image) => {
    if (!localStorage.getItem('token')) return false;

    let url = '';
    let options = {
        headers: new Headers({
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }),
    };

    const formData = new FormData();
    formData.append('images', new Blob([image]), image.name);

    url = `${API_URL}images`;
    options.method = 'POST';
    options.body = formData;

    try {
        let response = await fetch(url, options);
        let result = await response.json();
        console.info('IMAGE UPLOAD', result);

        if (response.status !== 200) {
            console.log('response error', result);
            throw result.error;
        }

        if (result.result && result.result.length) {
            return result.result[0]; // Always return the first element as it's only a singular uplaod
        } else {
            throw 'An error occurred while uploading Images';
        }
    } catch (error) {
        console.error('ERROR uploadImage', error);
        throw error;
    }
};

export const uploadCSVFile = async (file, resource, extraBody) => {
    if (!localStorage.getItem('token')) return false;

    let url = '';
    let options = {
        headers: new Headers({
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }),
    };

    const formData = new FormData();
    formData.append('files', new Blob([file]), file.name);
    if (extraBody) {
        for (let key in extraBody) {
            formData.append(key, extraBody[key]);
        }
    }
    url = `${API_URL}${resource}`;
    options.method = 'PUT';
    options.body = formData;

    try {
        let response = await fetch(url, options);
        let result = await response.json();
        console.info('FILE UPLOAD', result);

        if (response.status !== 200) {
            console.log('response error', result);
            if (result.stack && result.stack.statusCode !== httpStatus.PRECONDITION_REQUIRED)
                throw result.errorMessage; // Property coming from chai-gateway
        }

        return result;
    } catch (error) {
        console.error('ERROR uploadCSVFile', error);
        throw error;
    }
};

export const validateCSVFile = async (file, resource) => {
    if (!localStorage.getItem('token')) return false;

    let url = '';
    let options = {
        headers: new Headers({
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }),
    };

    const formData = new FormData();
    formData.append('files', new Blob([file]), file.name);

    url = `${API_URL}${resource}`;
    options.method = 'POST';
    options.body = formData;

    try {
        let response = await fetch(url, options);
        let result = await response.json();
        console.info('FILE UPLOAD', result);

        if (response.status !== 200) {
            console.log('response error', result);
            throw result.errorMessage; // Property coming from chai-gateway
        }

        return result;
    } catch (error) {
        console.error('ERROR validateCSVFile', error);
        throw error;
    }
};

export const getPublicImage = async (image) => {

    let url = '';
    let options = {
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
    };

    const key = 'files' + image.substr(image.lastIndexOf("/"));

    url = `${API_URL}publicImage`;
    options.method = 'POST';
    options.body = JSON.stringify({ key: decodeURIComponent(key) });
    console.log('options >>>>', image, options);

    try {
        let response = await fetch(url, options);
        let result = await response.json();
        console.info('GET IMAGE >>>>', response.status, result);

        if (response.status !== 200) {
            throw 'An error occurred while getting image';
        } else {
            return result;
        }
    } catch (error) {
        console.error('ERROR getImage', error);
        throw error;
    }
};

export const getImage = async (image) => {
    if (!localStorage.getItem('token')) return false;

    let url = '';
    let options = {
        headers: new Headers({
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }),
    };

    const key = 'files' + image.substr(image.lastIndexOf("/"));

    url = `${API_URL}image`;
    options.method = 'POST';
    options.body = JSON.stringify({ key: decodeURIComponent(key) });
    console.log('options >>>>', image, options);

    try {
        let response = await fetch(url, options);
        let result = await response.json();
        console.info('GET IMAGE >>>>', response.status, result);

        if (response.status !== 200) {
            throw 'An error occurred while getting image';
        } else {
            return result;
        }
    } catch (error) {
        console.error('ERROR getImage', error);
        throw error;
    }
};

export const getSignatureInformation = async (name) => {
    let url = '';
    let options = {
        headers: new Headers({
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        })
    };

    url = `${API_URL}signatureInfo/${name}`;
    options.method = 'GET';

    try {
        let response = await fetch(url, options);
        let result = await response.json();

        if (response.status !== 200) {
            throw 'An error occurred while getting signature information';
        } else {
            return result;
        }
    } catch (error) {
        console.error('ERROR getSignatureInformation', error);
        throw error;
    }
};
