const sha512 = require('js-sha512');
const BASE_URL = process.env.REACT_APP_CHAI_SECURITY_API_HOST || 'gateway/';

/**
 *
 * @param userId
 * @returns {Promise<Response>}
 */
//return user
export async function getUserById(userId) {
    const token = localStorage.getItem('token');
    const url = `${BASE_URL}provenances/user/${userId}`

    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
        method: 'GET'
    };
    try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }

        return result;
    } catch (error) {
        console.error('ERROR GetUserById()', error);
        throw error.error ? error.error : error;
    }
}

/**
 * Gets the share info for all users associated with the ledger.
 *
 * @param ID of the ledger to get the share info for.
 * @returns Promise containing the share attributes for each user the ledger is shared with.
 */
export async function getShareInfoByLedgerId(ledgerId) {
    try {
        const token = localStorage.getItem('token');
        const url = `${BASE_URL}ledgers/getShareInfoByLedgerId/${ledgerId}`;
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-type': 'application/json'
            },
            method: 'GET'
        };
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }

        return result;
    } catch (ex) {
        console.error("Error getShareInifoByLedgerId()", ex);
    }
}

/**
 *
 * @param ledgerNumber
 * @param ledgerVersion
 * @returns {Promise<Response>}
 */
//save users and ledgers password requried
export async function verifyPasswordAndSave(ledgers, users, password, overridePermissions = false) {
    try {
        const token = localStorage.getItem('token');
        const email = JSON.parse(localStorage.getItem('user')).email;
        const url = `${BASE_URL}ledgers/verifyAndConfirmPermissions`;
        const allUsers = JSON.parse(users);

        let allowedToEdit = null;
        let allowedToSell = null;
        let allowedToView = [];

        allUsers.filter((user) => user.delete !== true).forEach((user) => {
            if (user.canEdit && user.canSell) {
                //edit permissions
                allowedToEdit = {};
                allowedToEdit.userId = user.id;
                allowedToEdit.name = user.name;
                //sell permissions
                allowedToSell = {};
                allowedToSell.userId = user.id;
                allowedToSell.name = user.name;
            } else if (user.canEdit) {
                allowedToEdit = {};
                allowedToEdit.userId = user.id;
                allowedToEdit.name = user.name;
            } else if (user.canSell) {
                allowedToSell = {};
                allowedToSell.userId = user.id;
                allowedToSell.name = user.name;
            }
            allowedToView = [...allowedToView, {userId: user.id, name: user.name}];
        });
        
        ledgers = ledgers.map(ledger =>  { return { ledgerNumber: ledger.ledgerNumber, ledgerVersion: ledger.ledgerVersion }})
        const data = {
            ledgers: ledgers,
            email: email,
            password: sha512(password),
            allowedToEdit: allowedToEdit,
            allowedToSell: allowedToSell,
            allowedToView: allowedToView
        }

        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-type': 'application/json'
            },
            method: overridePermissions ? 'POST' : 'PATCH',
            body: JSON.stringify(data)
        };

        const response = await fetch(url, options);
        const result = await response.json();

        return result;
    } catch (ex) {
        console.error("Error verifyPasswordAndSave()", ex);
    }
    return null;
}
