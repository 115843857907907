import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import ChaiTextField from '../ChaiTextField';
import ChaiSwitch from '../ChaiSwitch';
import ChaiImageInput from '../ChaiImageInput';
import ChaiBaseForm from '../ChaiBaseForm';
import { getClosureCork, saveClosureCork } from './closureCork.js';
import formNames from '../ChaiBaseForm/formNames';

const fieldHeight = 66;

const styles = theme => ({});

class CorkForm extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.CLOSURECORK;
        let emptyDataState = {
            hasCorkBrandMark: false,
            corkBrandDescription: null,
            corkBrandImage: null,
            vintageLegible:false,
            vintageLegibleDescription: null,
            vintageLegibleImage: null,
            corkType: null
        };
        super({ ...props, formName, emptyDataState, loadTemplate: true });
        this.state = { ...this.state, milocalvar: 'Hola' };
    }

    getElement = async (ledger, version) => {
        await this.getOptionsWithFormName(formNames.CLOSURECORK);
        return getClosureCork(ledger.ledgerNumber, 0)
            .then(result => {
                return result ? { ...result, corkBrandDescription: (result.corkBrandMarkInfo ? result.corkBrandMarkInfo.description : ' '), vintageLegibleDescription: (result.vintageLegibleInfo ? result.vintageLegibleInfo.description : ' '), corkBrandImage: (result.corkBrandMarkInfo ? result.corkBrandMarkInfo.image : ' '), vintageLegibleImage: (result.vintageLegibleInfo ? result.vintageLegibleInfo.image : ' ') } : this.emptyDataState
            });
    };

    saveElement = async (ledger, version, element) => {
        try {
            let result = await saveClosureCork(ledger.ledgerNumber, version, element);
            return result;
        } catch (error) {
            throw error;
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <div className={classNames(classes.line, classes.height3t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch
                                {...this.getField('hasCorkBrandMark', true)}
                                {...this.getTemplateFieldStyle('hasCorkBrandMark')}
                            />
                        </div>
                    </div>
                    <ChaiTextField
                        {...this.getField('corkBrandDescription')}
                        {...this.getTemplateFieldStyle('corkBrandDescription')}
                        style={{
                            opacity: this.state.data.hasCorkBrandMark ? '1' : '0',
                            transition: '200ms'
                        }}
                        placeholder="Description"
                    />
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <div style={{
                                opacity: this.state.data.hasCorkBrandMark ? '1' : '0',
                                transition: '200ms'
                            }}>
                                <ChaiImageInput
                                    {...this.props}
                                    {...this.getFileField('corkBrandImage')}
                                    {...this.getTemplateFieldStyle('corkBrandImage')}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.height3t}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch
                                {...this.getField('vintageLegible', true)}
                                {...this.getTemplateFieldStyle('vintageLegible')}
                            />
                        </div>
                    </div>
                    <ChaiTextField
                        {...this.getField('vintageLegibleDescription')}
                        {...this.getTemplateFieldStyle('vintageLegibleDescription')}
                        style={{
                            opacity: this.state.data.vintageLegible ? '1' : '0',
                            transition: '200ms'
                        }}
                        placeholder="Description"
                    />
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <div style={{
                                opacity: this.state.data.vintageLegible ? '1' : '0',
                                transition: '200ms'
                            }}>
                                <ChaiImageInput
                                    {...this.props}
                                    {...this.getFileField('vintageLegibleImage')}
                                    {...this.getTemplateFieldStyle('vintageLegibleImage', { width: '10%' })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

CorkForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CorkForm);
