import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ChaiAdministrative from '../ChaiAdministrative';
import ChaiAuthenticator from '../ChaiAuthenticator';
import ChaiSpecialButton from '../ChaiSpecialButton';
import ChaiFooterContainer from '../ChaiFooterContainer';
import chaiErrorHandler from '../../Handler/chaiErrorHandler';
import { saveLedgerForSinglePage } from '../ChaiBottleMultiSectionComponent/service';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

import _ from 'lodash';

const styles = theme => ({
    marginBottom: {
        marginBottom: '130px'
    },
});

function ChaiAuthMultiSectionComponent(props) {
    const [commitToBlockFunction, setCommitToBlockFunction] = React.useState([]);
    const [modal, setModal] = React.useState(false);
    const [callbackFunc, setCallbackFunc] = React.useState([]);
    const sectionNames = ['Administrative', 'Authenticator', 'Wine Location'];

    const [formData, setFormData] = React.useState({});
    const [saveData, setSaveData] = React.useState({});

    const [pairedRfid, setPairedRfid] = React.useState(null);
    const [dialogMessage, setDialogMessage] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);


    const addCommitToBlockFunction = (func) => {
        commitToBlockFunction.push(func);
        setCommitToBlockFunction(commitToBlockFunction);
    };

    const getNextStep = () => {
        const { steps } = props;
        let nextIndex = 0;
        steps.map((step, index) => {
            if (step.menuTitle === 'Share & Sale') {
                nextIndex = index;
            }
        })
        return nextIndex;
    }

    const handleFormUpdate = (formName, { fetchedData, data, originalData, prepareToSendFunc }) => {
        const processToSave = prepareToSendFunc;
        const { currentLedger } = props;
        if (fetchedData) {
            formData[formName] = data;
            let processedData = data;
            if (processToSave) {
                processedData = processToSave(data, currentLedger.ledgerNumber, 0);
            }
            saveData[formName] = processedData;

            setFormData({ ...formData });
            setSaveData({ ...saveData });
        }
        props.handleFormUpdate(formName, { data, originalData, saveFunction: save });
    };

    useEffect(() => {
        const sections = props.steps.filter(s => {
            return sectionNames.includes(s.menuTitle)
        })
        try {
            if (!localStorage.getItem('authInfo')) {
                const keys = Object.keys(formData);

                if (keys.length === sections.length) {
                    localStorage.setItem('authInfo', JSON.stringify(formData));
                }
            }
        } catch (error) {
            console.error('useEffect() ERROR', error);
            console.log('Component not mounted');
        }
    });

    const save = async () => {
        const { currentLedger, showNotification } = props;
        localStorage.authInfo = JSON.stringify(formData);

        try {
            const validAdministrative = checkValidationForAdministrative();
            const validAuthenticator = checkValidationForAuthenticator();

            if (validAdministrative && validAuthenticator) {
                const qrCode = saveData['administrative'].qrCode;

                saveData['administrative'].qrCode = qrCode.indexOf('/') > -1 ?
                    qrCode.substr(qrCode.lastIndexOf('/') + 1) :
                    qrCode;

                const result = await saveLedgerForSinglePage(currentLedger.ledgerNumber, 0, saveData);
                showNotification('Data saved');
            }
        } catch (err) {
            if (!handleRFIDError(err)) {
                //We provide a notifier for the error handler so it displays the error once its handled
                chaiErrorHandler(showNotification).catch(err);
            }
        }
    };

    const handleRFIDError = err => {
        let errorDetail = (err.content.additionalData || { pairedRfid: null });
        if (errorDetail.pairedRfid) {

            setPairedRfid(errorDetail.pairedRfid);
            setDialogMessage(`${err.content.message}: ${err.content.content[0]}, do you want to replace it?`);
            setDialogOpen(true);

            return true;
        }
        return false;
    };

    const handleDialogCancel = () => {
        setPairedRfid(null);
        setDialogMessage(null);
        setDialogOpen(false);
    };

    const handleDialogOk = () => {
        setPairedRfid(null);
        setDialogMessage(null);
        setDialogOpen(false);

        saveData['administrative'].overrideRFIDCode = true;
        setSaveData({ ...saveData });

        save();
    };

    const checkValidationForAdministrative = () => {
        const { showNotification } = props;

        if (!validateQrCode()) {
            showNotification('Invalid QR Code Hash or url.');
            return false;
        }
        if (!validateSellerLink()) {
            showNotification('Please enter a valid SELLER LINK url including prefix, e.g.: https://');
            return false;
        }

        return true;
    };

    const validateQrCode = () => {
        const qrCode = saveData.administrative.qrCode;
        const pattern = /^([a-z0-9]+){4,}/;

        return pattern.test(qrCode.indexOf('/') > -1 ? qrCode.substr(qrCode.lastIndexOf('/') + 1) : qrCode);
    };

    const validateSellerLink = () => {
        const showLogo = saveData.administrative.showLogo;
        const sellerLink = saveData.administrative.sellerLink;

        return showLogo || (
            sellerLink !== null &&
            (sellerLink.indexOf('http://') > -1 || sellerLink.indexOf('https://') > -1)
        );
    };

    const checkValidationForAuthenticator = () => {
        return true;
    }

    const saveAndNext = async () => {
        await save();
        props.handleNextStep(getNextStep());
    };

    const { notifyLoadingChange, classes } = props;
    const user = JSON.parse(localStorage.getItem('user'));

    const footerData = {
        message: 'Please be sure to fill out the entire form before moving on.',
        buttonLabel: 'Save',
        loading: false,
        fixed: true,
        buttonStyle: { maxHeight: "50%" },
        extraButton: (user !== null && user.rol !== 'Collector') ?
            <ChaiSpecialButton
                disabled={false}
                label={'Commit to Blockchain'}
                style={{ maxHeight: "50%" }}
                onClick={commitToBlockFunction[0]}
            /> : ''
    };

    const getSection = (sectionName) => {
        switch (sectionName) {
            case 'Administrative':
                return (
                    <ChaiAdministrative
                        {...props}
                        notifyLoadingChange={notifyLoadingChange}
                        sectionName='Administrative'
                        handleFormUpdate={handleFormUpdate}
                    />
                )
            case 'Authenticator':
                return (
                    <ChaiAuthenticator
                        {...props}
                        notifyLoadingChange={notifyLoadingChange}
                        sectionName='Authenticator'
                        addCommitToBlockFunction={addCommitToBlockFunction}
                        handleFormUpdate={handleFormUpdate}
                    />
                )
            case 'Wine Location':
                return (
                    <ChaiAuthenticator
                        {...props}
                        notifyLoadingChange={notifyLoadingChange}
                        sectionName='Wine Location'
                        addCommitToBlockFunction={addCommitToBlockFunction}
                        handleFormUpdate={handleFormUpdate}
                    />
                )
            default:

        }
    }

    return (
        <Fragment>
            <div className={classes.marginBottom}>
                {dialogOpen && <Dialog
                    maxWidth="xs"
                    aria-labelledby="confirmation-dialog-title"
                    open={dialogOpen}
                >
                    <DialogTitle id="confirmation-dialog-title">Ledger already paired</DialogTitle>
                    <DialogContent dividers>
                        <p>{dialogMessage}</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleDialogCancel()} color="primary">
                            Cancel
                            </Button>
                        <Button onClick={() => handleDialogOk()} color="primary">
                            Ok
                            </Button>
                    </DialogActions>
                </Dialog>}
                {props.steps.map(step => {
                    return getSection(step.menuTitle)
                })}
            </div>
            <ChaiFooterContainer {...footerData} onClick={save} showInfo={false} />
        </Fragment>
    );
}


ChaiAuthMultiSectionComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiAuthMultiSectionComponent);
