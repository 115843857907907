import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const styles = theme => ({
    label: {
        color: '#3f3e3e',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '1em',
        fontWeight: 400
    },
});

function ChaiRadioButton(props) {
    const { classes, value, name, checked, label, style, disabled, onChange } = props;
    return (
        <FormControlLabel
            style={style}
            control={
                <Radio
                    checked={checked}
                    onChange={onChange}
                    value={value}
                    name={name}
                    disabled={disabled}
                    color="primary"
                />
            }
            classes={{label: classes.label}}
            label={label}
        />
    );
}

ChaiRadioButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiRadioButton);
