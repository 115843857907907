const BaseService = require('./base.service').default;
const config = require('../providers/config.provider').default.getConfig();
const blockchainHelper = require('../helpers/blockchain.helper').default();
const ContractMethodError = require("../exceptions/contractMethod.exception").default;

class TokenService extends BaseService {
    getContractWithMainAccountSigner() {
        const signer = blockchainHelper.getProvider().getSigner(0);

        return this.contract.connect(signer);
    }

    async balanceOf(userAddress) {
        try {
            const receiptBalance = await this.contract.balanceOf(userAddress);
            return receiptBalance.toNumber();
        } catch (ex) {
            throw new ContractMethodError('Get User balance', ex);
        }
    }

    async allowance(userAddress) {
        try {
            const receiptAllowance = await this.contract.allowance(this.mainAccount, userAddress);
            return receiptAllowance.toNumber();
        } catch (ex) {
            throw new ContractMethodError('Get User Allowance', ex);
        }
    }

    async totalSupply() {
        try {
            const receiptSupply = await this.contract.totalSupply();
            return receiptSupply.toNumber();
        } catch (ex) {
            throw new ContractMethodError('Get contract total Supply', ex);
        }
    }

    async addBalance(userAddress, balance) {
        try {
            const overrides = {
                gasLimit: 3000000
            };

            let contractWithSigner = this.getContractWithMainAccountSigner();
            const result = await contractWithSigner.transfer(userAddress, balance, overrides);

            return result;
        } catch (ex) {
            throw new ContractMethodError('Add User Balance', ex);
        }
    }

    async modifyAllowance(userAddress, allowance) {
        try {
            const overrides = {
                gasLimit: 3000000
            };

            let contractWithSigner = this.getContractWithMainAccountSigner();
            const result = await contractWithSigner.approve(userAddress, allowance, overrides);

            return result;
        } catch (ex) {
            throw new ContractMethodError('Approve Allowance', ex);
        }
    }
}

//Mapped contract key
export default (_config = config, _blockchainHelper = blockchainHelper) => new TokenService('CHVLT', _config, _blockchainHelper);
