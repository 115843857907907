import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { provenancesBlockchainBulkSavingProcess } from '../ChaiAuthenticator/authenticator';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiTextField from '../ChaiTextField';
import ChaiHeaderButton from '../ChaiHeaderButton';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';

const styles = theme => ({
    log: {
        width: '90%', marginBottom: 10, paddingBottom: 10, borderBottom: '1px solid #3e3e3e',
        fontFamily: '"Source Sans Code", sans-serif', fontSize: '1em', color: '#3e3e3e'
    }
});

class ChaiProvenancesBulkBlockchain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ledgers: null,
            privateKey: null,
            message: null,
            errorMessage: null,
            logs: [],
            working: false
        }
    }

    handlePrivateKey = (e) => {
        this.setState({ privateKey: e.target.value });
    };

    handleLedgers = (e) => {
        this.setState({ ledgers: e.target.value });
    };

    processBulkBlockchain = async () => {
        const { showNotification } = this.props;
        const { ledgers, privateKey } = this.state;

        const mappedLedgers = ledgers.split(';').map(l => l.trim());

        try {

            this.setState({ working: true });
            const result = await provenancesBlockchainBulkSavingProcess(mappedLedgers, privateKey,
                (message) => { showNotification(message) });

            const parsedMessage = `Processed Ledgers: ${result.ledgersOK.length ? result.ledgersOK.join(', ') : 0} - 
                Failed Ledgers: ${result.ledgersError.length ? result.ledgersError.join('; ') : 0}`;

            this.setState({
                message: parsedMessage, errorMessage: result.errorMessage, logs: result.logs, working: false
            })

        } catch (e) {
            this.setState({ working: false });

            console.error('BULK BLOCKCHAIN PROCESS', e);

            const errorToShow = typeof (e) === 'string' ?
                e : e.content ? (e.content.content ? e.content.content : e.content.message) :
                    'An error has occurred while saving data to the Blockchain.';

            showNotification(errorToShow);
        }
    };

    render() {
        const { classes } = this.props;
        const { ledgers, privateKey, message, messageError, logs, working } = this.state;

        return (
            <Fragment>
                <ChaiBodyContainer style={{ padding: 0, boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.49)' }}>
                    <ChaiRowBodyContainer>
                        <ChaiTextField disabled={working} label={'List Of Ledgers'} onChange={this.handleLedgers} value={ledgers} style={{ width: '100%' }} />
                    </ChaiRowBodyContainer>
                    <ChaiRowBodyContainer>
                        <ChaiTextField disabled={working} label={'Private Key'} onChange={this.handlePrivateKey} value={privateKey} type={'password'} style={{ width: '100%' }} />
                    </ChaiRowBodyContainer>
                    {
                        message && <ChaiRowBodyContainer>
                            <div>{message}</div>
                        </ChaiRowBodyContainer>
                    }
                    {
                        messageError && <ChaiRowBodyContainer>
                            <div style={{ color: 'red' }} >{messageError}</div>
                        </ChaiRowBodyContainer>
                    }
                    {
                        logs && logs.length ?
                            <ChaiRowBodyContainer>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="subheading">
                                            {'Logs'}
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{ display: 'grid' }}>
                                        {
                                            logs.map(detail => {
                                                return (
                                                    <div className={classes.log}>
                                                        {`Ledger Number: ${detail.ledgerNumber} - ${detail.error}`}
                                                    </div>
                                                );
                                            })
                                        }
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </ChaiRowBodyContainer>
                            : null
                    }
                    <ChaiRowBodyContainer>
                        {working ? <div style={{ textAlign: 'center' }}><CircularProgress /></div> :
                            <ChaiHeaderButton label={'PROCESS'} onClick={this.processBulkBlockchain} disabled={!ledgers || !privateKey} />
                        }
                    </ChaiRowBodyContainer>
                </ChaiBodyContainer>

            </Fragment >
        );
    }
}
ChaiProvenancesBulkBlockchain.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ChaiProvenancesBulkBlockchain);