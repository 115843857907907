import React from 'react';
import PropTypes from "prop-types";
import { debounce } from "debounce";
import { getPermissions, getOptionsByFormName, getOptionsByFormNameFieldName } from "./service";
import chaiErrorHandler from '../../Handler/chaiErrorHandler';
import imageCompression from 'browser-image-compression';
import _ from 'lodash';

const { uploadImage } = require('../../DataProviders/crudgeneric');

/**
 * This is our BaseForm component for all the chai forms
 * should extends from this one.
 *
 * WARNING: If you feel this ChaiBaseForm doesn't suit your
 * needs don't use it!
 *
 * @example
 *
 * class ChaiBottleInformation extends ChaiBaseForm {
 *   constructor(props) {
 *       let formName = 'bottleInformation';
 *       let emptyDataState = {
 *           barCode: null,
 *           typeOfInput: null,
 *           wineName: null,
 *       };
 *       super({...props, formName, emptyDataState });
 *       this.state = {...this.state, milocalvar : 'Hola'};
 *   }
 *
 * If you need a local state you should set it after the "super()" call
 *
 * and don't override the state
 *
 * this.state = {...this.state, milocalvar : 'Hola'};
 *
 * */
class ChaiBaseForm extends React.Component {

    /**
     * @param props { formName, emptyDataState }
     * props.formName {String} FormName, it is related to localStorage.
     * props.emptyDataState {Object} this.state.data where the form save its data.
     * */
    constructor(props) {
        super(props);
        this.emptyDataState = props.emptyDataState || {};
        const formName = this.getFormName();
        let options = [];
        options[formName] = [];
        this.state = {
            loadTemplate: props.loadTemplate,
            template: {},
            data: props.emptyDataState,
            formName: formName,
            loading: false,
            permissions: getPermissions(),
            options,
            setTemplate: this.loadTemplate,
            getComponentData: this.getComponentData,
            fetchedData: false,
            processToSave: null,
        }
    }

    getFormName = () =>
        this.props.formName ||
        (this.state || {}).formName || 
        this.constructor.name.replace(/^chai/i, '').replace(/^\w/, x => x.toLowerCase());

    /**
     * Get the element to edit.
     *
     * example:
     *
     *  getElement = async (ledger, version) => {
     *   return getBottle(ledger.ledgerNumber, 0)
     *       .then(result => {
     *           return result ? result : this.emptyDataState
     *       });
     *
     *  };
     * */
    getElement = async (ledger, version) => {
        throw "This method must be implemented!"
    };

    /**
     * Save Element new or edited.
     *
     *  saveElement = async (ledger, version, element) => {
     *    return saveBottleInfo(ledger.ledgerNumber, version, element);
     *  };
     * */
    saveElement = async (ledger, version, element) => {
        throw "This method must be implemented!"
    };

    /**
     * After the component is sucessfully mounted and working,
     * It will retrieve data and pemissions from the backend.
     * */
    componentDidMount() {
        const { currentLedger } = this.props;
        const optionsPromise = new Promise(async resolve =>
            resolve(
                this.props.loadOptions
                    ? await this.getOptionsWithFormName(this.getFormName())
                    : [])
        );
        const getElementPromise = new Promise((resolve, reject) =>
            _.isEmpty(currentLedger)
                ? reject(currentLedger)
                : resolve(this.getElement(currentLedger, 0)))
            .then(async data => await this.setState({
                data: { ...data, ledgerNumber: currentLedger.ledgerNumber, ledgerVersion: 0 },
                fetchedData: true
            }))
            .catch(async error =>
                this.props.defaultValues &&
                await this.setState({ data: {...this.state.data, ...this.props.defaultValues} })
            );

        this.setLoadingState(true);
        Promise
            .allSettled([optionsPromise, getElementPromise])
            .finally(async results => {
                // Saves original data for later use
                await this.setState({ originalData: _.cloneDeep(this.state.data) });
                // Updates the parent control's state
                this.updateParentForm(this.state.data);
                // And stop "loading"
                this.setLoadingState(false);
                // Check if form is complete
                this.isComplete();
                //
                if (this.state.loadTemplate) {
                    this.loadTemplate();
                }
                if (this.state.softLoad && !this.props.hideFooter) {
                    this.loadSoftSave();
                }
            });
    }


    loadTemplate = () => {
        const data = JSON.parse(localStorage.getItem('template'));
        if (data) {
            this.setState({ template: { ...data, stampsMarkings: data.stampMarkings } });
        }
    }

    loadSoftSave = () => {
        const { formName } = this.state
        let item = 'bottleInfo'
        if (formName === 'administrative' || formName === 'authenticator'){
            item = 'authInfo'
        }
        const localInfo = localStorage.getItem(item)
        if (localInfo) {
            const softData = JSON.parse(localInfo)
            if (softData[this.state.formName]) {
                let newData = softData[this.state.formName]
                this.setState({ data: { ...newData } });
            }  
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.changedFormName === this.getFormName() ||
            !_.isEqual(nextState, this.state) ||
            !_.isEqual(nextProps, this.props);
    }

    /**
     * After each update we are going to check if the form is Complete!
     * */
    componentDidUpdate(nextProps, nextState, nextContext) {
        const { template } = this.props;
        if (!_.isEmpty(this.state.template)) {
            this.setState({ template });
        }
        if (
            !_.isEmpty(this.props.data) &&
            !_.isEqual(this.props.data, this.state.data)
        ) {
            this.setState({ data: this.props.data });
        }
        if (
            !this.props.hideFooter &&
            !_.isEqual(nextState.data, this.state.data)
        ) {
            this.isComplete();
        }
    }

    /**
     * This method tells the parent when all the fields are completed.
     * */
    isComplete = () => {
        // TODO improve missing function, for '' and 0.
        const missing = this.state.data
            ? Object.values(this.state.data).some(x => !x)
            : false;

        if (!missing) {
            this.props.handleComplete(this.props.activeStep);
        } else {
            this.props.handleIncomplete(this.props.activeStep);
        }
    };

    /**
     * Will fire isComplete after 1000ms, if the function
     * is called before will wait another 1000ms.
     * */
    isCompleteDebounce = debounce(this.isComplete, 1000);

    /**
     * Check's for the existence of the handleFormUpdate prop and fires
     * it to update the parent component's state with this state.
     * */
    updateParentForm = data => {
        const { handleFormUpdate, index } = this.props;
        if (handleFormUpdate) {
            const { fetchedData, prepareToSendFunc, originalData } = this.state;
            handleFormUpdate(
                this.getFormName(),
                { data, fetchedData, index, originalData, prepareToSendFunc });
        }
    }

    /**
     * Generic form update handler for most use cases of updating the
     * state.
     * */
    handleFormUpdate = (formName, { data }) => {
        let formsData = this.state.formsData || {};
        formsData[formName] = {..._.cloneDeep(formsData[formName]), ..._.cloneDeep(data) };
        this.setState({ formsData });
    };

    /**
     * @param e event
     * @param field {String} FieldName
     *
     * Handles the event of a input and save the new value to the state.
     * */
    handleChange = (e, field, nextFunc = null) => {
        const data = { ...this.state.data };
        data[field] = e.target.value;
        this.updateParentForm(data);
        this.setState({ data }, nextFunc);
    };

    /**
     * @param e event
     * @param field {String} FieldName
     *
     * Handles the event of a DropDownMultiple and save push a new value to the state.
     * */
    onSelectArrayField = (e, field) => {
        const data = this.state.data;
        data[field] = e.target.value;
        this.updateParentForm(data);
        this.setState({ data });
    };

    onRemoveArrayField = (item, field) => {
        const data = this.state.data;
        const items = [...data[field]];
        data[field] = items.filter(e => e !== item);
        this.updateParentForm(data);
        this.setState({ data });
    };

    handleToogle = (e, field, nextFunc = null) => {
        const data = { ...this.state.data };
        data[field] = !data[field];
        this.updateParentForm(data);
        this.setState({ data }, nextFunc);
    };

    getTemplateFieldStyle = (field, style) => {
        const template = _.isEmpty(this.state.template)
            ? this.props.template
            : this.state.template;
        return ({
            style: {
                ...style,
                backgroundColor: _.isEmpty(template)
                    ? "transparent"
                    : !_.isEqual(this.state.data[field], template[field])
                        ? "#fffada"
                        : "#caf7d0"
            }
        });
    }

    /**
     * @param field {String} FieldName
     * @param asToggle {boolean} it is a boolean field (check or switch)
     * @return {{keyField: String, onChange: (function(*=): void), disabled: (*|disabled), value: *}}
     *
     * @example <ChaiTextField {...this.getField('wineName')} label="WINE NAME" style={{ width: '40%' }} />
     * */
    getField = (field, asToggle = false, nextFunc = null) => ({
            value: (this.state.data || {})[field],
            onChange: evt => asToggle
                ? this.handleToogle(evt, field, nextFunc)
                : this.handleChange(evt, field, nextFunc),
            keyField: field,
            disabled: this.state.loading || !this.calculateWritePermissionForms(field),
            visibility: this.calculateReadPermission(field)
        });

    getFileField = (field) => {
        let imagePieces = this.state.data[field] ? this.state.data[field].trim() ? this.state.data[field].split('/') : null : null;
        let fileNamePiece = imagePieces ? imagePieces[imagePieces.length - 1] : '';
        let filename = this.getShorterImageName(fileNamePiece);
        return {
            field,
            onChange: this.onFileSelect,
            fileName: filename,
            fileUrl: this.state.data[field],
            disabled: this.state.loading || !this.calculateWritePermissionForms(field)
        }
    };


    getOptionsWithFormNameFieldName = async (formName = this.state.formName, fieldName) => {
        if (this.state.options[formName] && this.state.options[formName][fieldName]) {
            return this.state.options[this.state.formName][fieldName];
        } else {
            const data = await getOptionsByFormNameFieldName(formName, fieldName);
            return data;
        }
    };

    getOptionsWithFormName = async (formName) => {
        const options = this.state.options || {};
        if (!_.isEmpty(options[formName])) {
            return options[formName];
        } else {
            if (!options[formName]) options[formName] = [];
            const data = await getOptionsByFormName(formName);
            !_.isEmpty(data) && data.fields.map(field => {
                return options[formName][field.field] = field.options.map(option => {
                    return option;
                });
            });
        }
    };

    getMultipleDropDownField = (field) => {
        return {
            onChange: (e) => this.onSelectArrayField(e, field),
            onDelete: (item) => this.onRemoveArrayField(item, field),
            value: this.state.data[field],
            disabled: !this.calculateWritePermissionForms(field)
        }
    };


    getShorterImageName = (fileName) => {
        const fileParts = fileName.split('.');
        const extension = fileParts[fileParts.length - 1];
        const fileNameShorter = fileName.length > 10 ? `${fileName.substr(0, 4)}...${extension}` : fileName;

        return fileNameShorter;
    };

    handleImageCompression = async (imageFile, uploadLimit) => {
        const options = {
            maxSizeMB: uploadLimit / 1024 / 1024
        };

        try {
            const compressedFile = await imageCompression(imageFile, options);
            return compressedFile;
        } catch (error) {
            console.info(error);
            this.setLoadingState(false);
            this.props.showNotification(`Something went wrong uploading the file`);
            return false;
        }
    };

    onFileSelect = async (e, field) => {
        try {
            const uploadLimit = process.env.REACT_APP_CHAI_MAX_UPLOAD_SIZE || 2000000;
            let file = e.target.files[0];

            let maxSizeFlag = false;
            this.setLoadingState(true);

            if (file.size && file.size > uploadLimit) {
                maxSizeFlag = true;
                let compressedFile = await this.handleImageCompression(file, uploadLimit);
                file = compressedFile;
            }

            const fileName = this.getShorterImageName(file.name);

            const result = await uploadImage(file);
            this.setLoadingState(false);
            let data = { ...this.state.data };
            data[field] = result.Location; // Location provided by AWS S3 Bucket

            this.updateParentForm(data);    
            this.setState({ [`fileName${field}`]: fileName, data });

            if (maxSizeFlag) {
                this.props.showNotification(`The image you have uploaded exceeds the maximum allowed size. It has been compressed to fit.`);
            }
        } catch (error) {
            console.info(error);
            this.setLoadingState(false);
            this.props.showNotification(`Something went wrong uploading the file`);
        }
    };

    /**
     * This function should be use in case no direct field is associated to permissions but needs to act according to them
     * @param field {string} Name of the field
     * @returns {boolean}
     */
    calculateWritePermissionForms = (field) => {
        return (this.state.permissions ? this.calculateWritePermission(this.state.formName, field, this.state.permissions) : false);
    };


    /**
     * Set this.state.loading state and notify parent using "notifyLoadingChange"
     * Take in account the "notifyLoadingChange" function works as observer pattern
     * */

    setLoadingState = (loading = false) => {
        this.setState({ loading });
        if (typeof this.props.notifyLoadingChange === "function") {
            this.props.notifyLoadingChange(loading);
        }
    };

    /**
     * Calculate read permission if fault the field should be hidden
     * If empty permissions the field will not display for that role
     * */

    calculateReadPermission = (fieldName) => {
        const { permissions, formName } = this.state;
        if (permissions && formName) {
            let fieldPermissions = ((permissions.data || {})[formName] || [])
                .map(p => p.split(':')).find(p => p[0] === fieldName);
            if (fieldPermissions) {
                return fieldPermissions[1].indexOf('r') > -1;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    /**
     * This function is for internal use, in case of a different permission schema is needed
     * use calculateWritePermissionForms() instead
     *
     * How it works? This function will check if the user is Owner/Creator of the ledger
     * or the ledger was shared with the user with edit permission or the user is Admin.
     *
     * @param formName {string} Form name
     * @param fieldName {string} the name of the field
     * @param permissions {Array}
     * @return {boolean}
     * */

    calculateWritePermission = (formName, fieldName, permissions) => {
        if (this.isOwnerOrCreator() || this.isSharedAndCanEdit() || this.isAdmin()) {
            let local = permissions.data[formName];
            let p = (local || []).map(l => l.split(':')).find(l => l[0] === fieldName);
            if (p) {
                return (p[1].indexOf('w') > - 1)
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    /**
     * Checking if user is Admin.
     * */

    isAdmin = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        return user.rol === 'Administrator';
    };

    /**
     * Checking if user can edit the currentLedger.
     * */
    isSharedAndCanEdit = () => {
        if (this.props.currentLedger) {
            const { currentLedger } = this.props;
            const user = JSON.parse(localStorage.getItem('user'));
            return currentLedger && currentLedger.allowedToEdit ? currentLedger.allowedToEdit.userId === user.id : false;
        } else {
            return false;
        }
    };

    /**
     * Checking ledger creator and/or owner
     * */
    isOwnerOrCreator = () => {
        if (this.props.currentLedger) {
            const { creator, owner } = this.props.currentLedger;
            const user = JSON.parse(localStorage.getItem('user'));
            return (
                owner && (
                    owner.userId === user.id ||
                    (creator && owner.userId === '' && creator.userId === user.id)
                )
            );
        } else {
            return false;
        }

    };

    /**
     *This function is optional
     *And is the function that runs after the save function
     * */
    afterSaveCallback = () => {

    };

    save = async () => {
        this.setLoadingState(true);
        console.log("SAVING DATA");
        try {
            console.log("SENDING");
            await this.saveElement(this.props.currentLedger, 0, this.state.data);
            console.log("COMPLETED RESPONSE");

            if (localStorage.modifiedTemplateFields) {
                const modifiedTemplateFields = JSON.parse(localStorage.modifiedTemplateFields);
                let modified = false;
                for (var k in modifiedTemplateFields) {
                    if (modified |= !modifiedTemplateFields[k]) {
                        break;
                    }
                }
                localStorage[`${window.location.hash.replace('#/', '')}Modified`] = modified;
                localStorage.modifiedTemplateField = "{}";
            }

            this.setLoadingState(false);
            this.props.handleNextStep();
            this.afterSaveCallback();
        } catch (e) {
            console.log("CAUGHT ERROR IN BASE FORM: ", e);
            this.setLoadingState(false);
            //We provide a notifier for the error handler so it displays the error once its handled
            chaiErrorHandler(this.notify).catch(e);
        }
    };

    /**
     * Method used to display messages to UI
     * @param message string The message to display
     */
    notify = message => {
        this.props && this.props.showNotification && this.props.showNotification(message);
    }
}

ChaiBaseForm.propTypes = {
    classes: PropTypes.object.isRequired,
    emptyDataState: PropTypes.object.isRequired
};
export default ChaiBaseForm;
