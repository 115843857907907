const { dpCrud, CREATE, GET_ONE, GET_LIST, UPDATE } = require('../../DataProviders/crudgeneric');
const sha512 = require('js-sha512');
const { addNewLedgerData } = require('../../Services/ledgers');

export async function saveAuthenticator(ledgerNumber, version, data) {
    try {
        const values = parseValues(data);
        const response = await dpCrud(CREATE, 'authenticator', { 'data': values });
        return response;
    } catch (ex) {
        console.error('error saveAuthenticator', ex);
        throw ex;
    }
}

export async function generateCertificate(ledgerNumber, ledgerVersion) {
    try {
        const values = { ledgerNumber, ledgerVersion };
        const url = `ledgers/${ledgerNumber}/versions/${ledgerVersion}/generateCertificate`
        const response = await dpCrud(CREATE, url, { 'data': values });
        return response;
    } catch (ex) {
        console.error('generateCertificate', ex);
        throw ex;
    }
}


export async function getTemplates(ledgerNumber, ledgerVersion) {
    try {
        const url = `ledgers/${ledgerNumber}/versions/${ledgerVersion}/getTemplates`
        const response = await dpCrud(GET_LIST, url);
        return response;
    } catch (ex) {
        console.error('getTemplates', ex);
        throw ex;
    }
}

export async function generateTemplate(ledgerNumber, ledgerVersion, name, description) {
    try {
        const values = { ledgerNumber, ledgerVersion, name, description };
        const url = `ledgers/${ledgerNumber}/versions/${ledgerVersion}/generateTemplate`;
        const response = await dpCrud(CREATE, url, { 'data': values });
        return response;
    } catch (ex) {
        console.error('generateTemplate', ex);
        throw ex;
    }
}

export async function updateTemplate(ledgerNumber, ledgerVersion) {
    try {
        const values = { ledgerNumber, ledgerVersion };
        const url = `ledgers/${ledgerNumber}/versions/${ledgerVersion}/updateTemplate`;
        const response = await dpCrud(UPDATE, url, { data: values });
        return response;
    } catch (ex) {
        console.error('updateTemplate', ex);
        throw ex;
    }
}

export async function generateAllCertificates(ledgers) {
    try {
        const url = `ledgers/generateAllCertificates`
        const response = await dpCrud(CREATE, url, { 'data': ledgers });

        return response;
    } catch (ex) {
        console.error('generateAllCertificates', ex);
        throw ex;
    }
}

export async function setVerification(ledgerNumber, ledgerVersion, password, address) {
    try {
        const values = { ledgerNumber, ledgerVersion, password: sha512(password), address };
        const response = await dpCrud(UPDATE, 'authenticator/verification', { 'data': values });
        return response;
    } catch (ex) {
        throw ex;
    }
}

export async function genChainStructure(ledgerNumber, ledgerVersion) {
    try {
        const url = `ledgers/${ledgerNumber}/versions/${ledgerVersion}/genChainStructure`;
        const response = await dpCrud(GET_LIST, url);
        return response;
    } catch (ex) {
        throw ex;
    }
}

export async function genAllChainStructure(ledgers) {
    try {
        const url = `ledgers/genAllChainStructure`;
        const response = await dpCrud(CREATE, url, { data: ledgers });
        return response;
    } catch (ex) {
        throw ex;
    }
}

export async function closeLedger(ledgerNumber, ledgerVersion) {
    try {
        const url = `ledgers/${ledgerNumber}/versions/${ledgerVersion}/close`;
        const response = await dpCrud(GET_LIST, url);
        return response;
    } catch (ex) {
        throw ex;
    }
}

export async function closeLedgers(ledgers) {
    try {
        const url = `ledgers/closeAll`;
        const response = await dpCrud(CREATE, url, { data: ledgers });
        return response;
    } catch (ex) {
        throw ex;
    }
}

export async function rollbackLedger(ledgerNumber, ledgerVersion) {
    try {
        const url = `ledgers/${ledgerNumber}/versions/${ledgerVersion}/rollback`;
        const response = await dpCrud(UPDATE, url);
        return response;
    } catch (ex) {
        throw ex;
    }
}

export async function rollbackAllLedgers(ledgers) {
    try {
        const url = `ledgers/rollbackAll`;
        const response = await dpCrud(UPDATE, url, { data: ledgers });
        return response;
    } catch (ex) {
        throw ex;
    }
}

export async function verifyCompleteLedger(ledgerNumber, ledgerVersion) {
    try {
        const url = `ledgers/${ledgerNumber}/versions/${ledgerVersion}/isComplete`;
        const response = await dpCrud(GET_LIST, url);
        return response;
    } catch (ex) {
        throw ex;
    }
}

export async function verifyCompleteAllLedgers(ledgers) {
    try {
        const url = `ledgers/areComplete`;
        const response = await dpCrud(CREATE, url, { 'data': ledgers }); // I need to use a create in order to pass params
        return response;
    } catch (ex) {
        throw ex;
    }
}

export async function verifyAllCertificates(ledgers) {
    try {
        const url = `provenances/verifyAllCertificates`;
        const response = await dpCrud(CREATE, url, { 'data': ledgers });
        return response;
    } catch (ex) {
        throw ex;
    }
}

export async function updateProvenancesForAll(ledgers) {
    try {
        const url = `provenances/updateProvenancesForAll`;
        const response = await dpCrud(CREATE, url, { 'data': ledgers });
        return response;
    } catch (ex) {
        throw ex;
    }
}

export async function getAuthenticator(ledgerNumber, ledgerVersion) {
    try {
        const data = await dpCrud(GET_ONE, 'authenticator', {
            ledger: {
                ledgerNumber: ledgerNumber,
                ledgerVersion: ledgerVersion
            }
        });
        return data.data;
    } catch (ex) {
        console.error('Error getAuthenticator', ex);
        return null;
    }
}

export async function verifyOwnershipTransfer(ledgerNumber, ledgerVersion) {
    try {
        const url = `provenances/${ledgerNumber}/versions/${ledgerVersion}/hasOwnershipTransfer`;
        const response = await dpCrud(GET_LIST, url);

        return response.data;
    } catch (ex) {
        console.error('Error verifyOwnershipTransfer', ex);
        throw ex;
    }
}

export async function verifyPendingOwnershipTransfer(ledgerNumber, ledgerVersion) {
    try {
        const url = `provenances/${ledgerNumber}/versions/${ledgerVersion}/hasPendingOwnershipTransfer`;
        const response = await dpCrud(GET_LIST, url);

        return response.data;
    } catch (ex) {
        console.error('Error verifyOwnershipTransfer', ex);
        throw ex;
    }
}

export async function verifyOwnershipTransferAll(ledgers) {
    try {
        const url = `provenances/hasOwnershipTransferForAll`;
        const response = await dpCrud(CREATE, url, { 'data': ledgers });

        return response.data;
    } catch (ex) {
        console.error('Error verifyOwnershipTransfer All', ex);
        throw ex;
    }
}

export async function verifyPendingOwnershipTransferAll(ledgers) {
    try {
        const url = `provenances/hasPendingOwnershipTransferForAll`;
        const response = await dpCrud(CREATE, url, { 'data': ledgers });
        return response.data;
    } catch (ex) {
        console.error('Error verifyOwnershipTransfer All', ex);
        throw ex;
    }
}

export async function ownershipTransfer(ledgerNumber, ledgerVersion) {
    try {
        const url = `ledgers/${ledgerNumber}/versions/${ledgerVersion}/ownershipTransfer`;
        const response = await dpCrud(GET_LIST, url);

        return response.data;
    } catch (ex) {
        console.error('Error ownershipTransfer', ex);
        throw ex;
    }
}

export async function ownershipTransferForAll(ledgers) {
    try {
        const url = `ledgers/ownershipTransferForAll`;
        const response = await dpCrud(CREATE, url, { 'data': ledgers });

        return response.data;
    } catch (ex) {
        console.error('Error ownershipTransfer', ex);
        throw ex;
    }
}

export async function commitToBlockchain(ledger, privateKey, notifyStep) {
    let interval = null;
    try {
        const { ledgerNumber } = ledger;
        const url = `ledgers/commitToBlockchain`;

        // Saving to blockchain
        notifyStep('Saving to blockchain...');
        interval = setInterval(() => {
            notifyStep('Still working...');
        }, 1 * 10000);

        const response = await dpCrud(CREATE, url, 
            { 'data': { ledgerNumber, ledgerVersion: 0, privateKey } });
        clearInterval(interval);
        
        if (response.data) {
            notifyStep('Data saved to the blockchain!');
        } else {
            console.error('LEDGER DATA ROLLBACKED');
            notifyStep`Some errors occurred while saving the data to blockchain. 
                Please, contact your administrator or try again later.`;
        }

        return response;
    } catch (ex) {
        clearInterval(interval);

        console.error('commitToBlockchain', ex);
        throw ex;
    }
}

export async function blockchainSavingProcess(ledger, privateKey, notifyStep) {
    const ledgerNumber = ledger.ledgerNumber;
    const user = JSON.parse(localStorage.getItem('user'));

    // Verify ownership transfer permissions
    const isAdmin = user.rol === "Administrator";
    const isOwner = (
        (ledger.owner.userId !== "" && ledger.owner.userId === user.id)
        || (ledger.owner.userId === "" && ledger.creator.userId === user.id)
    );
    const isSeller = ledger.allowedToSell.userId === user.id;
    if (!isAdmin && !isOwner && !isSeller) {
        throw `This ledger currently has a pending ownership transfer. Please contact it's owner regarding sale permissions before commiting to blockchain`;
    }
    //We verify that ownership transfer has been added
    notifyStep('Verifying ownership transfer...');
    const hasOwnershipTransfer = await verifyOwnershipTransfer(ledgerNumber, 0);
    if (!hasOwnershipTransfer) {
        throw `You need to include an Ownership Transfer to save to the Blockchain`;
    }
    //We verify there are pending ownership approvals
    const hasPendingOwnershipTransfer = await verifyPendingOwnershipTransfer(ledgerNumber, 0);
    if (hasPendingOwnershipTransfer) {
        throw `You need to approve or revoke every request of pending ownership tranfer`;
    }

    //We verify is all data is saved
    notifyStep('Verifying ledger completion...');
    const ledgerVerification = await verifyCompleteLedger(ledgerNumber, 0);

    if (!ledgerVerification.data.isComplete) {
        const missingDrafts = ledgerVerification.data.notFoundDrafts;
        const draftData = missingDrafts ? missingDrafts.join(", ") : null;

        console.error('LEDGER VERIFICATION PROCESS', draftData);
        throw draftData ? `There are some missing data related to ${draftData}. Please, complete all data.` :
            `There are some missing data. Saving to blockchain is not possible right now. Please, contact your administrator.`;
    } else {
        //Closing ledger
        notifyStep('Closing ledger...');
        await closeLedger(ledgerNumber, 0);

        //Saving to blockchain
        notifyStep('Saving to blockchain...');
        const interval = setInterval(() => {
            notifyStep('Still working...');
        }, 1 * 10000);

        const chainStructure = await genChainStructure(ledgerNumber, 0);
        const saveBlockchainResponse = await addNewLedgerData(privateKey, { ledgerNumber, ledgerVersion: 0 }, chainStructure.data);

        clearInterval(interval);

        if (saveBlockchainResponse.success) {
            //check ownership transfer && mark provenances as uploaded to blockchain
            notifyStep('Verifying Ledger ownership...');
            const verifyLedgerOwner = await ownershipTransfer(ledgerNumber, 0);
            if (!verifyLedgerOwner) {
                notifyStep('An error ocurred while verifying Ledger ownership');
                console.error('OWNERSHIP TRANSFER', verifyLedgerOwner.errors.join(", "));
            }
            notifyStep('Data saved to the blockchain!');
            //certificate
            try {
                await generateCertificate(ledgerNumber, 0);
                notifyStep('Certificate generated successfully!');
                return true;
            } catch (ex) {
                console.error('generateCertificateErrors', ex);
                return false;
            }



        } else {
            await rollbackLedger(ledgerNumber, 0);
            console.error('LEDGER DATA ROLLBACKED');
            console.error('BLOCKCHAIN PROCESS', saveBlockchainResponse.errors.join(", "));
            throw `Some errors occurred while saving the data to blockchain. Please, contact your administrator or try again later.`;
        }
    }
}

export async function blockchainBulkSavingProcess(ledgers, privateKey, notifyStep) {
    let notCompleted = null;
    let logs = [];

    //We verify is all data is saved
    notifyStep('Verifying ledgers completion...');
    const ledgersVerification = await verifyCompleteAllLedgers(ledgers);

    // Filter those that are not completed to be logged and re-process them again if needed
    notCompleted = ledgersVerification.data.filter(ledger => {
        return !ledger.isComplete;
    });

    const notCompletedLog = notCompleted.map(n => {
        return {
            ledgerNumber: n.ledgerNumber,
            error: 'Ledger is missing information'
        }
    });

    if (notCompletedLog && notCompletedLog.length) {
        logs = logs.concat(notCompletedLog);
    }

    const completed = ledgersVerification.data.filter(ledger => {
        return ledger.isComplete;
    });

    // I work with the completed ones first
    if (completed) {
        const ledgersMapped = completed.map(l => l.ledgerNumber);

        //Closing ledgers
        notifyStep('Closing ledgers...');
        await closeLedgers(ledgersMapped);

        //Saving to blockchain
        notifyStep('Saving to blockchain...');
        const interval = setInterval(() => {
            notifyStep('Still working...');
        }, 1 * 10000);

        const chainStructures = await genAllChainStructure(ledgersMapped);
        console.info('CHAIN STRUCTURES FOR ALL', chainStructures);

        let blockchainResults = [];
        for (const ledger of ledgersMapped) {
            let structureData = chainStructures.data.find(ch => ch.ledgerNumber === ledger);

            if (structureData.contractMappings) {
                let saveBlockchainResponse = await addNewLedgerData(
                    privateKey,
                    { ledgerNumber: ledger, ledgerVersion: 0 },
                    structureData.contractMappings);
                console.log(`BULK BLOCKCHAIN PROCESS FOR ${ledger} COMPLETED!`);

                saveBlockchainResponse.ledgerNumber = ledger;
                blockchainResults.push(saveBlockchainResponse);
            } else {
                logs.push({
                    ledgerNumber: ledger,
                    error: 'Could not generate information needed for the Blockchain'
                });
            }
        }

        clearInterval(interval);
        const failuredBlockchainLedgers = blockchainResults.filter(b => !b.success);
        const okBlockchainLedgers = blockchainResults.filter(b => b.success);

        try {
            const mappedOkLedgers = okBlockchainLedgers.map(bl => bl.ledgerNumber);

            notifyStep('Verifying Ledger ownership...');
            await ownershipTransferForAll(mappedOkLedgers);

            const certificatesResult = await generateAllCertificates(mappedOkLedgers);
            const certError = certificatesResult.data.filter(cr => !cr.ok);
            const certOk = certificatesResult.data.filter(cr => cr.ok);
            notifyStep(`${certOk.length} certificates generated successfully!`);

            if (certError && certError.length) {
                logs = logs.concat(certError.map(f => {
                    return {
                        ledgerNumber: f.ledgerNumber,
                        error: 'Error generating certificate'
                    }
                }));
            }

            let mappedFailedLedgers = [];
            if (failuredBlockchainLedgers.length) {
                mappedFailedLedgers = failuredBlockchainLedgers.map(fl => fl.ledgerNumber);

                logs = logs.concat(failuredBlockchainLedgers.map(f => {
                    return {
                        ledgerNumber: f.ledgerNumber,
                        error: f.errors.join(',')
                    }
                }));

                await rollbackAllLedgers(mappedFailedLedgers);
                notifyStep(`${mappedFailedLedgers.length} ledgers failed. Please, review their data and process them again.`);
            }

            const failedLedgers = mappedFailedLedgers.concat(
                notCompleted.length ? notCompleted.map(nc => nc.ledgerNumber) : [],
                certError.map(nc => nc.ledgerNumber));

            return { ledgersOK: mappedOkLedgers, ledgersError: failedLedgers, error: null, errorMessage: null, logs: logs };
        } catch (ex) {
            console.error('generateAllCertificates Errors', ex);
            return { ledgersOK: [], ledgersError: [], error: true, errorMessage: ex.message, logs: logs };
        }
    }
}

export async function provenancesBlockchainBulkSavingProcess(ledgers, privateKey, notifyStep) {
    let notCompleted = null;
    let notOwnerShip = [];
    let pendingOwnerShip = [];
    let logs = [];

    //Verifiy Ownership Trconansfer for the ledgers
    notifyStep('Verifying ownership transfer...');
    const hasOwnershipTransfer = await verifyOwnershipTransferAll(ledgers);

    notOwnerShip = hasOwnershipTransfer.filter(ledger => {
        return !ledger.success;
    });
    const ownerErrors = notOwnerShip.map(n => {
        return {
            ledgerNumber: n.ledgerNumber,
            error: n.log || 'OwnerShip transfer does not exist for Ledger'
        }
    });
    logs = logs.concat(ownerErrors);
    ledgers = hasOwnershipTransfer.filter(ledger => ledger.success).map(ledger => ledger.ledgerNumber)
    //We verify there are pending ownership approvals
    const hasPendingOwnershipTransfer = await verifyPendingOwnershipTransferAll(ledgers);
    pendingOwnerShip = hasPendingOwnershipTransfer.filter(ledger => {
        return !ledger.success;
    });
    const pendingErrors = pendingOwnerShip.map(n => {
        return {
            ledgerNumber: n.ledgerNumber,
            error: 'Pending Ownership transfer for Ledger'
        }
    });
    logs = logs.concat(pendingErrors);
    ledgers = hasPendingOwnershipTransfer.filter(ledger => ledger.success).map(ledger => ledger.ledgerNumber)
    //We verify is all certificates exist
    notifyStep('Verifying Certificates completion...');
    const certificatesVerification = await verifyAllCertificates(ledgers);

    // Filter those that do not exist to be logged and re-process them again if needed
    notCompleted = certificatesVerification.data.filter(ledger => {
        return !ledger.ok;
    });

    const notCompletedLog = notCompleted.map(n => {
        return {
            ledgerNumber: n.ledgerNumber,
            error: 'Certificate does not exist for Ledger'
        }
    });

    if (notCompletedLog && notCompletedLog.length) {
        logs = logs.concat(notCompletedLog);
    }

    const completed = certificatesVerification.data.filter(ledger => {
        return ledger.ok;
    });

    // I work with the completed ones first
    if (completed) {
        const certificatesMapped = completed.map(l => l.ledgerNumber);

        //Saving to blockchain
        notifyStep('Saving Provenances to blockchain...');
        const interval = setInterval(() => {
            notifyStep('Still working...');
        }, 1 * 10000);

        const chainStructures = await genAllChainStructure(certificatesMapped);
        console.info('CHAIN STRUCTURES FOR ALL', chainStructures);

        let blockchainResults = [];
        for (const ledger of certificatesMapped) {
            let structureData = chainStructures.data.find(ch => ch.ledgerNumber === ledger);

            if (structureData.contractMappings) {
                let provenanceContractMappings = structureData.contractMappings.filter(c => c.contractName === 'ChaiProvenanceAuctionAndPrivate' || c.contractName === 'ChaiProvenanceRetail');
                let saveBlockchainResponse = await addNewLedgerData(
                    privateKey,
                    { ledgerNumber: ledger, ledgerVersion: 0 },
                    provenanceContractMappings);
                console.log(`PROVENANCES BULK BLOCKCHAIN PROCESS FOR ${ledger} COMPLETED!`);
                saveBlockchainResponse.ledgerNumber = ledger;
                blockchainResults.push(saveBlockchainResponse);
            } else {
                logs.push({
                    ledgerNumber: ledger,
                    error: 'Could not generate information needed for the Blockchain'
                });
            }
        }

        clearInterval(interval);
        const failuredBlockchainLedgers = blockchainResults.filter(b => !b.success);
        const okBlockchainLedgers = blockchainResults.filter(b => b.success);

        try {
            const mappedOkLedgers = okBlockchainLedgers.map(bl => bl.ledgerNumber);
            notifyStep('Updating Ownership Transfers...');
            const ownershipTransferLog = await ownershipTransferForAll(mappedOkLedgers);
            notifyStep('Updating Certificate...');
            await updateProvenancesForAll(mappedOkLedgers);
            const failedOwnership = ownershipTransferLog.filter(ledger => !ledger.success);

            let mappedFailedLedgers = [];
            if (failuredBlockchainLedgers.length) {
                const blockErrors = failuredBlockchainLedgers.map(f => {
                    return {
                        ledgerNumber: f.ledgerNumber,
                        error: f.errors.join(',')
                    }
                });
                logs = logs.concat(blockErrors);

                notifyStep(`${mappedFailedLedgers.length} provenances failed. Please, review their data and process them again.`);
            }

            mappedFailedLedgers = logs.map(fl => fl.ledgerNumber);

            if (failedOwnership.length > 0) {
                logs = logs.concat(failedOwnership.map(led => {
                    return {
                        ledgerNumber: led.ledgerNumber,
                        error: led.log
                    }
                }))
            }

            const failedLedgers = mappedFailedLedgers.concat(
                notCompleted.length ? notCompleted.map(nc => nc.ledgerNumber) : []);

            return { ledgersOK: mappedOkLedgers, ledgersError: failedLedgers, error: null, errorMessage: null, logs: logs };
        } catch (ex) {
            console.error('provenancesBlockchainBulkSavingProcess Errors', ex);
            notifyStep(`An error ocurred while updating Provenances status.`);
            return { ledgersOK: [], ledgersError: [], error: true, errorMessage: ex.message, logs: logs };
        }
    }
}

function parseValues(values) {
    let data = {
        wineLocation: {
            typeOfStorage: values.typeOfStorage,
            boxNumber: values.boxNumber,
            locker: values.locker,
            bin: values.bin,
            shelf: values.shelf
        },
        ledgerNumber: values.ledgerNumber,
        ledgerVersion: values.ledgerVersion,
        nameOfPerson: values.nameOfPerson,
        authenticatorCode: values.authenticatorCode,
        dateOfInspection: values.dateOfInspection,
        inspectionLocation: values.inspectionLocation,
        peoplePresentAssistanceInspection: values.peoplePresentAssistanceInspection,
        company: values.company,
        bottleConditionSummary: values.bottleConditionSummary
    };

    return data;
}
