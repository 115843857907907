import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';
import ChaiAccounts from './ChaiAccounts';
import ChaiTokens from './ChaiTokens';
import ChaiBlockchainTransactions from './ChaiBlockchainTransactions';

const styles = theme => ({
    tabsRoot: {
        backgroundColor: '#efefef'
    },
    tabsIndicator: {
        height: 0
    },
    tab: {
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '1.2em',
        fontWeight: 600
    },
    tabRoot: {
        boxShadow: 'inset 7px -8px 23px -2px rgba(0,0,0,0.47)',
        backgroundColor: '#3f3e3e',
        color: 'white',
        '&$tabSelected': {
            boxShadow: 'none',
            color: '#3f3e3e',
            backgroundColor: '#efefef'
        }
    },
    tabSelected: {},
    tabContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }
});

class ChaiBlockchainUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            tab: 0,
            loading: false
        };
    }

    handleTab = (event, tab) => {
        if (!this.state.loading) {
            this.setState({ tab });
        }
    };

    handleLoading = (loading) => {
        this.setState({ loading: loading });
    };

    render() {
        const { tab } = this.state;
        const { classes, theme, showNotification } = this.props;

        return (
            <Fragment>
                {this.state.loading && <LinearProgress />}
                <AppBar position='static' style={{ boxShadow: 'none' }}>
                    <Tabs value={tab} onChange={this.handleTab} fullWidth classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
                        <Tab disabled={this.state.loading}
                            classes={{ root: classes.tabRoot, label: classes.tab, selected: classes.tabSelected }}
                            label='Accounts' />
                        <Tab disabled={this.state.loading}
                            classes={{ root: classes.tabRoot, label: classes.tab, selected: classes.tabSelected }}
                            label='Tokens' />
                        <Tab disabled={this.state.loading}
                            classes={{ root: classes.tabRoot, label: classes.tab, selected: classes.tabSelected }}
                            label='Blockchain Transactions' />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={tab}
                >
                    {
                        tab === 0 &&
                        <div dir={theme.direction}>
                            <ChaiAccounts showNotification={showNotification} />
                        </div>
                    }
                    {
                        tab === 1 &&
                        <div dir={theme.direction} className={classes.tabContainer}>
                            <ChaiTokens showNotification={showNotification} />
                        </div>
                    }
                    {
                        tab === 2 &&
                        <div dir={theme.direction} className={classes.tabContainer}>
                            <ChaiBlockchainTransactions showNotification={showNotification} />
                        </div>
                    }
                </SwipeableViews>
            </Fragment>
        );
    }
}

ChaiBlockchainUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ChaiBlockchainUser);
