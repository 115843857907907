import React from 'react';

import { ReactComponent as LedgerIcon } from '../../Assets/Ledger.svg';
import { ReactComponent as BottleInfoIcon } from '../../Assets/BottleInfo.svg';
import { ReactComponent as MeasurementsIcon } from '../../Assets/Measurements.svg';
import { ReactComponent as LabelsIcon } from '../../Assets/Labels.svg';
import { ReactComponent as ClosureIcon } from '../../Assets/Closure.svg';
import { ReactComponent as GlassIcon } from '../../Assets/Glass.svg';
import { ReactComponent as AntifraudIcon } from '../../Assets/Antifraud.svg';
import { ReactComponent as CounterfeitIcon } from '../../Assets/Counterfeit.svg';
import { ReactComponent as ChainOfCustodyIcon } from '../../Assets/ChainOfCustody.svg';

const isTablet = window.innerWidth <= 1024;
const isOverOn = { padding: '5', fill: '#3f3e3e', width: '30', backgroundColor: '#dee0df', borderRadius: 40, height: 'auto' };
const isActiveOn = { padding: '5', fill: '#fff', width: '30', backgroundColor: '#632222', borderRadius: 40, height: 'auto' };
const isNormal = { padding: '5', fill: '#b9b9b9', width: '30', height: 'auto' };
const isTitleOn = { marginRight: '20px', fill: '#3f3e3e', width: isTablet ? 28 : 38, height: '100%' };

export const ChaiLedgerMenuIcon = (props) => {
    const { isOver, isActive, isTitle } = props;
    const iconStyle = isTitle ? isTitleOn : isOver ? isOverOn :
        isActive ? isActiveOn : isNormal;

    return (
        <LedgerIcon style={iconStyle} />
    )
};

export const ChaiBottleInfoMenuIcon = (props) => {
    const { isOver, isActive, isTitle } = props;
    const iconStyle = isTitle ? isTitleOn : isOver ? isOverOn :
        isActive ? isActiveOn : isNormal;

    return (
        <BottleInfoIcon style={iconStyle} />
    )
};

export const ChaiBottleDataMenuIcon = (props) => {
    const { isOver, isActive, isTitle } = props;
    const iconStyle = isTitle ? isTitleOn : isOver ? isOverOn :
        isActive ? isActiveOn : isNormal;

    return (
        <BottleInfoIcon style={iconStyle} />
    )
};

export const ChaiMeasurementsMenuIcon = (props) => {
    const { isOver, isActive, isTitle } = props;
    const iconStyle = isTitle ? isTitleOn : isOver ? isOverOn :
        isActive ? isActiveOn : isNormal;

    return (
        <MeasurementsIcon style={iconStyle} />
    )
};

export const ChaiLabelsMenuIcon = (props) => {
    const { isOver, isActive, isTitle } = props;
    const iconStyle = isTitle ? isTitleOn : isOver ? isOverOn :
        isActive ? isActiveOn : isNormal;

    return (
        <LabelsIcon style={iconStyle} />
    )
};

export const ChaiClosuresMenuIcon = (props) => {
    const { isOver, isActive, isTitle } = props;
    const iconStyle = isTitle ? isTitleOn : isOver ? isOverOn :
    isActive ? isActiveOn : isNormal;

    return (
        <ClosureIcon style={iconStyle} />
    )
};

export const ChaiGlassMenuIcon = (props) => {
    const { isOver, isActive, isTitle } = props;
    const iconStyle = isTitle ? isTitleOn : isOver ? isOverOn :
        isActive ? isActiveOn : isNormal;

    return (
        <GlassIcon style={iconStyle} />
    )
};

export const ChaiAntifraudMenuIcon = (props) => {
    const { isOver, isActive, isTitle } = props;
    const iconStyle = isTitle ? isTitleOn : isOver ? isOverOn :
    isActive ? isActiveOn : isNormal;

    return (
        <AntifraudIcon style={iconStyle} />
    )
};


export const ChaiCounterfeitMenuIcon = (props) => {
    const { isOver, isActive, isTitle } = props;
    const iconStyle = isTitle ? isTitleOn : isOver ? isOverOn :
    isActive ? isActiveOn : isNormal;

    return (
        <CounterfeitIcon style={iconStyle} />
    )
};

export const ChaiChainOfCustodytMenuIcon = (props) => {
    const { isOver, isActive, isTitle } = props;
    const iconStyle = isTitle ? isTitleOn : isOver ? isOverOn :
        isActive ? isActiveOn : isNormal;

    return (
        <ChainOfCustodyIcon style={iconStyle} />
    )
};
