import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import { CardContent, CircularProgress } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DownloadIcon from '@material-ui/icons/FileDownload';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiFileInput from '../ChaiFileInput';
import ChaiHeaderButton from '../ChaiHeaderButton';

import { uploadCellarTracker } from './service';
import { download } from '../../Helpers/downloader';

const styles = theme => ({
    card: { marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#efefef' },
    log: {
        width: '100%', marginBottom: 10, paddingBottom: 10, borderBottom: '1px solid #3e3e3e',
        fontFamily: '"Source Sans Code", sans-serif', fontSize: '1em', color: '#3e3e3e'
    }
});

const messages = ['Still processing your data...', 'Working with ledgers...', 'This may take a while...', 'Will finish soon...'];
let interval;

class ChaiMassUploadCellarTracker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            csvFile: null,
            fileName: null,
            message: '',
            uploading: false,
            logs: null,
            open: false,
            downloaded: false,
            processed: false
        }
    }

    onFileSelect = (e, field) => {
        var file = e.target.files[0];

        this.setState({ csvFile: file, fileName: file.name });
    }

    getFileField = (field) => {
        const { uploading, fileName } = this.state;

        return {
            field,
            label: 'CELLAR TRACKER FILE',
            acceptedExtensions: '.csv',
            onChange: this.onFileSelect,
            fileName: fileName,
            disabled: uploading
        }
    };

    upload = async () => {
        try {
            const { csvFile, downloaded, processed } = this.state;
            const { isUploading } = this.props;

            if (!downloaded && processed) {
                this.setState({ open: true });
                return;
            };

            isUploading(true);
            this.setState({ logs: null, uploading: true, message: 'Upload process begun...' });

            interval = setInterval(() => {
                const currentMessage = messages[Math.floor(Math.random() * 4) + 1];
                this.setState({ message: currentMessage });
            }, 1 * 5000);

            const logUploadProcess = await uploadCellarTracker(csvFile);
            clearInterval(interval);

            if (logUploadProcess.error) {
                throw logUploadProcess.errorMessage;
            }

            const logs = logUploadProcess.logs;
            const finalMessage = `Upload complete. ${logUploadProcess.totalLines - logs.length} processed successfuly, ${logs.length} errors`;

            isUploading(false);
            this.setState({ uploading: false, message: finalMessage, logs: logUploadProcess.logs, processed: true, downloaded: false });

        } catch (error) {
            this.props.isUploading(false);
            this.setState({
                uploading: false,
                processed: false,
                downloaded: false,
                message: error || 'Upload process interrupted due to an error. Please, try again later or contact your Administrator.'
            });
            clearInterval(interval);
        }
    };

    downloadLogs = (e) => {
        e.preventDefault()

        const { logs } = this.state;
        const logsInLine = logs.reduce((previous, log) => {
            let detailLog = 'Line ' + log.lineNumber + ': ' + this.beautifyErrorMessage(log.error) + '\r\n';
            detailLog += 'Content: ' + this.beautifyJsonToString(log.lineContent) + '\r\n\n';
            detailLog += '------------------------------------------ \r\n\n';

            return previous + detailLog;
        }, '');
        download('data:text/plain,' + encodeURIComponent(logsInLine), `upload_cellar_tracker_log_${(new Date().getTime())}.txt`);

        this.setState({ downloaded: true });
    };

    beautifyJsonToString = (json) => {
        return JSON.stringify(json)
            .replace(/\\/gi, '')
            .replace("\"{", '')
            .replace("}\"", '');
    };

    beautifyErrorMessage = (logError) => {
        if (logError.indexOf(',') >= 0) {
            return logError.substr(0, logError.length - 2)
        }

        return logError;
    };

    openModal = () => {
        this.setState({ open: true });
    };

    closeModal = () => {
        this.setState({ open: false, processed: true, downloaded: false });
    };

    handleEraseLogs = () => {
        this.setState({
            open: false,
            processed: false,
            downloaded: false,
            logs: null,
            uploading: true,
            message: ''
        });

        setTimeout(() => {
            this.upload();
        }, 1000);
    };

    renderModal = () => {
        const { open } = this.state;
        const { classes } = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.closeModal}
                classes={{
                    paper: classes.dialogRoot
                }}
            >
                <DialogContent>
                    {'Logs generated will be lost with a new Upload process. \r\nDo you want to continue?'}
                </DialogContent>
                <DialogActions style={{ margin: 0, padding: '0px 20px 20px 0px' }}>
                    <ChaiHeaderButton label="OK" onClick={this.handleEraseLogs} />
                    <div style={{ width: 30 }}></div>
                    <ChaiHeaderButton label="Cancel" onClick={this.closeModal} />
                </DialogActions>
            </Dialog>
        )
    };

    render() {
        const { classes } = this.props;
        const { uploading, message, logs, fileName } = this.state;

        return (
            <Card className={classes.card}>
                <CardContent>
                    <ChaiBodyContainer>
                        <div className={classes.nullPointer}>
                            {this.renderModal()}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'unset', alignItems: 'center' }}>
                            <ChaiFileInput {...this.getFileField('csvFile')} />
                            <div style={{ width: 30 }} ></div>
                            {
                                uploading ? <CircularProgress /> :
                                    <ChaiHeaderButton
                                        onClick={this.upload}
                                        disabled={fileName === null}
                                        label={'UPLOAD'}
                                        style={{ marginTop: 20 }} />
                            }
                        </div>
                        <ChaiRowBodyContainer>
                            {message}
                        </ChaiRowBodyContainer>
                        {
                            logs && logs.length ?
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="subheading">
                                            {'Logs'}
                                        </Typography>
                                        <DownloadIcon style={{ marginLeft: 20, color: '#3e3e3e' }} onClick={(e) => this.downloadLogs(e)} />
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{ display: 'grid' }}>
                                        {
                                            logs.map(log => {
                                                return (
                                                    <div className={classes.log}>
                                                        <strong>{`Line ${log.lineNumber}: `}</strong>{this.beautifyErrorMessage(log.error)}
                                                        <br />
                                                        <strong>{`Content: `}</strong> {this.beautifyJsonToString(log.lineContent)}
                                                    </div>
                                                );
                                            })
                                        }
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                : null
                        }
                    </ChaiBodyContainer>
                </CardContent>
            </Card>
        )
    }
}

ChaiMassUploadCellarTracker.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiMassUploadCellarTracker);