import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

import ChaiTextField from "../ChaiTextField";
import ChaiHeaderButton from "../ChaiHeaderButton";
import ChaiRowBodyContainer from "../ChaiRowBodyContainer";
import ChaiProgress from "../ChaiProgress";
import { recover, verifyRecoveryToken } from "./service";
import ChaiForgotLayout from "./forgotLayout";
import Typography from "@material-ui/core/Typography";
var strongRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);

const styles = theme => ({});

class ChaiRecover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordVerification: "",
      hash: this.props.hash,
      loading: false,
      logged: false,
      open: false,
      message: "",
      linkExpired: false
    };
  }

  showNotification = message => {
    this.setState({ open: true, message: message });

    setTimeout(() => {
      this.setState({ open: false });
    }, 7000);
  };

  handleChange = (e, field) => {
    const state = this.state;
    state[field] = e.target.value;

    this.setState({ state });

    if (e.key === "Enter") {
      this.handleRecover();
    }
  };

  getField = field => {
    const value = this.state[field];

    const propsField = {
      value: value,
      onChange: e => this.handleChange(e, field),
      keyField: field
    };

    return propsField;
  };

  isValid = () => {
    if (this.state.password !== this.state.passwordVerification) {
      this.showNotification(
        "Password and password verfication does not matches"
      );
      return false;
    }

    if (
      this.state.password.length <= 7 ||
      !strongRegex.test(this.state.password)
    ) {
      const text = `The password must contain at least 1 Lowercase Alphabetical character \n
            The password must contain at least 1 Uppercase Alphabetical character \n 
            The password must contain at least 1 Numeric character \n
            The password must contain at least 1 Special character \n
            The password must be eight characters or longer`;
      const newText = text.split("\n").map(i => <p>{i}</p>);
      this.showNotification(newText);
      return false;
    }
    return true;
  };

  handleRecover = async () => {
    if (this.isValid()) {
      try {
          this.setState({ loading: true });

          const credentials = {
              password: this.state.password,
              token: this.state.hash
          };

          const result = await recover(credentials);
          if (result) {
              this.setState({ loading: false });
              window.location = "/";
          } else {
              this.setState({ loading: false });
          }
      } catch (error) {
          const message = error.message
              ? error.message
              : "An error occurred on your request";
          this.showNotification(message);
          this.setState({ loading: false });
      }
    }
  };

  handleTokenVerification = async () => {
    try {
        this.setState({ loading: true, linkExpired: false });
        await verifyRecoveryToken(this.state.hash);
        this.setState({ loading: false });
    } catch (error) {
        const message = error.message
            ? error.message
            : "An error occurred on your request";
        this.showNotification(message);
        this.setState({ loading: false, linkExpired: true });
    }
  };

  componentDidMount() {
    this.handleTokenVerification();
  }

  render() {
    const { loading, message, open } = this.state;

    if (this.state.linkExpired) {
      return (
        <ChaiForgotLayout message={message} open={open}>
          <div style={{ padding: 40 }}>
            <Typography variant="h5" gutterBottom>
              Link expired
            </Typography>
            <Typography variant="body1" gutterBottom>
              This request password link has expired or has been used. Click{" "}
              <a href="#/forgotpassword">here</a> to request new reset password
              link.
            </Typography>
            <br />
            <ChaiHeaderButton
                  label="BACK TO LOGIN"
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  onClick={() => window.location = "/"}
                />
          </div>
        </ChaiForgotLayout>
      );
    } else {
      return (
        <ChaiForgotLayout message={message} open={open}>
          <div style={{ padding: 40 }}>
            <ChaiRowBodyContainer>
              <ChaiTextField
                {...this.getField("password")}
                label="PASSWORD"
                type={"password"}
                style={{ width: "100%" }}
              />
            </ChaiRowBodyContainer>
            <ChaiRowBodyContainer>
              <ChaiTextField
                {...this.getField("passwordVerification")}
                label="PASSWORD VERIFICATION"
                type={"password"}
                style={{ width: "100%" }}
              />
            </ChaiRowBodyContainer>
            <ChaiRowBodyContainer>
              {loading ? (
                <ChaiProgress />
              ) : (
                <ChaiHeaderButton
                  label="RECOVER PASSWORD"
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  onClick={this.handleRecover}
                />
              )}
            </ChaiRowBodyContainer>
          </div>
        </ChaiForgotLayout>
      );
    }
  }
}

ChaiRecover.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChaiRecover);
