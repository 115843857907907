import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Snackbar } from '@material-ui/core';

const styles = theme => ({
    root: { background: '#b5615e', fontFamily: '"Source Sans Pro", sans-serif', fontSize: 20, justifyContent: 'center'}
});

const ChaiNotification = (props) => {
    const { classes, open, message } = props;

    return (
        <Snackbar
            ContentProps={{
                classes: {
                    root: classes.root
                }
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={open}
            message={message}
        />
    );
};

ChaiNotification.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChaiNotification);