const logger = require('../helpers/logger.helper').default('LedgerIdsService');
const BaseService = require("./base.service").default;
const config = require('../providers/config.provider').default.getConfig();
const blockchainHelper = require('../helpers/blockchain.helper').default;
const processor = require("../contentProcessors/ledgerIds.processor").default;
const ContentValidationError = require("../exceptions/contentValidation.exception").default;
const ContractMethodError = require("../exceptions/contractMethod.exception").default;
const provideGas = require("../providers/wallet.provider").default.provideGas;

class LedgerIdsService extends BaseService {
    async create(content, ledger, wallet){
        if(processor.isValidContent(ledger, content)){
            try {
                const overrides = { 
                    gasLimit: 3000000 
                };
                const createData = processor.prepareForBlockchain(content, ledger, wallet.address);
                const idStatus = await this.contract.getIDStatus(createData.IDType, createData.IDType);
                if(!idStatus){
                    throw new Error("ID not active for addition");
                }

                //const estimate = await this.contract.estimate.createLedgerID(...Object.values(createData), overrides);
                if(await provideGas(wallet)){
                    let contractWithSigner = this.contract.connect(wallet);
                    const receiptTx = await contractWithSigner.createLedgerID(...Object.values(createData), overrides);
                    const receipt = await receiptTx.wait();
                    await logger.infoExternal('createLedgerID', receipt, 'send', ledger);

                    return receipt;
                }
            } catch (ex) {
                await logger.errorExternal('createLedgerID', ex.message, 'send', ledger);
                throw new ContractMethodError('Create Ledger ID', ex);
            }
        } else {
            throw new ContentValidationError('Ledger ID Data is invalid');
        }
        
    }
}

//Mapped contract key
export default (_config = config, _blockchainHelper = blockchainHelper()) => new LedgerIdsService('ChaiLedgerIDs', _config, _blockchainHelper);
