import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChaiTextField from '../ChaiTextField';
import ChaiBaseForm from '../ChaiBaseForm';
import {
    getBottle,
    saveBottleInfo
} from './service';
import formNames from '../ChaiBaseForm/formNames';


const styles = theme => ({
    line: {
        borderBottom: '1px solid #9c9c9c'
    },
    link: {
        color: '#632222',
        textDecoration: 'underline',
        marginRight: '10px',
        whiteSpace: 'nowrap',
    },
    paragraph: {
        marginLeft: '3px',
        textTransform: 'capitalize',
        alignSelf: 'center',
    },
    itemIfo: {
        display: 'flex',
    },
    white: {
        backgroundColor: '#fff',
        '&:hover': {
            background: '#D2D2D2 ',
         },
    },
    grey: {
        backgroundColor: '#EFEFEF',
        '&:hover': {
            background: '#D2D2D2',
         },
    },
    skipLink: {
        color: '#632222',
        textDecoration: 'underline',
        marginBottom: '10px',
    }
});

class ChaiBottleInformation extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.BOTTLEINFORMATION;
        let emptyDataState = {
            barCode: ''
        };

        super({ ...props, formName, emptyDataState });
        this.state = {
            ...this.state,
            milocalvar: 'Hola',
            query: '',
            results: [],
            loadTemplate: true,
            templates: [],
            expanded: -1,
        };
    }

    getElement = async (ledger, version) => {
        await this.getOptionsWithFormName(formNames.BOTTLEINFORMATION);
        return getBottle(ledger.ledgerNumber, 0)
            .then(result => {
                return result ? result : this.emptyDataState
            });
    };

    saveElement = async (ledger, version, element) => {
        const response = await saveBottleInfo(ledger.ledgerNumber, version, element);

        return response;
    };

    afterSaveCallback = () => { };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.line}>
                <ChaiTextField
                    {...this.getField('barCode')}
                    placeholder="Inventory number/barcode"
                />
            </div>
        );
    }
}

ChaiBottleInformation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiBottleInformation);
