import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChaiTextField from '../ChaiTextField';
import ChaiDropDown from '../ChaiDropDown';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBaseForm from '../ChaiBaseForm';
import ChaiFooterContainer from "../ChaiFooterContainer";
import {
    applyTemplateToLedger,
    getBottle,
    getTemplateByBottleInfo,
    saveBottleInfo
} from './service';
import LinearProgress from '@material-ui/core/LinearProgress';
import formNames from '../ChaiBaseForm/formNames';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ChaiHeaderButton from '../ChaiHeaderButton';
import { formatDate } from '../../Helpers/formatDate';


const styles = theme => ({
    link: {
        color: '#632222',
        textDecoration: 'underline',
        marginRight: '10px',
        whiteSpace: 'nowrap',
    },
    paragraph: {
        marginLeft: '3px',
        textTransform: 'capitalize',
        alignSelf: 'center',
    },
    itemIfo: {
        display: 'flex',
    },
    white: {
        backgroundColor: '#fff',
        '&:hover': {
            background: '#D2D2D2 ',
         },
    },
    grey: {
        backgroundColor: '#EFEFEF',
        '&:hover': {
            background: '#D2D2D2',
         },
    },
    skipLink: {
        color: '#632222',
        textDecoration: 'underline',
        marginBottom: '10px',
    }
});

class ChaiBottleInformation extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.BOTTLEINFORMATION;
        let emptyDataState = {
            lwinCode: null,
            barCode: '',
            typeOfInput: 1,
            wineName: null,
            vintage: null,
            bottleSize: 2,
            country: null,
            producer: null,
            region: null,
            subRegion: null,
            appellation: null,
            wineType: 0,
            wineColor: 1,
            classification: null,
            varietalOrBlend: null,
            bottledByAt: 1,
            bottledByAtDescription: null,
            countryDescription: null,
            classificationDescription: null,
            wineTemplateModal: false,
            newWineName: '',
            newWineProducer: '',
            newWineCountry: 0,
            newWineCountryDesc: '',
            newWineRegion: '',
            newWineSubregion: '',
            newWineColor: 0,
            newWineType: 0,
            newWineDesignation: '',
            newWineClassification: 0,
            newWineAppellation: '',
            newWineBottleSize: 0,
            newWineVarietal: '',
        };

        super({ ...props, formName, emptyDataState });
        this.state = {
            ...this.state,
            milocalvar: 'Hola',
            query: '',
            results: [],
            loadTemplate: true,
            templates: [],
            expanded: -1,
        };
    }

    openModal = async (templates) => {
        if (templates.length > 0) {
            this.setState({ wineTemplateModal: true, templates })
        }
    }

    onClose = () => {
        this.setState({ wineTemplateModal: false })
    }

    getElement = async (ledger, version) => {
        await this.getOptionsWithFormName(formNames.BOTTLEINFORMATION);
        return getBottle(ledger.ledgerNumber, 0)
            .then(result => {
                return result ? result : this.emptyDataState
            });
    };

    saveElement = async (ledger, version, element) => {
        const response = await saveBottleInfo(ledger.ledgerNumber, version, element);

        return response;
    };

    getSize = (value) => {
        const size = (this.state.options[formNames.BOTTLEINFORMATION]['bottleSize']
            ? this.state.options[formNames.BOTTLEINFORMATION]['bottleSize']
            : []).find((obj) => {
                return obj.id === value
            });
        return size ? size.description : "Not Defined"
    };

    afterSaveCallback = () => { };

    handleExpansionPanelChange = index => {
        const { expanded } = this.state
        const newExpanded = expanded !== index ? index : -1

        this.setState({ expanded: newExpanded });
    };

    selectTemplate = async index => {
        const { selectTemplate } = this.props;
        if (selectTemplate) {
            const selectedTemplate = this.state.templates[index];
            localStorage.setItem('template', JSON.stringify(selectedTemplate));
            localStorage.modifiedTemplateFields = "{}";
            selectTemplate(selectedTemplate);
        }
        this.onClose();
    }

    renderWineTemplateModal = () => {
        const { wineTemplateModal, expanded, templates } = this.state;
        const { classes } = this.props;

        return (
            <Dialog
                open={wineTemplateModal}
                onClose={() => { }}
                aria-labelledby="select-wine-template-dialog-titles"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth='lg'
            >
                <DialogTitle id="template-dialog-title">
                    SELECT TEMPLATE
                    <Typography paragraph style={{ wordBreak: 'break-word' }}>
                        LATER YOU CAN UPDATE OR CREATE A NEW TEMPLATE FROM THIS ONE
                    </Typography>
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden' }}>
                    {templates.map((template, index) => {
                        const date = formatDate(template.updatedOn)
                        const bottleSize = this.getSize(this.getField('bottleSize').value)
                        const backgroundColor = index % 2 === 0 ? classes.grey : classes.white;

                        return (
                            <ExpansionPanel
                                square
                                expanded={expanded === index}
                                key={`panel-${index}`}
                                className={backgroundColor}
                            >
                                <ExpansionPanelSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                    onClick={this.handleExpansionPanelChange.bind(this, index)}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                    >
                                        {
                                            expanded === index ?
                                                <IndeterminateCheckBoxIcon
                                                    color="primary"
                                                />
                                                :
                                                <AddBoxIcon
                                                    color="primary"
                                                />
                                        }

                                        <div>
                                            <Typography
                                                variant="subheading"
                                                className={classes.paragraph}
                                            >
                                                {template.name}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                className={classes.paragraph}
                                            >
                                                Last Updated: {date}
                                            </Typography>
                                        </div>
                                        <div className={classes.itemIfo}>
                                            <Typography
                                                variant="subheading"
                                                className={classes.paragraph}
                                            >
                                                {this.getField('vintage').value}
                                            </Typography>
                                            <Typography
                                                variant="subheading"
                                                className={classes.paragraph}
                                            >
                                                {this.getField('producer').value}
                                            </Typography>
                                            <Typography
                                                variant="subheading"
                                                className={classes.paragraph}
                                            >
                                                {this.getField('wineName').value}
                                            </Typography>
                                            <Typography
                                                variant="subheading"
                                                className={classes.paragraph}
                                            >
                                                {bottleSize}
                                            </Typography>
                                        </div>
                                        <div>
                                            <a
                                                onClick={this.handleExpansionPanelChange.bind(this, index)}
                                                className={classes.link}
                                                href={() => false}
                                            >View details</a>
                                            <ChaiHeaderButton
                                                style={{ marginRight: '5px' }}
                                                label="Select"
                                                onClick={this.selectTemplate.bind(this, index)}
                                            />
                                        </div>
                                    </Grid>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography>
                                        {template.description}
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    })
                    }
                </DialogContent>
                <DialogActions style={{ marginRight: 25 }}>
                    <a
                        onClick={this.onClose}
                        className={classes.skipLink}
                        href={() => false}
                    >Skip</a>
                </DialogActions>
            </Dialog>
        )
    }
    onBlur = async (e) => {
        const data = {
            producer: this.state.data.producer,
            wineName: this.state.data.wineName,
            bottleSize: this.state.data.bottleSize,
            vintage: this.state.data.vintage
        }
        const result = await getTemplateByBottleInfo(data)
        this.openModal(result)
    }

    render() {
        const { formName } = this.state;
        const footerData = {
            message: 'Please be sure to fill out the entire form before moving on.',
            buttonLabel: 'Save & Next',
            loading: this.state.loading
        };
        const { hideFooter } = this.props;

        return (
            <Fragment>
                {this.state.loading && <LinearProgress />}
                {this.renderWineTemplateModal()}
                <ChaiBodyContainer>
                    {
                        !hideFooter &&
                        <ChaiRowBodyContainer>
                            <ChaiTextField
                                {...this.getField('barCode')}
                                label="INVENTORY NUMBER, BARCODE OR TCM INSPECTION CODE"
                                style={{ width: '50%' }}
                            />
                            <div style={{ width: '50%' }}></div>
                        </ChaiRowBodyContainer>
                    }
                    <ChaiRowBodyContainer>
                        <ChaiDropDown
                            {...this.getField('typeOfInput')}
                            label="TYPE OF INPUT*"
                            style={{ width: '20%' }}
                            items={this.state.options[formName]['typeOfInput'] || []}
                            {...this.getTemplateFieldStyle('typeOfInput', { width: "20%" })}
                        />
                        <ChaiTextField
                            {...this.getField('wineName')}
                            label="WINE NAME*"
                            style={{ width: '40%' }}
                            certificate={true}
                            onBlur={this.onBlur}
                        />
                        <ChaiDropDown
                            {...this.getField('vintage', false, this.onBlur)}
                            label="VINTAGE*"
                            certificate={true}
                            style={{ width: '20%' }}
                            items={this.state.options[formName]['vintage'] || []}
                        />
                        <ChaiDropDown
                            {...this.getField('bottleSize', false, this.onBlur)}
                            label="BOTTLE SIZE*" style={{ width: '20%' }}
                            certificate={true}
                            items={this.state.options[formName]['bottleSize'] || []}
                        />
                    </ChaiRowBodyContainer>
                    <ChaiRowBodyContainer>
                        <ChaiDropDown
                            {...this.getField('country')}
                            label="COUNTRY*"
                            {...this.getTemplateFieldStyle('country', { width: "20%" })}
                            certificate={true}
                            items={this.state.options[formName]['country'] || []}
                        />
                        {
                            this.state.data.country === 18 ?
                                <ChaiTextField
                                    {...this.getField('countryDescription')}
                                    label="DESCRIPTION*"
                                    {...this.getTemplateFieldStyle('countryDescription', { width: "20%" })}
                                /> : <div style={{ width: '20%' }}></div>
                        }
                        <ChaiTextField
                            {...this.getField('producer')}
                            certificate={true}
                            label="PRODUCER*" style={{ width: '20%' }}
                            onBlur={this.onBlur}
                        />
                        <ChaiTextField
                            {...this.getField('region')}
                            certificate={true}
                            label="REGION*"
                            {...this.getTemplateFieldStyle('region', { width: "20%" })}

                        />
                        <ChaiTextField
                            {...this.getField('subRegion')}
                            label="SUB REGION"
                            {...this.getTemplateFieldStyle('subRegion', { width: "20%" })}
                        />
                    </ChaiRowBodyContainer>
                    <ChaiRowBodyContainer>

                        <ChaiTextField
                            {...this.getField('appellation')}
                            certificate={true}
                            label="APPELLATION*"
                            {...this.getTemplateFieldStyle('appellation', { width: "20%" })}
                        />
                        <ChaiDropDown
                            {...this.getField('wineType')}
                            label="WINE TYPE*"
                            style={{ width: '20%' }}
                            items={this.state.options[formName]['wineType'] || []}
                            {...this.getTemplateFieldStyle('wineType', { width: "20%" })}
                        />
                        <ChaiDropDown
                            {...this.getField('wineColor')}
                            label="WINE COLOUR*"
                            style={{ width: '20%' }}
                            items={this.state.options[formName]['wineColor'] || []}
                            {...this.getTemplateFieldStyle('wineColor', { width: "20%" })}
                        />
                        <ChaiDropDown
                            {...this.getField('classification')}
                            label="CLASSIFICATION"
                            certificate={true}
                            style={{ width: '20%' }}
                            items={this.state.options[formName]['classification'] || []}
                            {...this.getTemplateFieldStyle('classification', { width: "20%" })}
                        />
                        {
                            this.state.data.classification === 12 ?
                                <ChaiTextField
                                    {...this.getField('classificationDescription')}
                                    label="DESCRIPTION"
                                    {...this.getTemplateFieldStyle('classificationDescription', { width: "20%" })}
                                /> : <div style={{ width: '20%' }}></div>
                        }

                    </ChaiRowBodyContainer>
                    <ChaiRowBodyContainer>
                        <ChaiTextField
                            {...this.getField('varietalOrBlend')}
                            label="VARIETAL/BLEND*"
                            {...this.getTemplateFieldStyle('varietalOrBlend', { width: "30%" })}
                        />

                        <ChaiDropDown
                            {...this.getField('bottledByAt')}
                            label="BOTTLED BY/AT*"
                            style={{ width: '30%' }}
                            items={this.state.options[formName]['bottledByAt'] || []}
                            {...this.getTemplateFieldStyle('bottledByAt', { width: "30%" })}
                        />
                        {
                            this.state.data.bottledByAt === 2 ?
                                <ChaiTextField
                                    {...this.getField('bottledByAtDescription')}
                                    label="DESCRIPTION*"
                                    style={{ width: '30%' }}
                                    {...this.getTemplateFieldStyle('bottledByAtDescription', { width: "30%" })}
                                /> : <div style={{ width: '30%' }}></div>
                        }
                    </ChaiRowBodyContainer>
                </ChaiBodyContainer>
                {!hideFooter && <ChaiFooterContainer {...footerData} onClick={this.save} />}
            </Fragment>
        );
    }
}

ChaiBottleInformation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiBottleInformation);
