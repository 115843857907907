const BASE_URL = process.env.REACT_APP_CHAI_SECURITY_API_HOST || 'gateway/';

export const getTemplate = async (ledgerId, ledgerVersion) => {
    const url = `${BASE_URL}ledgers/${ledgerId}/versions/${ledgerVersion}/getTemplate`;
    const options = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }, method: 'GET'
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }
        return prepareTemplateToUI(result);
    } catch (error) {
        console.error('ERROR getTemplate()', error);
        throw error.error ? error.error : error;
    }
};
function prepareTemplateToUI(rawData) {
    let data = {
        ...rawData,

        waterMarkLocation: rawData.waterMarkLocationRef ? rawData.waterMarkLocationRef.location : "",
        waterMarkDescription: rawData.waterMarkLocationRef ? rawData.waterMarkLocationRef.description : "",

        stampsMarkingsLocation: rawData.stampMarkingsLocationRef ? rawData.stampMarkingsLocationRef.location : "",
        stampsMarkingsDescription: rawData.stampMarkingsLocationRef ? rawData.stampMarkingsLocationRef.description : "",

        labelEmbossingLocation: rawData.labelEmbossingLocationRef ? rawData.labelEmbossingLocationRef.location : "",
        labelEmbossingDescription: rawData.labelEmbossingLocationRef ? rawData.labelEmbossingLocationRef.description : "",

        otherLabelLocation: rawData.otherLabelLocationRef ? rawData.otherLabelLocationRef.location : "",
        otherLabelDescription: rawData.otherLabelLocationRef ? rawData.otherLabelLocationRef.description : "",
        otherNeckLabelDescription : rawData.otherNeckDescription || ""
    };

    if(!data.labelPrintMethod){
        data.labelPrintMethod=[];
    }

    return data;
}
export const getAllLedgers = async () => {
    const url = `${BASE_URL}ledgers`;
    const options = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }, method: 'GET'
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }
        return result;
    } catch (error) {
        console.error('ERROR getAllLedgers()', error);
        throw error.error ? error.error : error;
    }
};

export const getLedgersByUser = async (query) => {
    const user = JSON.parse(localStorage.getItem('user'));
    let url = `${BASE_URL}users/${user.id}/ledgers?${query}`;

    const options = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }, method: 'GET'
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }

        return result;
    } catch (error) {
        console.error('ERROR getLedgersByUser()', error);
        throw error.error ? error.error : error;
    }
};

export const newLedger = async (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const ownerAddress = user ? user.address : '--';
    const url = `${BASE_URL}user/${ownerAddress}/ledger`;
    const options = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(data)
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();
        result.lastEdited = new Date().toLocaleDateString();
        result.authenticator = user.name;
        result.creator = { userId: user.id, name: user.name };
        result.owner = { userId: "", name: "" };
        result.allowedToEdit = { userId: "", name: "" };
        result.allowedToSell = { userId: "", name: "" };
        result.allowedToView = [];

        if (response.status !== 200) {
            throw result;
        }
        return result;
    } catch (error) {
        console.error('ERROR newLedger()', error);
        throw error.error ? error.error : error;
    }
};

export const getLedgersForCsvExport = async () => {
    const url = `${BASE_URL}ledgers/getLedgersForCsvExport`;
    const options = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }, method: 'GET'
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }
        return result;
    } catch (error) {
        console.error('ERROR getLedgersForCsvExport()', error);
        throw error.error ? error.error : error;
    }
};