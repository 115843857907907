import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {CircularProgress} from '@material-ui/core';

const styles = theme => ({
    root: { marginLeft: 'auto', marginRight: 'auto', padding: 10 },
    progress: { color: '#632222' }
});

const ChaiProgress = (props) => {
    const { classes } = props;

    return (
        <div className={classes.root}>
            <CircularProgress className={classes.progress} />
        </div>
    );
};

ChaiProgress.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChaiProgress);