import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import UploadIcon from '@material-ui/icons/FileUpload';
import './index.css';

const isTablet = window.innerWidth <= 1024;
const styles = theme => ({
    container: {
        display: 'grid',
        position: 'relative'
    },
    containerUploaded: {
        display: 'grid',
        position: 'relative',
        marginLeft: '0.8em',
        marginRight: '0.1em'
    },
    externalContainer: {
        display: 'contents',
    },
    label: {
        fontSize: isTablet ? '0.65em' : '0.85em',
        fontFamily: "'Source Sans Pro', sans-serif",
        fontWeight: 600,
        color: '#3f3e3e',
        textTransform: 'uppercase'
    },
    baseImageContainer: {
        zIndex: 10,
        backgroundColor: 'white',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "auto"
    },
    imageContainer: {
        overflow: 'hidden'
    },
    noImageContainer: {
        textAlign: 'center',
        padding: 10,
        fontFamily: "'Source Sans Pro', sans-serif",
        fontSize: '1em',
        color: '#3e3e3e',
        right: 0
    },
    image: {
        minWidth: "100%",
        width: "100%",
    },
    iconButton: {
        marginTop: "20px"
    },
    icon: {
        fontSize: "40px",
        padding: "3px",
    }
});

const ChaiFileInput = (props) => {
    const { classes, label, field, style, onChange, fileName, disabled, acceptedExtensions } = props;
    const backgroundColor = (disabled ? { backgroundColor: "grey" } : {});

    return (
        <div className={classes.externalContainer} style={style}>
            <div className={classes.container}
            >
                {label ? <label className={classes.label}>{label}</label> : null}
                <input
                    type="file"
                    name="file"
                    id={"label" + field}
                    class="inputfile"
                    disabled={disabled}
                    accept={acceptedExtensions}
                    onChange={(e) => onChange(e, field)}
                />
                <label for={"label" + field} style={
                    {
                        ...backgroundColor,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: isTablet ? 'center' : 'space-evenly',
                        width: 'auto',
                        fontSize: '1em',
                        padding: 15,
                        margin: label ? '3px 0px 4px 0' : '16px 0px 0px 0px'
                    }
                }>
                    <UploadIcon style={{ fontSize: isTablet ? '1.5em' : '1.2em' }} />
                    <div >{isTablet ? '' : fileName ? fileName : 'Choose a file'}</div>
                </label>

            </div>
        </div>
    );
};

ChaiFileInput.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired
};

export default withStyles(styles)(ChaiFileInput);