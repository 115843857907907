import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import ChaiDropDown from '../ChaiDropDown';
import ChaiBaseForm from '../ChaiBaseForm';
import ChaiSwitch from '../ChaiSwitch';
import LinearProgress from '@material-ui/core/LinearProgress';
import ChaiImageInput from '../ChaiImageInput';
import {getAdministrative, saveAdministrative} from './service';
import ChaiTextField from "../ChaiTextField";
import ChaiRadioButton from "../ChaiRadioButton";
import chaiErrorHandler from '../../Handler/chaiErrorHandler';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@material-ui/core';
import formNames from '../ChaiBaseForm/formNames';

const styles = theme => ({
    field: {
        display: 'flex',
        height: '66px',
        position: 'relative'
    },
    fieldBody: {
        alignItems: 'center',
        display: 'inline-flex',
        margin: '0 auto'
    },
    radio: {fontFamily: '"Source Sans Code", sans-serif', fontSize: '0.95em', color: '#3f3e3e'},
    radioContainer: {width: '32%', display: 'flex', padding: '20px', alignItems: 'center'}
});

class ChaiAdministrative extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.ADMINISTRATIVE;
        const user = JSON.parse(localStorage.getItem('user'));
        const brands = user.brands;
        let emptyDataState = {
            lwinCode: null,
            qrCode: null,
            rfidCode: null,
            availableForSale: false,
            updateConditionReport: false,
            updateOwnership: false,
            encryptOwnerName: false,
            encryptOwnerInformation: false,
            makeLedgerPrivate: false,
            capsuleBatch:'',
            sellerLogo: '',
            sellerLink: '',
            sellerName: '', 
            showLogo: false
        };

        super({...props, formName, emptyDataState});
        const selectBrands = this.formatBrands(brands);
        this.state = {...this.state, dialogOpen: false, dialogMessage: null, pairedRfid: null, brands:brands, selectBrands, activeBrand: null};
    }

    formatBrands = (brands) => {
        return brands.map((brand,index)=>{
            return {id:index, description:brand.name}
        })

    }

    getElement = async (ledger, version) => {
        return getAdministrative(ledger.ledgerNumber, version)
            .then(result => {
                const administrative = (result ? result : this.emptyDataState)
                return {...administrative}
            });
    };

    saveElement = async (ledger, version, element) => {
        return saveAdministrative(ledger.ledgerNumber, version, element);
    };

    handleForSale = event => {
        let data = {...this.state.data};
        data.availableForSale = event.target.value === 'public';
        this.updateParentForm(data);
        this.setState({data});
        console.log('state and event', this.state, event.target.value)
    };

    saveOrHandleValidation = async () => {
        if (!this.validateQrCode()) {
            this.props.showNotification('Invalid QR Code Hash or url.');
            return;
        }
        if (!this.validateSellerLink()) {
            this.props.showNotification('Please enter a valid SELLER LINK url including prefix, e.g.: https://');
            return;
        }

        let data = this.state.data;
        data.qrCode = data.qrCode.indexOf('/')>-1 ? data.qrCode.substr(data.qrCode.lastIndexOf('/')+1) : data.qrCode;
        this.setState({data});
        

        this.setLoadingState(true);
        console.log("SAVING DATA");
        try {
            console.log("SENDING");
            await this.saveElement(this.props.currentLedger, 0, this.state.data);
            console.log("COMPLETED RESPONSE");
            this.setLoadingState(false);
            this.props.handleNextStep();
        } catch (e) {
            console.log("CAUGHT ERROR IN BASE FORM: ", e);
            this.setLoadingState(false);

            if (!this.handleRFIDError(e)) {
                //We provide a notifier for the error handler so it displays the error once its handled
                chaiErrorHandler(this.notify).catch(e);
            }
        }
    };

    handleRFIDError = err => {
        let errorDetail= (err.content.additionalData || {pairedRfid: null});
        if (errorDetail.pairedRfid ) {
            this.setState({
                pairedRfid: errorDetail.pairedRfid,
                dialogMessage: `${err.content.message}: ${err.content.content[0]}, do you want to replace it?`,
                dialogOpen: true
            });
            return true;
        }
        return false;
    };

    handleDialogCancel() {
        this.setState({dialogOpen: false, dialogMessage: null, pairedRfid: null});
        this.setLoadingState(false);
    }

    handleDialogOk() {
        this.setState({dialogOpen: false, dialogMessage: null, pairedRfid: null});
        this.state.data.overrideRFIDCode = true;
        this.saveOrHandleValidation();
    }

    handleRfidChange = async (e) => {
        if (e.key === 'e') {
            e.preventDefault();
        } else {
            let data = this.state.data;
            data.rfidCode = e.target.value;
            await this.setState({ data });
            this.updateParentForm(data);
        }
    }

    validateQrCode = () => {
        const { qrCode } = this.state.data;
        const pattern = /^([a-z0-9]+){4,}/;

        return pattern.test(qrCode.indexOf('/')>-1 ? qrCode.substr(qrCode.lastIndexOf('/')+1) : qrCode);
    };

    validateSellerLink = () => {
        const { showLogo, sellerLink } = this.state.data;
        return showLogo || (
            sellerLink !== null &&
            (sellerLink.indexOf('http://') > -1 || sellerLink.indexOf('https://') > -1)
        );
    };

    setActiveBrand = (ev) => {
        const selectedBrand = this.state.brands[ev.target.value];
        this.setState({ data: {
            activeBrand: ev.target.value,
            sellerLink: selectedBrand.link,
            sellerLogo: selectedBrand.image,
            sellerName: selectedBrand.name
        } });
    }

    render() {
        const { classes, hideFooter } = this.props;

        return (
            <Fragment>
                {this.state.loading && <LinearProgress/>}
                {this.state.dialogOpen && <Dialog
                    maxWidth="xs"
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.dialogOpen}
                >
                    <DialogTitle id="confirmation-dialog-title">Ledger already paired</DialogTitle>
                    <DialogContent dividers>
                        <p>{this.state.dialogMessage}</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDialogCancel()} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => this.handleDialogOk()} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>}
                <div className={classNames(classes.line, classes.height1t)}>
                    <ChaiTextField
                        {...this.getField('qrCode')}
                        placeholder="Enter full url or hash..."
                    />
                </div>

                <div className={classNames(classes.line, classes.height1t)}>
                    <ChaiTextField
                        {...this.getField('rfidCode')}
                        onChange={e => this.handleRfidChange(e)}
                        placeholder="RFID Code"
                    />
                </div>

                <div className={classNames(classes.line, classes.height2t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiRadioButton
                                label="Public"
                                checked={this.state.data.availableForSale}
                                onChange={this.handleForSale}
                                value="public"
                                name="availableForSale"
                                ariaLabel="public"
                                disabled={this.state.loading || !this.calculateWritePermissionForms('availableForSale')}
                            />
                        </div>
                    </div>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiRadioButton
                                label="Private"
                                checked={!this.state.data.availableForSale}
                                onChange={this.handleForSale}
                                value="private"
                                name="availableForSale"
                                ariaLabel="private"
                                disabled={this.state.loading || !this.calculateWritePermissionForms('availableForSale')}
                            />
                        </div>
                    </div>
                </div>

                <div className={classNames(classes.line, classes.height2t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch {...this.getField('encryptOwnerName', true)} />
                        </div>
                    </div>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch {...this.getField('encryptOwnerInformation', true)} />
                        </div>
                    </div>
                </div>

                <div className={classNames(classes.line, classes.height1t)}>
                    <ChaiTextField
                        {...this.getField('capsuleBatch')}
                        placeholder="Capsule Batch"
                    />
                </div>

                <div className={classes.height5t}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch
                                label={
                                    hideFooter
                                        ? ''
                                        : 'This user does not want to display a logo'
                                }
                                {...this.getField('showLogo', true)}
                            />
                        </div>
                    </div>
                    <ChaiTextField
                        placeholder="Seller's Name"
                        {...this.getField('sellerName')}
                        disabled={this.state.data.showLogo}
                    />
                    <ChaiTextField
                        placeholder="Seller's Link"
                        {...this.getField('sellerLink')}
                        disabled={this.state.data.showLogo}
                    />
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiImageInput
                                {...this.props}
                                {...this.getFileField('sellerLogo')}
                                disabled={this.state.data.showLogo}
                            />
                        </div>
                    </div>
                    <ChaiDropDown
                        disabled={this.state.data.showLogo}
                        onChange={this.setActiveBrand}
                        value={this.state.data.activeBrand}
                        items={this.state.selectBrands}
                    />
                </div>
            </Fragment>
        );
    }
}

ChaiAdministrative.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiAdministrative);
