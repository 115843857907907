import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChaiTextField from '../ChaiTextField';
import { Card, CardContent } from '@material-ui/core';
import './index.css';

const styles = theme => ({
    container: {
        backgroundColor: 'white',
        boxShadow: '0 0 10px #b9b9b9',
        marginLeft: '5px',
        maxHeight: '400px',
        overflowY: 'auto',
        position: 'absolute',
        zIndex: 10,
    },
    item: {
        backgroundColor: 'white',
        fontFamily: '"Source Sans Code", sans-serif',
        fontSize: 18,
        color: '#3f3e3e',
        '&:hover': {
            backgroundColor: 'rgb(222, 224, 223)',
            cursor: 'pointer',
        },
        borderRadius: 0,
    },
    value: {
        color: '#3f3e3e',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: 16,
        fontWeight: 700,
    },
    title: {
        color: '#b9b9b9',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: 14,
        textTransform: 'uppercase',
    }
});

const ChaiAutoComplete = (props) => {
    const { classes, style, label, data, value, fields, disabled, placeholder, adornment } = props;
    const { onChange, onSelect } = props;

    return (
        <div style={style}>
            <ChaiTextField
                label={label}
                style={{ width: '100%' }}
                value={value}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder || 'Type any wine characteristics...'}
                adornment={adornment}
            />
            {
                data && data.length ? <div className={classes.container}>
                    {data && data.length && data.map((item, i) => {
                        var items = fields && fields.length ? fields
                            .filter(f =>
                                item[f] != null &&
                                item[f].trim() !== '' &&
                                item[f].split(',')
                                    .filter(x => !['', 'N/A'].includes(x.trim().toUpperCase()))
                                    .length > 0) : null;
                        return (
                            <Card
                                className={classes.item}
                                key={i}
                                onClick={() => onSelect(item)}
                            >
                                <CardContent>
                                    {
                                        items.map((f, j) => (
                                            <Fragment key={j}>
                                                <div className={classes.value}>
                                                    {item[f]}
                                                </div>
                                                <div className={classes.title}>
                                                    {f.toUpperCase()}
                                                </div>
                                            </Fragment>)
                                        )
                                    }
                                </CardContent>
                            </Card>
                        )
                    })}
                </div>
                    : null}
        </div>
    );
};

ChaiAutoComplete.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiAutoComplete);
