import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const styles = theme => ({
    root: {
        padding: 10
    },
    size: {
        width: 30,
        height: 30,
        color: '#6D0006',
        '&$checked': {
            color: '#6D0006',
        }
    },
    sizeIcon: {
        fontSize: 30,
        color: '#6D0006',
        padding: 2
    },
    sizeIconDisabled: {
        fontSize: 40,
        color: 'gray',
        padding: 2
    },
    checked: {},
    label: { fontSize: '1em', fontFamily: '"Source Sans Pro", sans-serif', fontWeight: 400, color: '#3f3e3e' }
});

const ChaiCheck = (props) => {
    const { value, label, style, disable, checked, iconColor, className } = props;
    const { onChange } = props;
    const classes = iconColor !== null ? { ...props.classes, sizeIcon: props.iconColor } : props.classes;

    return (
        <FormControlLabel
            control={
                <Checkbox
                    disabled={disable}
                    style={style}
                    className={className || classes.size}
                    onChange={onChange}
                    icon={<CheckBoxOutlineBlankIcon className={disable ? classes.sizeIconDisabled: classes.sizeIcon} />}
                    checkedIcon={<CheckBoxIcon className={disable ? classes.sizeIconDisabled : classes.sizeIcon} />}
                    value={value}
                    classes={{
                        root: classes.root
                    }}
                    checked={checked}
                />
            }
            label={label}
            classes={{
                label: classes.label
            }}
        />
    );
}

ChaiCheck.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiCheck);
