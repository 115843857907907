import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import chaiErrorHandler from '../../Handler/chaiErrorHandler';
import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiTextField from '../ChaiTextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { verifyPasswordAndSave } from './service'

const isTablet = window.innerWidth <= 1024;

const styles = theme => ({
    title: { fontSize: isTablet ? '0.8em' : '1em', fontWeight: 600 }
});

class ChaiPermissionsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            password: '',
            actualKey: '',
            errorModal:false,
            errors:[]
        };
    }

    openErrorModal = (errors) => {
        this.setState({ errorModal: true, errors: errors });
    };
 
    closeErrorModal = () => {
        this.setState({ errorModal: false });
    };
    
    formatName = (name) => {
        return name.replace(name.substr(name.lastIndexOf(' ')+1, name.length), ' *******');
    }

    messageToPreviousUsers = () => {
        const { add, previousUsers, usersToAdd } = this.props;
        if (previousUsers && add ) {
            let newUsersCanEdit = []
            let newUsersCanSell = []
            let oldUsersCanEdit = []
            let oldUsersCanSell = []

            if (this.props.usersToAdd) {
                newUsersCanEdit = usersToAdd.filter((us) => us.canEdit).map((us) => (us.id.length) ? `${this.formatName(us.name)}, ID: ${us.id}` : '');
                newUsersCanSell = usersToAdd.filter((us) => us.canSell).map((us) => (us.id.length) ? `${this.formatName(us.name)}, ID: ${us.id}` : '');
                oldUsersCanEdit = previousUsers.filter((us) => us.canEdit).map((us) => (us.id.length) ? `${this.formatName(us.name)}, ID: ${us.id}` : '');
                oldUsersCanSell = previousUsers.filter((us) => us.canSell).map((us )=> (us.id.length) ? `${this.formatName(us.name)}, ID: ${us.id}` : '');
            }

            let editMessage = (newUsersCanEdit.join('').length > 0 && oldUsersCanEdit.join('').length > 0) ? 'You are about to remove Edit permission from:' : 'You are about to grant Edit permissions';
            let sellMessage = (newUsersCanSell.join('').length > 0 && oldUsersCanSell.join('').length > 0) ?  'You are about to remove Sell permission from:' : 'You are about to grant Sell permissions';
            let editMessagePartTwo = (oldUsersCanEdit.join('').length > 0) ? 'and grant them to' : 'to';
            let sellMessagePartTwo = (oldUsersCanSell.join('').length > 0) ? 'and grant them to' : 'to';

            if (newUsersCanEdit.length > 0) {
                editMessage = oldUsersCanEdit.length > 0 ? `${editMessage} ${oldUsersCanEdit.join(",")} ${editMessagePartTwo} ${newUsersCanEdit.join(",")}` : ""
            }

            if (newUsersCanSell.length > 0) {
                sellMessage = oldUsersCanSell.length > 0 ? `${sellMessage} ${oldUsersCanSell.join(",")} ${sellMessagePartTwo} ${newUsersCanSell.join(",")}` : ""
            }
            
            return (
                <div>
                    <p>{newUsersCanEdit.length > 0 && editMessage}</p>
                    <p>{newUsersCanSell.length > 0 && sellMessage}</p>
                </div>
            );
        }
    };

    handlePasswordConfirmation = async () => {
        const ledgers = this.props.ledgers.map((ledger) => { return {ledgerNumber:ledger.ledgerNumber,ledgerVersion:0}});
        if (this.state.password.length > 0) {

            try{
                const { usersToAdd, previousUsers, add } = this.props;
                let newUsers = [];
                if (add) {
                    const newUserCanEdit = usersToAdd.find((us) => us.canEdit);
                    const newUserCanSell = usersToAdd.find((us) => us.canSell);
                    
                    previousUsers.forEach((item, index, arr) => {
                        if(!usersToAdd.find(us => us.id === item.id )){
                            if(item.canEdit && newUserCanEdit){
                                item.canEdit = false;
                            }
                            if(item.canSell && newUserCanSell){
                                item.canSell = false;
                            }
                            newUsers.push(item)
                        }  
                    });
                    newUsers = newUsers.concat(usersToAdd);
                }
                const allUsers = add ? newUsers  : previousUsers;
                const result = await verifyPasswordAndSave(
                    ledgers,
                    JSON.stringify(allUsers),
                    this.state.password,
                    this.props.permissionsOverride
                  );
                if (result && !result.isError) {
                    this.props.onAfterSaving();
                    this.props.closeModal();
                    this.props.showNotification("Permissions confirmed and saved");
                    if(result.errorLog.length > 0){
                        this.openErrorModal(result.errorLog);
                    } else {
                        if (add) {
                            this.props.onAddUsers(newUsers);
                        } else {
                            this.props.onEditUsers();
                        }
                    }
                } else if (result.isError) {
                    this.props.showNotification(result.content ? result.content.content[0] : "An error ocurred while saving permissions")
                }
            }
            catch (error) {
                console.error('ERROR handlePasswordConfirmation', error)
                chaiErrorHandler(this.notify).catch(error);
            }
        }
    };

    handlePasswordChange = (e) => {
        this.setState({ password: e.target.value });
        if (e.key === 'Enter') {
            this.handlePasswordConfirmation();
        }
    };

    renderErrorModal() {
        const {errorModal, errors} = this.state;
        return(
        <Dialog
                open={errorModal}
                onClose={this.closeErrorModal}
                aria-labelledby="form-dialog-title"
                fullWidth
            >
                <DialogContent>
                    <h3>Ledgers With Errors: </h3>
                    <ul>
                        { errors.map( (err)=> {
                            return (<li>{err}</li>)
                        }
                        )}
                    </ul>
                </DialogContent>
        </Dialog>)
    }

    render() {
         const { showModal, closeModal } = this.props;
 
         return (
            <Dialog
                open={showModal}
                onClose={closeModal}
                aria-labelledby="form-dialog-title"
                fullWidth
            >
                <DialogContent>
                    <p>
                        {this.props.usersToAdd && this.props.usersToAdd.length ? 'You are about to grant permissions to:' : 'You are about to modify permissions:'}
                    </p>
                    <ul>
                    {this.props.usersToAdd && this.props.usersToAdd.map((user)=>(
                            <li>{user.name}</li>
                        ))}
                    </ul>
                    {this.messageToPreviousUsers()}
                    {this.props.usersToAdd &&
                        this.props.usersToAdd.filter(user => user.canSell === true).length > 0 && (
                        <p>
                            WARNING: At least one of the users has been granted permissions
                            to change this ledger's owner.
                        </p>
                        )
                    }
                    <ChaiTextField
                        value={this.state.password}
                        onChange={(e) => this.handlePasswordChange(e)}
                        type={'password'}
                        label={'Re-Enter Password to confirm'}
                        style={{ width: '100%' }}
                    />
                </DialogContent>
                {this.renderErrorModal()}
                <DialogActions style={{ marginRight: 25 }}>
                    <ChaiHeaderButton label="Cancel" style={{marginRight: '10px'}} onClick={closeModal} />
                    <ChaiHeaderButton label="Confirm" onClick={this.handlePasswordConfirmation} />
                </DialogActions>
            </Dialog>
         )
    }
}

ChaiPermissionsModal.propTypes = {
    openModal: PropTypes.func.isRequired,
    permissionsOverride: PropTypes.bool.isRequired
};

export default withStyles(styles)(ChaiPermissionsModal);
