import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChaishareUsersTable from './ChaiShareUsersTable';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiPermissionsModal from './ChaiPermissionsModal';
import ChaiAutoComplete from '../ChaiAutoComplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'debounce';
import { searchUsers } from '../ChaiProvenance/service';

const isTablet = window.innerWidth <= 1024;

const styles = theme => ({
    title: { fontSize: isTablet ? '0.8em' : '1em', fontWeight: 600 },
    searchIcon: {
        top: 'calc(50% - 12px)',
        right: '12px',
        color: 'rgba(0, 0, 0, 0.54)',
        position: 'absolute',
        pointerEvents: 'none'
    }
});

class ChaiAddUsersWidget extends Component {

    constructor(props) {
    
        super(props);
        this.state = {
            usersToAdd:[],
            userId: '',
            query: '',
            results: [],
            modal: false
        };
    }

    addUser = async (item) => {
        const { usersToAdd } = this.state;
        const { currentLedger, ledgers } = this.props;

        const userSession = JSON.parse(localStorage.getItem('user'));
        if(userSession.id.indexOf(item.id)!==-1){
            this.props.showNotification("You can't add yourself");
            return;
        }

        const users = [...this.props.previousUsers, ...usersToAdd];
        let userExistsToAdd = users.find(user => user.id === item.id) !== undefined;
        if (userExistsToAdd) {
            this.props.showNotification(
                'This user is already in the list of possible candidates or current users.');
            return;
        }
        if (item.rol === 'Collector' && (
            (currentLedger && currentLedger.isAuthentic === false) ||
            (ledgers && ledgers.filter(x => !x.isAuthentic).length)
        )) {
            this.props.showNotification(
                'It\'s not allowed to share a Counterfeit Ledger with a Collector role.');
            return;
        }

        const user = [{
            id: item.id,
            name: item.name,
            handle: item.handle,
            canEdit: false,
            canSell: false
        }];
        this.handleSearchSelect(user);
    };

    searchUsers = async () => {
        const query = this.state.query;
        try {
            if (query) {
                const result = await searchUsers(query);
                const noResults = [{ name: 'No results' }];
                let data = noResults;

                if (result && result.length) {
                    data = result.filter(u => u.name !== 'Unassigned')
                    .map((u) => {return {...u,location :`${u.city || 'N/A'},${u.state || 'N/A'}, ${u.country || 'N/A'}`}});
                    if (data.length === 0) data = noResults;
                }
                this.setState({ results: data });
            }
        } catch (error) {
            this.props.showNotification(error.content ? error.content.message : 'An error has occurred');
        }
    };

    searchDelayed = debounce(this.searchUsers, 500);

    handleSearchChange = (e) => {
        let update = {
            query: e.target.value
        };
        if (!e.target.value) {
            update.results = [];
        }
        this.setState(update);
        this.searchDelayed();
    };

    handleResultSelect = async (item) => {
        this.addUser(item);
        this.setState({ results: [], query: '' });
    };

    handleSearchSelect = (newUsers) => {
        let usersToAdd  =  this.state.usersToAdd;
        usersToAdd = usersToAdd.concat(newUsers);
        this.props.newUsers(usersToAdd);
        this.setState({ results: [], query: '', usersToAdd, userId: ''});
    };

    onSelectEdit = (event, id) => {
        let data =  this.state.usersToAdd;
        data = data.map(
            (user) => user.id === id
                ? {...user,canEdit:event.target.checked}
                : {...user,canEdit:false}
        );
        this.props.newUsers(data);
        this.setState({usersToAdd:data}); 
    };

    onSelectSell = (event, id) => {
        let data =  this.state.usersToAdd;
        data = data.map(
            (user) => user.id === id
                ? {...user,canSell:event.target.checked}
                : {...user,canSell:false}
        );
        this.props.newUsers(data);
        this.setState({usersToAdd:data}); 
    };

    onSelectDelete = (id) => {
        let data =  this.state.usersToAdd;

        data = data.filter(user => user.id !== id);
        this.props.newUsers(data);
        this.setState({usersToAdd:data});
    };

    cleanAfterSaving = () => {
        this.setState({usersToAdd: [], userId: ''});
    };

    openModal = () => {
        this.setState({modal: true});
    };

    closeModal = () => {
        this.setState({modal: false});
    };

    render() {
        const { modal, usersToAdd, results, query } = this.state;
        const { classes, previousUsers, ledgers, showNotification } = this.props;
        const searchFields = ['name', 'location', 'handle'];

        return (
            <div> 
                <ChaiPermissionsModal
                    showModal={modal}
                    closeModal={this.closeModal}
                    onAddUsers={this.props.onAddUsers}
                    onAfterSaving={this.cleanAfterSaving}
                    previousUsers={previousUsers}
                    usersToAdd={usersToAdd}
                    ledgers={ledgers}
                    showNotification={showNotification}
                    add={true}
                    permissionsOverride={false}
                >
                </ChaiPermissionsModal>
                <ChaiRowBodyContainer>
                    <ChaiAutoComplete label={"USER LOOKUP"}
                        style={{ width: '80%' }}
                        data={results}
                        fields={searchFields}
                        value={query}
                        placeholder='Type any User name...'
                        onSelect={this.handleResultSelect}
                        onChange={(e) => this.handleSearchChange(e)}
                        adornment={
                            <InputAdornment position="end">
                                <SearchIcon className={classes.searchIcon} />
                            </InputAdornment>
                        }
                    />
                </ChaiRowBodyContainer>
                <ChaiRowBodyContainer>
                    <div className={classes.title}>USERS LISTED: </div>
                </ChaiRowBodyContainer>
                <ChaiRowBodyContainer>
                    <ChaishareUsersTable
                        style={{ width: '100%' }}
                        onConfirm={this.openModal} 
                        data={this.state.usersToAdd}
                        onSelectEdit={this.onSelectEdit}
                        onSelectSell={this.onSelectSell}
                        onSelectDelete={this.onSelectDelete}
                        iconColorEdit={false}
                        iconColorSell={false}
                    >
                    </ChaishareUsersTable>
                </ChaiRowBodyContainer>
            </div>     
        )
    }
}

ChaiAddUsersWidget.propTypes = {
    classes: PropTypes.object.isRequired,
    ledgers: PropTypes.array.isRequired,
    onAddUsers: PropTypes.func.isRequired,
    
};

export default withStyles(styles)(ChaiAddUsersWidget);
