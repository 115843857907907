import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Checkbox } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { systemRoles } from './options';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiProgress from '../ChaiProgress';

const styles = {
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '200px',
        minWidth: '300px'
    },
};

const specialTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#632222',
            fontFamily: '"Source Sans Code", sans-serif',
            color: '#3e3e3e'
        },
    }
});

const ChaiAccountDialog = (props) => {
    const { open, handleClose, handleChange, handleSave, account, saving } = props;
    const userTypeSelected = systemRoles.find(type => type.name === account.rol);

    return (
        <MuiThemeProvider theme={specialTheme}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                style={{ padding: 40 }}
            >
                <DialogTitle id="form-dialog-title">EDIT USER ACCOUNT</DialogTitle>
                <DialogContent>
                    <div style={styles.content}>
                        <TextField
                            autoFocus
                            label="User Name"
                            value={account.user}
                            onChange={(e) => handleChange(account, 'user', e)}
                        />
                        <FormControl styles={{}}>
                            <InputLabel htmlFor="role">Role</InputLabel>
                            <Select
                                value={userTypeSelected.id}
                                onChange={(e) => handleChange(account, 'rol', e)}
                                inputProps={{
                                    name: 'role',
                                    id: 'role',
                                }}
                            >
                                {systemRoles.map((userType, index) => {
                                    return (
                                        <MenuItem value={userType.id}>{userType.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color='primary'
                                    checked={account.active}
                                    onChange={(e) => handleChange(account, 'active', e)}
                                    value={account.active}
                                />
                            }
                            label="Active"
                        />
                    </div>
                </DialogContent>
                {
                    saving ?
                        <ChaiProgress /> :
                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 40, paddingLeft: 40 }}>
                            <ChaiHeaderButton label="Cancel" onClick={handleClose} />
                            <ChaiHeaderButton label="Save" onClick={() => handleSave(account)} />
                        </DialogActions>
                }
            </Dialog>
        </MuiThemeProvider>
    );
};

ChaiAccountDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ChaiAccountDialog);
