import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core';
import { Route } from 'react-router-dom';

import ChaiBaseForm from '../ChaiBaseForm';

import ChaiAdministrative from "../ChaiAdministrative";
import ChaiAntifraud from '../ChaiAntifraud';
import ChaiAuthenticator from '../ChaiAuthenticator';
import ChaiAuthMultiSectionComponent from '../ChaiAuthMultiSectionComponent';
import ChaiBottleData from '../ChaiBottleData';
import ChaiBottleInformation from '../ChaiBottleInformation';
import ChaiClosure from '../ChaiClosure';
import ChaiCounterfeit from '../ChaiCounterfeit';
import ChaiGlass from '../ChaiGlass';
import ChaiHeaderContainer from '../ChaiHeaderContainer';
import ChaiLedger from '../ChaiLedger';
import ChaiMainLabel from '../ChaiMainLabel'
import ChaiMeasurements from '../ChaiMeasurements';
import ChaiMultiBottleEntry from '../ChaiMultiBottleEntry';
import ChaiMultiSectionComponent from '../ChaiBottleMultiSectionComponent';
import ChaiOtherLabel from "../ChaiOtherLabel";
import ChaiProvenance from "../ChaiProvenance";
import ChaiPublicLedger from "../ChaiPublicLedger";
import ChaiSearchScreen from '../ChaiSearchScreen';
import ChaiShareAndSale from "../ChaiShareAndSale";
import ChaiStepper from '../ChaiStepper';
import ChaiUserSettings from "../ChaiUserSettings"

const styles = theme => ({});

class ChaiAuthenticatedRoutes extends ChaiBaseForm {
    constructor(props) {
        super(props);
        this.state = {};
    }

    addCommitToBlockFunction = (func) => {
        const commitToBlockFunction = this.state.commitToBlockFunction || [];
        commitToBlockFunction.push(func);
        this.setState({ commitToBlockFunction });
    };

    addSetTemplateFunction = (newSetTemplateFunction) => {
        const templateFunctions = this.state.templateFunctions || [];
        templateFunctions.push(newSetTemplateFunction);
        this.setState({ templateFunctions });
    };

    render() {
        const { currentLedger } = this.props;

        return (
            <Fragment>
                <Route exact path='/' render={() => (<ChaiLedger {...this.props} />)} />
                <Route exact path='/wineSearch'
                    render={() => (
                        <Fragment>
                            <ChaiHeaderContainer {...currentLedger} />
                            <ChaiSearchScreen
                                {...this.props}
                                handleFormUpdate={this.handleFormUpdate}
                            />
                        </Fragment>
                    )}
                />
                <Route exact path='/bottleInformation'
                    render={() => (
                        <Fragment>
                            <ChaiHeaderContainer {...currentLedger} />
                            <ChaiStepper {...this.props} />
                            <ChaiMultiSectionComponent
                                {...this.props}
                            />
                        </Fragment>
                    )}
                />
                <Route exact path='/provenance'
                    render={() => (
                        <Fragment>
                            <ChaiHeaderContainer {...currentLedger} />
                            <ChaiStepper {...this.props} />
                            <ChaiProvenance {...this.props} />
                        </Fragment>
                    )}
                />
                <Route exact path='/authenticator'
                    render={() => (
                        <Fragment>
                            <ChaiHeaderContainer {...currentLedger} />
                            <ChaiStepper {...this.props} />
                            <ChaiAuthMultiSectionComponent {...this.props} />
                        </Fragment>
                    )}
                />
                <Route exact path='/multiBottleEntry'
                    render={() => 
                        <ChaiMultiBottleEntry
                            {...this.props}
                            addCommitToBlockFunction={this.addCommitToBlockFunction}
                            addSetTemplateFunction={this.addSetTemplateFunction}
                            args={(this.state.formsData || {}).bottleInformation}
                        />
                    }
                />
                <Route exact path='/ledger/:id'
                    render={routeProps => (
                        <Fragment>
                            <ChaiPublicLedger {...this.props} ledgerId={routeProps.match.params.id} />
                        </Fragment>
                    )}
                />
                <Route exact path='/pl/:qrCode'
                    render={routeProps => (
                        <Fragment>
                            <ChaiPublicLedger {...this.props} qrCode={routeProps.match.params.qrCode} />
                        </Fragment>
                    )}
                />
                <Route exact path='/profile'
                    render={routeProps => (
                        <Fragment>
                            <ChaiUserSettings {...this.props} />
                        </Fragment>
                    )}
                />
                <Route exact path='/shareAndSale'
                    render={() => (
                        <Fragment>
                            <ChaiHeaderContainer {...currentLedger} />
                            <ChaiStepper {...this.props} />
                            <ChaiShareAndSale {...this.props} />
                        </Fragment>
                    )}
                />
            </Fragment>
        );
    }
};

export default withStyles(styles)(ChaiAuthenticatedRoutes);
