const { dpCrud,  CREATE, GET_ONE } = require('../../DataProviders/crudgeneric');

/**
 *
 * @param ledgerNumber
 * @param ledgerVersion
 * @returns {Promise<Response>}
 */
export async function getCounterfeit(ledgerNumber, ledgerVersion) {
    try {
        const data = await dpCrud(GET_ONE, "counterfeit", {
            ledger: {
                ledgerNumber: ledgerNumber,
                ledgerVersion: ledgerVersion
            }
        });
        return data.data;
    } catch (ex) {
        console.error("ERROR getCounterfeit", ex);
    }
    return null;
}

/**
 *
 * @param data
 * @returns {Promise<AxiosPromise<any>>}
 */
export async function saveCounterfeit(ledgerNumber, ledgerVersion, data) {
    const response = await dpCrud(CREATE, "counterfeit",
        {
            data: {...data, ledgerNumber, ledgerVersion }
        }
    );
    console.info("ERROR saveCounterfeit", response);
    return response;
}
