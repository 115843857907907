import { dpCrud, GET_LIST } from '../../DataProviders/crudgeneric';
import permission from './frontEndPermissions';

// TODO move the import permission from './frontEndPermissions'; to backend.
export function getPermissions(){
    //let permission = JSON.parse(localStorage.getItem('permission') ? localStorage.getItem('permission') : null);
    let user = JSON.parse(localStorage.getItem('user'));
    /*if(!permission) {
        const result  = await permissionsProvider(GET_LIST, 'permissions');
        const permissions = result.data;
        permission = permissions.find( p => p.role === "Administrator");
        localStorage.setItem('permission', JSON.stringify(permission));
    }*/
    return user ? permission.find(p => p.role === user.rol) : null;
}

export async function getOptionsByFormNameFieldName(formName, fieldName) {
    try {
        const data = await dpCrud(GET_LIST, `enums/${formName}/${fieldName}`);
        return data.data;
    } catch (ex) {
        console.error("Error getting options by formName and fieldName>> ", ex);
    }
    return null;
}

export async function getOptionsByFormName(formName) {
    try {
        const data = await dpCrud(GET_LIST, `enums/${formName}`);
        return data.data;
    } catch (ex) {
        console.error("Error getting options by formName>> ", ex);
    }
    return null;
}

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @returns {Promise} the Promise for a data response
 */
// TODO refactor to use dpCrud
const permissionsProvider = (type, resource) => {

    const API_URL =  `${process.env.REACT_APP_CHAI_SECURITY_API_HOST}` || 'gateway/';
    if (!localStorage.getItem('token')) return false
    let url = '';
    const options = {
        headers: new Headers({
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Origin': '',
        }),
    };

    switch (type) {
        case GET_LIST: {
            url = `${API_URL}${resource}`;
            break
        }
        default:
            throw new Error(`Unsupported Data Provider request type ${type}`)
    }

    return fetch(url, options)
        .then(res => res.json())
        .then((response) => {
            const res = {};
            res.data = response;
            res.total = response.length;
            return res
        }).catch((error) => {
            return error;
        })
};

