import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import '../../Assets/css/fontello.css';

const isTablet = window.innerWidth <= 1024;
const multilineMaxRows = 6;
const styles = theme => ({
    bootstrapRoot: {
        padding: 5,
        height: '100%'
    },
    bootstrapInput: {
        backgroundColor: '#fff !important',
        fontSize: '1.18rem',
        padding: '0.85rem',
        color: '#3f3e3e',
        fontFamily: "'Source Sans Pro', sans-serif",
        border: '1px solid #6d6c6c',
        fontWeight: 400,
        '&:focus': {
            border: '1px solid #6d6c6c',
            color: '#3f3e3e',
            fontWeight: 400,
            boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.49)'
        },
        '&:disabled': {
            color: '#c3c3c3',
            fontWeight: 400,
        }
    },
    bootstrapInputError: {
        backgroundColor: '#ffecec',
        border: '1px solid #d49292',
        fontSize: '1em',
        padding: '1em',
        color: '#ca4646',
        fontFamily: "'Source Sans Pro', sans-serif",
        fontWeight: 400
    },
    bootstrapFormLabel: {
        fontSize: isTablet ? '1.1rem' : '1.3rem',
        fontFamily: "'Source Sans Pro', sans-serif",
        fontWeight: 600,
        width: '100%',
        color: '#3f3e3e !important',
        textTransform: 'uppercase',
        marginLeft: 6
    },
    bootstrapHelpText: {
        fontSize: '1.1em',
        fontFamily: "'Source Sans Pro', sans-serif",
        fontWeight: 400,
        color: '#ca4646',
    },
    margin: {
        margin: theme.spacing.unit,
    },
    textField: {
        flexBasis: 200,
    },
    hide: {
        visibility: 'hidden'
    }
});

function ChaiTextField(props) {
    const {
        classes, error, errorMessage, label, value,
        style, keyField, type, disabled, visibility,
        multiline, rows, placeholder, defaultValue, autoFocus,
        maxlength, autoComplete, certificate, containerProperties,
        adornment, onBlur
    } = props;
    const { onChange } = props;
    const compuestLabel = certificate ? getLabelWithCertificateLogo(label) : label;

    function getLabelWithCertificateLogo(label) {
        return (<div>{label}&nbsp;<i class="demo-icon icon-icon-isotype" title="This field will be included in the ledger certificate"></i></div>)
    }

    return (
        <TextField
            autoFocus={autoFocus}
            onBlur={onBlur} 
            autoComplete={autoComplete ? autoComplete : 'off'}
            style={{ paddingTop: "4px", display: "flex", ...style }}
            className={visibility === false ? classes.hide : null}
            value={value}
            defaultValue={defaultValue}
            disabled={disabled}
            onChange={onChange}
            onKeyDown={onChange}
            label={compuestLabel}
            id={keyField}
            type={type}
            maxLength={maxlength}
            multiline={multiline}
            rows={Math.min(rows || 1, multilineMaxRows)}
            rowsMax={multilineMaxRows}
            helperText={error ? errorMessage : null}
            placeholder={placeholder ? placeholder : ''}
            InputProps={{
                disableUnderline: true,
                classes: {
                    root: containerProperties ? '' : classes.bootstrapRoot,
                    input: error ? classes.bootstrapInputError : classes.bootstrapInput
                },
                startAdornment: adornment
            }}
            InputLabelProps={{
                shrink: true,
                className: classes.bootstrapFormLabel,
            }}
            FormHelperTextProps={{
                className: classes.bootstrapHelpText
            }}
        />
    );
}

ChaiTextField.propTypes = {
    classes: PropTypes.object.isRequired,
    placeholder: PropTypes.string
};

export default withStyles(styles)(ChaiTextField);
