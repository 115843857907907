import React  from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import ChaiHeaderButton from '../ChaiHeaderButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Tooltip, Chip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ChaiCheck from '../ChaiCheck';

const styles = theme => ({
	root: {
		width: '100%',
		boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.49)'
	},
	table: {
    },
    disableCheck:{
        color:"lighgray"
    },
    sizeIcon: {
        fontSize: 34,
        color: 'green'
    }
});

function ChaiShareUsersTable ({
    classes,
    iconColorEdit,
    iconColorSell,
    data,
    onSelectEdit,
    onSelectDelete,
    onSelectSell,
    enableCheckDelete,
    onConfirm,
    usersBeforeEdit
}) {
    const disabledEdit = () => data.find(row => row.canEdit);
    const disabledSell = () => data.find(row => row.canSell);

    return (
        <Paper className={classes.root} >
            <Table className={classes.table} aria-labelledby="tableTitle">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Handle</TableCell>
                        <TableCell align="right">View</TableCell>
                        <TableCell align="right">
                            Edit&nbsp;
                            <Tooltip
                                title="Only one person can be selected for each this role at a time."
                                placement="top"
                            >
                                <Chip style={{ width: '15%', height: "22px" }} label="?"></Chip>
                            </Tooltip>
                        </TableCell>
                        <TableCell align="right">Remove</TableCell>
                        <TableCell align="right">
                            Sell&nbsp;
                            <Tooltip
                                title="Only one person can be selected for each this role at a time."
                                placement="top"
                            >
                                <Chip style={{ width: '15%', height: "22px" }} label="?"></Chip>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row,index) => (
                        (row.id) ? <TableRow key={row.id}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{
                                row.handle === null || row.handle.trim() === ''
                                    ? <i>NO HANDLE</i>
                                    : row.handle}
                            </TableCell>
                            <TableCell>
                                <ChaiCheck
                                    className={classes.disableCheck}
                                    checked={true}
                                    disable={true}
                                    style={{ width: '20%' }}
                            /></TableCell>
                            <TableCell>
                                <ChaiCheck
                                    checked={row.canEdit}
                                    disable={disabledEdit()&&!row.canEdit}
                                    style={{ width: '20%' }}
                                    onChange={(ev) => onSelectEdit(ev,row.id)} 
                                    iconColor={
                                        iconColorEdit &&
                                        row.canEdit !== usersBeforeEdit[index].canEdit
                                            ? classes.sizeIcon
                                            : null
                                        }
                                />
                            </TableCell>
                            <TableCell>
                                {enableCheckDelete && <ChaiCheck
                                    checked={row.delete}
                                    style={{ width: '20%' }}
                                    onChange={(ev) => onSelectDelete(ev,row.id)} 
                                    iconColor={row.delete ? classes.sizeIcon : null}
                                /> }
                                {!enableCheckDelete && <IconButton onClick={()=> onSelectDelete(row.id)} >
                                    <DeleteIcon/>
                                    </IconButton>
                                }
                                </TableCell>
                            <TableCell>
                                <ChaiCheck
                                    checked={row.canSell}
                                    disable={disabledSell()&&!row.canSell}
                                    style={{ width: '20%' }}
                                    onChange={(ev) => onSelectSell(ev,row.id)} 
                                    iconColor={iconColorSell ? (row.canSell !== usersBeforeEdit[index].canSell) ? classes.sizeIcon : null : null}
                                />
                            </TableCell>
                        </TableRow> : ''
                        )
                    )}
                </TableBody>
            </Table>
            <ChaiHeaderButton
                onClick={onConfirm}
                disabled={data.length === 0}
                label="Confirm"
                style={{ float:"right" , margin:"15px" }}
            />
        </Paper> 
    )
}


ChaiShareUsersTable.propTypes = {
    classes: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    onSelectEdit: PropTypes.func.isRequired,
    onSelectSell: PropTypes.func.isRequired,
    onSelectDelete: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,

};

export default withStyles(styles)(ChaiShareUsersTable);
