import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    main: { justifyContent: 'space-between', display: 'flex', padding: '20px', alignItems: 'center' }
});

const ChaiRowBodyContainer = (props) => {
    const { classes, children, style } = props;

    return (
        <div className={classes.main} style={style}>
            {children}
        </div>
    );
}

ChaiRowBodyContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiRowBodyContainer);
