import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChaishareUsersTable from './ChaiShareUsersTable';
import ChaiBaseForm from '../ChaiBaseForm';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiPermissionsModal from './ChaiPermissionsModal';
import { getShareInfoByLedgerId } from './service';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ChaiAddUsersWidget from './ChaiAddUsersWidget';
import formNames from '../ChaiBaseForm/formNames';

const isTablet = window.innerWidth <= 1024;


const styles = theme => ({
    title: { fontSize: isTablet ? '0.8em' : '1em', fontWeight: 600 },
});

class ChaiShareForm extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.SHARE;
        const emptyDataState = {
            users: [],
            usersToAdd: [],
            usersBeforeEdit:[]
        };
        super({ ...props, formName, emptyDataState });
        this.state = {
            ...this.state,
            milocalvar: 'Hola', modal: false
        };
    }

    getElement = async () => {
        const users = await getShareInfoByLedgerId(this.props.currentLedger.ledgerNumber) || [];
        return { users, usersBeforeEdit: users };
    };

    openModal = () => {
        this.setState({modal: true});
     };
 
     closeModal = () => {
         this.setState({modal: false});
     };
 
     renderModal = () => {
         const { modal } = this.state;
 
         return (
             <Dialog
                 open={modal}
                 onClose={this.closeModal}
                 aria-labelledby="form-dialog-title"
                 fullWidth
             >
                 <DialogContent>
                     
                     <p>
                        You are about to grant permissions to :</p>
                        <ul>
                            {this.state.data.users.map((user)=>(
                                <li>{user.name}</li>
                            ))}
                         </ul>
                     
                 </DialogContent>
                 <DialogActions style={{ marginRight: 25 }}>
                     <ChaiHeaderButton label="Confirm" onClick={this.editUsers} />
                 </DialogActions>
             </Dialog>
         )
    }

    onSelectEdit = (event, id) => {
        const data =  this.state.data;
        data.users = data.users.map(
            (user) => user.id === id
                ? {...user,canEdit:event.target.checked}
                : {...user,canEdit:false}
        );
        this.setState({data});
    };

    onSelectSell = (event, id) => {
        const data =  this.state.data;
        data.users = data.users.map(
            (user) => user.id === id
                ? {...user,canSell:event.target.checked}
                : {...user,canSell:false}
        );
        this.setState({data});
    };

    onSelectDelete = (ev,id) => {
        const data =  this.state.data;

        data.users = data.users.map((user) => {
            if(user.id === id) {
                user.delete = ev.target.checked;
            }
            return user;
        });
        this.setState({data});
        if (data.users.length === 0) this.openModal();
    };

    newUsers = (users) => {
        this.setState({usersToAdd: users});
    };

    addUsers = (usersAdded) => {
        const data = this.state.data;
        data.users = usersAdded;
        data.usersBeforeEdit = usersAdded;
        this.setState({ data, usersToAdd: [] });
    };

    editUsers = () => {
        const data = this.state.data;
        data.users = data.users.filter((user) => !user.delete );
        data.usersBeforeEdit = data.users;
        this.setState({ data });
        this.props.showNotification("Permissions were modified and saved");
    };

    cleanAfterSaving = () => {
        this.setState({usersToAdd: []});
    };

    render() {
        const { modal } = this.state;
        const { classes, ledgers, showNotification } = this.props;

        return (
            <ChaiBodyContainer> 
                <ChaiAddUsersWidget
                    {...this.props}
                    newUsers={this.newUsers}
                    onAddUsers={this.addUsers}
                    previousUsers={this.state.data.users}
                />
                <ChaiPermissionsModal
                    showModal={modal}
                    closeModal={this.closeModal}
                    onAddUsers={this.addUsers}
                    onAfterSaving={this.cleanAfterSaving}
                    onEditUsers={this.editUsers}
                    previousUsers={this.state.data.users}
                    add={false}
                    usersToAdd={[]}
                    ledgers={ledgers}
                    showNotification={showNotification}
                    permissionsOverride={true}
                >
                </ChaiPermissionsModal>
                <ChaiRowBodyContainer>
                    <div className={classes.title}>CURRENT USERS: </div>
                </ChaiRowBodyContainer>
                <ChaiRowBodyContainer>
                <ChaishareUsersTable 
                    style={{ width: '100%' }} 
                    onConfirm={this.openModal} 
                    data={this.state.data.users} 
                    onSelectEdit={this.onSelectEdit} 
                    onSelectSell={this.onSelectSell} 
                    onSelectDelete={this.onSelectDelete}
                    enableCheckDelete={true}
                    iconColorEdit={true}
                    iconColorSell={true}
                    usersBeforeEdit={this.state.data.usersBeforeEdit}
                >
                </ChaishareUsersTable >
                </ChaiRowBodyContainer>
            </ChaiBodyContainer>     
        )
    }
}

ChaiShareForm.propTypes = {
    classes: PropTypes.object.isRequired,
    ledgers: PropTypes.array.isRequired
};

export default withStyles(styles)(ChaiShareForm);
