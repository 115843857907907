const httpStatus = require('http-status-codes');
const { dpCrud, CREATE, GET_ONE, PATCH } = require('../../DataProviders/crudgeneric');
const BASE_URL = process.env.REACT_APP_CHAI_SECURITY_API_HOST || 'gateway/';

/**
 *
 * @param ledgerNumber
 * @param ledgerVersion
 * @returns {Promise<Response>}
 */
export async function getBottle(ledgerNumber, ledgerVersion) {
    try {
        const data = await dpCrud(GET_ONE, "bottles", {
            ledger: {
                ledgerNumber: ledgerNumber,
                ledgerVersion: ledgerVersion
            }
        });
        return data.data;
    } catch (ex) {
        console.error("Error getting bottle>> ", ex);
    }
    return null;
}

/**
 *
 * @param data
 * @returns {Promise<AxiosPromise<any>>}
 */
export async function saveBottleInfo(ledgerNumber, ledgerVersion, data) {
    const response = await dpCrud(CREATE, "bottles/info", {
        data: { ...data, ledgerNumber, ledgerVersion }
    });
    console.info("saving response >> ", response);
    return response;
}

/**
 *
 * @params dataList
 * @returns
 */
export async function saveBottleInfoList(dataList) {
    return await dpCrud(PATCH, "bottles/info", dataList);
}

/**
 *
 * @param data
 * @returns {Promise<AxiosPromise<any>>}
 */
export async function saveBottleData(ledgerNumber, ledgerVersion, data) {
    try {
        const response = await dpCrud(CREATE, "bottles/data", {
            ledger: {
                ledgerNumber: ledgerNumber,
                ledgerVersion: ledgerVersion
            },
            data
        });
        console.info("saving response >> ", response);

        return response;
    } catch (ex) {
        console.error("error saving label >> ", ex);
        return null;
    }
}

/**
 *
 * @param filter The filter query to search wines
 * @param limit The amount of results you want to show the user
 * @returns {Promise<AxiosPromise<any>>} An array containing all wines matching the filter query
 */
export async function filterWine(filter, limit) {
    const token = localStorage.getItem('token');
    const url = `${BASE_URL}lwin/${filter}/${limit}`;
    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
        method: 'GET'
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }

        return result;
    } catch (error) {
        console.error('ERROR filterWine()', error);
        throw error.error ? error.error : error;
    }
}

export async function addWine(data) {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    const url = `${BASE_URL}lwin/add`;

    data = { ...data, userId: user.id };

    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(data)
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }

        return result;
    } catch (error) {
        console.error('ERROR addWine()', error);
        throw error.error ? error.error : error;
    }
}

export async function getTemplateByBottleInfo(data) {
    const token = localStorage.getItem('token');
    const url = `${BASE_URL}WineTemplate/GetTemplateByBottleInfo`;

    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(data)
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200) {
            throw result;
        }

        return result;
    } catch (error) {
        console.error('getTemplateByBottleInfo', error);
        throw error.error ? error.error : error;
    }
}

export async function applyTemplateToLedger(ledgerNumber, ledgerVersion, templateId) {
    const token = localStorage.getItem('token');
    const ledger = `ledgers/${ledgerNumber}`;
    const version = `versions/${ledgerVersion}`;
    const template = `templates/${templateId}`;
    const url = `${BASE_URL}${ledger}/${version}/${template}/apply`;

    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
        method: 'GET'
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== httpStatus.OK) {
            throw result;
        }

        return result;
    } catch (error) {
        console.error('applyTemplateByLedger', error);
    }
}
