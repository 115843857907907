import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    main: { display: 'flex', flexDirection: 'column', justifySelf: 'space-evenly', backgroundColor: '#efefef', padding: '30px', width: 'auto' }
});

const ChaiBodyContainer = (props) => {
    const { classes, children, style } = props;

    return (
        <div className={classes.main} style={style}>
            {children}
        </div>
    );
}

ChaiBodyContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiBodyContainer);
