import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const styles = theme => ({
    cssLabel: {
        '&$cssFocused': {
            color: '#1e559e',
        },
    },
    cssFocused: {},
    cssUnderline: {
        '&:after': {
            borderBottomColor: '#1e559e',
        },
    },
});

function ChaiSearch(props) {
    const { classes, searchText, handleSearch, loading } = props;

    return (
        <div>
            <FormControl className={classes.margin}>
                <InputLabel
                    FormLabelClasses={{
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                    }}
                    htmlFor="custom-css-input"
                    type="search"
                >
                    search
                </InputLabel>
                <Input
                    disabled={loading}
                    classes={{
                        underline: classes.cssUnderline,
                    }}
                    id="custom-css-input"
                    onChange={handleSearch}
                    value={searchText}
                />
            </FormControl>
            <IconButton>
                <SearchIcon />
            </IconButton>
        </div>
    )
}


ChaiSearch.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiSearch);