import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import ChaiTextField from '../ChaiTextField';
import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiProgress from '../ChaiProgress';
import { login } from './service';

const styles = theme => ({});

class ChaiLogin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null,
            password: null,
            loading: false,
            logged: false
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(window.location.search).get('verified');
        if (query !== null) {
            this.props.showNotification(
                query === 1
                    ? 'Verification completed, you can now log in.'
                    : 'Verification failed. Invalid token.',
                'warning'
            );
        }
    }

    handleChange = (e, field) => {
        const state = this.state;
        state[field] = e.target.value;

        this.setState({ state });

        if (e.key === 'Enter') {
            this.handleLogin();
        }
    };

    getField = (field) => {
        const value = this.state[field];

        const propsField = {
            value: value,
            onChange: (e) => this.handleChange(e, field),
            keyField: field
        };

        return propsField;
    };

    handleLogin = async () => {
        try {
            this.setState({ loading: true });

            const credentials = {
                email: this.state.email,
                password: this.state.password
            };

            const result = await login(credentials);
            if (result) {
                this.setState({ loading: false });
                window.location = this.props.redirectAfterLogin;
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            this.props.showNotification(error);
            this.setState({ loading: false });
        }
    };

    render() {
        const { loading } = this.state;

        return (
            <div style={{padding: 40}}>
                <ChaiRowBodyContainer>
                    <ChaiTextField {...this.getField('email')} label="EMAIL" style={{ width: '100%' }}
                    />
                </ChaiRowBodyContainer>
                <ChaiRowBodyContainer>
                    <ChaiTextField {...this.getField('password')} label="PASSWORD" type={'password'} style={{ width: '100%' }}
                    />
                </ChaiRowBodyContainer>
                <ChaiRowBodyContainer>
                    {loading ?
                        <ChaiProgress /> :
                        <ChaiHeaderButton label="SIGN IN" style={{marginLeft: 'auto', marginRight: 'auto'}} onClick={this.handleLogin} />}
                </ChaiRowBodyContainer>
                <ChaiRowBodyContainer>
                    <p onClick={()=>{ window.location.hash = '#/forgotpassword'}} style={{cursor:"pointer"}}>Forgot password?</p>
                </ChaiRowBodyContainer>
            </div>
        );
    }
}

ChaiLogin.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChaiLogin);
