const httpStatus = require('http-status-codes');
const BASE_URL = process.env.REACT_APP_CHAI_SECURITY_API_HOST || 'gateway/';

const getOptions = () => ({
    headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.token}`,
        'Content-Type': 'application/json'
    })
});

const getUrl = path =>
    `${BASE_URL}${path}`;

const getRequest = async path =>
    localStorage.token
        ? await (await fetch(getUrl(path), getOptions())).json()
        : false;

export const bottleCaseQrCodeExists = async qrCode =>
    await getRequest(`BottleCases/qrCodeExists/${qrCode}`);

export const bottleCaseRfidCodeExists = async rfidCode =>
    await getRequest(`BottleCases/rfidCodeExists/${rfidCode}`);
