import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

const isTablet = window.innerWidth <= 1024;
const styles = theme => ({
    main: { display: 'flex', justifyCOntent: 'stretch', backgroundColor: '#b9b9b9', fontFamily: "'Source Sans Pro', sans-serif" },
    container: { color: '#3f3e33e', display: 'grid', padding: 15, borderRight: '1px solid #fff' },
    ledgerContainer: { width: '15%', backgroundColor: '#632222', color: 'white', display: 'grid', textAlign: 'center', padding: 15, borderRight: '1px solid #fff' },
    bottleNameContainer: { width: '45%' },
    lastEditedContainer: { width: '15%' },
    authenticatorContainer: { width: '25%', borderRight: 0 },
    title: { fontSize: isTablet ? '0.8em' : '1em', fontWeight: 600 },
    detail: { fontFamily: '"Source Sans Pro", sans-serif', fontSize: isTablet ? '1em' : '1.2em'},
    ledgerDetail: { fontSize: isTablet ? '1em' : '1.2em', fontWeight: 600 },
    ledgerTitle: { fontSize: isTablet ? '0.8em' : '1em', fontWeight: 600, paddingBottom: '10px' }
});

const ChaiHeaderContainer = (props) => {
    const { classes, ledgerNumber, wineName, lastEdited, authenticator, mode } = props;


    return (
        <div className={classes.main}>
            <div className={classes.ledgerContainer}>
                <div className={classes.ledgerTitle}>
                    {
                        mode === 'multiBottleEntry' || ledgerNumber
                            ? 'ENTERING DATA FOR'
                            : 'ADD A NEW BOTTLE'
                    }
                </div>
                <div className={classes.ledgerDetail}>
                    {
                        mode === 'multiBottleEntry'
                            ? 'Multiple bottles'
                            : ledgerNumber
                                ? ledgerNumber
                                : ''
                    }
                </div>
            </div>
            <div className={classNames(classes.container, classes.bottleNameContainer)}>
                <div className={classes.title}>WINE NAME</div>
                <div className={classes.detail}>
                    {mode !== 'multiBottleEntry' && ledgerNumber ? wineName : ''}
                </div>
            </div>
            <div className={classNames(classes.container, classes.lastEditedContainer)}>
                <div className={classes.title}>LAST EDITED</div>
                <div className={classes.detail}>
                    {
                        mode !== 'multiBottleEntry' && ledgerNumber
                            ? lastEdited
                            : new Date().toLocaleDateString()
                    }
                </div>
            </div>
            <div className={classNames(classes.container, classes.authenticatorContainer)}>
                <div className={classes.title}>AUTHENTICATOR</div>
                <div className={classes.detail}>
                    {
                        mode !== 'multiBottleEntry' && ledgerNumber
                            ? authenticator
                            : JSON.parse(localStorage.getItem("user")).name
                    }
                </div>
            </div>
        </div>
    );
}

ChaiHeaderContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiHeaderContainer);
