const BASE_URL = process.env.REACT_APP_CHAI_SECURITY_API_HOST || 'gateway/';

export const saveLedgerForSinglePage = async (id, version, data) => {
    const url = `${BASE_URL}ledgers/${id}/versions/${version}/saveLedgerForSinglePage`;
    const options = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }, 
        method: 'POST',
        body: JSON.stringify(data)
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.status !== 200 || result.isError) {
            throw result;
        }
        return result;
    } catch (error) {
        console.error('ERROR saveLedgerForSinglePage()', error);
        throw error;
    }
};
