const enviroment = process.env.REACT_APP_NODE_ENV;
const component = process.env.REACT_APP_COMPONENT;
const { dpCrud,  CREATE } = require('../DataProviders/crudgeneric');

export default async (level, payload, ledger) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return await dpCrud(CREATE, 'logs', {
        data: {
            ...payload,
            'tags': [component, enviroment],
            'type': level,
            'userId': user.address,
            'ledgerNumber': ledger.ledgerNumber,
            'ledgerVersion': ledger.ledgerVersion
        }
    })
}