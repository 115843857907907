import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiTextField from '../ChaiTextField';
import ChaiDropDown from '../ChaiDropDown';
import ChaiBaseForm from '../ChaiBaseForm';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import FormLabel from '@material-ui/core/FormLabel';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChaiBodyContainerHeader from '../ChaiBodyContainerHeader';
import ChaiHeaderButton from '../ChaiHeaderButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { Grid, Tooltip, Chip } from '@material-ui/core';
import chaiErrorHandler from '../../Handler/chaiErrorHandler';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getUserAddressFromPk } from '../../Services/users';
import {
    getAuthenticator,
    saveAuthenticator,
    setVerification,
    generateTemplate,
    updateTemplate,
    parseValues,
    commitToBlockchain,
    blockchainSavingProcess
} from './authenticator'
import { getBottleData } from '../ChaiBottleData/service';
import { getBottle, getTemplateByBottleInfo } from '../ChaiBottleInformation/service';
import { conditionOptions, fillLevelOptions, shoulderedOptions, burgundyOptions, otherCapsuleOptions, corkConditionOptions } from '../ChaiBottleData/options';
import '../../Assets/css/fontello.css';
import formNames from '../ChaiBaseForm/formNames';
import { INSPECTION_LOCATION, INSPECTION_WITNESSES, INSPECTION_COMPANY } from '../../constants';

const styles = theme => ({
    modalTitle: {
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '1.1em',
        fontWeight: 600
    },

    reportTitle: {
        fontSize: '0.85em',
        fontFamily: "'Source Sans Pro', sans-serif",
        fontWeight: 600,
        color: '#3f3e3e',
        textTransform: 'uppercase',
        marginLeft: 6
    }
});

class ChaiAuthenticator extends ChaiBaseForm {

    constructor(props) {
        let formName = formNames.AUTHENTICATOR;
        let emptyDataState = {
            nameOfPerson: null,
            authenticatorCode: null,
            dateOfInspection: new Date(),
            inspectionLocation: null,
            bottleConditionSummary: null,
            peoplePresentAssistanceInspection: null,
            company: null,
            ledgerNumber: null,
            currentConditionReport: {},
            previousConditionReports: [],
            typeOfStorage: 0,
            boxNumber: null,
            locker: null,
            bin: null,
            shelf: null
        };
        super({ ...props, formName, emptyDataState });
        this.state = {
            ...this.state,
            privateKey: '',
            password: '',
            modal: false,
            createWineTemplateModal: false,
            updateWineTemplateModal: false,
            confirmationUpdateWineTemplateModal: false,
            wineTemplateName: '',
            wineTemplateDescription: '',
            bottle: {},
            visibleBottleSummary: false,
            bottleSizeOptions: [],
            optionalFields: [
                "currentConditionReport",
                "previousConditionReports",
                "id",
                "typeOfStorage",
                "boxNumber",
                "locker",
                "shelf",
                "bin"
            ],
            prepareToSendFunc: parseValues,
            softLoad: true,
        };

        const { addCommitToBlockFunction } = props;

        addCommitToBlockFunction(this.verifyImages.bind(this))
    }

    getFillLevelAndDetail = (report) => {
        const condition = fillLevelOptions.find((obj) => {
            return obj.id === report.fillLevel;
        });

        const detailCondition = report.fillLevel === 1 ?
            shoulderedOptions.find((obj) => {
                return obj.id === report.shoulderedOption;
            }) :
            burgundyOptions.find((obj) => {
                return obj.id === report.burgundyOption;
            });

        return condition ? `${condition.description}, ${detailCondition ? detailCondition.description : 'No details added'}` : false;
    };

    getLabelConditionForAge = (report) => {
        const condition = conditionOptions.find((obj) => {
            return obj.id === report.labelConditionForAge;
        });

        return condition ? condition.description : false;
    };

    getCapsuleConditionAndDetails = (report) => {
        const condition = conditionOptions.find((obj) => {
            return obj.id === report.capsuleCondition;
        });

        const detailConditions = report.capsuleConditionDetails ? report.capsuleConditionDetails.map((detail) => {

            let capsuleDetail = otherCapsuleOptions.find((obj) => {
                return obj.id === detail;
            });

            return capsuleDetail ? capsuleDetail.description : false;
        }) : null;

        return condition ? condition.description.concat(' - ', (detailConditions != null && detailConditions.length) ? detailConditions.join(', ') : 'No details added') : false;
    };

    getCorkCondition = (report) => {
        const condition = corkConditionOptions.find((obj) => {
            return obj.id === report.corkCondition;
        });

        return condition ? condition.description : false;
    };

    currentConditionReport = (report, withSummary = false) => {
        return (
            <Fragment>
                <p><strong>{'Fill Level'}</strong>{` ${this.getFillLevelAndDetail(report) || "NA"}`}</p>
                <p><strong>{'Label Condition'}</strong>{` ${this.getLabelConditionForAge(report) || "NA"}`}</p>
                <p><strong>{'Capsule Condition'}</strong>{` ${this.getCapsuleConditionAndDetails(report) || "NA"}`}</p>
                <p><strong>{'Cork Condition'}</strong>{` ${this.getCorkCondition(report) || 'NA'}`}</p>
                <p><strong>{'Other Labels Conditions'}</strong>{` ${report.otherLabelsConditions || "NA"}`}</p>
                {
                    withSummary &&
                    <p><strong>{'Bottle Condition Summary'}</strong>{` ${report.bottleConditionSummary || "NA"}`}</p>
                }
            </Fragment>
        );
    };

    getElement = async (ledger, version) => {
        await this.getOptionsWithFormName(formNames.AUTHENTICATOR);
        return getAuthenticator(ledger.ledgerNumber, version)
            .then(result => {
                console.info('AUTHENTICATOR', result);
                if (result) {
                    if (result.wineLocation) {
                        result.wineLocation.typeOfStorage = result.wineLocation.typeOfStorage > 0 ? result.wineLocation.typeOfStorage : null;
                    }

                    if (result.previousConditionReports && result.previousConditionReports.length) {
                        this.setState({
                            previousConditionReports: result.previousConditionReports,
                            dateOfInspection: new Date(result.dateOfInspection[0]).toLocaleDateString()
                        });
                    }
                    const isDateZero = new Date(result.dateOfInspection).getFullYear() === 0;
                    const date = !isDateZero ? new Date(result.dateOfInspection).toISOString() : new Date().toISOString();
                    const user = JSON.parse(localStorage.getItem('user'));

                    const inspectionLocation =
                        result.inspectionLocation || localStorage.getItem(INSPECTION_LOCATION);
                    const peoplePresentAssistanceInspection =
                        result.peoplePresentAssistanceInspection ||
                        localStorage.getItem(INSPECTION_WITNESSES);
                    const company = result.company || localStorage.getItem(INSPECTION_COMPANY);

                    result.dateOfInspection = date.substring(0, date.indexOf("T"));
                    result.nameOfPerson = user.name;
                    result.authenticatorCode = user.id;
                    result.inspectionLocation = inspectionLocation;
                    result.peoplePresentAssistanceInspection = peoplePresentAssistanceInspection;
                    result.company = company;

                    return { ...result.wineLocation, ...result };
                }
                return this.emptyDataState;
            });
    };

    saveElement = async (ledger, version, element) => {
        return saveAuthenticator(ledger.ledgerNumber, version, element);
    };

    /**
     * @override Implementing save method and errorHandling
     */
    save = async () => {
        const { currentLedger, showNotification } = this.props;

        if (this.validAuthenticator()) {
            try {

                this.setLoadingState(true);
                await this.saveElement(currentLedger, 0, this.state.data);
                this.setLoadingState(false);
            } catch (e) {
                this.setLoadingState(false);
                const errorToShow = typeof (e) === 'string' ?
                    e : e.content ? (e.content.content ? e.content.content : e.content.message) :
                        'An error has occurred while saving the Authenticator data.';

                showNotification(errorToShow);
            }
        } else {
            showNotification("Some Authenticator attributes are empty or invalid.");
        }
    };

    saveToBlockchain = async () => {
        const { currentLedger, showNotification } = this.props;
        const { privateKey } = this.state;

        try {
            this.setState({ modal: false });
            this.setLoadingState(true);

            await blockchainSavingProcess(currentLedger, privateKey,
                (message) => { showNotification(message); });

            this.setLoadingState(false);
            const data = await this.getElement(currentLedger, 0);
            this.setState({...this.state, data})
            this.openWineTemplateModal();
        } catch (e) {
            console.error('BLOCKCHAIN PROCESS', e);

            this.setLoadingState(false);
            const errorToShow = typeof (e) === 'string' ?
                e : e.content ? (e.content.content ? e.content.content : e.content.message) :
                    'An error has occurred while saving data to the Blockchain.';

            showNotification(errorToShow);
        }
    };

    openModal = () => {
        this.setState({ modal: true });
    };

    closeModal = () => {
        this.setState({ modal: false });
    };

    openWineTemplateModal = async () => {
        const {
            template,
            bottleInformationModified,
            measurementsModified,
            mainLabelModified,
            otherLabelModified,
            closureModified,
            glassModified,
            antifraudModified
        } = localStorage;
        if (!template) {
            this.openCreateWineTemplateModal();
        } else if (
            bottleInformationModified === "1" ||
            measurementsModified === "1" ||
            mainLabelModified === "1" ||
            otherLabelModified === "1" ||
            closureModified === "1" ||
            glassModified === "1" ||
            antifraudModified === "1"
        ) {
            this.openUpdateWineTemplateModal();
        }
    };

    openUpdateWineTemplateModal = async () => {
        const bottle = await getBottle(this.props.currentLedger.ledgerNumber, 0);
        const bottleSizeOptions =
            await this.getOptionsWithFormNameFieldName(formNames.BOTTLEINFORMATION, 'bottleSize');
        this.setState({ updateWineTemplateModal: true, bottle, bottleSizeOptions });
    };

    openCreateWineTemplateModal = async () => {
        const bottle = await getBottle(this.props.currentLedger.ledgerNumber, 0);
        const bottleSizeOptions =
            await this.getOptionsWithFormNameFieldName(formNames.BOTTLEINFORMATION, 'bottleSize');
        this.setState({ createWineTemplateModal: true, bottle, bottleSizeOptions });
    };

    closeUpdateWineTemplateModal = () => {
        this.setState({ updateWineTemplateModal: false });
    };

    closeCreateWineTemplateModal = () => {
        this.setState({ createWineTemplateModal: false });
    };

    updateWineTemplate = async () => {
        const { data } = this.state;
        const { ledgerNumber, ledgerVersion } = data;
        const { showNotification } = this.props;

        showNotification(`Updating template...`);
        const result = await updateTemplate(ledgerNumber, ledgerVersion);

        if (result.data) {
            const templates = await getTemplateByBottleInfo(this.state.bottle);

            const selectedTemplate = templates.filter((i, v) => v.id === this.state.template.id);
            if (selectedTemplate.length) {
                localStorage.template = JSON.stringify(selectedTemplate[0]);
            } else {
                delete localStorage.template;
            }

            localStorage.bottleInformationModified = false;
            localStorage.measurementsModified = false;
            localStorage.mainLabelModified = false;
            localStorage.otherLabelModified = false;
            localStorage.closureModified = false;
            localStorage.glassModified = false;
            localStorage.antifraudModified = false;

            showNotification(`Template successfully updated!`);
        } else {
            showNotification(`An error occurred updating the template.`);
        }

        this.closeUpdateWineTemplateModal();
        this.closeConfirmationUpdateWineTemplate();
    };

    handleCreateWineTemplateModal = async () => {
        const { wineTemplateName, wineTemplateDescription } = this.state
        const { currentLedger, showNotification } = this.props;
        const { ledgerNumber } = currentLedger
        if (wineTemplateName !== '') {
            try {

                const result = await generateTemplate(ledgerNumber, 0, wineTemplateName, wineTemplateDescription)

                if (result.data) {
                    showNotification('Template saved');
                    delete localStorage.template;
                } else {
                    showNotification('Template save error');
                }
            } catch (e) {
                if (e.content && e.content.content && e.content.content.length > 0) {
                    showNotification(e.content.content[0]);
                } else {
                    showNotification('Template save error');
                }
            }
            this.closeUpdateWineTemplateModal();
            this.closeCreateWineTemplateModal();
        } else {
            showNotification('Template Name is empty');
        }

    }

    handleWineTemplateName = (e) => {
        this.setState({ wineTemplateName: e.target.value });
        if (e.key === 'Enter') {
            this.handleCreateWineTemplateModal();
        }
    };

    handleWineTemplateDescription = (e) => {
        this.setState({ wineTemplateDescription: e.target.value });
        if (e.key === 'Enter') {
            this.handleCreateWineTemplateModal();
        }
    }


    validAuthenticator = () => {
        // independent wine location fields
        const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
        if (user !== null && (
            user.rol === 'Collector' ||
            (user.rol === 'Vendor Licensee' && this.isSharedAndCanEdit())
        )) {
            return true;
        }

        const { optionalFields, data } = this.state;

        const mandatoryFields = Object.keys(data).filter((key) => {
            if (optionalFields.indexOf(key) === -1) { //exceptions
                if (data[key] === null || data[key] === '') {
                    return key;
                }
            }
            return null;
        });

        return mandatoryFields.length === 0;
    };

    handleAuthenticationConfirmation = async (e) => {
        const { password, privateKey } = this.state;
        const { currentLedger } = this.props;

        if (password && privateKey && password.length > 0 && privateKey.length > 0) {
            try {
                const userAddress = await getUserAddressFromPk(privateKey);
                const result = await setVerification(currentLedger.ledgerNumber, 0, password, userAddress);

                if (result.data) {
                    this.saveToBlockchain(e);
                }
            } catch (error) {
                chaiErrorHandler(this.notify).catch(error);
            }
        } else {
            this.props.showNotification('Incorrect password and/or key');
        }
    };

    handlePasswordChange = (e) => {
        this.setState({ password: e.target.value });
        if (e.key === 'Enter') {
            this.handleAuthenticationConfirmation(e);
        }
    };

    handlePrivateKeyChange = (e) => {
        this.setState({ privateKey: e.target.value });
        if (e.key === 'Enter') {
            this.handleAuthenticationConfirmation(e);
        }
    }

    verifyImages = async () => {
        const { currentLedger, showNotification } = this.props;
        const bottleData = await getBottleData(currentLedger.ledgerNumber, 0);

        if (bottleData && (bottleData.data.fullBottleImageFront === "" ||
            bottleData.data.fullBottleImageBack === "")) {
            showNotification("No Images were found for the Bottle, please make sure to upload them before sending to Blockchain.");

            return false;
        }
        this.openModal();
    };

    toggleBottleSummary = () => {
        this.setState({
            visibleBottleSummary: !this.state.visibleBottleSummary
        })
    }

    openConfirmationUpdateWineTemplate = () => {
        this.setState({ confirmationUpdateWineTemplateModal: true })
    }

    closeConfirmationUpdateWineTemplate = () => {
        this.setState({ confirmationUpdateWineTemplateModal: false })
    }

    updateLocalInspectionData = (e) => {
        localStorage.setItem(INSPECTION_LOCATION, this.state.data.inspectionLocation);
        localStorage.setItem(INSPECTION_WITNESSES, this.state.data.peoplePresentAssistanceInspection);
        localStorage.setItem(INSPECTION_COMPANY, this.state.data.company);
    }

    renderModal = (e) => {
        const { modal } = this.state;

        return (
            <Dialog
                open={modal}
                onClose={this.closeModal}
                aria-labelledby="form-dialog-title"
                fullWidth
            >
                <DialogContent style={{ overflow: 'hidden' }}>
                    <ChaiTextField
                        value={this.state.password}
                        onChange={(e) => this.handlePasswordChange(e)}
                        type={'password'}
                        autoComplete={'new-password'}
                        label={'Re-Enter Password to confirm'}
                        style={{ width: '100%' }}
                    />
                    <ChaiTextField
                        value={this.state.privateKey}
                        type={'password'}
                        onChange={(e) => this.handlePrivateKeyChange(e)}
                        label={'Please provide your private key'}
                        style={{ width: '95%' }}
                    />
                    <Tooltip
                        title="Your private key is in the file you had to download after you verified your account and before you could log in"
                        placement="top"
                    >
                        <Chip style={{ width: '5%' }} label="?"></Chip>
                    </Tooltip>
                </DialogContent>
                <DialogActions style={{ marginRight: 25 }}>
                    <ChaiHeaderButton label="Confirm" onClick={this.handleAuthenticationConfirmation} />
                </DialogActions>
            </Dialog>
        )
    };

    getSize = (value) => {
        const { bottleSizeOptions } = this.state
        const size = (bottleSizeOptions
            ? bottleSizeOptions
            : []).find((obj) => {
                return obj.id === value
            });
        return size ? size.description : "Not Defined"
    };


    renderTemplateTitle = bottle =>
        `${bottle.vintage} ${bottle.producer} ${bottle.wineName} ${this.getSize(bottle.bottleSize)}`;

    renderUpdateWineTemplateModal = () => {
        const { updateWineTemplateModal, bottle } = this.state;

        return (
            <Dialog
                open={updateWineTemplateModal}
                onClose={this.closeUpdateWineTemplateModal}
                aria-labelledby="form-dialog-title-2"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="template-dialog-title">
                    <Typography variant="subheading">
                        You have modified the template for
                    </Typography>
                    <Typography variant="subheading" style={{ wordBreak: 'break-word' }}>
                        <strong> {this.renderTemplateTitle(bottle)} </strong>
                    </Typography>
                </DialogTitle>
                <DialogContent>

                    <span style={{ paddingRight: '16px' }}>
                        Would you like to update the template as well? Or create a new one?
                    </span>
                </DialogContent>
                <DialogActions style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                    <ChaiHeaderButton
                        onClick={this.closeUpdateWineTemplateModal}
                        value="Skip"
                        label="Skip"
                        secondary
                    ></ChaiHeaderButton>
                    <ChaiHeaderButton
                        onClick={this.openConfirmationUpdateWineTemplate}
                        value="Update the existing template"
                        label="Update the existing template"
                        style={{ marginLeft: '10px' }}
                        secondary
                    ></ChaiHeaderButton>
                    <ChaiHeaderButton
                        onClick={this.openCreateWineTemplateModal}
                        value="Create a new template"
                        label="Create a new template"
                        style={{ marginLeft: '10px' }}
                    ></ChaiHeaderButton>
                </DialogActions>
            </Dialog>
        );
    };

    renderCreateWineTemplateModal = (e) => {
        const { createWineTemplateModal, bottle } = this.state;

        return (
            <Dialog
                open={createWineTemplateModal}
                onClose={this.closeCreateWineTemplateModal}
                aria-labelledby="form-dialog-title-2"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="template-dialog-title">
                    <Typography variant="subheading">
                        You have introduced new data for
                    </Typography>
                    <Typography variant="subheading" style={{ wordBreak: 'break-word' }}>
                        <strong> {this.renderTemplateTitle(bottle)} </strong>
                    </Typography>
                    <Typography variant="subheading" style={{ wordBreak: 'break-word' }}>
                        Do you wish to generate a template for it?
                    </Typography>
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden' }}>
                    <ChaiTextField
                        value={this.state.wineTemplateName}
                        onChange={(e) => this.handleWineTemplateName(e)}
                        label={'Name'}
                        style={{ width: '95%' }}
                    />
                    <ChaiTextField
                        value={this.state.wineTemplateDescription}
                        onChange={(e) => this.handleWineTemplateDescription(e)}
                        label={'Description'}
                        style={{ width: '95%' }}
                        placeholder="Enter the template description..."
                        multiline={true}
                        rows={4}
                    />
                </DialogContent>
                <DialogActions style={{ marginRight: 25 }}>
                    <ChaiHeaderButton
                        secondary
                        label="Don’t generate template"
                        onClick={this.closeCreateWineTemplateModal}
                    />
                    <ChaiHeaderButton
                        label="Create"
                        onClick={this.handleCreateWineTemplateModal}
                    />
                </DialogActions>
            </Dialog>
        )
    };

    renderConfirmationUpdateWineTemplateModal = (e) => {
        const { confirmationUpdateWineTemplateModal, bottle } = this.state;

        return (
            <Dialog
                open={confirmationUpdateWineTemplateModal}
                onClose={() => { }}
                aria-labelledby="form-dialog-title"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="template-dialog-title">
                    <Typography variant="subheading">
                        This will replace the existing template
                    </Typography>
                    <Typography variant="subheading" style={{ wordBreak: 'break-word' }}>
                        <strong> {this.renderTemplateTitle(bottle)} </strong>
                    </Typography>
                </DialogTitle>
                <DialogActions style={{ marginRight: 25 }}>
                    <ChaiHeaderButton
                        secondary
                        label="Cancel"
                        onClick={this.closeConfirmationUpdateWineTemplate}
                    />
                    <ChaiHeaderButton
                        label="Replace"
                        onClick={this.updateWineTemplate}
                    />
                </DialogActions>
            </Dialog>
        )
    };

    render() {
        const { classes, hideFooter } = this.props;
        const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

        return (
            <Fragment>
                {this.state.loading && <LinearProgress />}
                <ChaiBodyContainer >
                    <ChaiBodyContainerHeader {...this.props}>
                        {this.renderModal()}
                        {this.renderCreateWineTemplateModal()}
                        {this.renderUpdateWineTemplateModal()}
                        {user.rol !== 'Collector' &&
                            <div>
                                {
                                    !hideFooter &&
                                    <ChaiRowBodyContainer>
                                        <ChaiTextField
                                            {...this.getField('ledgerNumber')}
                                            label="Ledger Number"
                                            style={{ width: '33%' }}
                                            disabled={true}
                                        />
                                    </ChaiRowBodyContainer>
                                }
                                <ChaiRowBodyContainer>

                                    <ChaiTextField
                                        {...this.getField('nameOfPerson')}
                                        label="LEDGER CREATOR*"
                                        style={{ width: '33%' }}
                                        disabled={true}
                                    />

                                    <ChaiTextField
                                        {...this.getField('authenticatorCode')}
                                        label="Authenticator Code*"
                                        style={{ width: '33%' }}
                                        disabled={true}
                                    />

                                    <ChaiTextField
                                        {...this.getField('dateOfInspection')}
                                        label="Date of inspection*"
                                        certificate={true}
                                        type="date"
                                        style={{ width: '33%' }}
                                        disabled={true}
                                    />

                                </ChaiRowBodyContainer>

                                <ChaiRowBodyContainer>
                                    <ChaiTextField
                                        {...this.getField('inspectionLocation', false, this.updateLocalInspectionData)}
                                        label="Inspection Location*"
                                        certificate={true}
                                        style={{ width: '33%' }}
                                    />

                                    <ChaiTextField
                                        {...this.getField('peoplePresentAssistanceInspection', false, this.updateLocalInspectionData)}
                                        label="INSPECTION WITNESSES*"
                                        style={{ width: '33%' }}
                                    />

                                    <ChaiTextField
                                        {...this.getField('company', false, this.updateLocalInspectionData)}
                                        label="LEDGER CREATOR HIRED BY*"
                                        style={{ width: '33%' }}
                                    />
                                </ChaiRowBodyContainer>
                                {
                                    !hideFooter &&
                                    <ChaiRowBodyContainer style={{ display: 'flex', alignItems: 'normal', justifyContent: 'space-between' }}>
                                        <div style={{ width: '48%', display: 'grid', alignContent: 'start', justifyItems: 'start', marginLeft: 5 }}>
                                            <FormLabel shrink={'true'} className={classes.reportTitle}>
                                                <div style={{ marginBottom: 20 }}>
                                                    {'Current Condition Report '}
                                                    <i class="demo-icon icon-icon-isotype"></i>
                                                </div>
                                            </FormLabel>
                                            <Fragment style={{ height: '100%' }}>
                                                <ChaiTextField
                                                    label='Bottle condition summary'
                                                    {...this.getField("bottleConditionSummary")}
                                                    style={{
                                                        width: '90%'
                                                    }}
                                                    placeholder="Enter the current bottle condition..."
                                                    multiline={true}
                                                    rows={4}
                                                />
                                            </Fragment>
                                            <div style={{ height: 50 }}></div>
                                            <ChaiHeaderButton
                                                style={{ marginLeft: 5 }}
                                                onClick={() => this.toggleBottleSummary()}
                                                label="View more details"
                                            />
                                            {
                                                this.state.visibleBottleSummary &&
                                                <div style={{ fontFamily: '"Source Sans Code", sans-serif', fontSize: '1em', color: '#3e3e3e' }}>
                                                    {this.currentConditionReport(this.state.data.currentConditionReport)}
                                                </div>
                                            }
                                        </div>
                                        <div style={{ width: '48%' }}>
                                            <Grid item xs={12} style={{ marginBottom: "0.5em" }}>
                                                <FormLabel shrink={'true'} className={classes.reportTitle} >
                                                    {'PREVIOUS CONDITION REPORTS'}
                                                </FormLabel>
                                            </Grid>
                                            {this.state.previousConditionReports && this.state.previousConditionReports.map((report, index) => {
                                                return (<Grid item xs={12}>
                                                    <ExpansionPanel>
                                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{ fontFamily: '"Source Sans Code", sans-serif' }} >
                                                            {`Report #${index + 1} - ${new Date(report.dateOfInspection).toDateString()}`}
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails style={{ display: 'grid' }}>
                                                            {this.currentConditionReport(report, true)}
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                </Grid>)
                                            })
                                            }
                                        </div>
                                    </ChaiRowBodyContainer>
                                }
                                {
                                    !hideFooter &&
                                    <ChaiBodyContainerHeader sectionName="Wine Location" />
                                }
                            </div>
                        }
                        {
                            !hideFooter &&
                            <ChaiRowBodyContainer>
                                <ChaiDropDown
                                    {...this.getField('typeOfStorage')}
                                    label="Type Of Storage"
                                    style={{ width: '20%' }}
                                    items={this.state.options[formNames.AUTHENTICATOR]['typeOfStorage'] || []}
                                />
                                <ChaiTextField
                                    {...this.getField('boxNumber')}
                                    label="Box Number or Location 1"
                                    style={{ width: '20%' }}
                                />
                                <ChaiTextField
                                    {...this.getField('locker')}
                                    label="Locker/Wall or Location 2"
                                    style={{ width: '20%' }}
                                />
                                <ChaiTextField
                                    {...this.getField('bin')}
                                    label="Bin/Column or Location 3"
                                    style={{ width: '20%' }}
                                />
                                <ChaiTextField
                                    {...this.getField('shelf')}
                                    label="Shelf/Row or Location 4"
                                    style={{ width: '20%' }}
                                />
                            </ChaiRowBodyContainer>
                        }
                    </ChaiBodyContainerHeader>
                </ChaiBodyContainer>
            </Fragment>
        );
    }

}

ChaiAuthenticator.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ChaiAuthenticator);
