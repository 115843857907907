import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ChaiTextField from '../ChaiTextField';
import ChaiSwitch from '../ChaiSwitch';
import ChaiImageInput from '../ChaiImageInput';
import ChaiDropDown from '../ChaiDropDown';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBaseForm from '../ChaiBaseForm';
import { getClosureCork, saveClosureCork, prepareToSend } from './closureCork.js';
import formNames from '../ChaiBaseForm/formNames';
const styles = theme => ({
    subtitle: {
        fontSize: '25px',
    }
});

class CorkForm extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.CLOSURECORK;
        let emptyDataState = {
            hasCorkBrandMark: false,
            corkBrandDescription: null,
            corkBrandImage: null,
            vintageLegible:false,
            vintageLegibleDescription: null,
            vintageLegibleImage: null,
            corkType: null
        };
        super({ ...props, formName, emptyDataState, loadTemplate: true });
        this.state = { 
            ...this.state,
            softLoad: true,
            prepareToSendFunc: prepareToSend,
        };

        const { addSetTemplateFunction } = props;
        addSetTemplateFunction(this.state.setTemplate);
    }

    getElement = async (ledger, version) => {
        await this.getOptionsWithFormName(formNames.CLOSURECORK);
        return getClosureCork(ledger.ledgerNumber, 0)
            .then(result => {
                return result ? { ...result, 
                    corkBrandDescription: (result.corkBrandMarkInfo ? result.corkBrandMarkInfo.description : ' '), 
                    vintageLegibleDescription: (result.vintageLegibleInfo ? result.vintageLegibleInfo.description : ' '), 
                    corkBrandImage: (result.corkBrandMarkInfo ? result.corkBrandMarkInfo.image : ' '), 
                    vintageLegibleImage: (result.vintageLegibleInfo ? result.vintageLegibleInfo.image : ' ') } 
                    : this.emptyDataState
            });
    };

    saveElement = async (ledger, version, element) => {
        try {
            let result = await saveClosureCork(ledger.ledgerNumber, version, element);
            return result;
        } catch (error) {
            throw error;
        }
    };

    render() {
        const footerData = {
            message: 'Please be sure to fill out the entire form before moving on.',
            buttonLabel: 'Save & Next',
            loading: this.state.loading
        };
        const { classes } = this.props

        const { hideFooter } = this.props;

        return (
            <Fragment>
                <ChaiBodyContainer>
                <Typography className={classes.subtitle}>Cork:</Typography>
                    {
                        !hideFooter &&
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                {...this.getField('hasCorkBrandMark', true)}
                                label="Cork Brand Mark Legible" 
                            />
                            {
                                this.state.data.hasCorkBrandMark && (
                                    <Fragment>
                                        <ChaiTextField
                                            {...this.getField('corkBrandDescription')}
                                            label="Cork Brand Mark Description"
                                        />
                                        <ChaiImageInput
                                            label="Cork Brand Mark"
                                            {...this.getFileField('corkBrandImage')}
                                        />
                                    </Fragment>
                                )
                            }
                            <div style={{ width: '35%' }}></div>
                        </ChaiRowBodyContainer>
                    }
                    {
                        !hideFooter &&
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                {...this.getField('vintageLegible', true)}
                                label="Vintage Legible"                         
                            />
                            {
                                this.state.data.vintageLegible && (
                                    <Fragment>
                                        <ChaiTextField
                                            {...this.getField('vintageLegibleDescription')}
                                            label="Vintage Legible Description"
                                        />
                                        <ChaiImageInput
                                            label="Vintage Legible"
                                            {...this.getFileField('vintageLegibleImage')}
                                        />
                                    </Fragment>
                                )
                            }
                            <div style={{ width: '35%' }}></div>
                        </ChaiRowBodyContainer>
                    }
                    <ChaiRowBodyContainer>
                        <ChaiDropDown
                            {...this.getField('corkType')}
                            label="Cork Type*"
                            style={{ width: '30%' }}
                            items={this.state.options[formNames.CLOSURECORK]['corkType'] || []}
                            {...this.getTemplateFieldStyle('corkType',{ width: '30%'})}
                        />
                        <div style={{ width: '70%' }}></div>
                    </ChaiRowBodyContainer>
                </ChaiBodyContainer>
            </Fragment>
        );
    }
}

CorkForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CorkForm);
