import ErrorHandler from "./errorHandler";

class ChaiErrorHandler extends ErrorHandler {
    async handle(err) {
        if(!err) {
            return 'An error ocurred while saving the data. ';
        } else {
            if(err.content){
                let subMessages = (err.content.content || []).join(", ");
                return `${subMessages ? subMessages : ''}`;
            } else {
                if(typeof err == 'string'){
                    return err;
                } else {
                    return err.message;
                }
            }  
        };
    }
}

export default notificator => new ChaiErrorHandler(notificator);

