const logger = require("../helpers/logger.helper").default("AdminProcessor")
const isNumber = require("../helpers/number.helper").default.isNumber;

const prepareForBlockchain = (content, ledger, userAddr) => {
    /**
     * From solidity contract:
     *
        uint ledgerNumber,
        uint ledgerVersion,
        uint lwinCode,
        string memory qrCode,
        string memory rfidCode,
        bool makeLedgerAvailableForSale,
        bool updateConditionReport,
        bool updateOwnership,
        bool encryptOwnerName,
        bool encryptOwnerInformation
     * */

    return {
        ledgerNumber: ledger.ledgerNumber,
        ledgerVersion: ledger.ledgerVersion,
        lwinCode: content.lwinCode || 0,
        qrCode: content.qrCode || 'n/a',
        rfidCode: content.rfidCode || 'n/a',
        makeLedgerAvailableForSale: content.makeLedgerAvailableForSale,
        updateConditionReport:content.updateConditionReport,
        updateOwnership: content.updateOwnership !== null ? content.updateOwnership : false,
        encryptOwnerName: content.encryptOwnerName,
        encryptOwnerInformation: content.encryptOwnerInformation
    };
};

const isValidContent = (ledger, content) => {
    if(!content){
        logger.error(`isValidContent Empty content`);
        return false;
    }
    
    if(!isNumber(ledger.ledgerNumber) || !isNumber(ledger.ledgerVersion)){
        logger.error(`isValidContent Invalid ledger Data`);
        return false;
    }

    const propsToCheck = [
        'makeLedgerAvailableForSale',
        'updateConditionReport',
        'updateOwnership',
        'encryptOwnerName',
        'encryptOwnerInformation'
    ];
    for(let attr of propsToCheck){
        if(typeof(content[attr]) == 'undefined'){
            logger.error(`isValidContent ${attr} not found`);
            return false;
        }
    }
    return true;
}

export default {
    isValidContent: isValidContent,
    prepareForBlockchain: prepareForBlockchain
}