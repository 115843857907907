import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const isTablet = window.innerWidth <= 1024;
const styles = theme => ({
    bootstrapRoot: {
        boxShadow: 'none',
        textTransform: 'none',
        borderRadius: 0,
        padding: isTablet ? '5px 20px' : '10px 40px',
        fontSize: isTablet ? '1em' : '1.2em',
        border: '1px solid',
        backgroundColor: '#632222',
        borderColor: '#632222',
        color: '#ffffff',
        fontWeight: 600,
        fontFamily: "'Source Sans Pro', sans-serif",
        '&:hover': {
            backgroundColor: '#401415',
            borderColor: '#401415',
            color: '#ffffff',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#632222',
            borderColor: '#632222',
            color: '#ffffff'
        },
        '&:disabled': {
            boxShadow: 'none',
            backgroundColor: '#666',
            borderColor: '#b9b9b9',
            color: '#ffffff'
        }
    },
    secondary: {
        backgroundColor: '#684b4b'
    }
});

function ChaiHeaderButton(props) {
    const { classes, label, type, style, onClick, disabled, secondary } = props;
    return (
        <Button
            style={style}
            variant="flat"
            color="primary"
            disabled={disabled}
            disableRipple
            type={type}
            onClick={onClick}
            className={classNames(
                classes.margin,
                classes.bootstrapRoot,
                secondary ? classes.secondary : null
            )}
        > {label} </Button>
    );
}

ChaiHeaderButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiHeaderButton);
