import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
    root: {
        color: '#6D0006',
        '&$checked': {
            color: '#6D0006',
        }
    },
    checked: {},
    size: {
        width: 40,
        height: 40,
    },
    sizeIcon: {
        fontSize: 20,
    },
    label: {
        fontSize: 16,
        fontFamily: '"Source Sans Pro", sans-serif'
    }
});

const ChaiBlockchainCheck = props => {
    const { classes, agreeCheck, handleCheck } = props;

    return (
        <div>
            <ExpansionPanel >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>
                        <FormControlLabel
                            classes={{
                                label: classes.label,
                            }}
                            control={
                                <Checkbox
                                    checked={agreeCheck}
                                    onChange={handleCheck('agreeCheck')}
                                    value="agreeCheck"
                                    classes={{
                                        root: classes.root,
                                        checked: classes.checked,
                                    }}
                                />
                            }
                            label={'I understand I am the sole owner of my Private Key, and system administrators do not hold a backup copy.'}
                        />
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography paragraph align="justify" style={{fontSize: 16, fontFamily: '"Source Sans Pro", sans-serif'}}>
                        <p>
                            Dear User,
                            we will in the next screen provide you with your <strong>Private Key</strong> to make use of the ChaiVault system.
                        </p>
                        <p>Using this key, you will be able to generate Ledgers, Provenances and Trackings in this system.
                            As it name implies, it is imperative that this key is <strong>known only to you</strong>.
                            You <strong>must keep it safe</strong>, as we <strong>do not hold a copy</strong> of your private key.
                        </p>
                        <p>This assures you that nobody, nor even us, can use the system in your behalf, and so, you are the only creator of your own Ledgers, Provenances and Trackings in this system.
                        Please <strong>save a copy</strong> of your private key, in some digital form (a text file in a couple USB pendrives would be a good alternative).</p>
                        
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
        </div >
    )
};

ChaiBlockchainCheck.propTypes = {
    classes: PropTypes.object,
    agreeCheck: PropTypes.bool.isRequired,
    handleCheck: PropTypes.func.isRequired,
};

export default withStyles(styles)(ChaiBlockchainCheck);
