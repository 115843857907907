const ethers = require("ethers");
const blockchainHelper = require("../helpers/blockchain.helper").default();
const logger = require('../helpers/logger.helper').default('WalletProvider');
const securityFacade = new (require('../facades/security.facade').default)();

//We provide a wallet instance
const getUserWallet = privateKey => new ethers.Wallet(privateKey, blockchainHelper.getProvider());

//We provide gas from main account based on a given estimate
const provideGas = async (wallet, estimate = 3000000) => {
    try {
        const provider = blockchainHelper.getProvider();
        //Obtain address balance
        const balance = await provider.getBalance(wallet.address);
        //logger.info('WALLET BALANCE', balance);
        //Provide if needed, we use the BigNumber.lt fn to compare
        if (balance.lt(estimate)) {
            const mainAccount = blockchainHelper.getMainAccount();
            const signer = provider.getSigner(mainAccount);
            const tx = {
                to: wallet.address,
                value: ethers.utils.parseEther('1.0')
            }
            logger.info('PROVIDING GAS...');

            const unlockedAccount = await securityFacade.unlockMainAccount();
            if (unlockedAccount) {
                const receipt = await signer.sendTransaction(tx);
                logger.info(`GAS RECEIPT for wallet ${wallet.address} - ${JSON.stringify(receipt)}`);
            } else {
                logger.error('provideGas', 'An error occurred while unlocking Main Account');
                return Promise.reject(false);
            }
        }
        return Promise.resolve(true);
    }
    catch (ex) {
        logger.error('provideGas', ex);
        return Promise.reject(false);
    }
}

export default {
    getUserWallet: getUserWallet,
    provideGas: provideGas
}
