import loggerHelper from '../helpers/logger.helper';
import TokenService from '../services/token.service';
import { tokensEnabled } from '../helpers/featureFlags.helper';
const logger = loggerHelper("SecurityFacade");
const { dpCrud, CREATE } = require('../../DataProviders/crudgeneric');

class SecurityFacade {

    constructor(_tokenService = new TokenService()) {
        this.tokenService = _tokenService;
    }

    async getBalanceAndAllowanceTokenInformation(userAddress) {
        let result = {
            balance: null,
            allowance: null
        }
        try {
            result.balance = await this.tokenService.balanceOf(userAddress);
            result.allowance = await this.tokenService.allowance(userAddress);
            logger.info('Allowance and balance', result);
            
            return result;
        } catch (ex) {
            throw ex;
        }
    }

    async getTotalSupplyOfTokens() {
        try {
            const result = await this.tokenService.totalSupply();
            logger.info('Total Suppply Of Tokens', result);

            return result;
        } catch (ex) {
            throw ex;
        }
    }

    async canRequest(userAddress, trxCount = 1) {
        try {
            let result = await this.getBalanceAndAllowanceTokenInformation(userAddress);
            const isTokenFlagOn = await tokensEnabled();
            const allowance = parseInt(result.allowance);
            const balance = parseInt(result.balance);

            if (isTokenFlagOn) {
                let hasBalanceAndAllowance = trxCount < allowance && trxCount <= balance;
                logger.info('Can Request', hasBalanceAndAllowance);
            }
            
            let called = false;
            let unlockedAccount = {};
            const pendingAllowance = trxCount - allowance;
            const pendingBalance = trxCount - balance;
            if (pendingBalance > 0) {
                unlockedAccount = await this.unlockMainAccount();
                called = true;
                if (unlockedAccount) {
                    await this.tokenService.addBalance(userAddress, pendingBalance);
                }
            }
            if (pendingAllowance >= 0) {
                if (!called) {
                    unlockedAccount = await this.unlockMainAccount();
                    called = true;
                }
                if (unlockedAccount) {
                    await this.tokenService.modifyAllowance(userAddress, allowance + pendingAllowance);
                }
                return true;
            }
        } catch (ex) {
            throw ex;
        }
    }

    async modifyAllowanceOfTokens(userAddress, allowance) {
        try {
            const unlockedAccount = await this.unlockMainAccount();
            if (unlockedAccount) {
                const result = await this.tokenService.modifyAllowance(userAddress, allowance);
                logger.info('Modify allowance of tokens', result);
                return result;
            } else {
                throw 'An error occurred while unlocking Main Account';
            }
        } catch (ex) {
            throw ex;
        }
    }

    async addBalanceTokens(userAddress, balance) {
        try {
            const unlockedAccount = await this.unlockMainAccount();
            if (unlockedAccount) {
                const result = await this.tokenService.addBalance(userAddress, balance);
                logger.info('Add balance of tokens', result);

                return result;
            } else {
                throw 'An error occurred while unlocking Main Account';
            }
        } catch (ex) {
            throw ex;
        }
    }

    async unlockMainAccount() {
        const unlockedAccount = await dpCrud(CREATE, 'blockchain/unlockMainAccount', {});

        return unlockedAccount ? unlockedAccount.data : false;
    }
}

export default SecurityFacade;
