import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import SwipeableViews from 'react-swipeable-views';
import { Card } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ChaiVaultLogo from '../../Assets/ChaiVaultLogo.png';
import ChaiRegister from './register';
import ChaiLogin from './login';
import ChaiNotification from '../ChaiNotification';

const isTablet = window.innerWidth <= 1024;
const styles = theme => ({
    tabsRoot: {
        backgroundColor: '#efefef'
    },
    tabsIndicator: {
        height: 0
    },
    root: { width: isTablet ? '60%' : '40%', marginLeft: 'auto', marginRight: 'auto', marginTop: '3%', marginBottom: '3%', backgroundColor: '#efefef', boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.49)' },
    tab: {
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '1.2em',
        fontWeight: 600
    },
    tabRoot: {
        backgroundColor: '#3f3e3e',
        color: 'white',
        '&$tabSelected': {
            color: '#3f3e3e',
            backgroundColor: '#efefef'
        }
    },
    tabSelected: {},
    rootCheck: {
        color: '#6D0006',
        '&$checked': {
            color: '#6D0006',
        },
    },
    checked: {},
    tabContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }
});

const specialTheme = createMuiTheme({
    palette: {
        secondary: {
            main: '#632222',
        },
    }
});

const authStyles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    }
})

const AuthLayout = (props) => {
    const { children } = props;

    return (
        <div style={authStyles.main}>
            <div style={{ display: 'flex', justifyContent: 'center', height: '50px' }}>
                <img src={ChaiVaultLogo} style={{ height: '100%' }} alt="ChaiVault" />
            </div>
            {children}
        </div>
    )
};

class ChaiAuthorization extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            tab: 0,
            message: '',
            open: false,
            dialogMessage:'',
            modal:false
        };
    }
    
    handleTab = (event, tab) => {
        this.setState({ tab });
    };

    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    showNotification = (message) => {
        this.setState({ open: true, message: message });

        setTimeout(() => {
            this.setState({ open: false });
        }, 7000);
    };

    render() {
        const { tab, message, open } = this.state;
        const { classes, theme } = this.props;

        return (
            <MuiThemeProvider theme={specialTheme}>
                <AuthLayout>
                    <Card className={classes.root} elevation={0} style={{ borderRadius: 0 }} >
                        <AppBar position='static' style={{ boxShadow: 'none' }}>
                            <Tabs value={tab} onChange={this.handleTab} fullWidth classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
                                <Tab classes={{ root: classes.tabRoot, label: classes.tab, selected: classes.tabSelected }} label='LOGIN' />
                                <Tab classes={{ root: classes.tabRoot, label: classes.tab, selected: classes.tabSelected }} label='REGISTER' />
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={tab}
                            onChangeIndex={this.handleChangeIndex}
                        >
                            {tab === 0 &&
                                <div dir={theme.direction}>
                                    <ChaiLogin showNotification={this.showNotification} redirectAfterLogin={this.props.redirectAfterLogin}/>
                                </div>}
                            {tab === 1 &&
                                <div dir={theme.direction} className={classes.tabContainer}>
                                    <ChaiRegister showNotification={this.showNotification} handleTab={this.handleTab} />
                                </div>
                            }
                        </SwipeableViews>
                    </Card>
                    <ChaiNotification message={message} open={open} />
                </AuthLayout >
            </MuiThemeProvider>
        );
    }
};

ChaiAuthorization.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ChaiAuthorization);
