import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import ActiveIcon from '@material-ui/icons/Check';
import InactiveIcon from '@material-ui/icons/Clear';
import BalanceIcon from '@material-ui/icons/AccountBalance';
import AllowanceIcon from '@material-ui/icons/CheckCircle';

import Toolbar from '@material-ui/core/Toolbar';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';

import Grid from '@material-ui/core/Grid';
import { CardContent, Tooltip } from '@material-ui/core';

import { getAllUserInformationForTokens, getTotalSupplyOfTokens, addBalanceToUser, modifyAllowanceToUser } from './service';
import ChaiTokenDialog from './ChaiTokenDialog';
import Search from './ChaiSearch';

const styles = theme => ({
    title: { fontSize: 14 },
    card: { width: '100%', marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#efefef' },
    toolbar: { display: 'flex', padding: '30px', marginLeft: 20 },
    progress: { padding: '20px', display: 'flex', justifyContent: 'center' },
    centered: { display: 'flex', padding: '5px', justifyContent: 'space-between' },
    active: { color: 'green', fontSize: 14, fontWeight: 'bold' },
    inactive: { color: 'red', fontSize: 14, fontWeight: 'bold' },
    balance: { textAlign: 'right' },
    label: { fontWeight: 'bold' },
    root: {
        width: '95%',
        boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.49)',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 40
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    rootRow: {
        backgroundColor: 'white',
        "&$hover:hover": {
            backgroundColor: '#ffecec',
        }
    },
    hover: {},
    cell: {
        fontFamily: '"Source Sans Code", sans-serif',
        fontSize: '1em',
        color: '#3f3e3e', padding: '20px'
    },
    headerCell: {
        backgroundColor: 'rgb(99,34,34, 0.7)',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '1.1em',
        fontWeight: 600,
        textTransform: 'uppercase',
        padding: 15,
        color: 'white'
    },
    table: { padding: 20 }
});

const specialTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#632222',
            fontFamily: '"Source Sans Code", sans-serif',
            color: '#3e3e3e'
        },
    }
});

class ChaiTokens extends Component {
    constructor(props) {
        super(props)
        this.state = {
            totalSupply: 0,
            saving: false,
            users: [],
            usersFiltered: [],
            page: 0,
            rowsPerPage: 5,
            picked: null,
            originalPicked: null,
            field: null,
            title: null,
            subtitle: null
        }
    }

    componentDidMount() {
        this.loadMainInformation();
        this.loadTokens();
    }

    async loadMainInformation() {
        this.setState({ saving: true });

        try {
            let supplyMainAccount = await getTotalSupplyOfTokens();
            if (!supplyMainAccount) {
                throw new Error(`An error occurred while getting Token's information`);
            }

            this.setState({ totalSupply: Number(supplyMainAccount), saving: false });
        } catch (err) {
            this.setState({ saving: false });
            this.props.showNotification(err.message, { autoHideDuration: 10000 });
        }
    }

    async loadTokens() {
        this.setState({ saving: true });

        try {
            let users = await getAllUserInformationForTokens();
            if (!users) {
                throw new Error(`An error occurred while getting Token's information`);
            }

            this.setState({ users: users, usersFiltered: users, saving: false });
        } catch (err) {
            this.setState({ saving: false });
            this.props.showNotification(err.message, { autoHideDuration: 10000 });
        }
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleSearch = (event) => {
        let searchText = event.target.value;
        let filtered = this.state.users.filter(user => {
            let text = user.name.toLowerCase();

            return text.indexOf(searchText.toLowerCase()) > -1;
        });

        if (!filtered.length) {
            filtered = this.state.users.filter(user => {
                let text = user.rol.toLowerCase();

                return text.indexOf(searchText.toLowerCase()) > -1;
            });
        }

        this.setState({ usersFiltered: filtered });
    };

    handleTokens = (token, isAllowance) => {
        let update = {
            title: 'Manage Balance',
            subtitle: 'Balance',
            picked: token,
            originalPicked: { ...token },
            field: 'balance',
            open: true
        };

        if (isAllowance) {
            update.title = 'Manage Allowance';
            update.subtitle = 'Allowance';
            update.field = 'allowance';
        }

        this.setState(update);
    };

    handleChange = (token, field, event) => {
        let allUsers = this.state.users;
        let userIndex = -1;
        let userInEdition = allUsers.find((user, index) => {
            if (token.address === user.address) {
                userIndex = index;
                return user;
            }
            return null;
        });

        userInEdition[field] = event.target.value;

        allUsers[userIndex] = userInEdition;
        this.setState({ users: allUsers });
    }

    handleClose = (event) => {
        let allUsers = this.state.users;
        let userIndex = -1;
        allUsers.find((user, index) => {
            if (user.address === this.state.picked.address) {
                userIndex = index;
                return user;
            }
            return null;
        });
        allUsers[userIndex] = this.state.originalPicked;

        this.setState({ open: false, picked: null, originalPicked: null, users: allUsers });
    };

    handleSave = async (token, field) => {
        try {
            this.setState({ saving: true });
            let saveResult = null;

            if (field === 'allowance') {
                saveResult = await modifyAllowanceToUser(token.address, token.allowance);
            } else {
                saveResult = await addBalanceToUser(token.address, token.balance);
            }

            if (!saveResult) {
                this.props.showNotification(saveResult.message);
                this.setState({ saving: false});
            } else {
                this.props.showNotification(`User's ${this.state.field} saved successfuly!`);
                this.setState({ saving: false, open: false });
            }
        } catch (ex) {
            this.props.showNotification(ex.message);
            this.setState({ saving: false });
        }
    };

    render() {
        const { classes } = this.props;
        const {
            open,
            users,
            usersFiltered,
            saving,
            page,
            rowsPerPage,
            picked,
            field,
            title,
            subtitle
        } = this.state;

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);
        const inCirculation = users ? users.reduce((current, user) => Number(user.balance) + current, 0) : 0;

        return (
            <MuiThemeProvider theme={specialTheme}>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid style={{ width: '50%' }}>
                            <div style={{ color: 'gray', padding: '5px', borderBottom: '1px solid gray', marginBottom: '10px' }}>{'Summary Information'}</div>
                            <Typography className={classes.centered}>
                                <div className={classes.label}>{'Total Amount of Tokens'}</div>
                                <div className={classes.balance}>{'1000000000000000'}</div>
                            </Typography>
                            <Typography className={classes.centered}>
                                <div className={classes.label}>{'Tokens Available'}</div>
                                <div className={classes.balance}>{1000000000000000 - inCirculation}</div>
                            </Typography>
                            <Typography className={classes.centered}>
                                <div className={classes.label}>{'Tokens In Circulation'}</div>
                                <div className={classes.balance}>{inCirculation}</div>
                            </Typography>
                        </Grid>
                        <Toolbar className={classes.toolbar}>
                            <Search
                                saving={saving}
                                handleSearch={this.handleSearch}
                            />
                        </Toolbar>
                        <Table className={classes.root}>
                            <TableHead>
                                <TableRow
                                    classes={{ root: classes.rootRow, hover: classes.hover }}>
                                    <TableCell className={classes.headerCell}>User Name</TableCell>
                                    <TableCell className={classes.headerCell}>Role</TableCell>
                                    <TableCell className={classes.headerCell}>Address</TableCell>
                                    <TableCell className={classes.headerCell} title="How many tokens the user can spend">Allowance (tokens)</TableCell>
                                    <TableCell className={classes.headerCell} title="How many tokens the user has available">Balance (tokens)</TableCell>
                                    <TableCell className={classes.headerCell}>Active</TableCell>
                                    <TableCell className={classes.headerCell}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usersFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((token, index) => {
                                    const activeIcon = <ActiveIcon className={classes.active} />;
                                    const inactiveIcon = <InactiveIcon className={classes.inactive} />;

                                    return (
                                        <TableRow
                                            hover
                                            classes={{ root: classes.rootRow, hover: classes.hover }}
                                            key={index}
                                        >
                                            <TableCell className={classes.cell}>{token.name}</TableCell>
                                            <TableCell className={classes.cell}>{token.rol}</TableCell>
                                            <TableCell className={classes.cell}>{token.address}</TableCell>
                                            <TableCell style={{
                                                textAlign: 'center',
                                                color: token.allowance !== 0
                                                    ? 'rgba(0, 0, 0, 0.87)'
                                                    : 'red'
                                            }}>{token.allowance}</TableCell>
                                            <TableCell className={classes.cell} style={{ textAlign: 'center' }}>{token.balance}</TableCell>
                                            <TableCell className={classes.cell} style={{ textAlign: 'center' }}>{token.active ? activeIcon : inactiveIcon}</TableCell>
                                            <TableCell className={classes.cell} style={{ textAlign: 'center' }}>
                                                <Tooltip title="Manage Balance"><AllowanceIcon onClick={() => this.handleTokens(token, true)} /></Tooltip>
                                                <Tooltip title="Manage Balance"><BalanceIcon onClick={() => this.handleTokens(token, false)} /></Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 49 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={3}
                                        count={usersFiltered.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                        {
                            picked ?
                                <ChaiTokenDialog
                                    open={open}
                                    token={picked}
                                    field={field}
                                    saving={saving}
                                    title={title}
                                    subtitle={subtitle}
                                    handleClose={this.handleClose}
                                    handleChange={this.handleChange}
                                    handleSave={this.handleSave}
                                /> : null}
                    </CardContent>
                </Card>
            </MuiThemeProvider>
        )
    }
}

ChaiTokens.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(ChaiTokens);
