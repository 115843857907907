import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import WaxSeal from '../../Assets/WaxSeal.png';
import { Grid } from '@material-ui/core';
import { footerStyles } from './styles';


const CertificateFooter = (props) => {
    const { classes, authenticatedBy, sellerLogo, sellerLink, sellerName, signature, signatureDescription } = props;
    const link = sellerLink.length ? `https://${sellerLink.split("://").pop()}` : '';
    return (
        <Grid container className={classes.root}>
            {
                sellerLogo && sellerLogo.length &&
                <Grid style={{ marginBottom: 25 }} item container justify="center" xs={12} sm={6} md={4}>
                    <a
                        href={link ? link : '#'}
                        title={sellerName}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            alt={sellerName}
                            src={sellerLogo}
                            style={{ maxWidth: '80%', maxHeight: "260px" }}
                        />
                    </a>
                </Grid>
            }
            <Grid style={{ marginBottom: 25 }} item container justify="center" xs={12} sm={6} md={4}>
                <img alt="Seal of certification" src={WaxSeal} />
            </Grid>
            <Grid className={classes.signatureContainer} item container justify="center" direction="column" xs={12} sm={6} md={4}>
                <span style={{ paddingLeft: '50px', marginBottom: 15, alignSelf: "flex-start" }} className={classnames(classes.smText, classes.lightText, classes.whiteText)}>Authenticated By</span>
                <img style={{ marginBottom: 5, alignSelf: "center" }} alt="Signature" src={signature} />
                <span style={{ alignSelf: "center", fontWeight: 300, whiteSpace: "pre-line" }} className={classnames(classes.smText, classes.lightText, classes.whiteText)}>{signatureDescription}</span>
            </Grid>
        </Grid>
    );
}

CertificateFooter.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(footerStyles)(CertificateFooter);
