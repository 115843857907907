import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import { download } from '../../Helpers/downloader';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import ChaiTextField from '../ChaiTextField';
import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBlockchainCheck from './blockchainCheck';
import ChaiProgress from '../ChaiProgress';
import ChaiAccountDetail from './accountDetails';
import ChaiCheck from '../ChaiCheck';
import { register,setFileDownloaded } from './service';
var strongRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/);

const styles = theme => ({
    input: {
        minWidth: 530,
        marginTop: '1em',
        marginLeft: 4,
    },
    passwordInput: {
        minWidth: 510
    },
    passwordInputContainer: {
        marginTop: '1em',
        marginBottom: '1em',
    },
    cardActions: {
        marginBottom: '1em',
        width: '100%'
    },
    smallText: {
        fontSize: '.75em',
        textAlign: 'right',
        margin: '0 25px 20px 0'
    }
})

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        maxWidth: 'unset',
        backgroundColor: '#000',
        color: '#fff',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontWeight: 600,
        minWidth: 300,
        fontSize: '16px',
        lineHeight: '18px',
    },
}))(Tooltip);

class ChaiRegister extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            password: '',
            authenticator: false,
            authenticatorCode: '',
            error: false,
            loading: false,
            agreeCheck: false,
            open: false,
            downloaded: false
        };
    }

    handleChange = (e, field, isCheck) => {
        const state = this.state;
        state[field] = isCheck ? e.target.checked : e.target.value;

        this.setState({ state });
    }

    getField = (field, isCheck) => {
        const value = this.state[field];
        const emailError = field === 'email' && value ? !this.checkValidEmail(value) : false;

        const propsField = {
            value: value,
            onChange: (e) => this.handleChange(e, field, isCheck),
            keyField: field,
            error: emailError,
            disabled: false
        };

        return propsField;
    }

    checkValidEmail = (email) => {
        return (/^[a-zA-Z0-9-._]+@[a-zA-Z0-9]+\.[A-Za-z]+(\.[a-zA-Z]{2,})?$/.test(email));
    }

    handleCheck = name => event => {
        event.stopPropagation();
        this.setState({ [name]: event.target.checked });
    };


    isValid = () => {
        const { showNotification } = this.props;
        const { agreeCheck, password, name, email } = this.state;

        if (!password || !name || !email) {
            showNotification('All fields are required');
            return false;
        }

        if (!this.checkValidEmail(email)) {
            showNotification('Email is invalid');
            return false;
        }

        if (password.length <= 7 || !strongRegex.test(password)) {
            const text = `The password must contain at least 1 Lowercase Alphabetical character \n
            The password must contain at least 1 Uppercase Alphabetical character \n 
            The password must contain at least 1 Numeric character \n
            The password must contain at least 1 Special character \n
            The password must be eight characters or longer`;
            const newText = text.split('\n').map(i => <p>{i}</p>);

            showNotification(newText);
            return false;
        }

        if (!agreeCheck) {
            showNotification(`You haven't checked your understanding over public/private key uses. It's mandatory for you to properly understand blockchain basics use for your Account Information.`);
            return false;
        }

        return true;
    }

    handleRegister = async (event) => {
        const { name, email, password, authenticatorCode } = this.state;
        const values = { name, email, password, authenticatorCode };

        try {
            if (this.isValid()) {
                this.setState({ loading: true });

                const result = await register(values);

                if (result) {
                    this.props.showNotification('User has been created correctly. Check your email account in a few minutes to verify your account');
                    this.setState({ loading: false, open: true, account: result.blockchain });

                    console.info('result.blockchain', result.blockchain);
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            this.props.showNotification(error && error.content ? error.content : 'Please review the information provided and try again');
        }
    }

    downloadFile = (event) => {
        let account = this.state.account;

        if (account.address && account.privateKey) {
            let accountString = `address: ${account.address}\nprivateKey: ${account.privateKey}`;
            download('data:text/plain;charset=utf-8,' + encodeURIComponent(accountString), 'credentials.txt');

            this.setState({ downloaded: true, open: false });
        } else {
            this.props.showNotification('Account Credentials were not created', 'warning');
        }
    }

    handleClose = () => {
        if (this.state.downloaded) {
            this.setState({ open: false });
        } else {
            this.props.showNotification('Remember to download your credentials. Your Private Key will be requested to operate over the web application.');
        }
    }

    handleDownloadFile = async () => {
        const result = await setFileDownloaded(this.state.email); 
        if(result){
            this.downloadFile();
            this.props.handleTab(null, 0);
        }
    };

    renderModal = () => {
        const { open, account } = this.state;
        const { classes } = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth
            >
                <DialogContent>
                    <ChaiAccountDetail account={account} />
                </DialogContent>
                <DialogActions style={{ margin: '25px 25px 0 0' }}>
                    <ChaiHeaderButton style={{ backgroundColor: 'gray', borderColor: 'gray' }} label="Download account information" onClick={this.handleDownloadFile} />
                </DialogActions>
                <React.Fragment><span className={classes.smallText} style={{}}>* in .txt format</span></React.Fragment>
            </Dialog>
        )
    };

    render() {
        const { agreeCheck, loading } = this.state;

        return (
            <div style={{ padding: 40 }}>
                <ChaiRowBodyContainer>
                    <ChaiTextField label="NAME" {...this.getField('name')} style={{ width: '100%' }} />
                </ChaiRowBodyContainer>
                <ChaiRowBodyContainer>
                    <ChaiTextField label="EMAIL" {...this.getField('email')} type={'email'} style={{ width: '100%' }} />
                </ChaiRowBodyContainer>
                <ChaiRowBodyContainer>
                    <ChaiTextField label="PASSWORD"  {...this.getField('password')} type={'password'} style={{ width: '95%' }} />
                    <HtmlTooltip
                        placement="right-end"
                        title={
                            <React.Fragment>
                                The password must contain at least 1 Lowercase Alphabetical character<br />
                                The password must contain at least 1 uppercase Alphabetical character <br />
                                The password must contain at least 1 numeric character<br />
                                The password must contain at least 1 special character<br />
                                The password must be eight characters or longer
                        </React.Fragment>
                        }
                    >
                        <HelpIcon />
                    </HtmlTooltip>
                </ChaiRowBodyContainer>
                <ChaiRowBodyContainer>
                    <ChaiCheck label="I am registering as an Authenticator" {...this.getField('authenticator', true)} />
                    {this.state.authenticator ?
                        <ChaiTextField label="Authenticator Code" {...this.getField('authenticatorCode')} style={{ width: '40%' }} />
                        : <div style={{ width: '40%' }}></div>
                    }
                </ChaiRowBodyContainer>
                <ChaiRowBodyContainer>
                    {loading ?
                        <ChaiProgress /> :
                        <ChaiHeaderButton label="REGISTER" type='submit' style={{ marginLeft: 'auto', marginRight: 'auto' }} onClick={this.handleRegister} />}
                </ChaiRowBodyContainer>
                <ChaiRowBodyContainer>
                    <ChaiBlockchainCheck handleCheck={this.handleCheck} agreeCheck={agreeCheck} />
                </ChaiRowBodyContainer>
                {this.renderModal()}
            </div >
        )
    }
}

ChaiRegister.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChaiRegister);
