import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography
} from '@material-ui/core';

const isTablet = window.innerWidth <= 1024;
const styles = theme => ({
    headerContainer: {
        height: '3.3em',
        borderBottom: 'solid 1px #3e3e3e',
    },
    headerText: {
        fontSize: isTablet ? '1.3em' : '1.6em',
        color: 'rgb(99, 34, 34)'
    },
    left: {
        textAlign: 'end',
    },
});

function ChaiBodyContainerHeader(props) {
    const { classes, hideFooter, sectionName } = props;
    const [collapse, setCollapse] = React.useState(false)

    return (
        <>
            {
                !hideFooter &&
                <Grid
                    id={sectionName}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.headerContainer}
                    onClick={() => setCollapse(!collapse)}
                >
                    <Typography className={classes.headerText}>
                        {sectionName}
                    </Typography>
                    <Grid className={classes.left}>
                        <div>
                            <b style={{ marginRight: "17px" }}>*</b>Mandatory fields
                        </div>
                        <div>
                            <i
                                class="demo-icon icon-icon-isotype"
                                style={{ color: "#63222" }}
                                title="This field will be included in the ledger certificate">
                            </i>
                            The information with this symbol will be shown in the certificate
                    </div>
                    </Grid>
                </Grid>
            }
            {!collapse && props.children}
        </>
    );
}

ChaiBodyContainerHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    steps: PropTypes.array.isRequired,
    sectionName: PropTypes.number.isRequired,
};

export default withStyles(styles)(ChaiBodyContainerHeader);
