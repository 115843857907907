import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChaiProgress from '../ChaiProgress';
import ChaiHeaderButton from '../ChaiHeaderButton';

const styles = theme => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: '300px'
    },
    cssLabel: {
        '&$cssFocused': {
            color: '#1e559e',
        },
    },
    cssFocused: {},
    cssUnderline: {
        '&:after': {
            borderBottomColor: '#1e559e',
        },
    },
});

const ChaiTokenDialog = (props) => {
    const { open, handleClose, handleChange, handleSave, token, field, saving, title, subtitle, classes } = props;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{title.toUpperCase()}</DialogTitle>
            <DialogContent>
                <div className={classes.content}>
                    <FormControl className={classes.margin}>
                        <InputLabel
                            FormLabelClasses={{
                                root: classes.cssLabel,
                                focused: classes.cssFocused,
                            }}
                            htmlFor="custom-css-input"
                            type="search">{subtitle}</InputLabel>
                        <Input
                            classes={{
                                underline: classes.cssUnderline,
                            }}
                            id="custom-css-input"
                            onChange={(e) => handleChange(token, field, e)}
                            value={token[field]}
                        />
                    </FormControl>
                </div>
            </DialogContent>
            {
                saving ?
                    <ChaiProgress /> :
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 40, paddingLeft: 40 }}>
                        <ChaiHeaderButton label="Cancel" onClick={handleClose} />
                        <ChaiHeaderButton label="Save" onClick={() => handleSave(token, field)} />
                    </DialogActions>
            }
        </Dialog >
    );
}

ChaiTokenDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiTokenDialog);