import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ChaiNotification from '../ChaiNotification';

import AppBar from '@material-ui/core/AppBar';
import ChaiVaultLogo from '../../Assets/ChaiVaultLogo.png';
const isTablet = window.innerWidth <= 1024;

const styles = theme => ({
    tabsRoot: {
        backgroundColor: '#efefef'
    },
    tabsIndicator: {
        height: 0
    },
    root: { width: isTablet ? '60%' : '40%', marginLeft: 'auto', marginRight: 'auto', marginTop: '3%', marginBottom: '3%', backgroundColor: '#efefef', boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.49)' },
    tab: {
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '1.2em',
        fontWeight: 600
    },
    tabRoot: {
        backgroundColor: '#3f3e3e',
        color: 'white',
        '&$tabSelected': {
            color: '#3f3e3e',
            backgroundColor: '#efefef'
        }
    },
    tabSelected: {},
    rootCheck: {
        color: '#6D0006',
        '&$checked': {
            color: '#6D0006',
        },
    },
    checked: {},
    tabContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }
});

const specialTheme = createMuiTheme({
    palette: {
        secondary: {
            main: '#632222',
        },
    }
});

const authStyles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    }
})

const AuthLayout = (props) => {
    const { children } = props;

    return (
        <div style={authStyles.main}>
            <div style={{ display: 'flex', justifyContent: 'center', height: '50px' }}>
                <img src={ChaiVaultLogo} style={{ height: '100%' }} alt={'Chai Vault Logo'} />
            </div>
            {children}
        </div>
    )
};

class ChaiForgotLayout extends React.Component {
    render() {
        const { classes, message, open,children } = this.props;

        return (
            <MuiThemeProvider theme={specialTheme}>
                <AuthLayout>
                    <Card className={classes.root} elevation={0} style={{ borderRadius: 0 }} >
                        <AppBar position='static' style={{ boxShadow: 'none' }}>
                            
                        </AppBar>
                        {children}
                    </Card>
                    <ChaiNotification message={message} open={open} />
                </AuthLayout >
            </MuiThemeProvider>
        );
    }
}

ChaiForgotLayout.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChaiForgotLayout);
