const logger = require("../helpers/logger.helper").default("BottleInfoUniqueProcessor")
const isNumber = require("../helpers/number.helper").default.isNumber;

const prepareForBlockchain = (content, ledger, userAddr) => {
    /**
     * From solidity contract:
     *
        uint ledgerNumber,
        uint ledgerVersion,
        uint typeOfInput,
        uint vintage,
        uint bottleSize,
        string memory bottleByAt,
        uint labelConditionForAge,
        string memory notableNicksTearsConditions,
        uint capsuleCondition,
        string memory partGroupCaseOrLotParcel,
        string memory fullImageBodyHash
     * */

    return {
        ledgerNumber: ledger.ledgerNumber,
        ledgerVersion: ledger.ledgerVersion,
        typeOfInput: content.typeOfInput || 0,
        vintage: content.vintage || 0,
        bottleSize:content.bottleSize || 0,
        //TODO change when contract is changed
        bottleByAt: content.bottleByAt.toString() || 'n/a',
        labelConditionForAge: content.labelConditionForAge || 0,
        notableNicksTearsConditions: content.notableNicksTearsConditions || 'n/a',
        capsuleCondition: content.capsuleCondition || 0,
        partGroupCaseOrLotParcel: content.partGroupCaseOrLotParcel || 'n/a',
        fullImageBodyHash: content.fullImageBodyHash || 'n/a'
    };
};

const isValidContent = (ledger, content) => {
    if(!content){
        logger.error(`isValidContent Empty content`);
        return false;
    }

    if(!isNumber(ledger.ledgerNumber) || !isNumber(ledger.ledgerVersion)){
        logger.error(`isValidContent Invalid ledger Data`);
        return false;
    }
    return true;
}

export default {
    isValidContent: isValidContent,
    prepareForBlockchain: prepareForBlockchain
}