import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import UploadIcon from '@material-ui/icons/FileUpload';
import './index.css';
import { getImage } from "../../DataProviders/crudgeneric";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import ChaiModalHeader from '../ChaiModal/ChaiModalHeader'
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import VisibilityIcon from '@material-ui/icons/Visibility'
import { KeyboardHide } from '@material-ui/icons';

const isTablet = window.innerWidth <= 1024;
const styles = theme => ({
    container: {
        display: 'inline-block',
        position: 'relative',
        verticalAlign: 'middle'
    },
    containerUploaded: {
        display: 'inline-block',
        marginLeft: '0.8em',
        marginRight: '0.1em',
        position: 'relative',
        verticalAlign: 'middle'
    },
    externalContainer: {
        display: 'contents',
    },
    label: {
        fontSize: isTablet ? '0.80rem' : '1rem',
        fontFamily: "'Source Sans Pro', sans-serif",
        fontWeight: 600,
        color: '#3f3e3e',
        textTransform: 'uppercase'
    },
    baseImageContainer: {
        zIndex: 10,
        backgroundColor: 'white',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "auto"
    },
    imageContainer: {
        overflow:'hidden'
    },
    noImageContainer: {
        textAlign: 'center',
        padding: 10,
        fontFamily: "'Source Sans Pro', sans-serif",
        fontSize: '1em',
        color: '#3e3e3e',
        right: 0
    },
    image: {
        minWidth:"100%",
        width: "100%",
    },
    iconButton:{
        display: 'inline-block'
    },
    icon :{
        fontSize:"40px",
        padding:"3px",
    }
});

const ChaiImageInput = (props) => {
    const {
        classes,
        disabled,
        field,
        fileName,
        fileUrl,
        fullScreen,
        hideFooter,
        index,
        inputStyle,
        label,
        onChange,
        style,
        viewMode
    } = props;

    const backgroundColor = (disabled ? { backgroundColor: "grey" } : {});

    const [localURL, setLocalURL] = React.useState(null);
    const [showImage, setShowImage] = React.useState(false);

    React.useEffect(() => {
        if (fileUrl != null) {
            getImage(fileUrl)
                .then(result => setLocalURL(result.imageUrl));
        }
    }, [props.fileUrl]);

    const openModal = () => {
        setShowImage(true) 
     };
 
    const closeModal = () => {
        setShowImage(false)
    };

     const renderModal = (classes) => {
        let imagePart = null;
        if (fileUrl && showImage && localURL) {
            imagePart = <img src={localURL} className={classes.image} alt="" />
        } else if (fileUrl && showImage) {
            imagePart = <CircularProgress />
        } else if (showImage && !disabled) {
            imagePart = <label>{'No image available'}</label>
        } else {
            imagePart = null;
        }
        return (
            <Dialog
                open={showImage}
                fullScreen={fullScreen}
                aria-labelledby="form-dialog-title"
                maxWidth={"md"}
                onClose={closeModal}
                fullWidth
            >
                <ChaiModalHeader onClose={closeModal}></ChaiModalHeader>
                <DialogContent fullWidth>
                    <div className={classnames(classes.baseImageContainer,
                        showImage && !disabled ? classes.noImageContainer : classes.imageContainer)}>
                        {imagePart}
                    </div>
                </DialogContent>
            </Dialog>
        )
     };
    const internalContainerStyle = inputStyle || {};
    const id = `label_${field}_${index}`;
    return (
            <div className={classes.externalContainer} style={style}>
            {(!viewMode) && <div style={{...internalContainerStyle}} className={(fileUrl && localURL) ? classes.containerUploaded : classes.container}
                >
                    {label ? <label className={classes.label}>{label}</label> : null}
                    <input
                        type="file"
                        name="file"
                        id={id}
                        className="inputfile"
                        disabled={disabled}
                        onChange={(e) => onChange(e, field)}
                    />
                    <label htmlFor={id} style={
                        {
                            ...backgroundColor,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: isTablet ? 'center' : 'space-evenly',
                            width: 'auto',
                            fontSize: '1em',
                            padding: 15,
                            margin: !hideFooter
                                ? label
                                    ? '3px 0px 4px 0'
                                    : '16px 0px 0px 0px'
                                : '0'
                        }
                    }>
                        <UploadIcon style={{ fontSize: isTablet ? '1.5em' : '1.2em' }} />
                        <div >{isTablet ? '' : fileName ? fileName : 'Choose a file'}</div>
                    </label>
                    
                </div>}
                {
                    (fileUrl && localURL) &&
                    <div className={classes.iconButton}>
                        <IconButton onClick={openModal}>
                            <VisibilityIcon className={classes.icon}/>
                        </IconButton>
                    </div>
                }
                {renderModal(classes)}
            </div>
    );
};

ChaiImageInput.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog({breakpoint: 'md'})(withStyles(styles)(ChaiImageInput));
