const provenanceTypesOptions = [
    { id: 1, description: 'Retail Sale' },
    { id: 2, description: 'Auction Sale' },
    { id: 3, description: 'Private Sale' },
    { id: 4, description: 'Brokered Sale' },
    { id: 5, description: 'Retail Consignment' },
    { id: 6, description: 'Live Auction' },
    { id: 7, description: 'Online Auction' },
    { id: 8, description: 'Charity Auction' },
    { id: 9, description: 'Bottle Owner' },
    { id: 10, description: 'Direct Sale' }
];

const currencyTypesOptions = [
    { id: 1, description: 'USD' },
    { id: 2, description: 'EUR' },
    { id: 3, description: 'GBP' }
];

const specialDatesOptions = [
    { id: 1, description: 'On Release' },
    { id: 2, description: 'Pre-Arrival' },
    { id: 3, description: 'Futures' },
    { id: 4, description: 'Pre-1995' },
    { id: 5, description: 'Pre-2010' }
];

module.exports = {
   currencyTypesOptions,
   provenanceTypesOptions,
   specialDatesOptions
};