const { dpCrud, UPDATE } = require('../../DataProviders/crudgeneric');
const sha512 = require('js-sha512');
export const ledgerVisibilityOptions = [
    {id: 0, description: 'Private' },
    {id: 1, description: 'Public' }
];

/**
 *
 * @param data
 * @param capsuleBatch
 * @returns {Promise<Response>}
 */
export async function updateUserData(userData) {
    try {
        const data = await dpCrud(UPDATE, "users", {data: prepareToSend(userData)});
        return data;
    } catch (ex) {
        console.error("Error updating the profile>> ", ex);
        throw ex;
    }
}

function prepareToSend(rawData) {
    rawData.password =  sha512(rawData.password);
    rawData.newPassword = rawData.newPassword.length > 0 ? sha512(rawData.newPassword) : "";
    rawData.ledgersVisibilityPublic = rawData.ledgersVisibilityPublic === 1;
    return rawData;
}