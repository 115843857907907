import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ChaiTableToolbar from './chaiTableToolbar';
import ChaiTableHead from './chaiTableHead';
import ChaiHeaderButton from '../ChaiHeaderButton';
import { Link } from 'react-router-dom';
import ChaiCheck from '../ChaiCheck';
import ChaiTextField from '../ChaiTextField';
import LinearProgress from "@material-ui/core/LinearProgress";
import { lighten } from "@material-ui/core/styles/colorManipulator";

const styles = theme => ({
    root: {
        width: '100%',
        boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.49)'
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    rootRow: {
        "&$hover:hover": {
            backgroundColor: '#efefefcf',
        }
    },
    hover: {},
    cell: {
        fontFamily: '"Source Sans Code", sans-serif',
        fontSize: '1em',
        marginLeft: "20px",
        marginRight: "20px",
        color: '#3f3e3e', padding: '0px'
    },
    sizeIcon: {
        fontSize: 25,
        color: '#dce0e496',
        padding: 1
    },
    sizeIconActive: {
        fontSize: 25,
        color: '#6D0006',
        padding: 1
    },
    check: {
        color: '#6D0006',
        '&$checked': {
            color: '#6D0006',
        },
        padding: "0px",
        backgroundColor: "rgba(198, 198, 212, 0.22)",
        borderRadius: "0px",
        marginLeft: "1.5em",
        height: '0.7em',
        width: "0.7em"
    },
    checked: {}
});

const BorderLinearProgress = withStyles({
    root: {
        height: 20,
        width: '100%',
        borderRadius: 20,
        backgroundColor: lighten("#dcdcdc", 0.5)
    },
    bar: {
        borderRadius: 20,
        backgroundColor: "#dcdcdc"
    }
})(LinearProgress);


const ChaiLedgersTable = (props) => {

    const { columns, classes, title, onClick, enableCheckBox, enableTextBoxFilter,
        handleChangeTextFilter, textFilter, handleChangeRowsPerPage, loading,
        rowsPerPage, page, order, orderBy, handleRequestSort, handleChangePage, totalCount,
        handleCheck, checkAllOnPage, selected, data } = props;

    const rowsPerPageOptions = [5, 10, 25, 50, 100, 200, 500];
    const emptyRows = data.length < 5 ? 5 - data.length : 0;
    const checkAllInitialValue = data.filter(led => led.checked).length === data.length;

    function getLink(link, label) {
        if (link) {
            return (
                <Link style={{ textDecoration: "none" }} to={link} >
                    <ChaiHeaderButton label={label}
                        style={{ padding: "5px 10px 5px 10px", borderRadius: "5px", fontSize: "medium" }}>
                    </ChaiHeaderButton>
                </Link>
            );
        }

        return <span style={{ color: "#a2a2a2" }}>Not Available</span>;
    }

    function isSelected(id) {
        return selected.indexOf(id) !== -1;
    }

    return (
        <Paper className={classes.root} elevation={0} >
            {title ? <ChaiTableToolbar title={title} /> : null}
            {
                enableTextBoxFilter &&
                <ChaiTextField
                    style={{ width: '100%', backgroundColor: "#efefef", paddingTop: 0 }}
                    placeholder={'Type any Wine Name...'}
                    onChange={(e) => handleChangeTextFilter(e)}
                    value={textFilter}
                    containerProperties={{
                        padding: 0,
                        height: '100%'
                    }}
                />
            }
            <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                    <ChaiTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                        columns={columns}
                        checkAllOnPage={checkAllOnPage}
                        enableCheckBox={enableCheckBox}
                        checkAllInitialValue={checkAllInitialValue}
                    />
                    <TableBody>
                        {
                            !loading ? data.map((n, i) => {
                                return (
                                    <TableRow
                                        key={i}
                                        hover
                                        role="checkbox"
                                        aria-checked={isSelected}
                                        tabIndex={-1}
                                        selected={isSelected(n.id)}
                                        classes={{ root: classes.rootRow, hover: classes.hover }}
                                    >
                                        {
                                            enableCheckBox && (
                                                <TableCell className={classes.cell} >
                                                    <ChaiCheck
                                                        onChange={(ev) => handleCheck(ev, n)}
                                                        checked={n.checked}
                                                        className={classes.check}
                                                        iconColor={n.checked ? classes.sizeIconActive : classes.sizeIcon}
                                                    />
                                                </TableCell>)
                                        }
                                        {
                                            columns.map((value, i) => {
                                                const key = `${value.id}-${i}`;
                                                if (value.link) {
                                                    return (
                                                        <TableCell
                                                            className={classes.cell}
                                                            key={key}
                                                        >
                                                            {getLink(n[value.id], value.label)}
                                                        </TableCell>
                                                    );
                                                } else {
                                                    return (
                                                        <TableCell
                                                            className={classes.cell}
                                                            key={key}
                                                            onClick={e => onClick
                                                                ? onClick(n)
                                                                : e.preventDefault()}
                                                        >{n[value.id]}</TableCell>
                                                    );
                                                }

                                            })
                                        }
                                    </TableRow>
                                );
                            })
                                :
                                null
                        }
                        {
                            emptyRows > 0 && loading === false && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )
                        }
                        {
                            loading && Array(5).fill(1).map((v, i) => (
                                <TableRow key={`${v}-${i}`}>
                                    {
                                        columns.map((column, i) => (
                                            <TableCell
                                                className={classes.cell}
                                                key={`${column.id}-${i}`}
                                            >
                                                <BorderLinearProgress />
                                            </TableCell>
                                        ))
                                    }
                                    {enableCheckBox && (<TableCell className={classes.cell} >
                                        <BorderLinearProgress />
                                    </TableCell>)}
                                </TableRow>)
                            )

                        }
                    </TableBody>
                </Table>
            </div>
            {!loading &&
                <TablePagination
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    rowsPerPageOptions={rowsPerPageOptions}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />}
        </Paper>
    );
};

ChaiLedgersTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiLedgersTable);
