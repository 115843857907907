const { dpCrud,  CREATE, GET_ONE } = require('../../DataProviders/crudgeneric');
/**
 *
 * @param ledgerNumber
 * @param ledgerVersion
 * @returns {Promise<Response>}
 */
export async function getMeasurements(ledgerNumber, ledgerVersion) {
    try {
        const data = await dpCrud(GET_ONE, "measurements", {
            ledger: {
                ledgerNumber: ledgerNumber,
                ledgerVersion: ledgerVersion
            }
        });
        return data.data;
    } catch (ex) {
        console.error("Error getting bottle>> ", ex);
    }
    return null;
}

/**
 *
 * @param data
 * @returns {Promise<AxiosPromise<any>>}
 */
export async function saveMeasurements(ledgerNumber, ledgerVersion, data) {
    const response = await dpCrud(CREATE, "measurements", {
        data: {...data, ledgerNumber, ledgerVersion }
    });
    console.info("saving response >> ", response);
    return response;
}

