const BASE_URL = process.env.REACT_APP_CHAI_SECURITY_API_HOST || 'gateway/';

/**
 *
 * @returns {Promise<AxiosPromise<any>>} Permissions by Role
 */
export async function getMenuPermissions(id, type) {
    const token = localStorage.getItem('token');
    const url = `${BASE_URL}menu-permissions`;
    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
        method: 'GET'
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (response.status !== 200) {
            throw result;
        }
        return result;
    } catch (error) {
        console.error('ERROR getMenuPermissions', error);
        throw error;
    }
}
/**
 *
 * @param id 
 * @param type
 * @returns {Promise<AxiosPromise<any>>} A Ledger matched with the rfid
 */
export async function getLedgerByNumericReference(id, type) {
    const token = localStorage.getItem('token');
    const url = `${BASE_URL}ledgers/numericIdReferences/${type}/${id}`;
    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
        method: 'GET'
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (response.status !== 200) {
            throw result;
        }
        return result;
    } catch (error) {
        console.error('ERROR getLedgerByRfid()', error);
        throw error;
    }
}
/**
 *
 * @param id 
 * @returns {Promise<AxiosPromise<any>>} A Ledger matched with the rfid
 */
export async function getLedgerByRfid(id) {
    return getLedgerByNumericReference(id, "RFID")
}
/**
 *
 * @param id 
 * @returns {Promise<AxiosPromise<any>>} A Ledger matched with the rfid
 */
export async function getLedgerByBarcode(id) {
    return getLedgerByNumericReference(id, "BAR_CODE")
}
