const { dpCrud,  CREATE, GET_ONE } = require('../../DataProviders/crudgeneric');

/**
 *
 * @param ledgerNumber
 * @param ledgerVersion
 * @returns {Promise<Response>}
 */
export async function getAdministrative(ledgerNumber, ledgerVersion) {
    try {
        const data = await dpCrud(GET_ONE, 'administrative', {
            ledger: {
                ledgerNumber: ledgerNumber,
                ledgerVersion: ledgerVersion
            }
        });
        return data.data ? data.data: null ;
    } catch (ex) {
        console.error('ERROR getAntifraud', ex);
        return null;
    }

}

/**
 *
 * @param data
 * @returns {Promise<AxiosPromise<any>>}
 */
export async function saveAdministrative(ledgerNumber, ledgerVersion, data) {
    const response = await dpCrud(CREATE, 'administrative',
        {
            data: {...data, ledgerNumber, ledgerVersion }
        }
    );
    return response;
}

