const { dpCrud,  CREATE, GET_ONE } = require('../../DataProviders/crudgeneric');

/**
 *
 * @param ledgerNumber
 * @param ledgerVersion
 * @returns {Promise<Response>}
 */
export async function getOtherLabel(ledgerNumber, ledgerVersion) {
    try {
        const data = await dpCrud(GET_ONE, "labels", {
            ledger: {
                ledgerNumber: ledgerNumber,
                ledgerVersion: ledgerVersion
            }
        });
        return data.data ? prepareToUI(data.data): null ;
    } catch (ex) {
        console.error("Error getting bottle>> ", ex);
    }
    return null;
}

/**
 *
 * @param data
 * @returns {Promise<AxiosPromise<any>>}
 */
export async function saveOtherLabel(ledgerNumber, ledgerVersion, data) {
    const response = await dpCrud(CREATE, "labels/other",
        {
            data: {...prepareToSend(data), ledgerNumber, ledgerVersion }
        }
    );
    console.info("saving response >> ", response);
    return response;
}

function prepareToUI(rawData) {
    return {
        producerBackLabel : rawData.producerBackLabel,
        healthWarningLabel : rawData.healthWarningLabel,
        vintageNeckLabel : rawData.vintageNeckLabel,

        importLabel : rawData.importLabel,
        importLabelDescription : rawData.importLabelDescription,

        otherBackLabel : rawData.otherBackLabel,
        otherBackLabelDescription : rawData.otherBackLabelDescription,

        otherNeckLabel : rawData.otherNeckLabel,
        otherNeckLabelDescription : rawData.otherNeckLabelDescription,

        otherLabel1 : rawData.otherLabel1,
        otherLabel1Description : rawData.otherLabel1Description,

        otherLabel2 : rawData.otherLabel2,
        otherLabel2Description : rawData.otherLabel2Description,

        retailMerchantOrAuction1 : rawData.retailMerchantOrAuction1,
        retailMerchantOrAuction1Description : rawData.retailMerchantOrAuction1Description,

        retailMerchantOrAuction2 : rawData.retailMerchantOrAuction2,
        retailMerchantOrAuction2Description : rawData.retailMerchantOrAuction2Description
    };
}

export function prepareToSend(values) {
    console.log(values);
    return {
        ...values,

        producerBackLabel : values.producerBackLabel,
        healthWarningLabel : values.healthWarningLabel,
        vintageNeckLabel : values.vintageNeckLabel,

        importLabel : values.importLabel,
        importLabelDescription : values.importLabelDescription,

        otherBackLabel : values.otherBackLabel,
        otherBackLabelDescription : values.otherBackLabelDescription,

        otherNeckLabel : values.otherNeckLabel,
        otherNeckLabelDescription : values.otherNeckLabelDescription,

        otherLabel1 : values.otherLabel1,
        otherLabel1Description : values.otherLabel1Description,

        otherLabel2 : values.otherLabel2,
        otherLabel2Description : values.otherLabel2Description,

        retailMerchantOrAuction1 : values.retailMerchantOrAuction1,
        retailMerchantOrAuction1Description : values.retailMerchantOrAuction1Description,

        retailMerchantOrAuction2 : values.retailMerchantOrAuction2,
        retailMerchantOrAuction2Description : values.retailMerchantOrAuction2Description
    }
}
