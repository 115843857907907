import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ComingSoonBackground from '../../Assets/ComingSoonBg.jpg'
import ChaiLogo from '../../Assets/ChaiLogo.png'
import { Grid } from '@material-ui/core';

const styles = theme => ({
    imageContainer: {
        backgroundImage: `url(${ComingSoonBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        backgroundSize: "100%",
        minHeight:"50rem",
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center'
        } 
    },
    heading:{
        color:"white",
        textAlign: "center"
    }
});
const ChaiCountdown = (props) => {
    console.log("countdown")
    return (
        <div 
        style={{heght:"100%"}}>
        <Grid
        container
        direction="row"
        justify="center"
        alignItems="stretch"
        className={props.classes.imageContainer}
        >
        
        
        <Grid
            item
            container
            direction="column"
            justify="left"
            alignItems="center"
            md={8}
            xs={12}
            
        >
            <img src={ChaiLogo} alt="ChaiVault" />
            <h2 className={props.classes.heading}>The Chai Vault Certificate of Authentication and Provenance you are looking for could not be found</h2>
            <h4 className={props.classes.heading}>If you think you are getting this message by mistake, please contact us at info@chaivault.com</h4>

            
        </Grid>
        </Grid>
        </div>
    );
}
ChaiCountdown.propTypes = {

};
export default withStyles(styles)(ChaiCountdown);
