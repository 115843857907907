import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core';
import ChaiHeaderButton from "../ChaiHeaderButton";

const isTablet = window.innerWidth <= 1024;
const styles = theme => ({
    main: {
        display: 'flex',
        backgroundColor: '#dee0df',
        borderTop: '1px solid #b9b9b9',
        width: 'auto',
    },
    mainFixed: {
        display: 'flex',
        backgroundColor: '#dee0df',
        borderTop: '1px solid #b9b9b9',
        width: 'auto',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        height: '80px'
    },
    fixedBaseMessage: {
        width: '80%',
        padding: '15px 30px',
        lineHeight: '30px',
        fontSize: isTablet ? '0.9em' : '1.1em',
        fontFamily: '"Source Sans Pro", sans-serif',
        borderRight: '1px solid #b9b9b9'
    },
    baseMessage: {
        width: '80%',
        padding: isTablet ? '15px 30px' : '30px 60px 30px 30px',
        lineHeight: '50px',
        fontSize: isTablet ? '0.9em' : '1.1em',
        fontFamily: '"Source Sans Pro", sans-serif',
        borderRight: '1px solid #b9b9b9'
    },
    baseMessageExtra: {
        width: '60%',
        padding: isTablet ? '15px 30px' : '30px 60px 30px 30px',
        lineHeight: '50px',
        fontSize: isTablet ? '0.9em' : '1.1em',
        fontFamily: '"Source Sans Pro", sans-serif',
        borderRight: '1px solid #b9b9b9'
    },
    message: { color: '#3f3e3e' },
    messageError: { color: '#ca4646' },
    buttonContainer: { width: '20%', textAlign: 'center', padding: '30px', margin: "auto" },
    buttonExtraContainer: {
        padding: '30px',
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        margin: "auto"
    },
    fixedButtonContainer: { width: '20%', textAlign: 'center', margin: "auto" },
    fixedAndExtra: {
        width: '40%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        paddingRight: 5,
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingLeft: 20,
    }
});

const ChaiFooterContainer = (props) => {
    const {
        classes,
        message,
        error,
        buttonLabel,
        onClick,
        loading,
        extraButton,
        buttonStyle,
        showInfo = true,
        fixed = false
    } = props;

    const mainClass = fixed ? classes.mainFixed : classes.main;
    const buttonClass = fixed && extraButton ? classes.fixedAndExtra :
        fixed ? classes.fixedButtonContainer :
            extraButton ? classes.buttonExtraContainer :
                classes.buttonContainer

    return (
        <div className={mainClass}>
            <div className={error ?
                classnames(classes.baseMessage, classes.messageError) : fixed ?
                    classnames(classes.fixedBaseMessage, classes.message) : extraButton ?
                        classnames(classes.baseMessageExtra, classes.message) :
                        classnames(classes.baseMessage, classes.message)}>
                {showInfo ?
                    <div>
                        <div style={{ width: "100%", margin: "-0.8rem" }}>
                            <i
                                class="demo-icon icon-icon-isotype"
                                style={{ color: "#63222" }}
                                title="This field will be included in the ledger certificate">
                            </i>
                            The information with this symbol will be shown in the certificate
                        </div>
                        <div style={{ width: "100%", margin: "-0.8rem" }}>
                            <b style={{ marginRight: "17px" }}>*</b>
                            Mandatory fields
                        </div>
                    </div> : null
                }
                <div style={{ margin: " 0.7rem" }}>{message}</div>

            </div>
            <div className={buttonClass}>
                <ChaiHeaderButton
                    style={buttonStyle}
                    label={buttonLabel}
                    onClick={onClick}
                    disabled={error || loading}
                />
                {
                    extraButton ?
                        <Fragment>
                            <div style={{ width: 10 }}></div>
                            {extraButton}
                        </Fragment> :
                        null
                }
            </div>
        </div>
    );
};

ChaiFooterContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiFooterContainer);
