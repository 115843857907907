const printMethodOptions = [
    { id: 1, description: 'Ink Jet' },
    { id: 2, description: 'Laser Jet' },
    { id: 3, description: 'Dot Matrix' },
    { id: 4, description: 'Stamped' },
    { id: 5, description: 'Other' }
];

module.exports = {
    printMethodOptions
};
