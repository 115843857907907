const ethers = require("ethers");
const config = require('../providers/config.provider').default.getConfig();
const contractsProvider = require('../providers/contracts.provider').default;
const BadConfigurationError = require('../exceptions/badConfig.exception').default;

const Web3 = require('web3');
const blockchainURL = process.env.REACT_APP_BLOCKCHAIN;
const web3 = new Web3(new Web3.providers.HttpProvider(blockchainURL));

class BlockchainHelper {

    constructor(_ethers, _options, _contractsProvider){
        _options = _options || {};
        if(!_options.blockchainUrl){
            throw new BadConfigurationError("No Blockchain URL specified")
        }

        if(!_options.mainAccountAddress){
            throw new BadConfigurationError("No Main account specified")
        }

        if(!_options.contracts){
            throw new BadConfigurationError("No Contract Config specified")
        }

        this.contractConfigs = _options.contracts || [];
        this.mainAccountAddress = _options.mainAccountAddress;
        this.blockchainUrl = _options.blockchainUrl;
        this.contractProvider = _contractsProvider;
        this.rpcProvider = new _ethers.providers.JsonRpcProvider(this.blockchainUrl);
        this.ethers = _ethers;
    }

    getContract(contractKey){
        return this.contractProvider.getContract(contractKey, this.mainAccountAddress, this.rpcProvider, this.ethers);
    }

    getProvider(){
        return this.rpcProvider;
    }

    getMainAccount(){
        return this.mainAccountAddress;
    }

    async createNewAccount() {
        try {
            let newAccount = await web3.eth.accounts.create();
            return newAccount;
        } catch (err) {
            throw err;
        }
    }
}

export default (_ethers = ethers, _options = config, _contractsProvider = contractsProvider) => new BlockchainHelper(_ethers, _options, _contractsProvider);
