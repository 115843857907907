const logger = require('../helpers/logger.helper').default('LedgerProvenanceAuctionAndPrivateService');
const BaseService = require("./base.service").default;
const config = require('../providers/config.provider').default.getConfig();
const blockchainHelper = require('../helpers/blockchain.helper').default;
const processor = require("../contentProcessors/provenance.processor").default.auctionAndPrivate;
const ContentValidationError = require("../exceptions/contentValidation.exception").default;
const ContractMethodError = require("../exceptions/contractMethod.exception").default;
const provideGas = require("../providers/wallet.provider").default.provideGas;

class LedgerProvenanceAuctionAndPrivateService extends BaseService {
    async create(content, ledger, wallet) {
        if (processor.isValidContent(ledger, content)) {
            try {
                const overrides = {
                    gasLimit: 3000000
                };
                const gasProvided = await provideGas(wallet);
console.log('gas', gasProvided)
                if (gasProvided) {
                    const createData = processor.prepareForBlockchain(content, ledger, wallet.address);
console.log('prepareForBlockchain', createData);
                    let contractWithSigner = this.contract.connect(wallet);
console.log('contractWithSigner', contractWithSigner);
                    const receiptTx = await contractWithSigner.createProvenance(...Object.values(createData), overrides);
console.log('receiptTx', receiptTx);
                    logger.info('PROVENANCE AUCTION AND PRIVATE CONTRACT', createData);

                    const receipt = await receiptTx.wait();
                    await logger.infoExternal('createProvenance', receipt, 'send', ledger);

                    //const readData = await this.get(ledger, createData.dateOfSale);
                    //logger.info('PROVENANCE AUCTION AND PRIVATE CONTRACT READ', readData);

                    return receipt;
                }
            } catch (ex) {
                await logger.errorExternal('createProvenance', ex.message, 'send', ledger);
                console.log('createProvenance', ex.message, JSON.stringify(ex));
                throw new ContractMethodError('Create Auction and Private Provenance Data', ex);
            }
        } else {
            throw new ContentValidationError('Ledger Provenance Auction And Private Data is invalid');
        }

    }

    async get(ledger, dateOfSale, userAddress) {
        try {
            const response = await this.contract.getProvenance(ledger.ledgerNumber, dateOfSale);
            await logger.infoExternal('getProvenance', response, 'call', ledger);
            return response;
        } catch (ex) {
            await logger.errorExternal('getProvenance', ex.message, 'call', ledger);
            throw new ContractMethodError('Get Auction and Private Provenance Data', ex);
        }
    }
}

//Mapped contract key
export default (_config = config, _blockchainHelper = blockchainHelper()) => new LedgerProvenanceAuctionAndPrivateService('ChaiProvenanceAuctionAndPrivate', _config, _blockchainHelper);
