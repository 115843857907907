import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core';
import { Route } from 'react-router-dom';

import ChaiAuthorization from '../ChaiAuthorization';
import ChaiForgot from '../ChaiAuthorization/forgot';
import ChaiPublicLedger from "../ChaiPublicLedger";
import ChaiRecover from '../ChaiAuthorization/recover';

const styles = themes => ({});

const ChaiPublicRoutes = props => {
    const { redirectAfterLogin, setRedirectAfterLogin } = props;

    return (
        <Fragment>
            <Route exact path='/ledger/:id'
                render={routeProps => (
                    <Fragment>
                        <ChaiPublicLedger
                            {...props}
                            ledgerId={routeProps.match.params.id}
                            redirectAfterLogin={redirectAfterLogin}
                            setRedirectAfterLogin={setRedirectAfterLogin}
                        />
                    </Fragment>
                )}
            />
            <Route exact path='/pl/:qrCode'
                render={routeProps => (
                    <Fragment>
                        <ChaiPublicLedger
                            {...props}
                            qrCode={routeProps.match.params.qrCode}
                            redirectAfterLogin={redirectAfterLogin}
                            setRedirectAfterLogin={setRedirectAfterLogin}
                        />
                    </Fragment>
                )}
            />
            <Route exact path='/'
                component={routeProps =>
                    <ChaiAuthorization {...routeProps} redirectAfterLogin={redirectAfterLogin} />
                }
            />
            <Route exact path='/forgotpassword'
                component={routeProps => <ChaiForgot {...routeProps} />}
            />
            <Route exact path='/recoverpassword/:hash'
                render={routeProps => (
                    <Fragment>
                        <ChaiRecover hash={routeProps.match.params.hash} {...routeProps} />
                    </Fragment>
                )}
            />
        </Fragment>
    );
};

export default withStyles(styles)(ChaiPublicRoutes);
