import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import classNames from 'classnames';

const styles = theme => ({
    disabled: {
        color: '#999 !important',
        cursor: 'default',
        '&:hover': {
            backgroundColor: 'transparent !important'
        }
    },
    link: {
        color: 'rgb(99, 34, 34)',
        display: 'inline-block',
        margin: '2px',
        padding: '4px 10px',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: '#ddd'
        }
    },
    selected: {
        backgroundColor: '#ccc',
        fontWeight: 'bold'
    }
});

const ChaiPaginator = props => {
    const {
        classes,
        currentPage,
        margin,
        nextPageLabel,
        onNextPageClick,
        onPageClick,
        onPreviousPageClick,
        previousPageLabel,
        textAlign,
        totalPages
    } = props;
    return (
        <div style={{ margin, textAlign }}>
            <a
                className={
                    classNames(
                        classes.link,
                        currentPage === 1
                            ? classes.disabled
                            : null)
                }
                href={window.location.href}
                onClick={() => currentPage > 1 && onPreviousPageClick()}
            ><b>‹</b> {previousPageLabel || 'Previous Page'}</a>
            {
                [...Array(totalPages).keys()].map((x, i) => {
                    const page = i + 1;
                    return (
                        <a
                            className={
                                classNames(
                                    classes.link,
                                    page === currentPage
                                        ? classes.selected
                                        : null)
                            }
                            href={window.location.href}
                            onClick={() => onPageClick(page)}
                        >{page}</a>
                    );
                })
            }
            <a
                className={
                    classNames(
                        classes.link,
                        currentPage === totalPages
                            ? classes.disabled
                            : null)
                }
                href={window.location.href}
                onClick={() => currentPage < totalPages && onNextPageClick()}
            >{nextPageLabel || 'Next Page'} <b>›</b></a>
        </div>
    );
};

export default withStyles(styles)(ChaiPaginator);
