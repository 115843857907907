const { dpCrud,  CREATE, GET_ONE } = require('../../DataProviders/crudgeneric');
const { uploadImage } = require('../../DataProviders/crudgeneric');

export const getGlass = async (ledgerNumber, ledgerVersion) => {
    try {
        const data = await dpCrud(GET_ONE, "glasses", {
            ledger: {
                ledgerNumber: ledgerNumber,
                ledgerVersion: ledgerVersion
            }
        });
        return data.data;
    } catch (ex) {
        console.error("ERROR getGlass", ex);
    }
    return null;
};

export const saveGlass = async (ledgerNumber, ledgerVersion, data) => {
    const response = await dpCrud(CREATE, "glasses", {
        data: prepareToSend(data, ledgerNumber, ledgerVersion)
    });
    return response;
};

export const saveImage = async (image) => {
    const result = await uploadImage(image);
    return result;
}

function prepareToSend(values, ledgerNumber, ledgerVersion) {
    values.ledgerNumber = ledgerNumber;
    values.ledgerVersion = ledgerVersion;
    return values
}