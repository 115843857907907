import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import { CardContent, CircularProgress } from '@material-ui/core';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiHeaderButton from '../ChaiHeaderButton';

import { getLedgersForCsvExport } from '../ChaiLedger/ledger';
import { exportCsvHeaders } from './service'
import { screenOptions } from '../ChaiUploadSection/service';
import { CSVLink } from "react-csv";

const styles = theme => ({
    card: { marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#efefef' },
    log: {
        width: '100%', marginBottom: 10, paddingBottom: 10, borderBottom: '1px solid #3e3e3e',
        fontFamily: '"Source Sans Code", sans-serif', fontSize: '1em', color: '#3e3e3e'
    }
});

const messages = ['Still processing your data...', 'Working with ledgers...', 'This may take a while...', 'Will finish soon...'];
let interval;

class ChaiLedgerExport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: null,
            message: '',
            downloaded: false,
            downloading: false,
            open: false,
            processed: false
        }
    }

    export = async () => {
        try {
            const { downloaded, processed } = this.state;
            const { isUploading } = this.props;

            if (!downloaded && processed) {
                this.setState({ open: true });
                return;
            };

            isUploading(true);
            this.setState({ uploading: true, message: 'Export process begun...' });

            interval = setInterval(() => {
                const currentMessage = messages[Math.floor(Math.random() * 4) + 1];
                this.setState({ message: currentMessage });
            }, 1 * 5000);

            const ledgers = await getLedgersForCsvExport();

            const data = await this.parseResponse(ledgers);
            this.setState({data, processed: true, uploading: false, message: ''});

            isUploading(false);
            clearInterval(interval);
        } catch (error) {
            this.props.isUploading(false);
            this.setState({
                uploading: false,
                processed: false,
                downloaded: false,
                message: error || 'Export process interrupted due to an error. Please, try again later or contact your Administrator.'
            });
            clearInterval(interval);
        }
    };

    parseResponse = async (ledgers) => {
        if (ledgers && ledgers.length > 0) {
            let options = await screenOptions();

            const parsedData = ledgers.map((ledger) => {
                ledger.typeOfInput = (ledger.typeOfInput && ledger.typeOfInput>0) ? ledger.typeOfInput === 1 ? "Authentic" : "Counterfeit" : "";
                ledger.bottleSize = (ledger.bottleSize && ledger.bottleSize>0) ? options.bottleInformation.bottleSizeOptions.filter( x => x.id === ledger.bottleSize)[0].description || "" : "";
                ledger.wineColor = (ledger.wineColor && ledger.wineColor>0) ? options.bottleInformation.wineColorOptions.filter( x => x.id === ledger.wineColor)[0].description || "" : "";
                ledger.wineType = (ledger.wineType && ledger.wineType>0) ? options.bottleInformation.wineTypeOptions.filter( x => x.id === ledger.wineType)[0].description || "" : "";
                ledger.country = (ledger.country && ledger.country>0) ? options.bottleInformation.countryOptions.filter( x => x.id === ledger.country)[0].description || "" : "";
                ledger.classification = (ledger.classification && ledger.classification>0) ? options.bottleInformation.classificationOptions.filter( x => x.id === ledger.classification)[0].description || "" : "";
                ledger.bottledByAt = (ledger.bottledByAt && ledger.bottledByAt>0) ? options.bottleInformation.bottledByAtOptions.filter( x => x.id === ledger.bottledByAt)[0].description || "" : "";

                // Booleans
                ledger.isVintageLegible = ledger.isVintageLegible === false ? "false" : "true";
                ledger.puntAirVent = ledger.puntAirVent === false ? "false" : "true";
                ledger.producerBackLabel = ledger.producerBackLabel === false ? "false" : "true";
                ledger.healthWarningLabel = ledger.healthWarningLabel === false ? "false" : "true";

                ledger.fillLevel = (ledger.fillLevel && ledger.fillLevel>0) ? ledger.fillLevel === 1 ? "Shouldered Bottle" : "Burgundy or Hock Bottle" : "";
                ledger.labelConditionForAge = (ledger.labelConditionForAge && ledger.labelConditionForAge>0) ? options.bottleData.conditionOptions.filter( x => x.id === ledger.labelConditionForAge)[0].description || "" : "";
                ledger.corkCondition = (ledger.corkCondition && ledger.corkCondition>0) ? options.bottleData.corkConditionOptions.filter( x => x.id === ledger.corkCondition)[0].description || "" : "";
                ledger.capsuleCondition = (ledger.capsuleCondition && ledger.capsuleCondition>0) ? options.bottleData.conditionOptions.filter( x => x.id === ledger.capsuleCondition)[0].description || "" : "";
                ledger.capsuleConditionDetails = (ledger.capsuleConditionDetails && ledger.capsuleConditionDetails.length) ? ledger.capsuleConditionDetails.map( (x) => options.bottleData.otherCapsuleOptions.filter( v => v.id === x)[0].description) || "" : "";
                ledger.sediments = (ledger.sediments && ledger.sediments.length) ? ledger.sediments.map( (x) => options.bottleData.sedimentOptions.filter( v => v.id === x)[0].description) || "" : "";

                ledger.ribbing = (ledger.ribbing && ledger.ribbing != null) ? options.mainLabel.ribbingOptions.filter( x => x.id === ledger.ribbing)[0].description || "" : "";
                ledger.labelType = (ledger.labelType && ledger.labelType != null) ? options.mainLabel.labelTypeOptions.filter( x => x.id === ledger.labelType)[0].description || "" : "";
                ledger.paperFinish = (ledger.paperFinish && ledger.paperFinish != null) ? options.mainLabel.paperFinishOptions.filter( x => x.id === ledger.paperFinish)[0].description || "" : "";
                ledger.paperColor = (ledger.paperColor && ledger.paperColor != null) ? options.mainLabel.paperColorOptions.filter( x => x.id === ledger.paperColor)[0].description || "" : "";
                ledger.labelPrintMethod = (ledger.labelPrintMethod && ledger.labelPrintMethod != null) ? ledger.labelPrintMethod.map( (x) => options.mainLabel.labelPrintMethodOptions.filter( v => v.id === x)[0].description) || "" : "";
                ledger.labelFoilingOption = (ledger.labelFoilingOption && ledger.labelFoilingOption>0) ? (ledger.labelFoilingOption === 1 ? "Gold" : "Silver") : "";
                ledger.inkColor = (ledger.inkColor && ledger.inkColor.length) ? ledger.inkColor.map( (x) => options.mainLabel.inkColorsOptions.filter( v => v.id === x)[0].description) || "" : "";

                ledger.capsuleAppliedBy = (ledger.capsuleAppliedBy && ledger.capsuleAppliedBy>0) ? options.closure.capsuleApplier.filter( x => x.id === ledger.capsuleAppliedBy)[0].description || "" : "";
                ledger.capsuleMaterial = (ledger.capsuleMaterial && ledger.capsuleMaterial>0) ? options.closure.capsuleMaterial.filter( x => x.id === ledger.capsuleMaterial)[0].description || "" : "";
                ledger.capsuleColor = (ledger.capsuleColor && ledger.capsuleColor.length) ? ledger.capsuleColor.map( (x) => options.closure.capsuleColor.filter( v => v.id === x)[0].description) || "" : "";

                ledger.glassColor = (ledger.glassColor && ledger.glassColor>0) ? options.glass.colorOptions.filter( x => x.id === ledger.glassColor)[0].description || "" : "";
                ledger.puntTexture = (ledger.puntTexture && ledger.puntTexture>0) ? options.glass.textureOptions.filter( x => x.id === ledger.puntTexture)[0].description || "" : "";
                ledger.puntBase = (ledger.puntBase && ledger.puntBase>0) ? options.glass.baseOptions.filter( x => x.id === ledger.puntBase)[0].description || "" : "";
                ledger.puntDepth = (ledger.puntDepth && ledger.puntDepth>0) ? options.glass.depthOptions.filter( x => x.id === ledger.puntDepth)[0].description || "" : "";
                ledger.glassProduction = (ledger.glassProduction && ledger.glassProduction>0) ? options.glass.methodOptions.filter( x => x.id === ledger.glassProduction)[0].description || "" : "";
                ledger.kickShape = (ledger.kickShape && ledger.kickShape>0) ? options.glass.shapeOptions.filter( x => x.id === ledger.kickShape)[0].description || "" : "";

                return ledger;
            });

            return parsedData;
        }

        return [];
    };

    render() {
        const { classes } = this.props;
        const { uploading, message, processed, data } = this.state;

        return (
            <Card className={classes.card}>
                <CardContent>
                    <ChaiBodyContainer>
                        <div style={{ display: 'flex', justifyContent: 'unset', alignItems: 'center' }}>
                            <div style={{ width: 40 }} ></div>
                            {
                                uploading ? <CircularProgress /> :
                                    <ChaiHeaderButton
                                        onClick={this.export}
                                        disabled={processed}
                                        label={'EXPORT ALL WINES'}
                                        style={{ marginTop: 20 }} />
                            }
                            {
                                processed && <CSVLink style={{ padding: '10px 20px' }} filename={`CV-AdminAllLedgersExport-${(new Date().getTime())}.csv`} separator={';'} headers={exportCsvHeaders} data={data}>Download File</CSVLink>
                            }
                        </div>
                        <ChaiRowBodyContainer>
                            {message}
                        </ChaiRowBodyContainer>
                    </ChaiBodyContainer>
                </CardContent>
            </Card>
        )
    }
}

ChaiLedgerExport.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiLedgerExport);
