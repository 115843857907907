const logger = require('../helpers/logger.helper').default('LedgerAuthenticatorWineLocationService');
const BaseService = require("./base.service").default;
const config = require('../providers/config.provider').default.getConfig();
const blockchainHelper = require('../helpers/blockchain.helper').default;
const processor = require("../contentProcessors/wineLocation.processor").default;
const ContentValidationError = require("../exceptions/contentValidation.exception").default;
const ContractMethodError = require("../exceptions/contractMethod.exception").default;
const provideGas = require("../providers/wallet.provider").default.provideGas;

class LedgerAuthenticatorWineLocationService extends BaseService {
    async create(content, ledger, wallet) {
        if (processor.isValidContent(ledger, content)) {
            try {
                const overrides = {
                    gasLimit: 3000000
                };
                const gasProvided = await provideGas(wallet);
                if (gasProvided) {
                    const createData = processor.prepareForBlockchain(content, ledger, wallet.address);
                    let contractWithSigner = this.contract.connect(wallet);
                    const receiptTx = await contractWithSigner.createAuthenticator(...Object.values(createData), overrides);
                    logger.info('WINE LOCATION CONTRACT', createData);

                    const receipt = await receiptTx.wait();
                    await logger.infoExternal('createAuthenticator', receipt, 'send', ledger);

                    //const readData = await this.get(ledger);
                    //logger.info('WINE LOCATION CONTRACT READ', readData);

                    return receipt;
                }
            } catch (ex) {
                await logger.errorExternal('createAuthenticator', ex.message, 'send', ledger);
                throw new ContractMethodError('Create Wine Location Authenticator Data', ex);
            }
        } else {
            throw new ContentValidationError('Ledger uthenticator Wine Location Data is invalid');
        }

    }

    async get(ledger, userAddress) {
        try {
            const response = await this.contract.getAuthenticator(ledger.ledgerNumber, ledger.ledgerVersion);
            await logger.infoExternal('getAuthenticator', response, 'call', ledger);
            return response;
        } catch (ex) {
            await logger.errorExternal('getAuthenticator', ex.message, 'call', ledger);
            throw new ContractMethodError('Create Wine Location Authenticator Data', ex);
        }
    }
}

//Mapped contract key
export default (_config = config, _blockchainHelper = blockchainHelper()) => new LedgerAuthenticatorWineLocationService('ChaiAuthenticatorWineLocation', _config, _blockchainHelper);
