const config = require('../providers/config.provider').default.getConfig();
let contracts = [];


const getContract = (contractKey, mainAccount, rpcProvider, ethers) => {
    let contract = contracts.find(c => c.key === contractKey);
    if(!contract){
        const contractConfig = config.contracts.find(c => c.key === contractKey);
        let contractFile = {};
        if(contractConfig) {
            try {
                contractFile = require(`../contracts/${contractKey}.json`);
            } catch(ex){
                throw new Error(`Unable to load contract ${contractKey} in path ../${contractKey}.json`)
            }
            contract = new ethers.Contract(contractConfig.address, contractFile.abi, rpcProvider);
            contracts.push({...contractConfig, contract: contract});
            return contract;
        }
    } else {
        return contract.contract;
    }
}

export default {
    getContract: getContract
}
