import ChaiBaseForm from '../ChaiBaseForm';

const BASE_URL = process.env.REACT_APP_CHAI_SECURITY_API_HOST || 'gateway/';
const baseForm = new ChaiBaseForm({});

export const getLedgerCertificate = async (id, version) => {
    const url = `${BASE_URL}ledgers/${id}/versions/${version}/certificate`;
    const options = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }, method: 'GET'
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (response.status !== 200) {
            throw result;
        }
        return result;
    } catch (error) {
        console.error('ERROR getLedgerCertificate()', error);
        throw error.error ? error.error : error;
    }
};

export const formOptions = async () => {
    return (
        {
            bottleInformation: {
                bottleSize: await getEnumByFormNameFieldName('bottleInformation', 'bottleSize'),
                wineColor: await getEnumByFormNameFieldName('bottleInformation', 'wineColor'),
                wineType: await getEnumByFormNameFieldName('bottleInformation', 'wineType'),
                country: await getEnumByFormNameFieldName('bottleInformation', 'country'),
                classification: await getEnumByFormNameFieldName('bottleInformation', 'classification'),
                bottledByAt: await getEnumByFormNameFieldName('bottleInformation', 'bottledByAt')
            },
            bottleData: {
                otherCapsule: await getEnumByFormNameFieldName('bottleData', 'otherCapsule'),
                condition: await getEnumByFormNameFieldName('bottleData', 'condition'),
                corkCondition: await getEnumByFormNameFieldName('bottleData', 'corkCondition'),
                fillLevel: await getEnumByFormNameFieldName('bottleData', 'fillLevel'),
                burgundy: await getEnumByFormNameFieldName('bottleData', 'burgundy'),
                shouldered: await getEnumByFormNameFieldName('bottleData', 'shouldered')
            },
            authenticator: {
                typeOfStorage: await getEnumByFormNameFieldName('authenticator', 'typeOfStorage')
            }
        }
    );
};

export const getEnumByFormNameFieldName = async (form, field) => {
    const url = `${BASE_URL}enums/${form}/${field}`;
    const options = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }, method: 'GET'
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (response.status !== 200) {
            throw result;
        }
        return result;
    } catch (error) {
        console.error('ERROR getLedgerCertificate()', error);
        throw error.error ? error.error : error;
    }
};
