import ChaiBlockchain from '../Blockchain/index';

const options = {
    blockchainUrl: process.env.REACT_APP_BLOCKCHAIN,
    mainAccountAddress: process.env.REACT_APP_MAIN_BLOCKCHAIN_ACCOUNT,
    contracts: [
        {
            key: "CHVLT",
            address: process.env.REACT_APP_CHAI_TOKEN
        },
        {
            key: "ChaiUsers",
            address: process.env.REACT_APP_CHAI_USERS
        },
        {
            key: "ChaiLedgerAdmin",
            address: process.env.REACT_APP_CHAI_LEDGER_ADMIN
        },
        {
            key: "ChaiBottleInfoAndUnique",
            address: process.env.REACT_APP_CHAI_BOTTLE_INFO_AND_UNIQUE
        },
        {
            key: "ChaiLedgerIDs",
            address: process.env.REACT_APP_CHAI_LEDGER_IDS
        },
        {
            key: "ChaiProvenanceAuctionAndPrivate",
            address: process.env.REACT_APP_CHAI_PROVENANCE_AUCTION
        },
        {
            key: "ChaiProvenanceRetail",
            address: process.env.REACT_APP_CHAI_PROVENANCE_RETAIL
        },
        {
            key: "ChaiAuthenticator",
            address: process.env.REACT_APP_CHAI_AUTHENTICATOR
        },
        {
            key: "ChaiAuthenticatorWineLocation",
            address: process.env.REACT_APP_CHAI_AUTHENTICATOR_WINE_LOCATION
        },
    ]
}

export default ChaiBlockchain(options)