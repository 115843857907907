const { dpCrud,  CREATE, GET_ONE } = require('../../DataProviders/crudgeneric');

export const getClosureCork = async (ledgerNumber, ledgerVersion) => {
    try {
        const data = await dpCrud(GET_ONE, "closure-corks", {
            ledger: {
                ledgerNumber: ledgerNumber,
                ledgerVersion: ledgerVersion
            }
        });
        return data.data;
    } catch (ex) {
        console.error("Error getting bottle>> ", ex);
    }
    return null;
};

export const saveClosureCork = async (ledgerNumber, ledgerVersion, data) => {
    const response = await dpCrud(CREATE, "closure-corks", {
        data: prepareToSend(data, ledgerNumber, ledgerVersion)
    });
    return response;
};

function prepareToSend(values, ledgerNumber, ledgerVersion) {
    const cork = {
        ledgerNumber: ledgerNumber,
        ledgerVersion: ledgerVersion,
        hasCorkBrandMark: values.hasCorkBrandMark,
        vintageLegible: values.vintageLegible,
        corkBrandMarkInfo: {},
        vintageLegibleInfo: {},
        corkType: values.corkType
    };
    if (cork.hasCorkBrandMark) {
        cork.corkBrandMarkInfo.description = values.corkBrandDescription;
        cork.corkBrandMarkInfo.image = values.corkBrandImage;
    }
    if (cork.vintageLegible) {
        cork.vintageLegibleInfo.description = values.vintageLegibleDescription;
        cork.vintageLegibleInfo.image = values.vintageLegibleImage;
    }

    return cork;
}