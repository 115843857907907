import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import ChaiTextField from '../ChaiTextField';
import ChaiBaseForm from '../ChaiBaseForm';
import ChaiSwitch from '../ChaiSwitch';
import ChaiImageInput from '../ChaiImageInput';
import formNames from '../ChaiBaseForm/formNames';

import { getAntifraud, saveAntifraud } from './service';
const styles = theme => ({
    field: {
        display: 'flex',
        height: '66px',
        position: 'relative'
    },
    fieldBody: {
        alignItems: 'center',
        display: 'inline-flex',
        margin: '0 auto'
    }
});

class ChaiAntifraud extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.ANTIFRAUD;
        let emptyDataState = {
            glassEtchingEngraving: false,
            glassEtchingEngravingLocation: null,
            glassEtchingEngravingDescription: null,
            glassEtchingEngravingImage: null,

            invisibleInk: false,
            invisibleInkLocation: null,
            invisibleInkDescription: null,
            invisibleInkImage: null,

            hologram: false,
            hologramLocation: null,
            hologramDescription: null,
            hologramImage: null,

            microWriting: false,
            microWritingLocation: null,
            microWritingDescription: null,
            microWritingImage: null,

            qrCode: false,
            qrCodeLocation: null,
            qrCodeDescription: null,
            qrCodeImage: null,

            producerBarcode: false,
            producerBarcodeLocation: null,
            producerBarcodeDescription: null,
            producerBarcodeImage: null,

            chip: false,
            chipLocation: null,
            chipLocationDescription: null,
            chipImage: null,

            other: false,
            otherLocation: null,
            otherDescription: null,
            otherImage: null

        };

        super({ ...props, formName, emptyDataState, loadTemplate: true});
        this.state = { ...this.state, milocalvar: 'Hola' };
    }

    getElement = async (ledger, version) => {
        return getAntifraud(ledger.ledgerNumber, version).then(result => {
            return result ? result : this.emptyDataState
        });
    };

    saveElement = async (ledger, version, element) => {
        return saveAntifraud(ledger.ledgerNumber, version, element);
    };

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <div className={classNames(classes.line, classes.height4t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch
                                {...this.getField('glassEtchingEngraving', true)}
                                {...this.getTemplateFieldStyle('glassEtchingEngraving')}
                            />
                        </div>
                    </div>
                    <ChaiTextField
                        placeholder='Location'
                        {...this.getField('glassEtchingEngravingLocation')}
                        {...this.getTemplateFieldStyle('glassEtchingEngravingLocation', { width: "30%" })}
                        style={{
                            opacity: this.state.data.glassEtchingEngraving ? '1' : '0',
                            transition: '200ms'
                        }}
                    />
                    <ChaiTextField
                        placeholder='Description'
                        {...this.getField('glassEtchingEngravingDescription')}
                        {...this.getTemplateFieldStyle('glassEtchingEngravingDescription')}
                        style={{
                            opacity: this.state.data.glassEtchingEngraving ? '1' : '0',
                            transition: '200ms'
                        }}
                    />
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <div style={{
                                opacity: this.state.data.glassEtchingEngraving ? '1' : '0',
                                transition: '200ms'
                            }}>
                                <ChaiImageInput
                                    {...this.props}
                                    {...this.getFileField('glassEtchingEngravingImage')}
                                    {...this.getTemplateFieldStyle('glassEtchingEngravingImage')}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classNames(classes.line, classes.height4t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch
                                {...this.getField('qrCode', true)}
                                {...this.getTemplateFieldStyle('qrCode')}
                            />
                        </div>
                    </div>
                    <ChaiTextField
                        placeholder='Location'
                        {...this.getField('qrCodeLocation')}
                        {...this.getTemplateFieldStyle('qrCodeLocation')}
                        style={{
                            opacity: this.state.data.qrCode ? '1' : '0',
                            transition: '200ms'
                        }}
                    />
                    <ChaiTextField
                        placeholder='Description'
                        {...this.getField('qrCodeDescription')}
                        {...this.getTemplateFieldStyle('qrCodeDescription')}
                        style={{
                            opacity: this.state.data.qrCode ? '1' : '0',
                            transition: '200ms'
                        }}
                    />
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <div style={{
                                opacity: this.state.data.qrCode ? '1' : '0',
                                transition: '200ms'
                            }}>
                                <ChaiImageInput
                                    {...this.props}
                                    {...this.getFileField('qrCodeImage')}
                                    {...this.getTemplateFieldStyle('qrCodeImage')}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.height4t}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch
                                {...this.getField('producerBarcode', true)}
                                {...this.getTemplateFieldStyle('producerBarcode')}
                            />
                        </div>
                    </div>
                    <ChaiTextField
                        placeholder='Location'
                        {...this.getField('producerBarcodeLocation')}
                        {...this.getTemplateFieldStyle('producerBarcodeLocation')}
                        style={{
                            opacity: this.state.data.producerBarcode ? '1' : '0',
                            transition: '200ms'
                        }}
                    />
                    <ChaiTextField
                        placeholder='Description'
                        {...this.getField('producerBarcodeDescription')}
                        {...this.getTemplateFieldStyle('producerBarcodeDescription')}
                        style={{
                            opacity: this.state.data.producerBarcode ? '1' : '0',
                            transition: '200ms'
                        }}
                    />
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <div style={{
                                opacity: this.state.data.producerBarcode ? '1' : '0',
                                transition: '200ms'
                            }}>
                                <ChaiImageInput
                                    {...this.props}
                                    {...this.getFileField('producerBarcodeImage')}
                                    {...this.getTemplateFieldStyle('producerBarcodeImage')}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

ChaiAntifraud.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiAntifraud);