import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import { CardContent, CircularProgress } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DownloadIcon from '@material-ui/icons/FileDownload';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiFileInput from '../ChaiFileInput';
import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiBranding from '../ChaiBranding';
import ChaiTextField from '../ChaiTextField';

import { validateMassUpload, massUpload } from './service';
import { download } from '../../Helpers/downloader';

import httpStatus from 'http-status-codes';
import md from 'markdown';

const styles = theme => ({
    card: { marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#efefef' },
    log: {
        width: '100%', marginBottom: 10, paddingBottom: 10, borderBottom: '1px solid #3e3e3e',
        fontFamily: '"Source Sans Code", sans-serif', fontSize: '1em', color: '#3e3e3e'
    }
});

const messages = ['Still processing your data...', 'Working with ledgers...', 'This may take a while...', 'Will finish soon...'];
let interval;

class ChaiMassUpload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            csvFile: null,
            fileName: null,
            message: '',
            uploading: false,
            logs: null,
            open: false,
            downloaded: false,
            processed: false,
            ledgersList: '',
            downloadedList: false,
            confirmUploadWithoutBrandingOpen: false,
            proceedWithoutBranding: false,
            withoutBrandingConfirmationText: "",
            withoutBrandingErrorMessage: "",
            showBrandingModal: false
        }
    }

    onFileSelect = (e, field) => {
        var file = e.target.files[0];

        this.setState({ csvFile: file, fileName: file.name });
    }

    getFileField = (field) => {
        const { uploading, fileName } = this.state;

        return {
            field,
            label: 'MASS UPLOAD FILE',
            acceptedExtensions: '.csv',
            onChange: this.onFileSelect,
            fileName: fileName,
            disabled: uploading
        }
    };

    validate = async () => {
        try {
            const { csvFile, downloaded, processed } = this.state;
            const { isUploading } = this.props;

            if (!downloaded && processed) {
                this.setState({ open: true });
                return;
            };

            isUploading(true);
            this.setState({ logs: null, uploading: true, message: 'Validation process begun...' });

            interval = setInterval(() => {
                const currentMessage = messages[Math.floor(Math.random() * 4) + 1];
                this.setState({ message: currentMessage });
            }, 1 * 5000);

            const logUploadProcess = await validateMassUpload(csvFile);
            clearInterval(interval);

            if (logUploadProcess.error) {
                throw logUploadProcess.errorMessage;
            }

            const logs = logUploadProcess.logs;
            const lastLine = logs.pop();
            const finalMessage = `Validation complete. ${logUploadProcess.totalLines - lastLine.error} processed successfuly, ${lastLine.error} errors`;

            isUploading(false);
            this.setState({ uploading: false, message: finalMessage, logs: logUploadProcess.logs, processed: true, downloaded: false });
        } catch (error) {
            this.props.isUploading(false);
            this.setState({
                uploading: false,
                processed: false,
                downloaded: false,
                message: error || 'Validation process interrumped due to an error. Please, try again later or contact your Administrator.'
            });
            clearInterval(interval);
        }
    };

    upload = async () => {
        try {
            const { csvFile, proceedWithoutBranding } = this.state;
            const { isUploading } = this.props;

            isUploading(true);
            this.setState({ logs: null, uploading: true, message: 'Upload process begun...' });

            interval = setInterval(() => {
                const currentMessage = messages[Math.floor(Math.random() * 4) + 1];
                this.setState({ message: currentMessage });
            }, 1 * 5000);

            const logUploadProcess = await massUpload(csvFile, proceedWithoutBranding);
            clearInterval(interval);

            if (logUploadProcess.error || logUploadProcess.isError) {
                throw logUploadProcess.errorMessage || logUploadProcess;
            }

            const logs = logUploadProcess.logs;
            const lastLine = logs.pop();
            const finalMessage = `Upload complete. ${logUploadProcess.totalLines - lastLine.error} processed successfuly, ${lastLine.error} errors`;

            isUploading(false);
            this.setState({ uploading: false, message: finalMessage, logs: logUploadProcess.logs, processed: true, downloaded: true, ledgersList: lastLine.lineContent });
        } catch (error) {
            const errorMessage = error && error.content ? error.content.message : error;
            if (error &&
                error.stack &&
                error.stack.statusCode === httpStatus.PRECONDITION_REQUIRED
            ) {
                this.setState({ withoutBrandingErrorMessage: errorMessage });
                this.openConfirmUploadWithoutBrandingModal();
            }
            this.props.isUploading(false);
            this.setState({
                uploading: false,
                processed: false,
                downloaded: false,
                message: errorMessage || 'Upload process interrupted due to an error. Please, try again later or contact your Administrator.'
            });
            clearInterval(interval);
        }
    };

    downloadLogs = (e) => {
        e.preventDefault()

        const { logs } = this.state;

        const logsInLine = logs.reduce((previous, log) => {
            let detailLog = 'Line ' + log.lineNumber + ': ' + this.beautifyErrorMessage(log.error) + '\r\n';
            detailLog += 'Content: ' + this.beautifyJsonToString(log.lineContent) + '\r\n\n';
            detailLog += '------------------------------------------ \r\n\n';

            return previous + detailLog;
        }, '');

        download('data:text/plain,' + encodeURIComponent(logsInLine), `mass_upload_log_${(new Date().getTime())}.txt`);

        this.setState({ downloaded: true });
    };

    downloadLedgersList = (e) => {
        e.preventDefault()

        const { ledgersList } = this.state;
        download('data:text/plain,' + encodeURIComponent(ledgersList), `mass_upload_ledgers_list_${(new Date().getTime())}.csv`);
        this.setState({ downloadedList: true });
    };

    downloadTemplate = () => {
        download('Authenticator Entry Template.xlsx', 'Authenticator Entry Template.xlsx');
    };

    beautifyJsonToString = (json) => {
        return JSON.stringify(json)
            .replace(/\\/gi, '')
            .replace("\"{", '')
            .replace("}\"", '');
    };

    beautifyErrorMessage = (logError) => {
        if (logError.indexOf(',') >= 0) {
            return logError.substr(0, logError.length - 2)
        }

        return logError;
    };

    openModal = () => {
        this.setState({ open: true });
    };

    closeModal = () => {
        this.setState({ open: false, processed: true, downloaded: false });
    };

    handleEraseLogs = () => {
        this.setState({
            open: false,
            processed: false,
            downloaded: false,
            logs: null,
            uploading: true,
            message: ''
        });

        setTimeout(() => {
            this.validate();
        }, 1000);
    };

    openConfirmUploadWithoutBrandingModal = () => {
        this.setState({ confirmUploadWithoutBrandingOpen: true });
    };

    closeConfirmUploadWithoutBrandingModal = () => {
        this.setState({
            confirmUploadWithoutBrandingOpen: false,
            withoutBrandingConfirmationText: ""
        });
    };

    addBrandingConfirmUploadWithoutBrandingModal = () => {
        this.setState({ showBrandingModal: true });
        this.closeConfirmUploadWithoutBrandingModal();
    };

    proceedConfirmUploadWithoutBrandingModal = async () => {
        await this.setState({ proceedWithoutBranding: true });
        this.closeConfirmUploadWithoutBrandingModal();
        await this.upload();
        this.setState({ proceedWithoutBranding: false });
    };

    renderConfirmUploadWithoutBrandingModal = () => {
        const {
            confirmUploadWithoutBrandingOpen,
            showBrandingModal,
            withoutBrandingConfirmationText,
            withoutBrandingErrorMessage
        } = this.state;
        const disableProceedAnywayButton =
            withoutBrandingConfirmationText.toUpperCase() !== "PROCEED";
        return (
            <div>
            <ChaiBranding handleComplete={ () => { } }
                    handleIncomplete={ () => { } }
                    modal={ showBrandingModal }
                    onClose={ () => this.setState({ showBrandingModal: false }) }
                    skipStepper={ true }
            />
            <Dialog open={confirmUploadWithoutBrandingOpen}
                    onClose={this.closeConfirmUploadWithoutBrandingModal}>
                <DialogContent>
                    <DialogContentText style={{ color: '#000', cursor: 'default' }}>
                        <div style={ { textAlign: 'center' } } dangerouslySetInnerHTML={ {
                            __html: md.markdown.toHTML(withoutBrandingErrorMessage)
                        } }></div>
                        <p style={ { textAlign: 'justify' } }>
                            Do you want continue without the branding information?
                            To add your <b> branding </b> or <b> capsule </b> information click on
                            <b> GO TO BRANDING</b>.
                        </p>
                        <p style={ { textAlign: 'justify' } }>
                            To import the ledgers without the branding/capsule information write
                            in the field below:
                        </p>
                        <p style={ { color: '#632222', textAlign: 'center' } }>
                            <b> PROCEED </b>
                        </p>
                    </DialogContentText>
                    <ChaiTextField
                        autofocus
                        margin="dense"
                        id="withoutBrandingConfirmationText"
                        keyField="withoutBrandingConfirmationText"
                        label="Confirmation in writting"
                        type="text"
                        fullWidth
                        inputProps={ { style: { textTransform: 'uppercase' } } }
                        value={ withoutBrandingConfirmationText }
                        onChange={ e => this.setState({
                            [e.target.id]: e.target.value.toUpperCase()
                        }) }
                    />
                </DialogContent>
                <DialogActions>
                    <ChaiHeaderButton
                        onClick={this.closeConfirmUploadWithoutBrandingModal}
                        color="secondary"
                        label="Cancel"
                    />
                    <ChaiHeaderButton
                        onClick={this.addBrandingConfirmUploadWithoutBrandingModal}
                        color="primary"
                        label="Go to branding"
                        style={ { marginLeft: '10px' } }
                        variant="outlined"
                    />
                    <ChaiHeaderButton
                        onClick={this.proceedConfirmUploadWithoutBrandingModal}
                        color="primary"
                        disabled={ disableProceedAnywayButton }
                        label="Proceed Anyway"
                        style={ { marginLeft: '10px' } }
                    />
                </DialogActions>
            </Dialog>
            </div>
        );
    };

    renderModal = () => {
        const { open } = this.state;
        const { classes } = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.closeModal}
                classes={{
                    paper: classes.dialogRoot
                }}
            >
                <DialogContent>
                    {'Logs generated will be lost with a new Validation process. \r\nDo you want to continue?'}
                </DialogContent>
                <DialogActions style={{ margin: 0, padding: '0px 20px 20px 0px' }}>
                    <ChaiHeaderButton label="OK" onClick={this.handleEraseLogs} />
                    <div style={{ width: 30 }}></div>
                    <ChaiHeaderButton label="Cancel" onClick={this.closeModal} />
                </DialogActions>
            </Dialog>
        )
    };

    render() {
        const { classes } = this.props;
        const { uploading, message, logs, fileName, ledgersList } = this.state;
        const templateButton = <div style={{ display: 'flex', alignItems: 'center' }}>
            <DownloadIcon style={{ marginRight: 10 }} />{'TEMPLATE'}
        </div>;

        return (
            <Card className={classes.card}>
                <CardContent>
                    <ChaiBodyContainer>
                        <div className={classes.nullPointer}>
                            {this.renderModal()}
                        </div>
                        <div className={classes.nullPointer}>
                            {this.renderConfirmUploadWithoutBrandingModal()}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'unset', alignItems: 'center' }}>
                            <ChaiFileInput {...this.getFileField('csvFile')} />
                            <div style={{ width: 30 }} ></div>
                            <ChaiHeaderButton
                                onClick={this.upload}
                                disabled={fileName === null}
                                label={'UPLOAD'}
                                style={{ marginTop: 20 }} />
                            <div style={{ width: 30 }} ></div>
                            {
                                uploading ? <CircularProgress /> :
                                    <ChaiHeaderButton
                                        onClick={this.validate}
                                        disabled={fileName === null}
                                        label={'VALIDATE'}
                                        style={{ marginTop: 20 }} />
                            }
                            <div style={{ width: 30 }} ></div>
                            <ChaiHeaderButton
                                onClick={this.downloadTemplate}
                                disabled={uploading}
                                label={templateButton}
                                style={{ marginTop: 20 }}
                            />
                        </div>
                        <ChaiRowBodyContainer>
                            NOTE: Please remove the initial row (column count) from the template file and export it as a CSV before uploading it.
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            {message}
                        </ChaiRowBodyContainer>
                        {
                            logs && logs.length ?
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="subheading">
                                            {'Logs'}
                                        </Typography>
                                        <DownloadIcon style={{ marginLeft: 20, color: '#3e3e3e' }} onClick={(e) => this.downloadLogs(e)} />
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{ display: 'grid' }}>
                                        {
                                            logs.map(log => {
                                                return (
                                                    <div className={classes.log}>
                                                        <strong>{`Line ${log.lineNumber}: `}</strong>{this.beautifyErrorMessage(log.error)}
                                                        <br />
                                                        <strong>{`Content: `}</strong> {this.beautifyJsonToString(log.lineContent)}
                                                    </div>
                                                );
                                            })
                                        }
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                : null
                        }
                        {
                            ledgersList && ledgersList.length &&
                                <Fragment>
                                    <ChaiHeaderButton
                                        onClick={(e) => this.downloadLedgersList(e)}
                                        disabled={fileName === null}
                                        label={'DOWNLOAD LEDGERS LIST'}
                                        style={{ marginTop: 20 }}
                                    />
                                </Fragment>
                        }
                    </ChaiBodyContainer>
                </CardContent>
            </Card>
        )
    }
}

ChaiMassUpload.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiMassUpload);
