import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import ChaiTextField from '../ChaiTextField';
import ChaiDropDown from '../ChaiDropDown';
import ChaiDropDownMultiple from '../ChaiDropDownMultiple';
import ChaiBaseForm from '../ChaiBaseForm';
import ChaiSwitch from "../ChaiSwitch";
import ChaiImageInput from '../ChaiImageInput';
import { getBottleData, saveBottleData } from './service';
import formNames from '../ChaiBaseForm/formNames';

import LinearProgress from '@material-ui/core/LinearProgress';
import ChaiRadioButton from '../ChaiRadioButton';

const fieldHeight = 66;

const styles = theme => ({
    field: {
        display: 'flex',
        height: `${fieldHeight}px`,
        position: 'relative'
    },
    fieldBody: {
        alignItems: 'center',
        display: 'inline-flex',
        margin: '0 auto'
    },
    height1t: {
        height: `${fieldHeight}px`
    },
    height2t: {
        height: `${fieldHeight * 2}px`
    },
    height3t: {
        height: `${fieldHeight * 3}px`
    },
    height4t: {
        height: `${fieldHeight * 4}px`
    },
    line: {
        borderBottom: '1px solid #9c9c9c'
    },
    radio: {
        fontFamily: '"Source Sans Code", sans-serif',
        fontSize: '0.95em',
        color: '#3f3e3e'
    },
    radioContainer: {
        width: '32%',
        display: 'flex',
        padding: '20px',
        alignItems: 'center'
    }
});

class ChaiBottleData extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.BOTTLEDATA;
        let emptyDataState = {
            hasSerialNumber: false,
            serialNumber: null,
            serialNumberLocation: null,
            hasLotNumber: false,
            isBottleImageFront: false,
            fullBottleImage: '',
            lotNumber: null,
            lotNumberLocation: null,
            partOfGroup: false,
            caseLotID: null,
            labelConditionForAge: 1,
            notableNicksTearsConditions: false,
            notableNicksLocation: null,
            notableNicksDescription: null,
            notableNicksImage: null,
            otherLabelsConditions: false,
            otherLabelsConditionsDescription: null,
            capsuleCondition: 1,
            capsuleConditionDetails: [],
            capsuleOther: false,
            capsuleOtherDescription: null,
            corkCondition: 1,
            isVintageLegible: false,
            fillLevel: 0,
            shoulderedOption: 0,
            burgundyOption: 0,
            glassMarkings: false,
            glassMarkingsDescription: null,
            glassMarkingsImage: null,
            puntAirVent: true,
            sediments: []
        };

        super({ ...props, formName, emptyDataState });
        this.state = { ...this.state, milocalvar: 'Hola'};
    }

    getElement = async (ledger, version) => {
        await this.getOptionsWithFormName(formNames.BOTTLEDATA);
        return getBottleData(ledger.ledgerNumber, 0)
            .then(result => {
                return result ? result.data : this.emptyDataState
            });

    };

    saveElement = async (ledger, version, element) => {
        return saveBottleData(ledger.ledgerNumber, version, element);
    };

   

    handleFullBottleImage = event => {
        console.info('handleFullBottleImage', event.target.value);
        let data = { ...this.state.data };
        data.isBottleImageFront = event.target.value === 'front';
        console.info('handleFullBottleImage DATA', data);
        this.setState({ data });
    };

    render() {
        const formName = formNames.BOTTLEDATA;
        const footerData = {
            message: 'Please be sure to fill out the entire form before moving on.',
            buttonLabel: 'Save & Next',
            loading: this.state.loading
        };

        const { classes, hideFooter } = this.props;

        const isTablet = window.innerWidth <= 1024;

        return (
            <Fragment>
                {this.state.loading && <LinearProgress />}
                <div className={classNames(classes.line, classes.height2t)}>
                    <ChaiDropDown
                        {...this.getField('fillLevel')}
                        items={this.state.options[formName]['fillLevel'] || []}
                    />
                    {
                        this.state.data.fillLevel === 1
                            ? (<ChaiDropDown
                                {...this.getField('shoulderedOption')}
                                items={this.state.options[formName]['shouldered'] || []}
                            />)
                            : this.state.data.fillLevel === 2
                                ? (<ChaiDropDown
                                    {...this.getField('burgundyOption')}
                                    items={this.state.options[formName]['burgundy'] || []}
                                />)
                                : <div className={classes.field}>
                                    <div className={classes.fieldBody}>&nbsp;</div>
                                </div>
                    }
                </div>
                <div className={classNames(classes.line, classes.height1t)}>
                    <ChaiDropDown
                        {...this.getField('labelConditionForAge')}
                        items={this.state.options[formName]['condition'] || []}
                    />
                </div>
                <div className={classNames(classes.line, classes.height1t)}>
                    <ChaiDropDown
                        {...this.getField('corkCondition')}
                        items={this.state.options[formName]['corkCondition'] || []}
                    />
                </div>
                <div className={classNames(classes.line, classes.height4t)}>
                    <ChaiDropDown
                        {...this.getField('capsuleCondition')}
                        items={this.state.options[formName]['condition'] || []}
                    />
                    { // Added Missing option to exclussion
                        <ChaiDropDownMultiple
                            {...this.getMultipleDropDownField('capsuleConditionDetails')}
                            placeholder="DETAILS"
                            style={{
                                opacity: this.state.data.capsuleCondition !== 1 &&
                                    this.state.data.capsuleCondition !== 8 ? '1' : '0',
                                transition: '200ms'
                            }}
                            items={this.state.options[formName]['otherCapsule'] || []}
                        />
                    }
                </div>

                <div className={classNames(classes.line, classes.height1t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch
                                {...this.getField('isVintageLegible', true)}
                            />
                        </div>
                    </div>
                </div>

                <div className={classNames(classes.line, classes.height3t)}>
                    <ChaiDropDownMultiple
                        {...this.getMultipleDropDownField('sediments')}
                        items={this.state.options[formName]['sediment'] || []}
                    />
                </div>

                <div className={classNames(classes.line, classes.height2t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiImageInput
                                {...this.props}
                                {...this.getFileField('fullBottleImageFront')}
                            />
                        </div>
                    </div>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiImageInput
                                {...this.props}
                                {...this.getFileField('fullBottleImageBack')}
                            />
                        </div>
                    </div>
                </div>

                <div className={classNames(classes.line, classes.height3t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch {...this.getField('hasSerialNumber', true)} />
                        </div>
                    </div>
                    <ChaiTextField
                        {...this.getField('serialNumber')}
                        style={{
                            opacity: this.state.data.hasSerialNumber ? '1' : '0',
                            transition: '200ms'
                        }}
                        placeholder="Serial number"
                    />
                    <ChaiTextField
                        {...this.getField('serialNumberLocation')}
                        style={{
                            opacity: this.state.data.hasSerialNumber ? '1' : '0',
                            transition: '200ms'
                        }}
                        placeholder="Location"
                    />
                </div>

                <div className={classNames(classes.line, classes.height3t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch {...this.getField('hasLotNumber', true)} />
                        </div>
                    </div>
                    <ChaiTextField
                        {...this.getField('lotNumber')}
                        style={{
                            opacity: this.state.data.hasLotNumber ? '1' : '0',
                            transition: '200ms'
                        }}
                        placeholder="Lot number"
                    />
                    <ChaiTextField
                        {...this.getField('lotNumberLocation')}
                        style={{
                            opacity: this.state.data.hasLotNumber ? '1' : '0',
                            transition: '200ms'
                        }}
                        placeholder="Location"
                    />
                </div>

                <div className={classNames(classes.line, classes.height4t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch
                                {...this.getField('notableNicksTearsConditions', true)}
                            />
                        </div>
                    </div>
                    <ChaiTextField
                        {...this.getField('notableNicksLocation')}
                        style={{
                            opacity: this.state.data.notableNicksTearsConditions ? '1' : '0',
                            transition: '200ms'
                        }}
                        placeholder="Location"
                    />
                    <ChaiTextField
                        {...this.getField('notableNicksDescription')}
                        style={{
                            opacity: this.state.data.notableNicksTearsConditions ? '1' : '0',
                            transition: '200ms'
                        }}
                        placeholder="Description"
                    />
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <div style={{
                                opacity: this.state.data.notableNicksTearsConditions ? '1' : '0',
                                transition: '200ms'
                            }}>
                                <ChaiImageInput
                                    {...this.props}
                                    {...this.getFileField('notableNicksImage')}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classNames(classes.line, classes.height2t)}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch
                                {...this.getField('otherLabelsConditions', true)}
                            />
                        </div>
                    </div>
                    <ChaiTextField
                        {...this.getField('otherLabelsConditionsDescription')}
                        style={{
                            opacity: this.state.data.otherLabelsConditions ? '1' : '0',
                            transition: '200ms'
                        }}
                        placeholder="Description"
                    />
                </div>

                <div className={classes.height2t}>
                    <div className={classes.field}>
                        <div className={classes.fieldBody}>
                            <ChaiSwitch {...this.getField('capsuleOther', true)} />
                        </div>
                    </div>
                    <ChaiTextField
                        {...this.getField('capsuleOtherDescription')}
                        style={{
                            opacity: this.state.data.capsuleOther ? '1' : '0',
                            transition: '200ms'
                        }}
                        placeholder="Description"
                    />
                    
                </div>
            </Fragment>
        );
    }
}

ChaiBottleData.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiBottleData);
