import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChaiTextField from '../ChaiTextField';
import Typography from '@material-ui/core/Typography';
import ChaiDropDown from '../ChaiDropDown';
import ChaiSwitch from '../ChaiSwitch';
import ChaiImageInput from '../ChaiImageInput';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBaseForm from '../ChaiBaseForm';
import ChaiFooterContainer from "../ChaiFooterContainer";
import { getClosureCapsule, saveClosureCapsule, prepareToSend } from './closureCapsule.js';
import ChaiDropDownMultiple from '../ChaiDropDownMultiple';
import formNames from '../ChaiBaseForm/formNames';
import chaiErrorHandler from '../../Handler/chaiErrorHandler';

const styles = theme => ({
    subtitle: {
        fontSize: '25px',
    }
});

class CapsuleForm extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.CLOSURECAPSULE;
        let emptyDataState = {
            closureCapsule: true,
            capsuleAppliedBy: 2,
            capsuleSideImage: null,
            capsuleMaterial: 10,
            capsuleTopImage: null,
            capsuleColor: [],
            capsuleColorDescription: '',
            capsuleArtSide: false,
            capsuleArtSideDescription: null,
            capsuleArtTop: false,
            capsuleArtTopDescription: null,
            capsuleOther: false,
            capsuleOtherDescription: null,
            puntEmbossing: false,
            puntEmbossingDescription: null,
        };
        super({ ...props, formName, emptyDataState, loadTemplate: true });
        this.state = { 
            ...this.state,
            prepareToSendFunc: prepareToSend,
            softLoad: true,
        };

        const { addSetTemplateFunction } = props;
        addSetTemplateFunction(this.state.setTemplate);
    }

    getElement = async (ledger, version) => {
        await this.getOptionsWithFormName(formNames.CLOSURECAPSULE);
        return getClosureCapsule(ledger.ledgerNumber, version)
            .then(result => {
                return result ? result : this.emptyDataState
            });

    };

    saveElement = async (ledger, version, element) => {
        let result = await saveClosureCapsule(ledger.ledgerNumber, version, element);
        return result;
    };

    /**
     * @override save method
     *
     * This was done to control the selection.
     * Reimplementing error handling
     * */
    save = async () => {
        try {
            this.setLoadingState(true);
            await this.saveElement(this.props.currentLedger, 0, this.state.data);
            this.setLoadingState(false);
        } catch (error) {
            this.setLoadingState(false);
            chaiErrorHandler(this.notify).catch(error);
        }
    };

    render() {
        const formName = formNames.CLOSURECAPSULE;
        const footerData = {
            message: 'Please be sure to fill out the entire form before moving on.',
            buttonLabel: 'Save & Next',
            loading: this.state.loading
        };
        const { classes, hideFooter } = this.props;
        const otherCapsuleColor = this.state.data.capsuleColor.indexOf(19) > -1;
        return (
            <Fragment>
                <ChaiBodyContainer>
                <Typography className={classes.subtitle}>Capsule:</Typography>
                    <ChaiRowBodyContainer>
                        <ChaiDropDown
                            {...this.getField('capsuleAppliedBy')}
                            label="Capsule applied By*"
                            items={this.state.options[formName]['capsuleApplier'] || []}
                            {...this.getTemplateFieldStyle('capsuleAppliedBy',{ width: '20%'})}
                        />
                        {
                            this.state.data.capsuleAppliedBy != null && (
                                <Fragment>
                                    <ChaiImageInput
                                        label="Capsule Side"
                                        {...this.getFileField('capsuleSideImage')}
                                        {...this.getTemplateFieldStyle('capsuleSideImage',{})}
                                    />
                                </Fragment>
                            )
                        }
                        <div style={{ width: '5%' }}></div>
                        <ChaiDropDown
                            {...this.getField('capsuleMaterial')}
                            label="Capsule Material*"
                            items={this.state.options[formName]['capsuleMaterial'] || []}
                            {...this.getTemplateFieldStyle('capsuleMaterial',{ width: '20%'})}
                        />
                        {
                            this.state.data.capsuleMaterial != null && (
                                <Fragment>
                                    <ChaiImageInput
                                        label="Capsule Top"
                                        {...this.getFileField('capsuleTopImage')}
                                        {...this.getTemplateFieldStyle('capsuleTopImage',{ })}
                                    />
                                </Fragment>
                            )
                        }
                        <div style={{ width: '5%' }}></div>
                    </ChaiRowBodyContainer>
                    <ChaiRowBodyContainer>
                        <ChaiDropDownMultiple
                            {...this.getMultipleDropDownField('capsuleColor')}
                            label="Capsule Color*"
                            items={this.state.options[formName]['capsuleColor'] || []}
                            {...this.getTemplateFieldStyle('capsuleColor',{ width: '50%'})}
                        />
                        { otherCapsuleColor ? 
                                <ChaiTextField
                                {...this.getField('capsuleColorDescription')}
                                label="Description"
                                {...this.getTemplateFieldStyle('capsuleColorDescription',{ width: '50%'})}
                                /> : <div style={{ width: '50%' }}></div>
                        }
                    </ChaiRowBodyContainer>
                    <ChaiRowBodyContainer>
                        <ChaiSwitch
                            {...this.getField('capsuleArtSide', true)}
                            label="Art Side"
                            {...this.getTemplateFieldStyle('capsuleArtSide',{ width: '20%'})}

                        />
                        {
                            this.state.data.capsuleArtSide ? (
                                <Fragment>
                                    <ChaiTextField
                                        {...this.getField('capsuleArtSideDescription')}
                                        label="Art Side Description"
                                        {...this.getTemplateFieldStyle('capsuleArtSideDescription',{ width: '25%'})}

                                    />
                                </Fragment>
                            ) : <div style={{ width: '25%' }}></div>
                        }
                        <div style={{ width: '5%' }}></div>
                        <ChaiSwitch
                            {...this.getField('capsuleArtTop', true)}
                            label="Art Top"
                            {...this.getTemplateFieldStyle('capsuleArtTop',{ width: '20%'})}
                        />
                        {
                            this.state.data.capsuleArtTop ? (
                                    <Fragment>
                                        <ChaiTextField
                                            {...this.getField('capsuleArtTopDescription')}
                                            label="Art Top Description"
                                            {...this.getTemplateFieldStyle('capsuleArtTopDescription',{ width: '30%'})}

                                        />
                                    </Fragment>
                                ) :
                                <div style={{ width: '30%' }}></div>
                        }
                    </ChaiRowBodyContainer>
                    <ChaiRowBodyContainer>
                        <ChaiSwitch
                            {...this.getField('capsuleOther', true)}
                            label="Capsule Other"
                            {...this.getTemplateFieldStyle('capsuleOther',{ width: '20%'})}
                        />

                        {
                            this.state.data.capsuleOther && (
                                <Fragment>
                                    <ChaiTextField
                                        {...this.getField('capsuleOtherDescription')}
                                        label="Capsule Other Description"
                                        {...this.getTemplateFieldStyle('capsuleOtherDescription',{ width: '25%'})}
                                    />
                                </Fragment>
                            )
                        }
                        <div style={{ width: '55%' }}></div>
                    </ChaiRowBodyContainer>
                    <ChaiRowBodyContainer>
                        <ChaiSwitch
                            label="Punt Markings/Embossing*"
                            {...this.getField('puntEmbossing', true)}
                            {...this.getTemplateFieldStyle('puntEmbossing',{ width: '25%'})}
                        />
                        {
                            this.state.data.puntEmbossing && (
                                <Fragment>
                                    <ChaiTextField
                                        {...this.getField('puntEmbossingDescription')}
                                        label="DESCRIPTION*"
                                        {...this.getTemplateFieldStyle('puntEmbossingDescription',{ width: '75%'})}
                                    />
                                </Fragment>
                            )
                        }
                    </ChaiRowBodyContainer>
                </ChaiBodyContainer>
            </Fragment>

        );
    }
}

CapsuleForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CapsuleForm);
